var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "welcometext" },
    [
      _vm.vueAppClass === "uuva"
        ? [
            _c("CardTitle", { attrs: { text: "Tervetuloa" } }),
            _c("p", { staticClass: "subtitle-1" }, [
              _vm._v(" Tervetuloa käyttämään Uuva Cloudia. "),
            ]),
            _c("p", [
              _vm.oikeusAnittaOnlineen
                ? _c("span", [
                    _vm._v(
                      "Uuva Cloudilla hoidat jatkossa kaiken saman, ja paljon enemmän, kuin vanhalla Uuva Onlinella. "
                    ),
                  ])
                : _vm._e(),
              _vm._v(
                "Uuva Cloud on käyttäjälähtöisesti kehitetty – pyrimme täyttämään haastavimmatkin tarpeesi laskun elinkaaren hallintaan liittyen. "
              ),
            ]),
            _c("p", [
              _vm._v(" Kuulisimme mielellämme, mitä mieltä olet. "),
              _c(
                "a",
                { attrs: { href: _vm.palautelomakelinkki, target: "_blank" } },
                [_vm._v("Voit antaa meille palautetta")]
              ),
              _vm._v(" ja näin auttaa palvelun kehittämisessä. "),
            ]),
            _vm.anittaOnlineLinkki && _vm.oikeusAnittaOnlineen
              ? _c("p", [
                  _vm._v(" Siirry vanhaan "),
                  _c("a", { attrs: { href: _vm.anittaOnlineLinkki } }, [
                    _vm._v("Uuva Onlineen"),
                  ]),
                  _vm._v(". "),
                ])
              : _vm._e(),
          ]
        : _c("h1", { staticClass: "mb-6" }, [
            _vm._v(
              " Tervetuloa käyttämään " +
                _vm._s(_vm.instanceTitle) +
                " -palvelua. "
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }