<template>
  <v-tabs
    hide-slider
    :centered="center"
  >
    <v-tab
      v-if="text"
      disabled
      :class="['cardTitle', color]"
    >
      {{ text }}
      <TooltipInfo
        v-if="tooltipInfoTeksti"
        icon-color="primary"
        :text="tooltipInfoTeksti"
      />
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'CardTitle',
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    tooltipInfoTeksti: {
      type: String,
      required: false,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: 'primary--text',
    },
    center: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.cardTitle {
  //display: block;
  //position: absolute;
  //top: 0;
  padding: 0em 1em; //Ennen 0.3em 1em
  font-size: 0.875rem; // Mukailee v-tabsin tyyliä
  letter-spacing: 0.0892857143em; // Mukailee v-tabsin tyyliä
  text-transform: uppercase;
  background: #fff;
  //transform: translateY(-50%);
  //box-shadow: 0px -3px 1px -2px rgba(0, 0, 0, 0.2),
  //  0px -2px 2px 0px rgba(0, 0, 0, 0.14);
}
</style>
