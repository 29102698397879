<template>
  <v-toolbar
    class="sticky"
    color="primary"
    flat
    tabs
    pl-6
  >
    <v-btn
      :dark="true"
      icon
      @click="palaaEdelliselleSivulle"
    >
      <v-icon>arrow_back</v-icon>
    </v-btn>
    <v-toolbar-title v-if="title">
      {{ title }}
    </v-toolbar-title>
    <v-spacer />
    <DenseToggleButton
      v-if="denseToggleButton"
      class="mr-2"
      bg-color="secondary"
      color="primary"
    />
    <slot />
  </v-toolbar>
</template>

<script>

export default {
  name: 'ViewTopBarBase',
  components: {

  },
  props: {
    backButtonRoute: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    denseToggleButton: {
      type: Boolean,
      required: false,
      default () {
        return true
      },
    },
    title: {
      type: String,
      required: false,
      default () {
        return this.$route.meta.title
      },
    }
  },
  methods: {
    palaaEdelliselleSivulle () {
      this.$router.go(-1)
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
