import Vue from 'vue'
import Dinero from 'dinero.js'
import { Decimal } from 'decimal.js'
import { laskeDesimaalipaikat } from '@/utils/misc'

export default function formatSum (sum, desimaalipaikat = null) {
  if (sum === null || typeof sum == 'undefined') return null

  sum = sum || 0

  let sumDecimal = new Decimal(sum)
  let formatStr = '$0'
  if (desimaalipaikat === null) {
    desimaalipaikat = laskeDesimaalipaikat(sum)
  }
  if (desimaalipaikat >= 0) {
    sumDecimal = sumDecimal.toFixed(desimaalipaikat)
    if (desimaalipaikat >= 1) {
      formatStr += '.'
      for (let i = 0; i < desimaalipaikat; i++) {
        formatStr += '0'
      }
    }
  }
  const cents = Decimal.mul(sumDecimal, 10 ** desimaalipaikat)

  return Dinero({
    amount: cents.toNumber(),
    precision: desimaalipaikat,
  }).toFormat(formatStr)
}

Vue.filter('formatSum', formatSum)
