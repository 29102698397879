<template>
  <FormRaporttiAsetuksetBase>
    <template v-slot:vasen-sarake>
      <v-text-field
        v-model="raporttilomake.asetukset.kayta_pankkitunnus"
        label="Pankkitunnus"
        :rules="[validoiPankkitunnus]"
        :hint="$t('common:infotekstit.kTLInfo.useBankId')"
      />
      <v-text-field
        v-model="raporttilomake.asetukset.kayta_tilinumero"
        label="Tilinumero (IBAN)"
        :rules="raporttilomake.asetukset.kayta_tilinumero ? [$validationRules.isValidIBAN] : []"
        :hint="$t('common:infotekstit.kTLInfo.useAccountNumber')"
      />
    </template>
    <template v-slot:oikea-sarake>
      <v-text-field
        v-model="raporttilomake.asetukset.ktl_kirjauspaiva_tilityksesta"
        label="Kirjauspäivän viive"
        :hint="$t('common:infotekstit.kTLInfo.ktlTransactionDateSettlement')"
      />
      <v-select
        v-model="raporttilomake.asetukset.kayta_tunnistetta"
        label="Viitteenä käytettävä tunniste"
        type="text"
        :items="KAYTA_TUNNISTETTA_VAIHTOEHDOT"
        item-value="value"
        item-text="selite"
        :menu-props="{ zIndex:'999', auto: true }"
        :hint="$t('common:infotekstit.kTLInfo.useId')"
      />
      <v-select
        v-model="raporttilomake.asetukset.ktl_rivinvaihto"
        label="Rivinvaihto"
        type="text"
        :items="KTL_RIVINVAIHTO_VAIHTOEHDOT"
        item-value="value"
        item-text="selite"
        :menu-props="{ zIndex:'999', auto: true }"
        :hint="$t('common:infotekstit.kTLInfo.ktlNewLine')"
      />
    </template>
  </FormRaporttiAsetuksetBase>
</template>

<script>

import { mapState } from 'vuex'
import FormRaporttiAsetuksetBase from '@/components/FormRaporttiAsetuksetBase'
import { KtlRaportti } from '@/class/Raportti'

export default {
  name: 'FormRaporttiAsetuksetKTLRaportti',
  components: {
    FormRaporttiAsetuksetBase,
  },
  data () {
    return {
      KAYTA_TUNNISTETTA_VAIHTOEHDOT: KtlRaportti.KAYTA_TUNNISTETTA_VAIHTOEHDOT ,
      KTL_RIVINVAIHTO_VAIHTOEHDOT: KtlRaportti.KTL_RIVINVAIHTO_VAIHTOEHDOT,
    }
  },
  computed: {
    ...mapState({
      raporttilomake: state => state.raportitUusi.raporttilomake,
    })
  },
  created () {
    this.$store.commit('raportitUusi/setRaporttilomakeAsetukset', KtlRaportti.oletusasetukset)
  },
  methods: {
    validoiPankkitunnus (arvo) {
      if (arvo && arvo.length !== 2) {
        return 'Pankkitunnuksen pituuden tulee olla 2 merkkiä.'
      } else {
        return true
      }
    }
  }
}
</script>

<style>

</style>
