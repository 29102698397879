import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import yleinen from './modules/yleinen'
import notifications from './modules/notifications'
import Asetukset from "@/store/modules/asetukset"
import KayttajatSelaa from './modules/kayttajatSelaa'
import LaskutusAsiakasNayta from './modules/laskutusAsiakasNayta'
import LaskutusAsiakasSelaa from './modules/laskutusAsiakasSelaa'
import LaskutusNayta from './modules/laskutusNayta'
import LaskutusSelaa from './modules/laskutusSelaa'
import OstolaskuMaksutoimeksiantoSelaa from './modules/ostolaskuMaksutoimeksiantoSelaa'
import OstolaskuNayta from './modules/ostolaskuNayta'
import OstolaskuSelaa from './modules/ostolaskuSelaa'
import OstolaskuToimittajaSelaa from './modules/ostolaskuToimittajaSelaa'
import PaamieshallintaSelaa from './modules/paamieshallintaSelaa'
import PerintaNayta from './modules/perintaNayta'
import PerintaSelaa from './modules/perintaSelaa'
import PerintaUusi from './modules/perintaUusi'
import RaportitKuukausiraporttiSelaa from './modules/raportitKuukausiraporttiSelaa'
import RaportitSelaa from './modules/raportitSelaa'
import RaportitUusi from './modules/raportitUusi'
import SopimusNayta from './modules/sopimusNayta'
import SopimusSelaa from './modules/sopimusSelaa'
import TilitysSelaa from './modules/tilitysSelaa'
import TuoterekisteriSelaa from './modules/tuoterekisteriSelaa'
import TuoterekisteriTuoteryhmaSelaa from './modules/tuoterekisteriTuoteryhmaSelaa'
import ViestitSelaa from './modules/viestitSelaa'
import palveluhakemus from './modules/palveluhakemus'
import socketIo from './modules/socketIo'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  // Huom. vuex-persistedstaten käyttöönotto aiheuttaa odottamattomia vaikutuksia useassa kohtaa
  // järjestelmää (mm. route-kohtainen väriteema katoaa refreshin jälkeen; tietyt storejen actionit
  // eivät laukea ym. Storen toiminta on suunniteltu ja toteutettu
  // odotuksella, että refresh nollaa storen tilan. Jos persistedstate tarvitsee ottaa käyttöön,
  // tulisi se tehdä route-kohtaisesti ja testata hyvin.
  plugins: [createPersistedState({
    // storage: window.sessionStorage,
    paths: [
      'palveluhakemus',
      'user',
      'yleinen'
    ]
  })],
  modules: {
    notifications,
    socketIo,
    palveluhakemus,
    user,
    yleinen,
    asetukset: new Asetukset().getModule(),
    kayttajatSelaa: new KayttajatSelaa().getModule(),
    laskutusAsiakasNayta: new LaskutusAsiakasNayta().getModule(),
    laskutusAsiakasSelaa: new LaskutusAsiakasSelaa().getModule(),
    laskutusNayta: new LaskutusNayta().getModule(),
    laskutusSelaa: new LaskutusSelaa().getModule(),
    ostolaskuMaksutoimeksiantoSelaa: new OstolaskuMaksutoimeksiantoSelaa().getModule(),
    ostolaskuNayta: new OstolaskuNayta().getModule(),
    ostolaskuSelaa: new OstolaskuSelaa().getModule(),
    ostolaskuToimittajaSelaa: new OstolaskuToimittajaSelaa().getModule(),
    paamieshallintaSelaa: new PaamieshallintaSelaa().getModule(),
    perintaNayta: new PerintaNayta().getModule(),
    perintaSelaa: new PerintaSelaa().getModule(),
    perintaUusi: new PerintaUusi().getModule(),
    raportitKuukausiraporttiSelaa: new RaportitKuukausiraporttiSelaa().getModule(),
    raportitSelaa: new RaportitSelaa().getModule(),
    raportitUusi: new RaportitUusi().getModule(),
    sopimusNayta: new SopimusNayta().getModule(),
    sopimusSelaa: new SopimusSelaa().getModule(),
    tilitysSelaa: new TilitysSelaa().getModule(),
    tuoterekisteriSelaa: new TuoterekisteriSelaa().getModule(),
    tuoterekisteriTuoteryhmaSelaa: new TuoterekisteriTuoteryhmaSelaa().getModule(),
    viestitSelaa: new ViestitSelaa().getModule(),
  },
})
