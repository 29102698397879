/**
 * https://stackoverflow.com/questions/48327261/way-to-make-inheritance-in-vuex-modules/56790942#56790942
 */

import api from '@/plugins/api'
import { HttpError } from '@/utils/errors'
import {
  doRequestWithTimeout,
} from '@/utils/misc'
import BaseModule from '@/store/modules/baseModule'
import { Paamiesosoite } from "@/class/Paamies";

export default class Asetukset extends BaseModule {
  state () {
    return {
      ...super.state(),
      yhteydenottoosoite: new Paamiesosoite(),
      laskutusosoite: null,
      kirjeosoite: null,
      tilaukset: [],
    }
  }

  mutations () {
    return {
      setYhteydenottoosoite (state, payload) {
        state.yhteydenottoosoite = payload
      },
      setLaskutusosoite (state, payload) {
        state.laskutusosoite = payload
      },
      setKirjeosoite (state, payload) {
        state.kirjeosoite = payload
      },
      setTilaukset (state, payload) {
        state.tilaukset = payload
      },
    }
  }

  actions () {
    return {
      async haePaamiesosoitteet({commit, rootState}) {
        const request = await doRequestWithTimeout(
          api.Paamies,
          {
            method: 'GET',
            url: `${rootState.user.valittuPaamies.id}/paamiesosoitteet/`,
          },
        )
        if (!request.success) throw new HttpError(request)

        // Yhteydenotto-osoite palautetaan aina
        commit('setYhteydenottoosoite', new Paamiesosoite(request.result.body.yhteydenottoosoite))

        // Jos laskutusosoite tai kirjeosoite on sama kuin yhteydenotto-osoite palautuu null
        const laskutusosoite = request.result.body.laskutusosoite
          ? new Paamiesosoite(request.result.body.laskutusosoite)
          : request.result.body.laskutusosoite

        commit('setLaskutusosoite', laskutusosoite)

        const kirjeosoite = request.result.body.kirjeosoite
          ? new Paamiesosoite(request.result.body.kirjeosoite)
          : request.result.body.kirjeosoite

        commit('setKirjeosoite', kirjeosoite)
      },

      async paamiesosoitteetSubmit({state, rootState}) {
        const body = {
          yhteydenottoosoite: state.yhteydenottoosoite.getPostData(),
          // Jos osoite on null, lähetetään null-arvo
          kirjeosoite: state.kirjeosoite === null ? null : state.kirjeosoite.getPostData(),
          laskutusosoite: state.laskutusosoite === null ? null : state.laskutusosoite.getPostData(),
        }

        const request = await doRequestWithTimeout(
          api.Paamies,
          {
            method: 'PUT',
            url: `${rootState.user.valittuPaamies.id}/paamiesosoitteet/`,
            body: body,
          },
          'doSingleRequest'
        )
        if (!request.success) throw new HttpError(request)
      },

      async haeTilaukset ({ commit, rootState }) {
        const query = new URLSearchParams()
        query.append('paamies_id', rootState.user.valittuPaamies.id)
        query.append('order', '-paattymispaiva')

        const request = await doRequestWithTimeout(
          api.Palvelusopimustilaus,
          {query: query.toString()},
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        commit('setTilaukset', request.result.body.results)
      },
    }

  }
}
