var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        { staticClass: "mb-n7" },
        [
          _c(
            "v-col",
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.tilastot,
                  rules: [_vm.$validationRules.required],
                  loading: _vm.lataaTilastoja,
                  label: _vm.$t("common:statistics.statistics"),
                  "item-text": "nimi",
                  "return-object": "",
                },
                model: {
                  value: _vm.value.tilasto,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "tilasto", $$v)
                  },
                  expression: "value.tilasto",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.raportit,
                  loading: _vm.lataaRaportteja,
                  label: _vm.$t("common:report"),
                  "item-text": "nimi",
                  "item-value": "id",
                  "return-object": "",
                },
                model: {
                  value: _vm.value.raportti,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "raportti", $$v)
                  },
                  expression: "value.raportti",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm.naytaPaamiehet
            ? _c(
                "v-col",
                { staticClass: "mb-n7" },
                [
                  _c("AutocompletePaamies", {
                    attrs: {
                      label: _vm.$t("creditor"),
                      "validointi-saannot": _vm.ACValidointisaannot,
                      multiple: "",
                      "tarkistettava-oikeus":
                        "onlinerestapi.onlinerestapi_tilasto",
                      disabled: _vm.value.kaikkiPaamiehet,
                    },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: { label: _vm.$t("statistics.allCreditors") },
                        model: {
                          value: _vm.value.kaikkiPaamiehet,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "kaikkiPaamiehet", $$v)
                          },
                          expression: "value.kaikkiPaamiehet",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            type: "submit",
                            color: "success",
                            disabled: !_vm.valid,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("common:statistics.searchStatistics")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }