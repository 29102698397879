var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-select", {
        staticClass: "required",
        attrs: {
          label: _vm.$t("common:newCreditor"),
          items: _vm.paamiehet,
          "item-text": function (paamies) {
            return paamies.nimi + " (" + paamies.id + ")"
          },
          "item-value": "id",
          rules: [_vm.$validationRules.required],
        },
        model: {
          value: _vm.value.paamies_id,
          callback: function ($$v) {
            _vm.$set(_vm.value, "paamies_id", $$v)
          },
          expression: "value.paamies_id",
        },
      }),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }