<template>
  <div :class="divClass">
    <v-progress-circular
      indeterminate
      color="primary"
    />
    <p
      v-if="teksti"
      class="my-4 font-no-data"
    >
      {{ teksti }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'Spinner',
  props: {
    teksti: {
      type: String,
      required: false,
      default: '',
    },
    divClass: {
      type: String,
      required: false,
      default: 'text-center pa-12',
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
