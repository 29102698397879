var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      items: _vm.items,
      headers: _vm.tableHeadersToimeksiantoAsiakirja,
      "hide-default-footer": _vm.items.length <= 10,
      dense: _vm.tiivisNakyma,
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "TableRow",
              [
                item.nimi
                  ? [
                      _c("td", [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.haeAsiakirja(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.nimi))]
                        ),
                      ]),
                      _c("td", { staticClass: "text-no-wrap" }, [
                        _vm._v(" " + _vm._s(_vm.$date(item.luotu)) + " "),
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("v-tooltip", {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              text: "",
                                              icon: "",
                                              small: _vm.tiivisNakyma,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.haeAsiakirja(item)
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: _vm.tiivisNakyma,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " anitta-icon-file-download "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common:dataTableToimeksiantoAsiakirja.downloadAttachment"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }