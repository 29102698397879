<template>
  <v-form
    ref="formToimitusosoite"
    @submit.prevent
  >
    <v-text-field
      v-model="toimitusosoite.saaja"
      label="Saaja"
      class="mt-4"
    />
    <v-text-field
      v-model="toimitusosoite.osoite"
      label="Lähiosoite"
      :rules="[toimitusosoiteKenttienPakollisuus]"
    />
    <v-row>
      <v-col
        md="5"
        class="py-0"
      >
        <v-text-field
          v-model="toimitusosoite.numero"
          label="Postinumero"
          :rules="[toimitusosoiteKenttienPakollisuus]"
        />
      </v-col>
      <v-col
        md="7"
        class="py-0"
      >
        <v-text-field
          v-model="toimitusosoite.toimipaikka"
          label="Toimipaikka"
          :rules="[toimitusosoiteKenttienPakollisuus]"
        />
      </v-col>
    </v-row>
    <v-select
      v-model="toimitusosoite.maa"
      label="Maa"
      :items="kenttienValinnat.maat"
      item-value="value"
      item-text="selite"
      :menu-props="{ zIndex:'999', auto: true }"
    />
    <v-row
      class="mt-4 pa-3"
      justify="end"
    >
      <v-btn
        class="mr-4"
        @click="$emit('close')"
      >
        Peruuta
      </v-btn>
      <v-btn
        color="success"
        @click="lisaaToimitusosoiteLaskulle"
      >
        Lisää laskulle
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>

import { AsiakasPostiosoite } from '@/class/Asiakas'
import _ from 'lodash'

export default {
  name: 'FormToimitusosoite',
  props: {
    onAuki: {
      type: Boolean,
      required: true,
    },
    kenttienValinnat: {
      type: Object,
      required: true,
    },
    lasku: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      toimitusosoite: new AsiakasPostiosoite()
    }
  },
  watch: {
    onAuki () {
      this.alustaToimitusosoite()
    }
  },
  mounted () {
    this.alustaToimitusosoite()
  },
  methods: {
    alustaToimitusosoite () {
      if (this.lasku.toimitusosoite.osoite) {
        this.toimitusosoite = _.cloneDeep(this.lasku.toimitusosoite)
        this.toimitusosoite.id = ''
      } else {
        this.toimitusosoite = new AsiakasPostiosoite(this.lasku.asiakas.id)
      }
    },
    toimitusosoiteKenttienPakollisuus (arvo) {
      if (!arvo && (this.toimitusosoite.saaja || this.toimitusosoite.osoite || this.toimitusosoite.numero || this.toimitusosoite.toimipaikka)) {
        return 'Täytä loput osoitetiedoista'
      } else {
        return true
      }
    },
    lisaaToimitusosoiteLaskulle () {
      if (this.$refs.formToimitusosoite.validate()) {
        this.lasku.toimitusosoite = _.cloneDeep(this.toimitusosoite)
        this.$emit('close')
      }
    },
  }
}


</script>

<style lang="scss" scoped>

 .v-text-field, .v-select {
   padding-top: 0px;
   margin-top: 4px;
 }

</style>
