<template>
  <v-form
    ref="form"
    v-model="valid"
    class="white--text"
    @submit.prevent
  >
    <v-row>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="tuoteryhma">
                {{ $t('common:productGroup.productGroupHeader') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="tuoteryhma"
                v-model="value.tuoteryhma"
                label=""
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="koodi">
                {{ $t('common:code') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="koodi"
                v-model="value.koodi"
                color="white"
                label=""
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="selite">
                {{ $t('common:definition') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="selite"
                v-model="value.selite"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'
import FormTable from '@/components/FormTable'
import FormTableRow from '@/components/FormTableRow'
import FormTableCell from '@/components/FormTableCell'
import FormTableLabel from '@/components/FormTableLabel'

export default {
  name: 'FormSearchTuote',
  components: {
    FormTable,
    FormTableRow,
    FormTableCell,
    FormTableLabel,
  },
  mixins: [FormMixin],
}

</script>

<style lang="scss" scoped>

</style>
