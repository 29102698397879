import { render, staticRenderFns } from "./FormUusiViesti.vue?vue&type=template&id=5f291f80&scoped=true&"
import script from "./FormUusiViesti.vue?vue&type=script&lang=js&"
export * from "./FormUusiViesti.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f291f80",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCol,VForm,VIcon,VRow,VTextarea,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f291f80')) {
      api.createRecord('5f291f80', component.options)
    } else {
      api.reload('5f291f80', component.options)
    }
    module.hot.accept("./FormUusiViesti.vue?vue&type=template&id=5f291f80&scoped=true&", function () {
      api.rerender('5f291f80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FormUusiViesti.vue"
export default component.exports