var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "max-width": "290",
            "nudge-bottom": "40",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        attrs: {
                          label: "Päättymispäivä",
                          value: _vm.$date(_vm.value.loppumispaiva),
                          clearable: "",
                          readonly: "",
                          placeholder: "Voimassa toistaiseksi",
                          "persistent-placeholder": "",
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.loppumispaivaMenu,
            callback: function ($$v) {
              _vm.loppumispaivaMenu = $$v
            },
            expression: "loppumispaivaMenu",
          },
        },
        [
          [
            _c("v-date-picker", {
              attrs: {
                locale: "fi-FI",
                "first-day-of-week": "1",
                "no-title": "",
              },
              on: {
                change: function ($event) {
                  _vm.loppumispaivaMenu = false
                },
              },
              model: {
                value: _vm.value.loppumispaiva,
                callback: function ($$v) {
                  _vm.$set(_vm.value, "loppumispaiva", $$v)
                },
                expression: "value.loppumispaiva",
              },
            }),
          ],
        ],
        2
      ),
      _c("v-text-field", {
        staticClass: "required",
        attrs: { label: "Selite", rules: [_vm.$validationRules.required] },
        model: {
          value: _vm.value.selite,
          callback: function ($$v) {
            _vm.$set(_vm.value, "selite", $$v)
          },
          expression: "value.selite",
        },
      }),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }