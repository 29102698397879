var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("div", { staticClass: "login__top" }, [
        _c("div", { staticClass: "login__wrap" }, [
          _c(
            "div",
            { staticClass: "login__col login__col--left pr-6" },
            [
              _c("Logo", {
                staticClass: "mx-0",
                attrs: { "logo-key": _vm.logoKey, "enable-link": false },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "login__col login__col--right pa-6" },
            [
              _c(
                "v-card",
                { staticClass: "pa-10" },
                [
                  _vm.salasananUusimispyyntolomakeAktiivinen
                    ? [
                        _c("h2", { staticClass: "headline mb-6" }, [
                          _vm._v(" Salasanan palautus "),
                        ]),
                        _c("p", [
                          _vm._v(
                            "Kirjoita sähköpostiosoitteesi alla olevaan kenttään. Saat sähköpostiisi ohjeet salasanan palauttamiseksi."
                          ),
                        ]),
                        _c("FormSalasananUusimispyynto", {
                          attrs: { loading: _vm.loading },
                          on: { submit: _vm.lahetaSalasananUusimispyynto },
                          model: {
                            value: _vm.formSalasananUusimispyynto,
                            callback: function ($$v) {
                              _vm.formSalasananUusimispyynto = $$v
                            },
                            expression: "formSalasananUusimispyynto",
                          },
                        }),
                      ]
                    : [
                        _c("h2", { staticClass: "headline mb-6" }, [
                          _vm._v(" Kirjaudu sisään "),
                        ]),
                        _c("FormLogin", {
                          attrs: { loading: _vm.loading },
                          on: { submit: _vm.login },
                          model: {
                            value: _vm.formLoginData,
                            callback: function ($$v) {
                              _vm.formLoginData = $$v
                            },
                            expression: "formLoginData",
                          },
                        }),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "login__wrap" }, [
          _c("div", { staticClass: "login__col login__col--left pa-6" }),
          _c(
            "div",
            { staticClass: "login__col login__col--right pa-6 loginHelp pt-6" },
            [
              _vm.salasananUusimispyyntolomakeAktiivinen
                ? [
                    _c("p", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.salasananUusimispyyntolomakeAktiivinen = false
                            },
                          },
                        },
                        [_vm._v("Peruuta")]
                      ),
                    ]),
                  ]
                : [
                    _c("p", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.salasananUusimispyyntolomakeAktiivinen = true
                            },
                          },
                        },
                        [_vm._v("Unohtuiko salasana?")]
                      ),
                    ]),
                  ],
              _c("p", [
                _vm._v(
                  " Palvelu on käytössä vain toimeksiantaja-asiakkaillemme. "
                ),
              ]),
              _vm.palveluhakemusKaytossa && _vm.appClass === "uuva"
                ? _c("p")
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _c("BusinessFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }