<template>
  <v-form ref="formAsetuksetYhteystiedot">
    <v-row>
      <v-col
        md="12"
        class="py-0"
      >
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <CardTitle
                color="black--text"
                :text="$t('contactInformation')"
              />
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <FormAsetuksetPaamiesosoite
                v-model="asetukset.yhteydenottoosoite"
                :verkkolaskuoperaattorit="verkkolaskuoperaattorit"
                button-text=""
                :salli-poisto="false"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <CardTitle
                color="black--text"
                :text="$t('settings.invoicingAddress')"
              />
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <FormAsetuksetPaamiesosoite
                v-model="asetukset.laskutusosoite"
                :verkkolaskuoperaattorit="verkkolaskuoperaattorit"
                :button-text="$t('common:settings.addInvoicingAddress')"
                :salli-poisto="true"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <CardTitle
                color="black--text"
                :text="$t('settings.addressInLetter')"
              />
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <FormAsetuksetPaamiesosoite
                v-model="asetukset.kirjeosoite"
                :verkkolaskuoperaattorit="verkkolaskuoperaattorit"
                :button-text="$t('common:settings.addAddressInLetter')"
                :salli-poisto="true"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row
      class="mt-4 pa-3"
      justify="end"
    >
      <v-btn
        large
        color="primary"
        @click="tallenna"
      >
        {{ $t("common:save") }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import FormAsetuksetPaamiesosoite from '@/components/FormAsetuksetPaamiesosoite'
import { mapState } from 'vuex'

export default {
  name: 'FormAsetuksetYhteystiedot',
  components: {
    FormAsetuksetPaamiesosoite,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      asetukset: (state) => state.asetukset,
      verkkolaskuoperaattorit: (state) => state.yleinen.verkkolaskuoperaattorit,
      paamies: (state) => state.user.valittuPaamies,
    }),
  },
  methods: {
    async tallenna() {
      if (!this.$refs.formAsetuksetYhteystiedot.validate()) return

      try {
        await this.$store.dispatch('asetukset/paamiesosoitteetSubmit')

        this.$naytaOnnistumisilmoitus(
          this.$t('common:settings.contactInformationSaved')
        )
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t('common:settings.contactInformationSaveFailed')
        )
      }
    },
  },
}
</script>
