var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "white--text",
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "tila" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("common:status")) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-select", {
                            attrs: {
                              id: "tila",
                              items: _vm.tilat,
                              "item-text": "label",
                              "item-value": "terms",
                              multiple: "",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.tila,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "tila", $$v)
                              },
                              expression: "value.tila",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          !_vm.$store.state.user.vainYksiPaamies
                            ? _c(
                                "FormTableLabel",
                                { attrs: { for: "paamiehet" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("common:creditors")) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c(
                            "v-theme-provider",
                            { attrs: { dark: "" } },
                            [
                              !_vm.$store.state.user.vainYksiPaamies
                                ? _c("AutocompletePaamies", {
                                    attrs: {
                                      multiple: true,
                                      tapaus: _vm.value,
                                      label: "",
                                      "tarkistettava-oikeus":
                                        "onlinerestapi.onlinerestapi_raportti",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "alkamispaiva" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:starting")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "alkaen",
                                label: _vm.$t("common:starting"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.alkaenAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "alkaenAlkaen", $$v)
                                },
                                expression: "value.alkaenAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "loppuen",
                                label: _vm.$t("common:ending"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.alkaenPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "alkaenPaattyen", $$v)
                                },
                                expression: "value.alkaenPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "alkamispaiva" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:ending")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "alkaen",
                                label: _vm.$t("common:starting"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.loppuenAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "loppuenAlkaen", $$v)
                                },
                                expression: "value.loppuenAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "loppuen",
                                label: _vm.$t("common:ending"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.loppuenPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "loppuenPaattyen", $$v)
                                },
                                expression: "value.loppuenPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "luotu" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("common:created")) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "alkaen",
                                label: _vm.$t("common:starting"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.luotuAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "luotuAlkaen", $$v)
                                },
                                expression: "value.luotuAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "loppuen",
                                label: _vm.$t("common:ending"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.luotuPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "luotuPaattyen", $$v)
                                },
                                expression: "value.luotuPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "vain_yhteenveto" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:onlyTotal")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-checkbox", {
                            attrs: { id: "vain_yhteenveto", dark: true },
                            model: {
                              value: _vm.value.vain_yhteenveto,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "vain_yhteenveto", $$v)
                              },
                              expression: "value.vain_yhteenveto",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }