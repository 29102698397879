var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.iconClass
    ? _c(
        "div",
        _vm._g(
          {
            class: _vm.classObj,
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.onClick($event)
              },
            },
          },
          _vm.eventListener
        ),
        [
          _c("div", { staticClass: "status-icon__wrap" }, [
            _c("span", { class: _vm.iconClass }),
            _vm.hoverIcon
              ? _c("span", { class: _vm.hoverIconClass })
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }