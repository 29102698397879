<template>
  <div class="offCanvasLasku">
    <OffCanvasToolbar
      toolbar-title="Laskun tiedot"
      to-name="laskutus-nayta"
      :to-id="value ? value.id : null"
      tooltip-title="lasku"
      @close-button-click="$emit('close-button-click')"
    />
    <v-container class="grey lighten-5 px-8">
      <OffCanvasDetailsLasku v-model="value" />
    </v-container>
    <v-container class="px-8">
      <Timeline
        :items="value.aikajana"
        @msg-mark-as-unread="$emit('msg-mark-as-unread')"
        @msg-mark-as-read="$emit('msg-mark-as-unread')"
      />
    </v-container>
  </div>
</template>

<script>

import ClickOutside from 'vue-click-outside'
import Timeline from '@/components/Timeline'
import OffCanvasDetailsLasku from '@/components/OffCanvasDetailsLasku'
import OffCanvasToolbar from '@/components/OffCanvasToolbar'

export default {
  name: 'OffCanvasLasku',
  directives: {
    ClickOutside,
  },
  components: {
    Timeline,
    OffCanvasDetailsLasku,
    OffCanvasToolbar,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
