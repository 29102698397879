var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classObj },
    [
      _c(
        "v-card",
        {
          staticClass: "card__wrap",
          attrs: { to: _vm.to },
          on: { click: _vm.clickHandler },
        },
        [
          _c(
            "header",
            { staticClass: "card__header pa-4" },
            [
              _vm.data.toimeksiantovelallinen_set.length
                ? _c("h2", { staticClass: "card__title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.data.toimeksiantovelallinen_set[0].velallinen.nimi
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _c("v-tooltip", {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c("StatusIcon", {
                          staticClass: "card__icon",
                          attrs: {
                            size: "large",
                            icon: _vm.data.ikoni_ja_vari.ikoni,
                            hoverIcon: "open-in-new",
                            eventListener: on,
                            bgColor: _vm.data.ikoni_ja_vari.vari,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                name: "perinta-nayta",
                                params: { id: _vm.data.id },
                              })
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [_c("span", [_vm._v("Avaa toimeksianto")])]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "card__content" },
            [
              _c(
                "v-row",
                { staticClass: "card__details" },
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("span", { staticClass: "grey--text" }, [
                      _vm._v("Asianumero"),
                    ]),
                    _c("br"),
                    _vm.data.id
                      ? _c("span", [_vm._v(_vm._s(_vm.data.id))])
                      : _vm._e(),
                  ]),
                  _vm.data.tila && _vm.data.vaihe
                    ? [
                        _vm.data.tila.value != "XX" &&
                        _vm.data.tila.value != "PE" &&
                        _vm.data.tila.value != "MY"
                          ? _c(
                              "v-col",
                              {
                                staticClass: "text-right",
                                attrs: { cols: "6" },
                              },
                              [
                                _c("span", { staticClass: "grey--text" }, [
                                  _vm._v("Vaihe"),
                                ]),
                                _c("br"),
                                _vm.data.vaihe
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.data.vaihe.nimi)),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "my-2" }),
              _c(
                "v-row",
                { staticClass: "card__details" },
                [
                  _c("v-col", { attrs: { cols: "8" } }, [
                    _c("span", { staticClass: "grey--text" }, [
                      _vm._v("Päämies"),
                    ]),
                    _c("br"),
                    _vm.data.paamies
                      ? _c("span", [_vm._v(_vm._s(_vm.data.paamies.nimi))])
                      : _vm._e(),
                  ]),
                  _c("v-col", { attrs: { cols: "4" } }),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right align-self-end",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("span", { staticClass: "grey--text" }, [
                        _vm._v("Avoinna"),
                      ]),
                      _c("br"),
                      _c("span", { staticClass: "orange--text subtitle-1" }, [
                        _vm._v(
                          _vm._s(_vm._f("formatSumToFixed2")(_vm.data.avoinna))
                        ),
                      ]),
                      _c("br"),
                      _vm.data.nayta_kuluja_auki_teksti
                        ? _c("span", [_vm._v(" (kuluja avoinna)")])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.data.stacked
        ? [
            _c("v-card", { staticClass: "card__stackItem" }),
            _c("v-card", {
              staticClass: "card__stackItem card__stackItem--second",
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }