<template>
  <v-form ref="formAsiakas">
    <v-row>
      <AutocompleteYTJHaku
          input-hint="Hakutuloksen valinta täyttää yritysasiakkaan tiedot lomakkeelle."
          @autocomplete-input="asetaYrityksenTiedotLomakkeelle"
        />
    </v-row>
    <v-row>
      <v-col
        md="6"
        class="pl-4 pr-12"
      >
        <AutocompletePaamies
          v-if="!$store.state.user.vainYksiPaamies && !kaareObjekti"
          ref="autocompletePaamies"
          v-model="asiakas"
          label="Päämies"
          :tapaus="asiakas"
          tarkistettava-oikeus="onlinerestapi.onlinerestapi_lasku"
          @autocomplete-input="paamiesKasitteleValinta"
        />
      </v-col>

    </v-row>
    <v-row>
      <!-- Vasen sarake-->
      <v-col
        md="6"
        class="pl-4 pr-12"
      >
        <h3 class="my-8">
          Asiakastiedot
        </h3>
        <v-text-field
          v-model="asiakas.nro"
          label="Asiakasnumero"
          :error-messages="asiakasnroErrors"
          @input="tarkistaAsiakasnro($event)"
        />
        <v-select
          v-model="asiakas.tyyppi"
          label="Tyyppi"
          type="text"
          :items="tyyppiItems"
          item-value="value"
          item-text="selite"
          :menu-props="{ zIndex:'999' }"
          :rules="[$validationRules.required]"
          class="required"
          @input="asiakas.tunnus ? tarkistaTunnus(asiakas.tunnus) : () => {}"
        />
        <v-text-field
          v-model="asiakas.tunnus"
          label="Y-tunnus / Hetu"
          :error-messages="tunnusErrors"
          @input="tunnusErrors.length ? tarkistaTunnus($event) : () => {}"
          @blur="[tarkistaTunnus($event.target.value), tarkistaOlemassaOlevaTunnus($event.target.value)]"
        />
        <v-select
          v-model="asiakas.kieli"
          label="Kieli"
          type="text"
          :items="kenttienValinnat.kielet || []"
          :value="kenttienValinnat.kielet ? kenttienValinnat.kielet[0] : ''"
          item-value="value"
          item-text="selite"
          :menu-props="{ zIndex:'999' }"
          :rules="[$validationRules.required]"
          class="required"
        />
        <h3 class="my-8">
          Osoitetiedot
        </h3>
        <div
          v-if="asiakas.tyyppi === 'H'"
          class="comboField"
        >
          <v-text-field
            v-model="asiakas.etunimi"
            label="Etunimi"
            :rules="[$validationRules.required]"
            :hint="'Anna yksi tai useampi'"
            class="required"
            @blur="tarkistaOlemassaOlevaNimiHenkilo"
          />
          <span class="comboField__separator"></span>
          <v-text-field
            v-model="asiakas.sukunimi"
            label="Sukunimi"
            :rules="[$validationRules.required]"
            class="required"
            @blur="tarkistaOlemassaOlevaNimiHenkilo"
          />
        </div>
        <v-text-field
          v-else
          v-model="asiakas.nimi"
          label="Nimi"
          :rules="[$validationRules.required]"
          class="required"
          @blur="tarkistaOlemassaOlevaNimi($event.target.value)"
        />
        <v-text-field
          v-model="asiakas.postiosoite.saaja"
          label="Saaja / Tarkenne"
        />
        <v-text-field
          v-model="asiakas.postiosoite.osoite"
          label="Lähisoite"
          :rules="[$validationRules.required]"
          class="required"
        />
        <v-row>
          <v-col
            md="5"
            class="py-1"
          >
            <v-text-field
              v-model="asiakas.postiosoite.numero"
              label="Postinumero"
              :rules="[$validationRules.required]"
              class="required"
            />
          </v-col>
          <v-col
            md="7"
            class="py-1"
          >
            <v-text-field
              v-model="asiakas.postiosoite.toimipaikka"
              label="Postitoimipaikka"
              :rules="[$validationRules.required]"
              class="required"
            />
          </v-col>
        </v-row>
        <v-select
          v-model="asiakas.postiosoite.maa"
          label="Maa"
          type="text"
          :items="maat"
          item-value="value"
          item-text="selite"
          :menu-props="{ zIndex:'999', auto: true }"
          :rules="[$validationRules.required]"
          class="required"
        />
        <v-select
          v-if="asiakas.id"
          ref="vanhaOsoite"
          v-model="vanhaOsoite"
          label="Hae vanhoista osoitteista"
          item-text="osoite"
          :items="asiakasPostiosoiteItems"
          return-object
        >
          <template v-slot:item="{ item }">
            <span><span v-if="item.saaja">{{ item.saaja }}, </span>{{ item.osoite }}, {{ item.numero }}, {{ item.toimipaikka }}</span>
          </template>
        </v-select>
      </v-col>

      <!-- Oikea sarake -->
      <v-col
        md="6"
        class="pl-12 pr-4"
      >
        <h3 class="my-8">
          Yhteystiedot
        </h3>
        <v-text-field
          v-model="asiakas.puhelin.numero"
          label="Puhelin"
        />
        <div v-for="(email, idx) in emailosoitteet" :key="idx">
          <v-text-field ref="emailosoitteet[idx]"
              v-model="emailosoitteet[idx]"
              type="email"
              :rules="[$validationRules.email]"
              dense
              label="Sähköpostiosoite"
              placeholder="Kirjoita sähköpostiosoite"
              >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" @click="emailLisaa"  v-on="on" v-bind="attrs" >
                    add
                  </v-icon>
                </template>
                <span>Lisää sähköpostiosoite</span>
              </v-tooltip>
            </template>
            <template v-slot:append-outer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" @click="emailPoista(idx)"  v-on="on" v-bind="attrs" >
                    delete
                  </v-icon>
                </template>
                <span>Poista sähköpostiosoite</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </div>
        <v-text-field
          v-model="asiakas.verkkolaskuosoite.verkkolaskuosoite"
          label="Verkkolaskuosoite"
          :disabled="asiakas.tyyppi !== 'Y'"
          :error-messages="verkkolaskuosoiteErrors"
        />
        <v-autocomplete
          v-model="asiakas.verkkolaskuosoite.operaattori"
          class="py-1"
          clearable
          :disabled="asiakas.tyyppi !== 'Y'"
          :items="verkkolaskuoperaattorit"
          :filter="verkkolaskuoperaattoriFilter"
          item-text="autocomplete_selite"
          item-value="id"
          label="Verkkolaskuoperaattori"
          :error-messages="verkkolaskuoperaattoriErrors"
        ></v-autocomplete>
        <h3 class="my-8">
          Laskun lähetyksen lisätiedot
        </h3>
        <v-select
          v-model="asiakas.lahetystapa"
          label="Lähetystapa"
          type="text"
          :items="lahetystapaSuodatettuItems()"
          item-value="value"
          item-text="label"
          :menu-props="{ zIndex:'999' }"
        />
        <v-text-field
          v-model="asiakas.oletustoimitustapa"
          label="Toimitustapa"
        />
        <v-text-field
          v-model="asiakas.oletusmaksuehto"
          label="Maksuehto"
          suffix="pv netto"
        />
        <v-text-field
          v-model="asiakas.oletusviitteemme"
          label="Viitteemme"
        />
        <v-text-field
          v-model="asiakas.oletusviitteenne"
          label="Viitteenne"
        />
        <v-textarea
          v-model="asiakas.oletusvapaateksti"
          label="Laskulla näkyvä teksti"
          type="text"
          rows="2"
        />
      </v-col>
    </v-row>
    <!-- <template :v-if="process.env.VUE_APP_DEV_MODE== 1" >{{ this.asiakas }}</template> -->

    <v-row
      class="mt-4 pa-3"
      justify="end"
    >
      <v-btn
        large
        class="mr-4"
        @click="$emit('close')"
      >
        Peruuta
      </v-btn>
      <v-btn
        large
        color="success"
        @click="tallenna"
      >
        Tallenna
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>

import _ from 'lodash'
import { Asiakas, AsiakasPostiosoite } from '@/class/Asiakas'
import { Lasku } from '@/class/Lasku'
import AutocompletePaamies from '@/components/AutocompletePaamies'
import AutocompleteYTJHaku from '@/components/AutocompleteYTJHaku'
import { onkoLahetystapaDisabloitu } from '@/utils/misc'
import { mapState } from "vuex";

export default {
  name: 'FormAsiakas',
  components: {
    AutocompletePaamies,
    AutocompleteYTJHaku,
  },
  props: {
    // Asiakkaan sisältävä objekti kuten lasku. Jos sitä ei ole, ollaan asiakasnäkymässä luomassa
    // uutta asiakasta.
    kaareObjekti: {
      type: Object,
      required: false,
      default () {
        return null
      }
    },
    onAuki: {
      type: Boolean,
      required: true,
    },
    kenttienValinnat: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      asiakas: new Asiakas(),
      asiakasnroErrors: [],
      debounceDelay: 300,
      emailosoitteet: [""],
      ilmoitetutOlemassaOlevatNimet: [],
      kieliItems: [],
      lahetystavatHintoineen: [],
      tarkistaOlemassaOlevaNimiTimeout: null,
      tarkistaOlemassaOlevaTunnusTimeout: null,
      tunnusErrors: [],
      vanhaOsoite: null,
      verkkolaskuoperaattoriErrors: [],
      verkkolaskuosoiteErrors: [],
    }
  },
  computed: {
    ...mapState({
      verkkolaskuoperaattorit: state => state.yleinen.verkkolaskuoperaattorit,
      maat: state => state.yleinen.maat,
    }),
    asiakasNimiAsiakasTunnus () {
      return `${this.asiakas.nimi}|${this.asiakas.tunnus}`
    },
    asiakasPostiosoiteItems () {
      return this.asiakas.postiosoite_set || []
    },
    tyyppiItems () {
      let tyyppiItems = []
      if (this.kenttienValinnat.tyypit) {
        tyyppiItems = [...this.kenttienValinnat.tyypit].sort((a, b) => {
          if (a.selite < b.selite) {
            return -1
          } else if (a.selite > b.selite) {
            return 1
          } else {
            return 0
          }
        })
      }

      return tyyppiItems
    },
  },
  watch: {
    onAuki: {
      immediate: true,
      handler () {
        this.$nextTick().then(() => {
          this.$refs.formAsiakas.resetValidation()
        })

        if (this.kaareObjekti) {
          if (this.kaareObjekti.asiakas.id) {
            // Asiakkaan muokkaus
            this.asiakas = _.cloneDeep(this.kaareObjekti.asiakas)
            this.asiakas.id = this.kaareObjekti.asiakas.id

            // Täytetään email-kenttä
            this.emailosoitteet = this.asiakas.emailosoite_set.map(eo => eo.email)
          } else if (this.kaareObjekti.paamies && this.kaareObjekti.paamies.id) {
            // Uusi asiakas, kun on annettu kääreobjekti, jolla on päämies
            this.asiakas = new Asiakas({ paamies_id: this.kaareObjekti.paamies.id })
          }
          this.alustaLahetystavatHintoineen()
        } else {
          // Uusi asiakas selausnäkymän kautta
          this.asiakas = new Asiakas()
          if (this.$store.state.user.vainYksiPaamies) {
            this.asiakas.paamies = this.$store.state.user.valittuPaamies
            this.alustaLahetystavatHintoineen()
          } else {
            this.$nextTick().then(() => {
              if (this.$refs.autocompletePaamies) {
                this.asiakas.paamies = this.$store.state.user.valittuPaamies
                this.alustaLahetystavatHintoineen()
                // Asetetaan päämiehen nimi hakukenttään, kun päämies on haettu arvo storesta
                setTimeout(() => {
                  this.$refs.autocompletePaamies.paivitaPaamiehenTiedotHakukenttaan(
                    this.$store.state.user.valittuPaamies
                  )
                }, 0)
              }
            })
          }
        }
        if (!this.emailosoitteet || this.emailosoitteet.length == 0) {
          this.emailosoitteet.push("")
        }
      }
    },
    'asiakas': {
      deep: true,
      immediate: true,
      handler (val) {
        if (val.paamies) {
          this.asiakas.paamies_id = val.paamies.id

          if (!this.kaareObjekti && val.paamies.id) {
            this.$store.commit('user/setValittuPaamies', this.asiakas.paamies)
          }
        }
      }
    },
    'asiakas.verkkolaskuosoite': {
      deep: true,
      handler(verkkolaskuosoite) {
        if (verkkolaskuosoite.verkkolaskuosoite && !verkkolaskuosoite.operaattori) {
          this.verkkolaskuoperaattoriErrors = ['Syötä myös verkkolaskuoperaattori']
        } else {
          this.verkkolaskuoperaattoriErrors = []
        }

        if (!verkkolaskuosoite.verkkolaskuosoite && verkkolaskuosoite.operaattori) {
          this.verkkolaskuosoiteErrors = ['Syötä myös verkkolaskuosoite']
        } else {
          this.verkkolaskuosoiteErrors = []
        }
      }
    },
    vanhaOsoite: {
      handler (val) {
        if (val) {
          this.asiakas.postiosoite = new AsiakasPostiosoite(this.vanhaOsoite)
          this.$nextTick(()=> {
            this.vanhaOsoite = ''
            this.$refs.vanhaOsoite.isFocused = false
          })
        }
      }
    },
  },
  methods: {
    emailLisaa () {
      this.emailosoitteet.push("")
    },
    emailPoista (idx) {
      this.emailosoitteet.splice(idx, 1)
      if (!this.emailosoitteet || this.emailosoitteet.length == 0) {
        this.emailosoitteet = [""]
      }
    },
    
    async tallenna () {
      // Jos käyttäjä on viimeiseksi kirjoittanut comboboxiin, joka ei ole poistunut fokuksesta,
      // ei muutos tallenu formille ennen http-kyselyä, joten poistetaan fokus.
      //this.$refs.emailosoitteet.blur()
      if (this.emailosoitteet.length < 1 || (this.emailosoitteet.length == 1 && this.emailosoitteet[0] == "")) {
        this.emailosoitteet = []
      }
      if (!this.$refs.formAsiakas.validate()) return

      const httpMethod = this.asiakas.id ? 'PUT' : 'POST'
      const url = this.asiakas.id ? `${this.asiakas.id}/` : ''

      // FIXME Päivittää joka kerta osoitteen, eli muut kentät kuin email nollaantuvat aina
      if (this.emailosoitteet.length) {
        this.asiakas.emailosoite_set = this.emailosoitteet.map(email => ({ email }))
      } else {
        this.asiakas.emailosoite_set = []
      }

      try {
        if (this.asiakas.id) {
          if (!this.emailosoitteet.length) {
            // jos sähköposti tyhjä poistetaan kaikki asiakkaan sähköpostit
            const email_response = await this.$doRequestWithTimeout(
              this.$api.Asiakkaat,
              {
                method: 'DELETE',
                url: `${url}poista_emailosoitteet/`
              },
            )

            if (!email_response.success) throw new this.$HttpError(email_response)
          }

          if (!this.asiakas.puhelin.numero) {
            // jos puhelinnumero tyhjä poistetaan kaikki asiakkaan puhelinnumerot
            const puh_response = await this.$doRequestWithTimeout(
              this.$api.Asiakkaat,
              {
                method: 'DELETE',
                url: `${url}poista_puhelinnumerot/`
              },
            )

            if (!puh_response.success) throw new this.$HttpError(puh_response)
          }
        }

        const request = await this.$doRequestWithTimeout(
          this.$api.Asiakkaat,
          {
            method: httpMethod,
            url: url,
            body: this.asiakas.getPostData(),
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.$emit('asiakasTallennettu', new Asiakas(request.result.body))

        this.$emit('close')
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Asiakkaan tallennus epäonnistui!')
      }
    },
    onkoLahetystapaDisabloitu (lahetystapa, asiakas) {
      if (lahetystapa === 'email') {
        return !this.emailosoitteet.length && onkoLahetystapaDisabloitu(lahetystapa, asiakas)
      } else {
        return onkoLahetystapaDisabloitu(lahetystapa, asiakas)
      }
    },
    lahetystapaSuodatettuItems() {
      return this.lahetystavatHintoineen.map(lahetystapa => {
        return Object.assign({ disabled: this.onkoLahetystapaDisabloitu(lahetystapa.value, this.asiakas) }, lahetystapa)
      })
    },
    paamiesKasitteleValinta () {
      this.alustaLahetystavatHintoineen()
    },
    alustaLahetystavatHintoineen () {
      this.lahetystavatHintoineen = []

      let porrashinnasto_set = this.asiakas.paamies?.porrashinnasto_set
      if (!porrashinnasto_set?.length) {
        porrashinnasto_set = this.kaareObjekti?.paamies?.porrashinnasto_set
      }
      try {
        if (!porrashinnasto_set?.length) {
          // Ei aseteta hintoja jos hinnastoa ei löydy
          this.lahetystavatHintoineen = Object.assign([], Lasku.LAHETYSTAVAT)
          return
        }

        Lasku.LAHETYSTAVAT.map((lahetystapa => {
          const lt = Object.assign({}, lahetystapa)
          lt.label = this.$t('common:invoiceSend.sendMethods.' + lahetystapa.value)
          const hinnasto = _.find(porrashinnasto_set, ['koodi', lahetystapa.koodi])
          const hinta = hinnasto ? this.$formatSum(hinnasto.ahinta) : this.$formatSum(0)
          lt.label = lahetystapa.label + ' ' + hinta
          this.lahetystavatHintoineen.push(lt)
        }))
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Lähetystapojen alustus epäonnistui')
      }
    },
    async tarkistaAsiakasnro (nro) {
      this.asiakasnroErrors = []

      if (!nro || !this.asiakas.paamies_id && !this.asiakas.paamies) return

      const query = new URLSearchParams()
      query.append('method', 'tarkista_asiakasnro')
      query.append('nro', nro)
      query.append('paamies_id', this.asiakas.paamies_id || this.asiakas.paamies.id)

      const request = await this.$doRequestWithTimeout(
        this.$api.Yleinen,
        {
          url: 'tarkista',
          query: query.toString(),
        },
        'pushRequest',
      )

      const asiakasnroVapaana = request.result.body
      if (!asiakasnroVapaana && this.asiakas.nro) {
        this.asiakasnroErrors = ['Asiakasnumero on jo käytössä']
      } else {
        this.asiakasnroErrors = []
      }
    },
    async tarkistaTunnus (tunnus) {
      this.tunnusErrors = []

      if (!tunnus || this.asiakas.postiosoite.maa !== 'FI') return

      const query = new URLSearchParams()
      query.append('method', 'tarkista_tunnus')
      query.append('tunnus', tunnus)
      query.append('tyyppi', this.asiakas.tyyppi)

      const request = await this.$doRequestWithTimeout(
        this.$api.Yleinen,
        {
          url: 'tarkista/',
          query: query.toString(),
        },
        'pushRequest',
      )

      const tunnusOnOikeellinen = request.result.body
      if (!tunnusOnOikeellinen && this.asiakas.tunnus) {
        this.tunnusErrors = ['Tarkista tunnus']
      } else {
        this.tunnusErrors = []
      }
    },
    tarkistaOlemassaOlevaTunnus (tunnus) {
      if (this.kaareObjekti) return

      this.tarkistaOlemassaOlevaTunnusTimeout = setTimeout(async () => {
        try {
          const query = new URLSearchParams()
          query.append('method', 'tarkista_asiakas_tunnus')
          query.append('tunnus', tunnus.trim())
          query.append('paamies_id', this.asiakas.paamies_id || this.asiakas.paamies.id)

          const request = await this.$doRequestWithTimeout(
            this.$api.Asiakkaat,
            {
              url: 'toiminnot/tarkista/',
              query: query.toString(),
            },
            'pushRequest',
          )
          if (!request.success) throw new this.$HttpError(request)

          const body = request.result.body

          if (body && body.asiakkaat && body.asiakkaat.length) {
            let asiakkaatStr = ''
            body.asiakkaat.map((asiakas) => {
              asiakkaatStr += `\n- ${asiakas.nimi} (${asiakas.id})`
            })

            this.$naytaVaroitusilmoitus(
              'Syötetyllä tunnuksella on jo olemassa oleva asiakas:' + asiakkaatStr,
              { position: 'top-right'},
            )
          }
        } catch (e) {
          this.$sentryCaptureCustom(e)
        }
      }, 500)
    },
    /**
     * Tarkistaa onko käyttäjän päämiehien nimissä jo syötetyn kaltainen nimi.
     * Ks. onlinerestapin metodi _tarkista_asiakas_nimi
     */
    async tarkistaOlemassaOlevaNimi (syote) {
      clearTimeout(this.tarkistaOlemassaOlevaNimiTimeout)

      let nimi = syote.trim()
      if (
        this.asiakas.id ||
        !nimi ||
        (nimi.length <= 3)
      ) return

      this.tarkistaOlemassaOlevaNimiTimeout = setTimeout(async () => {
        try {
          const query = new URLSearchParams()
          query.append('method', 'tarkista_asiakas_nimi')
          query.append('nimi', nimi)
          query.append('paamies_id', this.asiakas.paamies_id || this.asiakas.paamies.id)

          const request = await this.$doRequestWithTimeout(
            this.$api.Asiakkaat,
            {
              url: 'toiminnot/tarkista/',
              query: query.toString(),
            },
            'pushRequest',
          )
          if (!request.success) throw new this.$HttpError(request)

          const body = request.result.body

          if (body && body.asiakkaat && body.asiakkaat.length) {
            let asiakkaatStr = ''
            body.asiakkaat.map((asiakas) => {
              if (!this.ilmoitetutOlemassaOlevatNimet.includes(asiakas.nimi))
                // Ilmoitetaan kustakin nimestä vain kerran, jotta käyttöliittymä ei ole häiritsevä.
                asiakkaatStr += `\n- ${asiakas.nimi} (${asiakas.nro ? 'nro: ' + asiakas.nro : 'id: ' + asiakas.id})`
                this.ilmoitetutOlemassaOlevatNimet.push(asiakas.nimi)
            })

            if (asiakkaatStr) {
              this.$naytaInfoilmoitus(
                'Samankaltaisella nimellä löytyi olemassa olevia asiakkaita:' + asiakkaatStr,
                {
                  timeout: 10000,
                  position: 'top-right'
                },
              )
            }
          }
        } catch (e) {
          this.$sentryCaptureCustom(e)
        }
      }, 1000)
    },
    tarkistaOlemassaOlevaNimiHenkilo () {
      if (this.asiakas.etunimi && this.asiakas.sukunimi) {
        this.tarkistaOlemassaOlevaNimi(`${this.asiakas.sukunimi} ${this.asiakas.etunimi}`)
      }
    },
    verkkolaskuoperaattoriFilter (item, hakusana) {
      const id = item.id.toLowerCase()
      const nimi = item.nimi.toLowerCase()
      const hakuteksti = hakusana.toLowerCase()

      return id.indexOf(hakuteksti) > -1 || nimi.indexOf(hakuteksti) > -1
    },
    asetaYrityksenTiedotLomakkeelle (yritys) {
      this.asiakas = Object.assign(this.asiakas, yritys)
      this.asiakas.postiosoite = new AsiakasPostiosoite(yritys.postiosoite)
    },
  }
}

</script>

<style lang="scss" scoped>

 .v-text-field, .v-select {
   padding-top: 0px;
   margin-top: 4px;
 }

 .hidden {
   visibility: hidden;
 }

</style>
