<template>
  <div class="offCanvasTuote">
    <v-toolbar
      color="primary"
      flat
      tabs
    >
      <v-btn
        :dark="true"
        icon
        @click="$emit('close-button-click')"
      >
        <v-icon>clear</v-icon>
      </v-btn>

      <v-toolbar-title>
        {{ $t('common:product.productInfo') }}
      </v-toolbar-title>

      <v-spacer />
    </v-toolbar>
    <v-container class="grey lighten-5 px-8">
      <OffCanvasDetailsTuote v-model="value" />
    </v-container>
    <v-container class="px-8 pt-6">
      <v-row justify="center">
        <v-btn
          color="primary"
          class="my-2 list-btn"
          large
          @click="valitseTuoteMuokattavaksi"
        >
          <v-icon left>
            edit
          </v-icon>
          {{ $t('common:product.editProduct') }}
        </v-btn>
      </v-row>
      <v-row justify="center">
        <v-btn
          color="primary"
          class="my-2 list-btn"
          large
          @click="valitseTuoteKopioitavaksi"
        >
          <v-icon left>
            edit
          </v-icon>
          {{ $t('common:product.copyProductAsNew') }}
        </v-btn>
      </v-row>
      <v-row justify="center">
        <v-btn
          color="primary"
          class="my-2 list-btn"
          large
          @click="valitseTuoteryhmaMuokattavaksi"
        >
          <v-icon left>
            edit
          </v-icon>
          {{ $t('common:productGroup.editProductGroup') }}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import ClickOutside from 'vue-click-outside'
import OffCanvasDetailsTuote from '@/components/OffCanvasDetailsTuote'

export default {
  name: 'OffCanvasTuote',
  directives: {
    ClickOutside,
  },
  components: {
    OffCanvasDetailsTuote
  },
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    valitseTuoteMuokattavaksi () {
      this.$emit('close-button-click')
      this.$emit('valitseTuoteMuokattavaksi', this.value)
    },
    valitseTuoteryhmaMuokattavaksi () {
      this.$emit('close-button-click')
      this.$emit('valitseTuoteryhmaMuokattavaksi', this.value.tuoteryhma)
    },
    valitseTuoteKopioitavaksi () {
      this.$emit('close-button-click')
      this.$emit('valitseTuoteKopioitavaksi', this.value)
    },
  }
}

</script>

<style lang="scss" scoped>

.list-btn {
  width: 268px;
}

</style>
