<template>
  <div class="bgImg">
    <div
      v-if="imgUrl"
      class="bgImg__img"
      :style="imgStyle"
    />
    <div
      v-if="overlayColor"
      class="bgImg__overlay"
      :style="overlayStyle"
    />
  </div>
</template>

<script>

export default {
  name: 'BgImg',
  props: {
    img: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    posX: {
      type: String,
      required: false,
      default () {
        return 'center'
      },
    },
    posY: {
      type: String,
      required: false,
      default () {
        return 'center'
      },
    },
    opacity: {
      type: [String, Number],
      required: false,
      default () {
        return 1
      },
    },
    overlayColor: {
      type: String,
      required: false,
      default () {
        return '#f9f9f9'
      },
    },
    overlayOpacity: {
      type: [Number, String],
      required: false,
      default () {
        return 0.65
      },
    },
  },
  computed: {
    imgStyle () {
      return {
        backgroundImage: (this.imgUrl) ? 'url(' + this.imgUrl + ')' : null,
        backgroundPositionX: (this.posX) ? this.posX : null,
        backgroundPositionY: (this.posY) ? this.posY : null,
        backgroundSize: 'cover',
        opacity: (this.opacity) ? this.opacity : null,
      }
    },
    overlayStyle () {
      return {
        backgroundColor: (this.overlayColor) ? this.overlayColor : null,
        opacity: (this.overlayOpacity) ? this.overlayOpacity : null,
      }
    },
    imgUrl () {
      if (!this.img) return null

      return require('@/assets/backgrounds/' + this.img + '.jpg')
    }
  },
}

</script>

<style lang="scss" scoped>

  .bgImg {
    z-index: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;

    &__img,
    &__overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

</style>
