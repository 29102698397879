var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          type: "text",
          label: "Selite",
          placeholder: "Anna mitätöinnin peruste",
          "persistent-placeholder": "",
          rules: [_vm.$validationRules.required],
        },
        model: {
          value: _vm.value.selite,
          callback: function ($$v) {
            _vm.$set(_vm.value, "selite", $$v)
          },
          expression: "value.selite",
        },
      }),
      _vm.lasku.sopimus
        ? _c("v-checkbox", {
            staticClass: "mx-2",
            attrs: { label: "Poista lasku sopimukselta" },
            model: {
              value: _vm.value.poista_sopimukselta,
              callback: function ($$v) {
                _vm.$set(_vm.value, "poista_sopimukselta", $$v)
              },
              expression: "value.poista_sopimukselta",
            },
          })
        : _vm._e(),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
          "submit-teksti": "Mitätöi",
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }