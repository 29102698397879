var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination py-4" },
    [
      _c(
        "v-pagination",
        _vm._b(
          {
            attrs: { "total-visible": _vm.totalVisible },
            model: {
              value: _vm.localState,
              callback: function ($$v) {
                _vm.localState = $$v
              },
              expression: "localState",
            },
          },
          "v-pagination",
          _vm.$props,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }