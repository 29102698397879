var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    ref: "autocompletePaamies",
    staticClass: "required show-hidden-child-on-hover",
    attrs: {
      items: _vm.paamiesItems,
      loading: _vm.paamiesLoading,
      "search-input": _vm.paamiesSearchQuery,
      "hide-no-data": !_vm.paamiesSearchQuery || _vm.paamiesLoading,
      "item-text": "nimi",
      "item-value": "id",
      label: _vm.label,
      placeholder: _vm.$t("common:autocompletePaamies.searchWithCreditorName"),
      "persistent-placeholder": "",
      "return-object": "",
      clearable: "",
      "cache-items": _vm.multiple,
      chips: _vm.multiple,
      "deletable-chips": _vm.multiple,
      multiple: _vm.multiple,
      counter: _vm.valintojenMaksimiLkm,
      rules: _vm.validointiSaannotKoottu,
      disabled: _vm.disabled,
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.paamiesSearchQuery = $event
      },
      "update:search-input": function ($event) {
        _vm.paamiesSearchQuery = $event
      },
      input: _vm.kasitteleValinta,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item",
          fn: function (ref) {
            var item = ref.item
            return [
              [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(_vm._s(item.nimi) + " (" + _vm._s(item.id) + ")"),
                    ]),
                    _vm.tarkistaLaskuEsto && item.esta_laskun_luonti
                      ? _c("v-list-item-subtitle", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "common:autocompletePaamies.createInvoiceNotPermitted"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm.tarkistaToimeksiantoEsto &&
                        item.esta_toimeksiannon_luonti
                      ? _c("v-list-item-subtitle", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "common:autocompletePaamies.createAssignmentNotPermitted"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : item.postiosoite
                      ? _c(
                          "v-list-item-subtitle",
                          [
                            item.postiosoite.postiosoite
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.postiosoite.postiosoite) +
                                      ", "
                                  ),
                                ]
                              : _vm._e(),
                            item.postiosoite.postinumero
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.postiosoite.postinumero) +
                                      " "
                                  ),
                                ]
                              : _vm._e(),
                            item.postiosoite.postitoimipaikka
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.postiosoite.postitoimipaikka
                                      ) +
                                      " "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
            ]
          },
        },
        {
          key: "no-data",
          fn: function () {
            return [
              _c("p", { staticClass: "pa-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "common:autocompletePaamies.searchFoundNoCreditors"
                      )
                    ) +
                    " "
                ),
              ]),
            ]
          },
          proxy: true,
        },
        _vm.paamieshallinta &&
        _vm.$permission.checkSinglePerm(
          "onlinerestapi.onlinerestapi_paamieshallinta"
        ) &&
        !_vm.tapaus.paamies.id
          ? {
              key: "append-outer",
              fn: function () {
                return [
                  _c("LomakenappiIkonilla", {
                    attrs: {
                      ikoni: "add",
                      infoteksti: _vm.$t(
                        "autocompletePaamies.createNewCreditor"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("open-dialog-paamies")
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
    model: {
      value: _vm.tapaus.paamies,
      callback: function ($$v) {
        _vm.$set(_vm.tapaus, "paamies", $$v)
      },
      expression: "tapaus.paamies",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }