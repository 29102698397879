import { Decimal } from 'decimal.js'
import Laskurivi from '@/class/Laskurivi'
import { Asiakas } from '@/class/Asiakas'
import store from '@/store'
import _ from 'lodash'
import { format as dateFnsFormat } from 'date-fns'

const TILA_AKTIIVINEN = 'A'
const TILA_MYYTY = 'M'
const TILA_PAATTYNYT = 'P'
const TILA_HYVAKSYTTAVA = 'S'
const TILA_HYLATTY = 'H'
const TILA_ERAANNYTETTY = 'E'

const Sopimus = class {
  static get TILA_AKTIIVINEN () {
    return TILA_AKTIIVINEN
  }

  static get TILA_MYYTY () {
    return TILA_MYYTY
  }

  static get TILA_PAATTYNYT () {
    return TILA_PAATTYNYT
  }

  static get TILA_HYVAKSYTTAVA () {
    return TILA_HYVAKSYTTAVA
  }

  static get TILA_HYLATTY () {
    return TILA_HYLATTY
  }

  static get TILA_ERAANNYTETTY () {
    return TILA_ERAANNYTETTY
  }

  static get LAHETYSPAIVAN_PERUSTEET () {
    return [
      {
        value: 'laskutuspaiva',
        selite: 'Laskutuspäivä'
      },
      {
        value: 'erapaiva',
        selite: 'Eräpäivä'
      },
    ]
  }

  static get LASKUTUSJAKSOT () {
    return [
      {
        value: 'KK',
        selite: 'Kuukausi',
        paivanValintaSelite: 'Kuukauden',
      },
      {
        value: 'VP',
        selite: 'Kuukausi (viimeinen päivä)',
        paivanValintaSelite: 'Kuukauden viimeinen päivä',
      },
      {
        value: 'KV',
        selite: 'Kvartaali',
        paivanValintaSelite: 'Kvartaalin 1. kuukauden',
      },
      {
        value: 'PU',
        selite: 'Puoli vuotta',
        paivanValintaSelite: 'Puolivuotiskauden 1. kuukauden',
      },
      {
        value: 'VU',
        selite: 'Vuosi',
        paivanValintaSelite: 'Yksivuotiskauden 1. kuukauden',
      },
    ]
  }

  static get SOPIMUSJAKSOT () {
    return [
      {
        value: 'VU',
        selite: 'Vuosi',
      },
      {
        value: 'KK',
        selite: 'Kuukausi',
      },
      {
        value: 'Vi',
        selite: 'Viikko',
      },
    ]
  }

  static get LASKUN_LAHETYS () {
    return [
      {
        value: 'K',
        selite: 'Kaikki laskut'
      },
      {
        value: '1',
        selite: 'Vain ensimmäinen lasku'
      },
      {
        value: '0',
        selite: 'Ei laskun lähetystä'
      },
    ]
  }

  // Read-only -kenttiä ei lähetetä luonnin tai muokkauksen yhteydessä backendiin
  constructor (lisaaTyhjaRivi = false) {
    this.id = null
    this.alkamispaiva = new Date().toISOString().substring(0, 10)
    this.asiakas = new Asiakas()
    this.erapaiva = ''
    this.kassaalennus_maksuaika = 0
    this.kassaalennus_prosentti = 0
    this.kirjepohja_id = ''
    this.kuukautta_aikaisemmin = false // Aloitetaanko laskutus alkamispäivään nähden kk:ta aiemmin
    this.lahetyspaivanPeruste = 'laskutuspaiva' // Read-only
    this.laji = ''
    this.laskunlahetys = 'K'
    this.laskutusjakso = 'KK'
    this.laskutuspaiva = ''
    this.loppumispaiva = '' // Päättymispäivä
    this.maksuehto = 14
    this.paamies = {}
    this.paivanumero = null // Laskupäivän tai eräpäivän numero
    this.pm_viite = ''
    this.rivit = []
    this.seuraava_erapaiva = null // Ei käytetä sopimusta luodessa
    this.seuraava_laskutuspaiva = null // Ei käytetä sopimusta luodessa
    this.sopimusaika = 1
    this.sopimusjakso = 'VU'
    this.sopimuslisatieto_set = [] // Read-only
    this.tila = {} // Read-only
    this.laskunteksti = '' // Laskulla näkyvä teksti
    this.vero = 0 // Read-only
    this.verollinen = 0 // Read-only
    this.veroton = 0 // Read-only
    this.viitteemme = ''
    this.viitteenne = ''
    this.viivastyskorko = null // Lasketaan backendissä automaattisesti kun postataan null

    if (lisaaTyhjaRivi) this.rivit.push(new Laskurivi(Laskurivi.oletusPreset))
  }

  asetaLisatietoKuukauttaAikaisemminJasenmuuttujaan () {
    this.kuukautta_aikaisemmin = !!this.sopimuslisatieto_set.find((lisatieto) => {
      return lisatieto.key === 'aloita_laskutus_kuukautta_aikaisemmin' &&
      // eslint-disable-next-line
      lisatieto.value == 1
    })
  }

  lisaaRivi (rowIndex) {
    if (typeof rowIndex !== 'undefined') {
      // Laskurivin lisäys olemassaolevalla rivillä oltaessa käyttäjän painaessa enteriä.
      this.rivit.splice(rowIndex + 1, 0, new Laskurivi(Laskurivi.oletusPreset))
    } else {
      this.rivit.push(new Laskurivi(Laskurivi.oletusPreset))
    }
  }

  poistaRivi (rowIndex) {
    this.rivit.splice(rowIndex, 1)
  }

  laskeYhteissummat () {
    // eslint-disable-next-line
    return new Promise(async (resolve) => {
      var veroton = 0
      var vero = 0
      var verollinen = 0

      for (const row of this.rivit) {
        await row.calculateTotals()

        veroton = new Decimal(veroton).plus(row.veroton)
        vero = new Decimal(vero).plus(row.vero)
        verollinen = new Decimal(verollinen).plus(row.verollinen)
      }

      this.veroton = veroton.toFixed(2)
      this.vero = vero.toFixed(2)
      this.verollinen = verollinen.toFixed(2)

      resolve()
    })
  }

  getPostData (paivitys = false) {
    let data = {
      kassaalennus_maksuaika: this.kassaalennus_maksuaika ? this.kassaalennus_maksuaika : 0,
      kassaalennus_prosentti: this.kassaalennus_prosentti ? this.kassaalennus_prosentti : 0,
      laji: this.laji,
      laskunlahetys: this.laskunlahetys,
      laskunteksti: this.laskunteksti,
      laskutusjakso: this.laskutusjakso,
      maksuehto: this.maksuehto,
      viitteemme: this.viitteemme,
      viitteenne: this.viitteenne,
    }

    if (this.laskutusjakso !== 'VP') {
      if (this.lahetyspaivanPeruste === 'erapaiva') {
        data.erapaiva = this.paivanumero
      } else {
        data.laskutuspaiva = this.paivanumero
      }
    } else {
      // Asetetaan laskutuspäivän arvoksi 1 kun valitaan kuukauden viimeinen päivä
      data.laskutuspaiva = 1
    }
    data.loppumispaiva = this.loppumispaiva ? this.loppumispaiva : null
    data.viivastyskorko = this.viivastyskorko ? this.viivastyskorko : null
    if (this.kirjepohja_id) data.kirjepohja_id = this.kirjepohja_id

    if (!paivitys) {
      const luontiData = {
        alkamispaiva: this.alkamispaiva,
        aloita_laskutus_kuukautta_aikaisemmin: !!this.kuukautta_aikaisemmin,
        paamies_id: this.paamies.id,
        sopimusaika: this.sopimusaika,
        sopimusasiakas_set: [
          {
            asiakas_id: this.asiakas.id,
            rooli_id: 'LASKUTUS',
          }
        ],
        sopimusjakso: this.sopimusjakso,
        sopimuslaskusisalto_set: this.rivitPostDataMuotoon(),
      }
      data = {
        ...luontiData,
        ...data,
      }
    } else {
      data.seuraava_laskutuspaiva = this.seuraava_laskutuspaiva
    }

    return data
  }

  rivitPostDataMuotoon () {
    const rivitPostData = []
    for (const r of this.rivit) {
      const riviData = r.getPostData()
      riviData.id = r.id ? r.id : null
      riviData.sopimus_id = this.id ? this.id : null
      rivitPostData.push(riviData)
    }

    return rivitPostData
  }

  getLaskusisaltoPostData () {
    return {
      sopimuslaskusisalto_set: this.rivitPostDataMuotoon()
    }
  }

  tasmaakoSeuraavaLaskutuspaiva () {
    if (this.erapaiva) {
      return this.erapaiva == new Date(this.seuraava_erapaiva).getDate()
    } else if (this.laskutuspaiva) {
      return this.laskutuspaiva == new Date(this.seuraava_laskutuspaiva).getDate()
    } else {
      return true
    }
  }

  seuraavaLaskutuspaivaVaroitus () {
    const paivanValintaSelite = _.find(Sopimus.LASKUTUSJAKSOT, ['value', this.laskutusjakso]).paivanValintaSelite.toLowerCase()
    let seuraavaPaivaTeksti = ''
    let laskutusvaliTeksti = ''

    if (this.erapaiva) {
      seuraavaPaivaTeksti = `eräpäivä ${dateFnsFormat(this.seuraava_erapaiva, 'D.M.YYYY')}`
      laskutusvaliTeksti = `eräpäivä ${paivanValintaSelite} ${this.erapaiva}. päivä`
    } else {
      seuraavaPaivaTeksti = `laskutuspäivä ${dateFnsFormat(this.seuraava_laskutuspaiva, 'D.M.YYYY')}`
      laskutusvaliTeksti = `laskutuspäivä ${paivanValintaSelite} ${this.laskutuspaiva}. päivä`
    }

    return `
      Sopimuksen seuraava ${seuraavaPaivaTeksti} ei vastaa sopimukselle määritettyä laskutusväliä: ${laskutusvaliTeksti}. Korjaa ohjaustietoja muokkaamalla joko eräpäivä tai seuraava eräpäivä. Poista sitten tarvittaessa laskuttamattomat laskutusaiheet ja lisää laskutusjaksoja. Mikäli päivää ei korjata edellä mainitulla tavalla, muodostuvat tulevat automaattisesti täydennettävät laskutusjaksot väärin.
      `
  }
}

export default Sopimus
