var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "sidebarLeft",
      attrs: {
        width: "250",
        app: "",
        permanent: "",
        "mini-variant": _vm.miniVariant,
      },
    },
    [
      _c("div", { staticClass: "sidebarLeft__wrap" }, [
        _c(
          "div",
          { staticClass: "sidebarLeft__section sidebarLeft__section--top" },
          [
            _c(
              "div",
              { staticClass: "sidebarLeft__block pa-4" },
              [
                _c("Logo", {
                  staticClass: "sidebarLeft__logo",
                  class: _vm.miniVariant ? null : "pa-3",
                  attrs: { small: _vm.miniVariant, "max-width": "130px" },
                }),
                _vm.tekeytyminenKaynnissa
                  ? _c("TekeytyminenAlert", {
                      attrs: { "mini-variant": _vm.miniVariant },
                    })
                  : _c("LoggedInUser", {
                      staticClass: "my-6",
                      class: _vm.miniVariant ? "px-0" : "px-4",
                      attrs: {
                        "show-username": !_vm.miniVariant,
                        "mini-variant": _vm.miniVariant,
                      },
                      on: {
                        logOut: _vm.logOut,
                        "siirry-onlineen": _vm.siirryOnlineen,
                      },
                    }),
                _c("NavMain", { attrs: { "show-titles": !_vm.miniVariant } }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "sidebarLeft__section sidebarLeft__section--bottom" },
          [
            _c("v-tooltip", {
              attrs: { right: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    return [
                      _vm.$vuetify.breakpoint.lgAndUp
                        ? _c(
                            "v-btn",
                            _vm._g(
                              {
                                class: _vm.toggleButtonClass,
                                attrs: { icon: "" },
                                on: { click: _vm.toggleWidth },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("arrow_back")])],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.toggleButtonTooltip) + " ")]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }