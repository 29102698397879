<template>
  <v-form
    ref="formRaportti"
    @submit.prevent="submit"
  >
    <v-row>
      <!-- Vasen sarake -->
      <v-col
        class="px-6 pt-6"
        md="6"
      >
        <span class="paper__title hidden-md-and-up">Raportti</span>

        <!-- Päämies -->
        <v-hover
          v-if="!user.vainYksiPaamies"
          v-slot:default="{ hover }"
          open-delay="50"
        >
          <v-card
            v-if="!user.vainYksiPaamies"
            class="pa-2 show-hidden-child-on-hover"
            :class="hover && $store.getters['user/salliPaamiehenTyhjennys'] ? 'elevation-2 hoverBackground' : 'elevation-0'"
          >
            <div>
              <AutocompletePaamies
                ref="autocompletePaamies"
                v-model="raporttilomake"
                :valintojen-maksimi-lkm="paamiesValintojenMaksimiLkm"
                label="Päämiehet"
                :multiple="(true)"
                tarkistettava-oikeus="onlinerestapi.onlinerestapi_raportti"
              />
            </div>
          </v-card>
        </v-hover>

        <!-- Raportti -->
        <div
          v-if="raporttilomake.paamies.length"
          class="pa-2"
        >
          <h3
            class="mb-8"
            :class="!user.vainYksiPaamies ? 'mt-8' : ''"
          >
            Raportin luomisen asetukset
          </h3>
          <v-autocomplete
            v-model="raportti"
            label="Raportti"
            :items="valittavatRaportitItems || []"
            item-value="id"
            item-text="nimi"
            return-object
            :menu-props="{ zIndex:'999' }"
            :rules="[$validationRules.requiredObject]"
            class="required"
          />
        </div>
        <template v-else>
          <p class="ma-2 font-no-data">
            Valitse ensin ainakin yksi päämies.
          </p>
        </template>
      </v-col>

      <!-- Oikea sarake -->
      <v-col
        class="px-6 pt-6"
        md="6"
      >
        <v-col class="pa-2 pt-0">
          <span class="paper__title hidden-sm-and-down">Raportti</span>
        </v-col>
      </v-col>
    </v-row>
    <component
      :is="valitunRaportinAsetuksetKomponentti"
      v-if="valitunRaportinAsetuksetKomponentti"
    />
  </v-form>
</template>

<script>

import { mapState } from 'vuex'
import AutocompletePaamies from '@/components/AutocompletePaamies'
import FormRaporttiAsetuksetKTLRaportti from '@/components/FormRaporttiAsetuksetKTLRaportti'
import FormRaporttiAsetuksetReskontraRaportti from '@/components/FormRaporttiAsetuksetReskontraRaportti'
import OsoiteKappale from '@/components/OsoiteKappale'
import { KtlRaportti, Raportti, ReskontraRaportti } from '@/class/Raportti'

export default {
  name: 'FormRaportti',
  components: {
    AutocompletePaamies,
    OsoiteKappale,
    FormRaporttiAsetuksetKTLRaportti,
    FormRaporttiAsetuksetReskontraRaportti,
  },
  data () {
    return {
      TYYPPI_RESKONTRARAPORTTI: { Raportti },
      TYYPPI_KTL_RAPORTTI: { Raportti },
    }
  },
  computed: {
    ...mapState({
      luotuRaportti: state => state.raportitUusi.luotuRaportti,
      reskontraraportit: state => state.raportitUusi.reskontraraportit,
      user: state => state.user,
    }),
    raporttilomake: {
      get () {
        return this.$store.state.raportitUusi.raporttilomake
      },
      set (val) {
        this.$store.commit('raportitUusi/setRaporttilomake', val)
      }
    },
    raportti: {
      get () {
        return this.$store.state.raportitUusi.raporttilomake.raportti
      },
      set (val) {
        this.$store.commit('raportitUusi/setRaporttilomakeRaportti', val)
      }
    },
    valitunRaportinAsetuksetKomponentti () {
      if (this.raporttilomake.raportti.tyyppi === Raportti.TYYPPI_RESKONTRARAPORTTI) {
        return 'FormRaporttiAsetuksetReskontraRaportti'
      } else if (this.raporttilomake.raportti.tyyppi === Raportti.TYYPPI_KTL_RAPORTTI) {
        return 'FormRaporttiAsetuksetKTLRaportti'
      } else {
        return ''
      }
    },
    paamiesValintojenMaksimiLkm () {
      return this.raporttilomake.raportti.constructor.name === 'KtlRaportti' ? 1 : false
    },
    reskontraraporttiItems () {
      return this.reskontraraportit.map(raportti => {
        return new ReskontraRaportti(raportti)
      })
    },
    valittavatRaportitItems () {
      const ktlRaportti = new KtlRaportti()

      let raportit = [
        ...this.reskontraraporttiItems,
        //ktlRaportti, Piilotettu toistaiseksi, koska tarvetta ei tällä hetkellä ole
      ]
      raportit = raportit.sort((a,b) => a.nimi > b.nimi ? 1 : -1)
      return raportit
    }
  },
  methods: {
    submit () {
      this.$emit('submit', this.raportti)
    },
    validoiRaportti () {
      let lomakeValidi = true

      if (!this.$refs.formRaportti.validate()) {
        this.$naytaVirheilmoitus('Tarkista lomakkeen kenttien sisältö!')
        lomakeValidi = false
      }

      return lomakeValidi
    },
  }
}

</script>

<style lang="scss" scoped>
  .hoverBackground {
    background-color: #e6510021;
  }

  .v-input {
    padding-top: 4px;
  }

  .col-icon {
    display: flex;
    justify-content: flex-end;
  }

</style>
