var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    ref: _vm.reference,
    class: _vm.required ? "required" : "",
    attrs: {
      items: _vm.tuoteryhmaItems,
      loading: _vm.tuoteryhmaLoading,
      "search-input": _vm.tuoteryhmaSearchQuery,
      "hide-no-data": !_vm.tuoteryhmaSearchQuery || _vm.tuoteryhmaLoading,
      placeholder:
        !_vm.tuoteryhmaSearchQuery && !_vm.tuoteryhmaItems.length
          ? "Ei tuoteryhmiä. Luo ensin ainakin yksi tuoteryhmä."
          : "",
      "item-text": "nimi",
      "item-value": "id",
      label: _vm.$t("common:productGroup.productGroupHeader"),
      "return-object": "",
      rules: _vm.rules,
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.tuoteryhmaSearchQuery = $event
      },
      "update:search-input": function ($event) {
        _vm.tuoteryhmaSearchQuery = $event
      },
      "click:append": _vm.uusiTuoteryhma,
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (ref) {
          var item = ref.item
          return [
            [
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(item.nimi))])],
                1
              ),
            ],
          ]
        },
      },
      {
        key: "no-data",
        fn: function () {
          return [
            _c(
              "v-list-item",
              [
                _c(
                  "v-list-item-title",
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { color: "primary" },
                                        on: { click: _vm.uusiTuoteryhma },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" add ")]
                                ),
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { on: { click: _vm.uusiTuoteryhma } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "common:productGroup.addProductGroup"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "common:productGroup.searchFoundNoProductGroups"
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "append",
        fn: function () {
          return [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: { color: "primary" },
                                on: { click: _vm.uusiTuoteryhma },
                              },
                              "v-icon",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_vm._v(" add ")]
                        ),
                      ]
                    },
                  },
                ]),
              },
              [_c("span", [_vm._v("Lisää tuoteryhmä")])]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.tuote.tuoteryhma,
      callback: function ($$v) {
        _vm.$set(_vm.tuote, "tuoteryhma", $$v)
      },
      expression: "tuote.tuoteryhma",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }