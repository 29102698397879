var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "ml-2",
      attrs: { color: _vm.color, to: _vm.to },
      on: { click: _vm.click },
    },
    [
      _vm.icon
        ? _c("v-icon", { attrs: { left: "" } }, [
            _vm._v(" " + _vm._s(_vm.icon) + " "),
          ])
        : _vm._e(),
      _vm._t("default", [_vm._v(" " + _vm._s(_vm.$t("common:button")) + " ")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }