<template>
  <div :class="classObj">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'FormTableCell',
  props: {
    header: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
  },
  computed: {
    classObj () {
      return {
        'formTable__cell': true,
        'formTable__cell--header': this.header,
      }
    }
  },
}

</script>

<style lang="scss">

  .formTable__cell {
    display: table-cell;
    padding-left: .5em;
    padding-right: .5em;
    width: 100%;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--header {
      // text-align: right;
      white-space: nowrap;
      width: auto;
    }

    .v-text-field {
      padding-top: 0;
      margin-top: 0;
    }

    .v-input--checkbox {
      padding-top: 0;
      margin-top: 0;
      position: relative;
      top: 8px;
    }
  }

</style>
