<template>
  <v-dialog
    v-model="dialogAuki"
    persistent
    :width="width"
  >
    <v-card
      ref="wrap"
      class="card-dialog"
    >
      <div
        v-if="title || showCloseButton"
        class="card-dialog__header"
      >
        <v-row
          align="center"
          :class="titleClass"
        >
          <v-col class="px-4">
            <h2
              v-if="title"
            >
              {{ title }}
              <TooltipInfo
                v-if="tooltipInfoTeksti"
                :text="tooltipInfoTeksti"
                icon-color="primary"
              />
            </h2>
          </v-col>
          <v-col class="px-4 shrink">
            <v-btn
              v-if="showCloseButton"
              icon
              class="ma-0"
              @click="$emit('close')"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="$slots['default']"
        ref="content"
        class="card-dialog__content"
        :class="contentClass"
      >
        <slot />
      </div>
      <div
        v-if="$slots['footer']"
        class="card-dialog__footer pa-4"
      >
        <div :class="footerClass">
          <slot name="footer" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'CardDialog',
  props: {
    dialogAuki: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    width: {
      type: String,
      required: false,
      default () {
        return "500"
      }
    },
    title: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    showCloseButton: {
      type: Boolean,
      required: false,
      default () {
        return true
      },
    },
    scrollToBottom: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    contentClass: {
      type: String,
      required: false,
      default () {
        return 'px-4 pb-4'
      },
    },
    titleClass: {
      type: String,
      required: false,
      default () {
        return 'pa-4 mx-0'
      }
    },
    footerClass: {
      type: String,
      required: false,
      default () {
        return 'pa-4'
      }
    },
    tooltipInfoTeksti: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  watch: {
    dialogAuki: {
      handler(arvo) {
        if (arvo) {
          this.$nextTick().then(() => {
            this.$refs['wrap'].$el.parentElement.scrollTop = 0
          })
        }
      }
    }
  },
  updated () {
    // Vieritä näkymä alas, kun dialogissa on tehty jokin korkeutta kasvattava toiminto,
    // kuten esim. viestin lähettäminen
    this.$nextTick().then(() => {
      if (this.scrollToBottom && this.$refs['content'] && this.$refs['wrap']) {
        this.$refs['wrap'].$el.parentElement.scrollTop = this.$refs['content'].clientHeight
      }
    })
  }
}

</script>

<style lang="scss" scoped>

  .card-dialog {
    overflow: auto;

    &__wrap {
      position: relative;
    }

    &__header,
    &__footer {
      position: sticky;
      z-index: 5;
      background: #fff;
      overflow-x: hidden;
    }

    &__header {
      font-size: 1.0em;
      font-weight: bold;
      top: 0;
    }

    &__footer {
      bottom: 0;
    }

    &__content {
      z-index: 1;
    }
  }

</style>
