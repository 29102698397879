import BaseOptimizedCountSelaa from "@/store/modules/baseOptimizedCountSelaa";
import { HttpError } from '@/utils/errors'
import { oletusVirheenkasittely } from '@/utils/misc'

export default class TuoteryhmaSelaa extends BaseOptimizedCountSelaa {
  state () {
    return {
      ...super.state(),
      apiName: 'Tuoteryhmat',
    }
  }

  actions () {
    return {
      ...super.actions(),
      async setSelectedItem ({ commit, getters }, { item, silent = false }) {
        try {
          if (!silent) commit('setLoadingOffCanvas', true)

          if (item.id === undefined) {
            commit('setSelectedItem', { item: {} })
          } else {
            commit('setShowOffCanvas', true)

            const api = getters.api
            const request = await api.pushRequest({
              method: 'GET',
              url: ':id/',
              params: {
                id: item.id
              },
            })

            if (!request.success) throw new HttpError(request)

            commit('setSelectedItem', { item: Object.assign({}, request.result.body) })
          }
        } catch (e) {
          oletusVirheenkasittely(e)
        } finally {
          commit('setLoadingOffCanvas', false)
        }
      },
    }
  }
}
