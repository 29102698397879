var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.naytaMaksutiedot
        ? _c(
            "div",
            [
              _c("h2", { staticClass: "my-4" }, [
                _vm._v(" " + _vm._s(_vm.$t("common:paymentInfo")) + " "),
              ]),
              _c("FormAsetuksetMaksutiedot", {
                on: { submit: _vm.maksutiedotSubmit },
                model: {
                  value: _vm.maksutiedotFormData,
                  callback: function ($$v) {
                    _vm.maksutiedotFormData = $$v
                  },
                  expression: "maksutiedotFormData",
                },
              }),
              _c("v-divider", { staticClass: "my-6" }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("h2", { staticClass: "my-4" }, [
                _vm._v(" " + _vm._s(_vm.$t("common:logo")) + " "),
              ]),
              _vm.$permission.checkSinglePerm(
                "onlinerestapi.onlinerestapi_lasku"
              )
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-3 align-self-start d-block",
                      attrs: { color: "info" },
                      on: { click: _vm.avaaPaamiesLogoDialog },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("common:settings.uploadLogo")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-col", [
            _vm.paamiesLogoOlemassa
              ? _c(
                  "div",
                  { staticClass: "d-flex align-left app-bg--white rounded" },
                  [
                    _c("v-img", {
                      staticClass: "mx-4",
                      attrs: {
                        alt: "Logo",
                        contain: "",
                        src: _vm.info.src,
                        "aspect-ratio": "1",
                        height: _vm.$vuetify.breakpoint.smAndDown ? 40 : 100,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.paamiesLogoOlemassa
              ? _c(
                  "div",
                  { staticClass: "d-flex align-left app-bg--white rounded" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("common:settings.logoNotUploaded")) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-6" }),
      _c("h2", { staticClass: "my-4" }, [
        _vm._v(" " + _vm._s(_vm.$t("common:settings.invoiceLetterText")) + " "),
      ]),
      _c("FormAsetuksetPaamiesasetukset", {
        on: { submit: _vm.paamiesasetuksetSubmit },
        model: {
          value: _vm.paamiesasetuksetFormData,
          callback: function ($$v) {
            _vm.paamiesasetuksetFormData = $$v
          },
          expression: "paamiesasetuksetFormData",
        },
      }),
      _vm.$permission.checkSinglePerm("onlinerestapi.onlinerestapi_beta")
        ? _c(
            "div",
            [
              _c("v-divider", { staticClass: "my-6" }),
              _c("h2", { staticClass: "my-4" }, [
                _vm._v(" Laskunumero (2.vaihe) "),
              ]),
              _vm._m(0),
              _c("h2", { staticClass: "my-4" }, [
                _vm._v(" Oletusasetukset (2.vaihe) "),
              ]),
              _vm._m(1),
            ],
            1
          )
        : _vm._e(),
      _c(
        "CardDialog",
        {
          ref: "refLogoLataus",
          attrs: {
            "dialog-auki": _vm.paamiesLogoDialogAuki,
            title: "Lisää laskukirjeen logo",
          },
          on: {
            close: function ($event) {
              _vm.paamiesLogoDialogAuki = false
            },
          },
        },
        [
          _vm.paamiesLogoOlemassa
            ? [
                _c(
                  "v-card",
                  {
                    staticClass: "mx-4 mb-4 pa-4 d-flex justify-space-between",
                    attrs: { color: "info" },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-2", attrs: { dark: "" } },
                          [_vm._v(" info ")]
                        ),
                        _c(
                          "span",
                          { staticClass: "text-subtitle-1 white--text" },
                          [_vm._v("Logo on jo lisätty.")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "v-card",
                  { staticClass: "mx-4 mb-4 pa-4", attrs: { color: "info" } },
                  [
                    _c("v-icon", { staticClass: "mr-2", attrs: { dark: "" } }, [
                      _vm._v(" info "),
                    ]),
                    _c("span", { staticClass: "text-subtitle-1 white--text" }, [
                      _vm._v("Uuden logon lisääminen korvaa vanhan logon."),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c("FormLiite", {
            staticClass: "pa-4",
            attrs: {
              loading: _vm.paamiesLogoFormLoading,
              "on-auki": _vm.paamiesLogoDialogAuki,
              "tarkista-logon-kuvasuhde": "",
            },
            on: {
              cancel: function ($event) {
                _vm.paamiesLogoDialogAuki = false
              },
              submit: _vm.paamiesLogoSubmit,
              close: function ($event) {
                _vm.paamiesLogoDialogAuki = false
              },
            },
            model: {
              value: _vm.paamiesLogoFormData,
              callback: function ($$v) {
                _vm.paamiesLogoFormData = $$v
              },
              expression: "paamiesLogoFormData",
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("seuraavan laskunumeron valinta")]),
      _c("li", [_vm._v("laskunumeron etuliitteen valinta")]),
      _c("li", [_vm._v("laskunumeron takaliiteen valinta")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("sähköpostiviestin saatesanat oletuksena")]),
      _c("li", [
        _vm._v("oletusasetus: laskulla näkyvä teksti ennen tuoterivejä"),
      ]),
      _c("li", [_vm._v("oletusasetus: (vain yritykset): viivästyskorko")]),
      _c("li", [_vm._v("oletusasetus: maksuehto")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }