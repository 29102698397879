var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "white--text",
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "tuoteryhma" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "common:productGroup.productGroupHeader"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "tuoteryhma",
                              label: "",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.tuoteryhma,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "tuoteryhma", $$v)
                              },
                              expression: "value.tuoteryhma",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "koodi" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("common:code")) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "koodi",
                              color: "white",
                              label: "",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.koodi,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "koodi", $$v)
                              },
                              expression: "value.koodi",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "selite" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("common:definition")) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "selite",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.selite,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "selite", $$v)
                              },
                              expression: "value.selite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }