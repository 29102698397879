var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "formLasku",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-6", attrs: { md: "6" } },
            [
              _c("span", { staticClass: "paper__title hidden-md-and-up" }, [
                _vm._v("Lasku"),
              ]),
              _c("v-hover", {
                attrs: { "open-delay": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "v-card",
                          {
                            staticClass: "pa-2 show-hidden-child-on-hover",
                            class:
                              hover &&
                              _vm.$store.getters["user/salliPaamiehenTyhjennys"]
                                ? "elevation-2 hoverBackground"
                                : "elevation-0",
                          },
                          [
                            _c(
                              "div",
                              [
                                _vm.lasku.paamies.id
                                  ? [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "space-between" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "6" },
                                            },
                                            [
                                              _c("h3", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("invoicer"))
                                                ),
                                              ]),
                                            ]
                                          ),
                                          !_vm.muokkaamassaLaskua &&
                                          _vm.$store.getters[
                                            "user/salliPaamiehenTyhjennys"
                                          ]
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "col-icon py-0",
                                                  attrs: { cols: "3" },
                                                },
                                                [
                                                  _c("LomakenappiIkonilla", {
                                                    attrs: {
                                                      ikoni: "clear",
                                                      infoteksti:
                                                        _vm.$t("clear"),
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.tyhjennaPaamies,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("OsoiteKappale", {
                                        attrs: {
                                          "renderoi-tyhja": true,
                                          "nayta-nimi": true,
                                          nimi: _vm.lasku.paamies.nimi,
                                          postiosoite: _vm.lasku.paamies
                                            .postiosoite
                                            ? _vm.lasku.paamies.postiosoite
                                                .postiosoite
                                            : null,
                                          postinumero: _vm.lasku.paamies
                                            .postiosoite
                                            ? _vm.lasku.paamies.postiosoite
                                                .postinumero
                                            : null,
                                          postitoimipaikka: _vm.lasku.paamies
                                            .postiosoite
                                            ? _vm.lasku.paamies.postiosoite
                                                .postitoimipaikka
                                            : null,
                                        },
                                      }),
                                    ]
                                  : [
                                      _c("AutocompletePaamies", {
                                        ref: "autocompletePaamies",
                                        attrs: {
                                          "tarkista-lasku-esto": true,
                                          "tarkistettava-oikeus":
                                            _vm.muokkaamassaLaskua
                                              ? "onlinerestapi.onlinerestapi_lasku_muokkaa"
                                              : "onlinerestapi.onlinerestapi_lasku",
                                        },
                                        on: {
                                          "autocomplete-input":
                                            _vm.paamiesKasitteleValinta,
                                        },
                                        model: {
                                          value: _vm.lasku,
                                          callback: function ($$v) {
                                            _vm.lasku = $$v
                                          },
                                          expression: "lasku",
                                        },
                                      }),
                                    ],
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.lasku.paamies.id
                ? [
                    _c("div", {
                      class: {
                        "py-3": _vm.$vuetify.breakpoint.smAndDown,
                        "py-5": _vm.$vuetify.breakpoint.mdAndUp,
                      },
                    }),
                    _c("v-hover", {
                      attrs: { "open-delay": "50" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "pa-2 show-hidden-child-on-hover",
                                    class: hover
                                      ? "elevation-2 hoverBackground"
                                      : "elevation-0",
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm.lasku.asiakas.id
                                          ? [
                                              _c(
                                                "v-row",
                                                {
                                                  attrs: {
                                                    justify: "space-between",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c("h3", {}, [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "recipient"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col-icon py-0",
                                                      attrs: { cols: "3" },
                                                    },
                                                    [
                                                      _vm.lasku.asiakas
                                                        .muistiinpano_set &&
                                                      _vm.lasku.asiakas
                                                        .muistiinpano_set.length
                                                        ? _c(
                                                            "LomakenappiIkonilla",
                                                            {
                                                              attrs: {
                                                                ikoni:
                                                                  "anitta-icon-note",
                                                                infoteksti:
                                                                  _vm.$t(
                                                                    "customerNotes"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$emit(
                                                                      "avaa-dialog-asiakas-muistiinpanot"
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "LomakenappiIkonilla",
                                                        {
                                                          attrs: {
                                                            ikoni: "clear",
                                                            infoteksti:
                                                              _vm.$t("clear"),
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.tyhjennaAsiakas,
                                                          },
                                                        }
                                                      ),
                                                      _c(
                                                        "LomakenappiIkonilla",
                                                        {
                                                          attrs: {
                                                            ikoni: "edit",
                                                            infoteksti:
                                                              _vm.$t(
                                                                "editCustomer"
                                                              ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "avaa-dialog-asiakas"
                                                              )
                                                            },
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("OsoiteKappale", {
                                                attrs: {
                                                  nimi: _vm.lasku.asiakas.nimi,
                                                  "renderoi-tyhja": true,
                                                  "nayta-nimi": true,
                                                  saaja:
                                                    _vm.lasku.asiakas
                                                      .postiosoite.saaja,
                                                  postiosoite:
                                                    _vm.lasku.asiakas
                                                      .postiosoite.osoite,
                                                  postinumero:
                                                    _vm.lasku.asiakas
                                                      .postiosoite.numero,
                                                  postitoimipaikka:
                                                    _vm.lasku.asiakas
                                                      .postiosoite.toimipaikka,
                                                },
                                              }),
                                            ]
                                          : [
                                              _c("v-autocomplete", {
                                                ref: "autocompleteAsiakas",
                                                staticClass: "required",
                                                attrs: {
                                                  items: _vm.asiakasItems,
                                                  loading: _vm.asiakasLoading,
                                                  "search-input":
                                                    _vm.asiakasSearchQuery,
                                                  "hide-no-data":
                                                    !_vm.asiakasSearchQuery ||
                                                    _vm.asiakasLoading,
                                                  "item-text": "nimi",
                                                  "item-value": "id",
                                                  label: _vm.$t("recipient"),
                                                  placeholder: _vm.lasku.paamies
                                                    .id
                                                    ? "Hae asiakkaan nimellä"
                                                    : "Valitse ensin laskuttaja",
                                                  "persistent-placeholder": "",
                                                  disabled:
                                                    !_vm.lasku.paamies.id,
                                                  "return-object": "",
                                                  rules: [_vm.validoiAsiakas],
                                                  "validate-on-blur": true,
                                                },
                                                on: {
                                                  "update:searchInput":
                                                    function ($event) {
                                                      _vm.asiakasSearchQuery =
                                                        $event
                                                    },
                                                  "update:search-input":
                                                    function ($event) {
                                                      _vm.asiakasSearchQuery =
                                                        $event
                                                    },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.nimi
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                item.postiosoite
                                                                  ? _c(
                                                                      "v-list-item-subtitle",
                                                                      [
                                                                        item
                                                                          .postiosoite
                                                                          .osoite
                                                                          ? [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item
                                                                                      .postiosoite
                                                                                      .osoite
                                                                                  ) +
                                                                                  ", "
                                                                              ),
                                                                            ]
                                                                          : _vm._e(),
                                                                        item
                                                                          .postiosoite
                                                                          .numero
                                                                          ? [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item
                                                                                      .postiosoite
                                                                                      .numero
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          : _vm._e(),
                                                                        item
                                                                          .postiosoite
                                                                          .toimipaikka
                                                                          ? [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item
                                                                                      .postiosoite
                                                                                      .toimipaikka
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          : _vm._e(),
                                                                      ],
                                                                      2
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "no-data",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "pa-4",
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$emit(
                                                                          "avaa-dialog-asiakas"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "lasku.searchFoundNoCustomers"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                    !_vm.lasku.asiakas.id
                                                      ? {
                                                          key: "append-outer",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "LomakenappiIkonilla",
                                                                {
                                                                  attrs: {
                                                                    ikoni:
                                                                      "person_add",
                                                                    infoteksti:
                                                                      _vm.$t(
                                                                        "createCustomer"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$emit(
                                                                          "avaa-dialog-asiakas"
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        }
                                                      : null,
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.lasku.asiakas,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.lasku,
                                                      "asiakas",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "lasku.asiakas",
                                                },
                                              }),
                                            ],
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4050942380
                      ),
                    }),
                  ]
                : _vm._e(),
              _vm.lasku.asiakas.id
                ? [
                    _c("div", {
                      class: {
                        "py-2": _vm.$vuetify.breakpoint.smAndDown,
                        "py-3": _vm.$vuetify.breakpoint.mdAndUp,
                      },
                    }),
                    _c("v-hover", {
                      attrs: { "open-delay": "50" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "pa-2 show-hidden-child-on-hover",
                                    class: hover
                                      ? "elevation-2 hoverBackground"
                                      : "elevation-0",
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _vm.lasku.toimitusosoite.osoite
                                          ? [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "pt-4",
                                                  attrs: {
                                                    justify: "space-between",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: { cols: "6" },
                                                    },
                                                    [
                                                      _c("h3", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "deliveryAddress"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col-icon py-0",
                                                      attrs: { cols: "3" },
                                                    },
                                                    [
                                                      _c(
                                                        "LomakenappiIkonilla",
                                                        {
                                                          attrs: {
                                                            ikoni: "clear",
                                                            infoteksti:
                                                              _vm.$t("clear"),
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.tyhjennaToimitusosoite,
                                                          },
                                                        }
                                                      ),
                                                      _c(
                                                        "LomakenappiIkonilla",
                                                        {
                                                          attrs: {
                                                            ikoni: "edit",
                                                            infoteksti: _vm.$t(
                                                              "editDeliveryAddress"
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$emit(
                                                                "avaa-dialog-toimitusosoite"
                                                              )
                                                            },
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("OsoiteKappale", {
                                                staticClass: "pb-2",
                                                attrs: {
                                                  "nayta-nimi": false,
                                                  saaja:
                                                    _vm.lasku.toimitusosoite
                                                      .saaja,
                                                  postiosoite:
                                                    _vm.lasku.toimitusosoite
                                                      .osoite,
                                                  postinumero:
                                                    _vm.lasku.toimitusosoite
                                                      .numero,
                                                  postitoimipaikka:
                                                    _vm.lasku.toimitusosoite
                                                      .toimipaikka,
                                                },
                                              }),
                                              _c("v-text-field", {
                                                staticClass: "pr-12 mt-2",
                                                attrs: {
                                                  label: "Toimitustapa",
                                                },
                                                model: {
                                                  value: _vm.lasku.toimitustapa,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.lasku,
                                                      "toimitustapa",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "lasku.toimitustapa",
                                                },
                                              }),
                                            ]
                                          : [
                                              _c("v-select", {
                                                attrs: {
                                                  items:
                                                    _vm.toimitusosoiteItems,
                                                  "item-text": "osoite",
                                                  label: "Toimitusosoite",
                                                  "no-data-text":
                                                    "Ei olemassaolevia toimitusosoitteita",
                                                  disabled:
                                                    !_vm.lasku.asiakas.id,
                                                  "return-object": "",
                                                  "item-value": "id",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.osoite
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-list-item-subtitle",
                                                                  [
                                                                    item.saaja
                                                                      ? [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.saaja
                                                                              ) +
                                                                              ", "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                    item.osoite
                                                                      ? [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.osoite
                                                                              ) +
                                                                              ", "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                    item.numero
                                                                      ? [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.numero
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                    item.toimipaikka
                                                                      ? [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.toimipaikka
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "append-outer",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "LomakenappiIkonilla",
                                                            {
                                                              attrs: {
                                                                ikoni: "add",
                                                                infoteksti:
                                                                  _vm.$t(
                                                                    "addDeliveryAddress"
                                                                  ),
                                                                "nayta-aina":
                                                                  "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$emit(
                                                                      "avaa-dialog-toimitusosoite"
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.lasku.toimitusosoite,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.lasku,
                                                      "toimitusosoite",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "lasku.toimitusosoite",
                                                },
                                              }),
                                            ],
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1814657325
                      ),
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "v-col",
            { staticClass: "pa-6", attrs: { md: "6" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-2 pt-0" },
                [
                  _c(
                    "span",
                    { staticClass: "paper__title hidden-sm-and-down" },
                    [_vm._v(_vm._s(_vm.$t("invoice")))]
                  ),
                  _c("v-text-field", {
                    ref: "laskunumero",
                    staticClass: "mt-1 pt-1",
                    attrs: {
                      label: "Laskunumero",
                      disabled: _vm.lasku.laskunumeroDisabloitu,
                    },
                    model: {
                      value: _vm.lasku.laskunumero,
                      callback: function ($$v) {
                        _vm.$set(_vm.lasku, "laskunumero", $$v)
                      },
                      expression: "lasku.laskunumero",
                    },
                  }),
                  _c("v-menu", {
                    attrs: {
                      "close-on-content-click": false,
                      "max-width": "290",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                {
                                  staticClass: "mt-1 pt-1",
                                  class: _vm.laskupaivaClassArr,
                                  attrs: {
                                    label: "Laskupäivä",
                                    value: _vm.$date(_vm.lasku.laskupaiva),
                                    readonly: "",
                                    rules: [_vm.$validationRules.required],
                                  },
                                },
                                on
                              )
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("v-date-picker", {
                              staticClass: "mt-1 pt-1",
                              attrs: {
                                locale: "fi-FI",
                                "first-day-of-week": "1",
                                "no-title": "",
                              },
                              on: {
                                change: function ($event) {
                                  _vm.laskupaivaMenu = false
                                },
                              },
                              model: {
                                value: _vm.lasku.laskupaiva,
                                callback: function ($$v) {
                                  _vm.$set(_vm.lasku, "laskupaiva", $$v)
                                },
                                expression: "lasku.laskupaiva",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.laskupaivaMenu,
                      callback: function ($$v) {
                        _vm.laskupaivaMenu = $$v
                      },
                      expression: "laskupaivaMenu",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-1 pt-1",
                    class: _vm.maksuehtoClassArr,
                    attrs: {
                      label: "Maksuehto",
                      rules: [
                        _vm.$validationRules.notIsNaN,
                        _vm.$validationRules.zeroOrBigger,
                      ],
                      suffix: "pv netto",
                      "full-width": false,
                    },
                    model: {
                      value: _vm.lasku.maksuehto,
                      callback: function ($$v) {
                        _vm.$set(_vm.lasku, "maksuehto", $$v)
                      },
                      expression: "lasku.maksuehto",
                    },
                  }),
                  _c("v-menu", {
                    attrs: {
                      "close-on-content-click": false,
                      "max-width": "290",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                {
                                  staticClass: "mt-1 pt-1",
                                  class: _vm.erapaivaClassArr,
                                  attrs: {
                                    label: "Eräpäivä",
                                    value: _vm.$date(_vm.lasku.erapaiva),
                                    readonly: "",
                                    rules: [_vm.$validationRules.required],
                                  },
                                },
                                on
                              )
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("v-date-picker", {
                              attrs: {
                                locale: "fi-FI",
                                "first-day-of-week": "1",
                                "no-title": "",
                              },
                              on: {
                                change: function ($event) {
                                  _vm.erapaivaMenu = false
                                },
                              },
                              model: {
                                value: _vm.lasku.erapaiva,
                                callback: function ($$v) {
                                  _vm.$set(_vm.lasku, "erapaiva", $$v)
                                },
                                expression: "lasku.erapaiva",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.erapaivaMenu,
                      callback: function ($$v) {
                        _vm.erapaivaMenu = $$v
                      },
                      expression: "erapaivaMenu",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "required mt-1 pt-1",
                    attrs: {
                      type: "number",
                      label: "Viivästyskorko",
                      suffix: "%",
                      rules: [
                        _vm.$validationRules.requiredLoose,
                        _vm.tarkistaViivkorko,
                      ],
                      "validate-on-blur": true,
                    },
                    model: {
                      value: _vm.lasku.viivastyskorko,
                      callback: function ($$v) {
                        _vm.$set(_vm.lasku, "viivastyskorko", $$v)
                      },
                      expression: "lasku.viivastyskorko",
                    },
                  }),
                  _vm.muokkaamassaLaskua
                    ? _c("v-text-field", {
                        staticClass: "mt-1 pt-1",
                        attrs: { label: "PM-viite", type: "text" },
                        model: {
                          value: _vm.lasku.pm_viite,
                          callback: function ($$v) {
                            _vm.$set(_vm.lasku, "pm_viite", $$v)
                          },
                          expression: "lasku.pm_viite",
                        },
                      })
                    : _vm._e(),
                  _c("v-text-field", {
                    staticClass: "mt-1 pt-1",
                    attrs: { label: "Viitteemme", type: "text" },
                    model: {
                      value: _vm.lasku.viitteemme,
                      callback: function ($$v) {
                        _vm.$set(_vm.lasku, "viitteemme", $$v)
                      },
                      expression: "lasku.viitteemme",
                    },
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-1 pt-1",
                    attrs: { label: "Viitteenne", type: "text" },
                    model: {
                      value: _vm.lasku.viitteenne,
                      callback: function ($$v) {
                        _vm.$set(_vm.lasku, "viitteenne", $$v)
                      },
                      expression: "lasku.viitteenne",
                    },
                  }),
                  _c("v-select", {
                    staticClass: "mt-1 pt-1",
                    attrs: {
                      label: "Laskupohja",
                      type: "text",
                      items: _vm.laskupohjatItems,
                      "item-text": "str",
                      "item-value": "id",
                      "no-data-text": "Ei laskupohjia",
                    },
                    model: {
                      value: _vm.lasku.kirjepohja_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.lasku, "kirjepohja_id", $$v)
                      },
                      expression: "lasku.kirjepohja_id",
                    },
                  }),
                  _c("v-textarea", {
                    staticClass: "mt-1 pt-1",
                    attrs: {
                      label: "Laskulla näkyvä teksti",
                      type: "text",
                      rows: "2",
                      counter:
                        _vm.lasku.vapaateksti.length > 1023 ? 1023 : undefined,
                      rules: [
                        _vm.$validationRules.tooManyCharacters(
                          _vm.lasku.vapaateksti.length,
                          1023
                        ),
                      ],
                    },
                    model: {
                      value: _vm.lasku.vapaateksti,
                      callback: function ($$v) {
                        _vm.$set(_vm.lasku, "vapaateksti", $$v)
                      },
                      expression: "lasku.vapaateksti",
                    },
                  }),
                  _c("v-select", {
                    staticClass: "mt-1 pt-1",
                    attrs: {
                      label: "Myyjä",
                      type: "text",
                      clearable: "",
                      items: _vm.myyjatItems,
                      "item-text": "str",
                      "item-value": "id",
                    },
                    model: {
                      value: _vm.lasku.myyja_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.lasku, "myyja_id", $$v)
                      },
                      expression: "lasku.myyja_id",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pa-6" },
        [
          _c("h2", { staticClass: "mb-4" }, [_vm._v(" Laskurivit ")]),
          _c("LaskuriviTable", {
            ref: "laskuriviTable",
            attrs: { "cell-size": "small" },
            model: {
              value: _vm.lasku,
              callback: function ($$v) {
                _vm.lasku = $$v
              },
              expression: "lasku",
            },
          }),
          _c(
            "v-row",
            { staticClass: "mt-8" },
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("AutocompleteLaskurivi", {
                    attrs: { lasku: _vm.lasku },
                    on: {
                      "autocomplete-input": _vm.poistaTyhjaEnsimmainenRivi,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }