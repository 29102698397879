<template>
  <div>
    <v-form ref="saatavariviTable">
      <table
        class="riviTable"
      >
        <thead>
          <tr>
            <th
              :class="[...thClassArr, 'riviTable__cell--required']"
              scope="col"
            >
              {{ $t('common:invoiceNo') }}
            </th>
            <th
              :class="[...thClassArr, 'riviTable__cell--required']"
              scope="col"
            >
              {{ $t('common:invoiceDate') }}
            </th>
            <th
              :class="[...thClassArr, 'riviTable__cell--required']"
              scope="col"
            >
              {{ $t('common:dueDate') }}
            </th>
            <template v-if="toimeksianto.muistutettu">
              <th
                :class="[...thClassArr, 'riviTable__cell--required']"
                scope="col"
              >
                {{ $t('common:reminderDate') }}
              </th>
              <th
                :class="[...thClassArr, 'riviTable__cell--required']"
                scope="col"
              >
                {{ $t('common:reminderDueDate') }}
              </th>

              <th
                :class="[...thClassArr, 'riviTable__cell--required']"
                scope="col"
              >
                {{ $t('common:reminderFee') }}
              </th>
            </template>
            <th
              :class="[...thClassArr, 'riviTable__cell--required']"
              scope="col"
            >
              {{ $t('common:invoiceRef') }}
            </th>
            <th
              :class="[...thClassArr, 'riviTable__cell--required', 'text-right']"
              scope="col"
            >
              {{ $t('common:capital') }}
            </th>
            <th
              :class="thClassArr"
              scope="col"
            >
              {{ $t('common:definition') }}
            </th>
            <th
              :class="thClassArr"
              scope="col"
            >
              {{ $t('common:lateInterest') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in toimeksianto.rivit"
            :key="item.index"
            :disabled="(true)"
            @input="lahetaEventKayttajasyote"
          >
            <td :class="[...tdClassArr, 'riviTable__cell--min-width-medium']">
              <v-text-field
                v-model="item.laskunumero"
                type="text"
                hide-details
                :rules="[$validationRules.required]"
                @keydown.enter="toimeksianto.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-menu
                v-model="laskupaivaMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="$date(item.laskupaiva)"
                    readonly
                    hide-details
                    :rules="[$validationRules.required]"
                    v-on="on"
                  />
                </template>
                <template v-slot:default>
                  <v-date-picker
                    v-model="item.laskupaiva"
                    :locale="$languageLocale()"
                    first-day-of-week="1"
                    no-title
                    @change="laskupaivaMenu = false"
                  />
                </template>
              </v-menu>
            </td>
            <td :class="tdClassArr">
              <v-menu
                v-model="erapaivaMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="$date(item.erapaiva)"
                    readonly
                    hide-details
                    :rules="[$validationRules.required]"
                    v-on="on"
                  />
                </template>
                <template v-slot:default>
                  <v-date-picker
                    v-model="item.erapaiva"
                    :locale="$languageLocale()"
                    first-day-of-week="1"
                    no-title
                    @change="erapaivaMenu = false"
                  />
                </template>
              </v-menu>
            </td>
            <template v-if="toimeksianto.muistutettu">
              <td :class="tdClassArr">
                <v-menu
                  v-model="muistutuspaivaMenu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="$date(item.muistutuspaiva)"
                      readonly
                      hide-details
                      :rules="toimeksianto.muistutettu ? [$validationRules.required] : []"
                      v-on="on"
                    />
                  </template>
                  <template v-slot:default>
                    <v-date-picker
                      v-model="item.muistutuspaiva"
                      :locale="$languageLocale()"
                      first-day-of-week="1"
                      no-title
                      @change="muistutuspaivaMenu = false"
                    />
                  </template>
                </v-menu>
              </td>
              <td :class="tdClassArr">
                <v-menu
                  v-model="muistutuserapaivaMenu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="$date(item.muistutuserapaiva)"
                      readonly
                      hide-details
                      :rules="toimeksianto.muistutettu ? [$validationRules.required] : []"
                      v-on="on"
                    />
                  </template>
                  <template v-slot:default>
                    <v-date-picker
                      v-model="item.muistutuserapaiva"
                      :locale="$languageLocale()"
                      first-day-of-week="1"
                      no-title
                      @change="muistutuserapaivaMenu = false"
                    />
                  </template>
                </v-menu>
              </td>
              <td :class="tdClassArr">
                <v-text-field
                  v-model="item.muistutuskulu"
                  type="number"
                  :rules="toimeksianto.muistutett ? [$validationRules.required] : []"
                  required
                  reverse
                  hide-details
                  @keydown.enter="toimeksianto.lisaaRivi(index)"
                />
              </td>
            </template>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.pm_viite"
                type="text"
                reverse
                hide-details
                @keydown.enter="toimeksianto.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.paaoma"
                type="number"
                :rules="[$validationRules.required]"
                required
                reverse
                hide-details
                @keydown.enter="toimeksianto.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.selite"
                type="text"
                reverse
                hide-details
                @keydown.enter="toimeksianto.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.viivastyskorko"
                type="number"
                :rules="[tarkistaViivkorko]"
                :hint="viivkorkoInfo"
                suffix="%"
                @keydown.enter="toimeksianto.lisaaRivi(index)"
              />
            </td>
            <td class="riviTable__cell--btn">
              <LomakenappiIkonilla
                v-if="salliPoistaminen"
                ikoni="delete"
                :infoteksti="$t('deleteRow')"
                nayta-aina
                @click="poistaRivi(index)"
              />
            </td>
          </tr>
        </tbody>
        <tfoot
          v-if="naytaYhteenveto"
          class="riviTable__footer"
        >
          <div class="mt-5"></div>
          <tr>
            <td>
              <v-btn

                color="primary"
                @click="toimeksianto.lisaaRivi()"
              >
                <v-icon
                  left
                  icon
                >
                  library_add
                </v-icon>
                {{ $t('common:newRow') }}
              </v-btn>
            </td>
            <th
              class="riviTable__cell riviTable__cell--footer"
              colspan="3"
              scope="row"
            >
              {{ $t('common:total') }}
            </th>
            <td class="riviTable__cell riviTable__cell--footer">
              {{ paaomaYhteensa | formatSumToFixed2 }}
            </td>
            <td class="riviTable__cell riviTable__cell--footer">
            </td>
            <td class="riviTable__cell riviTable__cell--footer"></td>
          </tr>
        </tfoot>
      </table>
    </v-form>
  </div>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'
import { Saatavarivi } from '@/class/Toimeksianto'
import LomakenappiIkonilla from '@/components/LomakenappiIkonilla'

export default {
  name: 'SaatavariviTable',
  components: {
    LomakenappiIkonilla,
  },
  mixins: [FormMixin],
  model: {
    prop: 'toimeksianto'
  },
  props: {
    toimeksianto: {
      type: Object,
      required: true,
    },
    salliPoistaminen: {
      type: Boolean,
      required: false,
      default () {
        return true
      },
    },
    naytaYhteenveto: {
      type: Boolean,
      required: false,
      default () {
        return true
      },
    },
    cellSize: {
      type: String,
      required: false,
      validator: (arvo) => ['small', 'medium'].includes(arvo),
      default () {
        return 'medium'
      }
    },
  },
  data () {
    return {
      erapaivaMenu: false,
      laskupaivaMenu: false,
      muistutuspaivaMenu: false,
      muistutuserapaivaMenu: false,
    }
  },
  computed: {
    thClassArr () {
      return [
        'riviTable__cell',
        'riviTable__cell--header',
        this.cellSize === 'small' ? 'riviTable__cell--small' : 'riviTable__cell--medium',
      ]
    },
    tdClassArr () {
      return [
        'riviTable__cell',
        this.cellSize === 'small' ? 'riviTable__cell--small' : 'riviTable__cell--medium',
      ]
    },
    paaomaYhteensa () {
      let summa = 0

      for (const sr of this.toimeksianto.rivit) {
        summa += parseFloat(sr.paaoma) || 0
      }

      return summa
    },
    viivkorkoInfo () {
      return `Henkilöasiakkaan viivästyskorko voi olla korkeintaan ${Saatavarivi.HENKILO_MAX_VIIVKORKO}%.`
    }
  },
  methods: {
    poistaRivi (rowIndex) {
      if (!this.toimeksianto.rivit[rowIndex].isEmpty()) {
        this.$swal({
          text: this.$t('common:saatavariviTable.sureReceivablesRowDeleted'),
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: this.$t('common:saatavariviTable.deleteReceivablesRow'),
          cancelButtonText: this.$t('common:saatavariviTable.cancel'),
        }).then(result => {
          if (!result.value) return

          this.toimeksianto.poistaRivi(rowIndex)
          this.lahetaEventKayttajasyote()
        })
      } else {
        this.toimeksianto.poistaRivi(rowIndex)
        this.lahetaEventKayttajasyote()
      }
    },
    lahetaEventKayttajasyote () {
      this.$emit('kayttajasyote')
    },
    validate () {
      return this.$refs.saatavariviTable.validate()
    },
    tarkistaViivkorko (viivkorko) {
      const maxKorko = Saatavarivi.HENKILO_MAX_VIIVKORKO
      if (this.toimeksianto.velallinen.tyyppi === 'H' && viivkorko > maxKorko) {
        return `Henkilöasiakkaan viivästyskorko voi olla korkeintaan ${maxKorko}%`
      }

      return true
    },
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/riviTableStyles.scss';

</style>
