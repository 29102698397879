
export default {
  namespaced: true,
  state: {

  },
  mutations: {
    naytaIlmoitus (state, payload) {
      const defaultConfig = {
        timeout: payload.text ? 4000 + payload.text.length * 10 : 5000,
      }
      const toastConfig = Object.assign(defaultConfig, payload.config)
      this._vm.$toast(payload.text || payload.content, toastConfig)
    },
  },
}
