var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-menu", {
    attrs: { disabled: _vm.disabled, bottom: "", "nudge-bottom": "40" },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function (ref) {
          var on = ref.on
          return [
            _c(
              "v-btn",
              _vm._g(
                {
                  attrs: {
                    disabled: _vm.disabled,
                    color: "primary",
                    dark: !_vm.disabled,
                  },
                },
                on
              ),
              [
                _c("v-icon", { attrs: { left: "" } }, [_vm._v("more_vert")]),
                _vm._v(" Toiminnot "),
                _vm.itemCount > 0
                  ? [_vm._v("(" + _vm._s(_vm.itemCount) + " kpl)")]
                  : _vm._e(),
              ],
              2
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "v-list",
              [
                _c(
                  "v-list-item",
                  {
                    attrs: { disabled: !_vm.hyvaksyMaksuunSallittu },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("hyvaksyMaksuun")
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item-avatar",
                      [_c("v-icon", [_vm._v("check")])],
                      1
                    ),
                    _c("v-list-item-title", [_vm._v(" Hyväksy maksuun ")]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  {
                    attrs: { disabled: !_vm.hylkaaLaskuSallittu },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("hylkaa")
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item-avatar",
                      [_c("v-icon", [_vm._v("cancel")])],
                      1
                    ),
                    _c("v-list-item-title", [_vm._v(" Hylkää lasku ")]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  {
                    attrs: { disabled: !_vm.palautaAvoimeksiSallittu },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("palautaAvoimeksi")
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item-avatar",
                      [_c("v-icon", [_vm._v("keyboard_return")])],
                      1
                    ),
                    _c("v-list-item-title", [_vm._v(" Palauta avoimeksi ")]),
                  ],
                  1
                ),
                _c(
                  "v-list-item",
                  {
                    attrs: { disabled: !_vm.vieMaksuunSallittu },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("vieMaksuun")
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item-avatar",
                      [_c("v-icon", [_vm._v("euro")])],
                      1
                    ),
                    _c("v-list-item-title", [_vm._v(" Vie maksuun ")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }