<template>
  <div>
    <OffCanvasToolbar
      toolbar-title="Kayttajan tiedot"
      tooltip-title="kayttaja"
      @close-button-click="$emit('close-button-click')"
    />
    <v-container class="grey lighten-5 px-8">
      <v-row>
        <v-col>
          <v-btn
            v-if="naytaTekeytymisnappi"
            :disabled="lukittu"
            @click="tekeydy"
          >
            Tekeydy käyttäjäksi
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import ClickOutside from 'vue-click-outside'
import OffCanvasToolbar from '@/components/OffCanvasToolbar'

export default {
  name: 'OffCanvasKayttaja',
  directives: {
    ClickOutside,
  },
  components: {
    OffCanvasToolbar,
  },
  props: {
    kayttaja: {
      type: Object,
      required: true,
    },
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    lukittu () {
      if (this.user.tekeytyminenKaynnissa) return true
      if (this.user.id === this.kayttaja.id) return true
      if (!this.kayttaja.is_active) return true
      if (this.kayttaja.is_superuser) return true
      if (this.user.isSuperuser) return false
      if (this.kayttaja.is_staff) return true

      return false
    },
    naytaTekeytymisnappi () {
      return (
        this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_tekeytyminen') &&
        (this.user.isSuperuser || this.user.isStaff)
      )
    },
  },
  methods: {
    async tekeydy () {
      try {
        await this.$store.dispatch('user/tekeydy', {
          kayttajaId: this.kayttaja.id,
          siirrySivulle: 'tyopoyta',
        })
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
  }
}

</script>

<style lang="scss" scoped>

</style>
