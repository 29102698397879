<template>
  <v-form
    ref="form"
    v-model="valid"
    class="white--text"
    @submit.prevent
  >
    <v-row>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="laskunumeroAlkaen">
                Laskunumero
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="laskunumero"
                v-model="value.laskunumero"
                type="number"
                label=""
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="sopimus">
                Sopimus
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="sopimus"
                v-model="value.sopimus"
                type="number"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <template v-if="ostolasku">
                <FormTableLabel for="toimittaja">
                  Toimittaja
                </FormTableLabel>
              </template>
              <template v-else>
                <FormTableLabel for="asiakas">
                  Asiakas
                </FormTableLabel>
              </template>
            </FormTableCell>
            <FormTableCell>
              <template v-if="ostolasku">
                <v-text-field
                  id="toimittaja"
                  v-model="value.toimittaja"
                  color="white"
                  label="Nimi, tunnus tai toimittajanumero"
                  clearable
                  :dark="true"
                />
              </template>
              <template v-else>
                <v-text-field
                  id="asiakas"
                  v-model="value.asiakas"
                  color="white"
                  label="Nimi, tunnus tai asiakasnumero"
                  clearable
                  :dark="true"
                />
              </template>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <template v-if="ostolasku">
                <FormTableLabel for="toimittajaTyyppi">
                  Toimittajan tyyppi
                </FormTableLabel>
              </template>
              <template v-else>
                <FormTableLabel for="asiakasTyyppi">
                  Asiakkaan tyyppi
                </FormTableLabel>
              </template>
            </FormTableCell>
            <FormTableCell>
              <template v-if="ostolasku">
                <v-select
                  id="toimittajaTyyppi"
                  v-model="value.toimittajaTyyppi"
                  :items="toimittajaTyypit"
                  item-text="label"
                  item-value="terms"
                  multiple
                  clearable
                  :dark="true"
                />
              </template>
              <template v-else>
                <v-select
                  id="asiakasTyyppi"
                  v-model="value.asiakasTyyppi"
                  :items="asiakasTyypit"
                  item-text="label"
                  item-value="terms"
                  multiple
                  clearable
                  :dark="true"
                />
              </template>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow v-if="rahoitusKaytossa">
            <FormTableCell :header="(true)">
              <FormTableLabel
                for="rahoitus"
                class="mt-n3"
              >
                Rahoitettu
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-checkbox
                id="rahoitus"
                v-model="value.rahoitus"
                class="mt-n1"
                color="white"
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="tila">
                Tila
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-select
                id="tila"
                v-model="value.tila"
                :items="tilat"
                item-text="label"
                item-value="terms"
                multiple
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="paamies">
                Päämies
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="paamies"
                v-model="value.paamies"
                color="white"
                label="Nimi tai numero"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="laskupaivaAlkaen">
                Laskupäivä
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <InputfieldDatePicker
                  id="laskupaivaAlkaen"
                  v-model="value.laskupaivaAlkaen"
                  label="Alkaen"
                  color="white"
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <InputfieldDatePicker
                  id="laskupaivaPaattyen"
                  v-model="value.laskupaivaPaattyen"
                  label="Päättyen"
                  color="white"
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="erapaivaAlkaen">
                Eräpäivä
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <InputfieldDatePicker
                  id="erapaivaAlkaen"
                  v-model="value.erapaivaAlkaen"
                  label="Alkaen"
                  color="white"
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <InputfieldDatePicker
                  id="erapaivaPaattyen"
                  v-model="value.erapaivaPaattyen"
                  label="Päättyen"
                  color="white"
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="viite">
                Viite
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="viite"
                v-model="value.viite"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="viitteemme">
                Viitteemme
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="viitteemme"
                v-model="value.viitteemme"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="viitteenne">
                Viitteenne
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="viitteenne"
                v-model="value.viitteenne"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="pmViite">
                PM-viite
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="pmViite"
                v-model="value.pmViite"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'
import {
  SelectablesAsiakasTyypitLaskulle,
  SelectablesLaskuTilat,
  SelectablesOstolaskuTilat,
  SelectablesToimittajaTyypit,
} from '@/utils/selectables'
import { onkoModuuliKaytossa } from '@/utils/misc'
import FormTable from '@/components/FormTable'
import FormTableRow from '@/components/FormTableRow'
import FormTableCell from '@/components/FormTableCell'
import FormTableLabel from '@/components/FormTableLabel'
import InputfieldDatePicker from '@/components/InputfieldDatePicker'

export default {
  name: 'FormSearchLasku',
  components: {
    FormTable,
    FormTableRow,
    FormTableCell,
    FormTableLabel,
    InputfieldDatePicker,
  },
  mixins: [FormMixin],
  props: {
    ostolasku: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
  },
  data () {
    return {
      tilat: this.ostolasku ? SelectablesOstolaskuTilat : SelectablesLaskuTilat,
      asiakasTyypit: SelectablesAsiakasTyypitLaskulle,
      toimittajaTyypit: SelectablesToimittajaTyypit,
    }
  },
  computed: {
    rahoitusKaytossa () {
      return onkoModuuliKaytossa('rahoitus')
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
