var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "offCanvasTuote" },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "primary", flat: "", tabs: "" } },
        [
          _c(
            "v-btn",
            {
              attrs: { dark: true, icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close-button-click")
                },
              },
            },
            [_c("v-icon", [_vm._v("clear")])],
            1
          ),
          _c("v-toolbar-title", [_vm._v(" Tuoteryhmän tiedot ")]),
          _c("v-spacer"),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "grey lighten-5 px-8" },
        [
          _c("OffCanvasDetailsTuoteryhma", {
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "px-8 pt-6" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "my-2 list-btn",
                  attrs: { color: "primary", large: "" },
                  on: { click: _vm.valitseTuoteryhmaMuokattavaksi },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v(" edit ")]),
                  _vm._v(" Muokkaa tuoteryhmää "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }