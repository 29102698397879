<template>
  <div class="login">
    <div class="login__top">
      <div class="login__wrap">
        <div class="login__col login__col--left pr-6">
          <Logo
            :logo-key="logoKey"
            :enable-link="(false)"
            class="mx-0"
          />
        </div>
        <div class="login__col login__col--right pa-6">
          <v-card class="pa-10">
            <template v-if="salasananUusimispyyntolomakeAktiivinen">
              <h2 class="headline mb-6">
                Salasanan palautus
              </h2>
              <p>Kirjoita sähköpostiosoitteesi alla olevaan kenttään. Saat sähköpostiisi ohjeet salasanan palauttamiseksi.</p>
              <FormSalasananUusimispyynto
                v-model="formSalasananUusimispyynto"
                :loading="loading"
                @submit="lahetaSalasananUusimispyynto"
              />
            </template>
            <template v-else>
              <h2 class="headline mb-6">
                Kirjaudu sisään
              </h2>
              <FormLogin
                v-model="formLoginData"
                :loading="loading"
                @submit="login"
              />
            </template>
          </v-card>
        </div>
      </div>
      <div class="login__wrap">
        <div class="login__col login__col--left pa-6"></div>
        <div class="login__col login__col--right pa-6 loginHelp pt-6">
          <template v-if="salasananUusimispyyntolomakeAktiivinen">
            <p>
              <a
                href="#"
                @click.prevent="(salasananUusimispyyntolomakeAktiivinen = false)"
              >Peruuta</a>
            </p>
          </template>
          <template v-else>
            <p>
              <a
                href="#"
                @click.prevent="(salasananUusimispyyntolomakeAktiivinen = true)"
              >Unohtuiko salasana?</a>
            </p>
          </template>
          <p>
            Palvelu on käytössä vain toimeksiantaja-asiakkaillemme.
          </p>
          <p v-if="palveluhakemusKaytossa && appClass === 'uuva'">
            <!--Kommentoitu pois kunnes julkaisu tapahtuu virallisesti
            <a
              @click="siirryPalveluhakemukseen"
            >Rekisteröidy käyttäjäksi</a>
            -->
          </p>
        </div>
      </div>
    </div>
    <BusinessFooter />
  </div>
</template>

<script>

import {
  BusinessFooter,
  FormLogin,
  FormSalasananUusimispyynto,
  Logo,
} from '@/components'
import { onkoModuuliKaytossa } from '@/utils/misc'

export default {
  name: 'Kirjaudu',
  components: {
    BusinessFooter,
    FormLogin,
    FormSalasananUusimispyynto,
    Logo,
  },
  data () {
    return {
      loading: false,
      salasananUusimispyyntolomakeAktiivinen: false,
      logoKey: '',
      formLoginData: {},
      formSalasananUusimispyynto: {},
    }
  },
  computed: {
    footerStyle () {
      return {
        color: this.footerTextColor,
        backgroundColor: this.footerBgColor,
      }
    },
    appClass () {
      return process.env.VUE_APP_CLASS
    },
    palveluhakemusKaytossa () {
      return onkoModuuliKaytossa('palveluhakemus')
    }
  },
  watch: {
    salasananUusimispyyntolomakeAktiivinen (val) {
      if (val) {
        this.formLoginData = {}
      } else {
        this.formSalasananUusimispyynto = {}
      }
    }
  },
  created () {
    // Insen localhost-uudelleenohjaus ohjaa suoraan projektin juureen. Käytössä vain lokaalissa kehityksessä.
    if (window.location.hostname === 'localhost' && process.env.VUE_APP_DEV_MODE == 1) {
      const access = new URL(location.href).searchParams.get('access')
      const refresh = new URL(location.href).searchParams.get('refresh')

      if (access && refresh) {
        this.$router.replace({ name: 'palveluhakemus-2', query: { access, refresh } })
        return
      }
    }

    if (localStorage.getItem('accessToken') && localStorage.getItem('refreshToken')) {
      this.uudelleenohjaaKirjautunutKayttaja()
    }

    // Kirjautumissivu ei ole creditvisorilla käytössä
    if (this.appClass === 'creditvisor') {
      window.location.replace(process.env.VUE_APP_ANITTA_ONLINE_URL)
    }

    this.logoKey = (process.env.VUE_APP_LOGO_LOGIN) ? process.env.VUE_APP_LOGO_LOGIN : process.env.VUE_APP_LOGO
  },
  methods: {
    async login () {
      this.loading = true

      try {
        const loginRequest = await this.$doRequestWithTimeout(
          this.$api.Token,
          {
            method: 'POST',
            body: this.formLoginData,
          },
          'doSingleRequest'
        )

        if (!loginRequest.success) {
          if (loginRequest.result.status === 401) {
            throw new this.$AuthenticationError(loginRequest)
          } else {
            throw new this.$HttpError(loginRequest, 'Kirjautuminen epäonnistui!')
          }
        }

        // Haetaan käyttäjätiedot jo ennen uudelleenohjausta, jotta navigointi ei jää välitilaan
        // odottamaan niiden latautumista ennen seuraavalle sivulle saapumista.
        await this.$api.User.doRequest({ method: 'GET' })

        this.uudelleenohjaaKirjautunutKayttaja()
      } catch (e) {
        console.log(e)
        this.$naytaVirheilmoitus(e.message)
        if (e.name !== 'AuthenticationError') this.$sentryCaptureCustom(e)
      } finally {
        this.loading = false
      }
    },

    uudelleenohjaaKirjautunutKayttaja () {
      const nextPath = this.$router.currentRoute.query.next

      if (nextPath) {
        this.$router.replace({ path: nextPath })
      } else {
        const name = process.env.VUE_APP_LOGIN_REDIRECT_ROUTE || 'tyopoyta'
        this.$router.replace({ name })
      }
    },

    async lahetaSalasananUusimispyynto () {
      this.loading = true

      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.UusiSalasana,
          {
            method: 'POST',
            url: '',
            body: this.formSalasananUusimispyynto,
          },
          'doSingleRequest'
        )

        if (!request.success) {
          if (request.result.status === 400) {
            // Pyydetyllä osoitteella ei löydy tiliä, mutta ei kerrota käyttäjälle
          } else {
            throw new this.$HttpError(request, 'Salasanan asettaminen epäonnistui!')
          }
        }

        this.$naytaOnnistumisilmoitus('Lähetimme sähköpostiisi ohjeet salasanan palauttamiseksi, mikäli sähköpostiosoitteeseen liittyy olemassa oleva tili.')
      } catch (e) {
        console.log(e)
        this.$naytaVirheilmoitus(e.message)
        if (e.name !== 'PasswordResetExpiredError') this.$sentryCaptureCustom(e)
      } finally {
        this.salasananUusimispyyntolomakeAktiivinen = false
        this.loading = false
      }
    },
    siirryPalveluhakemukseen () {
      this.$router.push({ name: 'palveluhakemus-0' })
    }
  },
}

</script>

<style lang="scss" scoped>
  @import '@/assets/scss/loginStyles.scss';
</style>
