var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "offCanvasOstolasku" },
    [
      _c(
        "v-toolbar",
        { staticClass: "px-1", attrs: { color: "primary", flat: "" } },
        [
          _c(
            "v-btn",
            {
              attrs: { dark: true, icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close-button-click")
                },
              },
            },
            [_c("v-icon", [_vm._v("clear")])],
            1
          ),
          _c("v-toolbar-title", [_vm._v(" Laskun tiedot ")]),
          _c("v-spacer"),
          _c("v-tooltip", {
            attrs: { left: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    typeof _vm.value.id != "undefined"
                      ? _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "white v-btn--has-bg primary--text",
                              attrs: {
                                dark: true,
                                icon: "",
                                to: {
                                  name: "ostolasku-nayta",
                                  params: {
                                    id: _vm.value.id,
                                  },
                                },
                              },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("open_in_new")])],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "default",
                fn: function () {
                  return [_c("span", [_vm._v("Avaa lasku")])]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "grey lighten-5 px-8" },
        [
          _c("OffCanvasDetailsOstolasku", {
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "px-8" },
        [
          _c("Timeline", {
            attrs: { items: _vm.value.aikajana },
            on: {
              "msg-mark-as-unread": function ($event) {
                return _vm.$emit("msg-mark-as-unread")
              },
              "msg-mark-as-read": function ($event) {
                return _vm.$emit("msg-mark-as-unread")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }