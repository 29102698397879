<template>
  <div class="sticky">
    <v-toolbar
      color="primary"
      class="px-2"
      flat
      tabs
    >
      <v-toolbar-title v-if="$route.meta.title">
        {{ $route.meta.title }}
      </v-toolbar-title>

      <v-spacer />
      <slot />
      <v-spacer />

      <template v-if="!searchPanelOpen">
        <FormSearch
          ref="formSearch"
          v-model="searchQuery"
          :label="pikahaunPlaceholderTeksti"
          :input-hint="currentTab && currentTab !== 'kaikki' ? $t('common:noteTabAffectsSearch') : ''"
        />
        <v-tooltip
          v-if="searchPanel"
          left
        >
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              large
              dark
              @click="openSearchPanel"
              v-on="on"
            >
              <v-icon>
                add_circle
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:default>
            <span>{{ $t('common:moreSearch') }}</span>
          </template>
        </v-tooltip>
      </template>
      <v-btn
        v-else-if="searchPanel"
        text
        :dark="true"
        @click="closeSearchPanel"
      >
        {{ $t('common:emptySearch') }}
        <v-icon
          right
          :dark="true"
        >
          close
        </v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>

import FormSearch from '@/components/FormSearch'

export default {
  name: 'ViewSelaaTopBarBase',
  components: {
    FormSearch,
  },
  props: {
    pikahaunPlaceholderTeksti: {
      type: String,
      required: true,
    },
    vuexModuleName: {
      type: String,
      required: true,
    },
    searchPanel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    currentTab () {
      return this.$store.state[this.vuexModuleName].currentTab
    },
    searchPanelOpen () {
      return this.$store.state[this.vuexModuleName].searchPanelOpen
    },
    searchQuery: {
      get () {
        return this.$store.state[this.vuexModuleName].searchQuery
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setSearchQuery`, val)
      }
    },
  },
  methods: {
    openSearchPanel () {
      this.$emit('open-search-panel')
    },
    closeSearchPanel () {
      this.$emit('close-search-panel')
    },
  },
}
</script>

<style>

</style>
