var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "formTuoteryhma",
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      !this.$store.state.user.vainYksiPaamies
        ? _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                [
                  _vm.tuoteryhma.id
                    ? _c("v-text-field", {
                        staticClass: "required",
                        attrs: {
                          label: _vm.$t("common:creditor"),
                          disabled: true,
                        },
                        model: {
                          value: _vm.tuoteryhma.paamies.nimi,
                          callback: function ($$v) {
                            _vm.$set(_vm.tuoteryhma.paamies, "nimi", $$v)
                          },
                          expression: "tuoteryhma.paamies.nimi",
                        },
                      })
                    : _c("AutocompletePaamies", {
                        ref: "autocompletePaamies",
                        attrs: {
                          label: _vm.$t("common:creditor"),
                          tapaus: _vm.tuoteryhma,
                          "validointi-saannot": [
                            _vm.$validationRules.requiredObject,
                          ],
                          "tarkistettava-oikeus":
                            "onlinerestapi.onlinerestapi_tuoterekisteri",
                        },
                        model: {
                          value: _vm.tuoteryhma,
                          callback: function ($$v) {
                            _vm.tuoteryhma = $$v
                          },
                          expression: "tuoteryhma",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                staticClass: "required",
                attrs: {
                  label: _vm.$t("common:name"),
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.tuoteryhma.nimi,
                  callback: function ($$v) {
                    _vm.$set(_vm.tuoteryhma, "nimi", $$v)
                  },
                  expression: "tuoteryhma.nimi",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4 pa-3", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { large: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                color: "success",
                disabled: !_vm.valid || _vm.loading,
              },
              on: { click: _vm.tallenna },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:save")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }