<template>
  <div>
    <div
      v-if="!user.vainYksiPaamies && paamies"
      :class="tiivisNakyma ? 'mb-2' : 'mb-3'"
    >
      <p class="mb-0 font-weight-bold grey--text darken-2">
        {{ $t('common:creditor') }}
      </p>
      <h3>
        <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
        {{ paamies.nimi }} <template v-if="paamies.id">({{ paamies.id }})</template>
      </h3>
    </div>
    <slot name="vastaanottaja">
      <div
        v-if="vastapuoli"
      >
        <p
          v-if="vastapuolityyppi"
          class="mb-0 font-weight-bold grey--text darken-2"
        >
          {{ vastapuolityyppi }}
        </p>

        <h3>
          <template v-if="eiVastapuoliLinkkia">
            {{ vastapuoli.nimi }} <span v-if="vastapuoli.nro">({{ vastapuoli.nro }})</span>
          </template>
          <router-link
            v-else
            :to="{ name: vastapuoliRoute, params: { id: vastapuoli.id } }"
            class="inlineList__link"
          >
            {{ vastapuoli.nimi }} <span v-if="vastapuoli.nro">({{ vastapuoli.nro }})</span>
          </router-link>
        </h3>
        <OsoiteKappale
          v-if="vastapuoli.postiosoite"
          :saaja="vastapuoli.postiosoite.saaja ? vastapuoli.postiosoite.saaja : ''"
          :postiosoite="vastapuoli.postiosoite.osoite"
          :postinumero="vastapuoli.postiosoite.numero"
          :postitoimipaikka="vastapuoli.postiosoite.toimipaikka"
          :class="tiivisNakyma ? 'mb-0' : 'mb-2'"
        />
        <div>
          <p
            v-if="vastapuoli.tunnus"
            class="mb-0"
          >
            {{ tunnustyyppi }}: {{ vastapuoli.tunnus }}
          </p>
          <p
            v-if="vastapuoli.emailosoite && vastapuoli.emailosoite.email"
            class="mb-0"
          >
            {{ vastapuoli.emailosoite.email }}
          </p>
          <p
            v-if="vastapuoli.verkkolaskuosoite && vastapuoli.verkkolaskuosoite.verkkolaskuosoite"
            class="mb-0"
          >
            {{ $t('common:eInvoiceAddress') }} {{ vastapuoli.verkkolaskuosoite.verkkolaskuosoite }}
          </p>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>

import OsoiteKappale from '@/components/OsoiteKappale'
import TiivisNakymaMixin from '@/mixins/TiivisNakymaMixin'
import { mapState } from 'vuex'

export default {
  name: 'OsapuoltenTiedotKappale',
  components: {
    OsoiteKappale,
  },
  mixins: [TiivisNakymaMixin],
  props: {
    paamies: {
      type: Object,
      required: true,
    },
    vastapuoli: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    vastapuoliRooli: {
      type: String,
      required: false,
      default () {
        return 'asiakas'
      },
      validator: (arvo) => ['asiakas', 'toimittaja', 'velallinen'].includes(arvo)
    },
    vastapuoliRoute: {
      type: String,
      required: false,
      default () {
        return 'laskutus-asiakas-nayta'
      }
    },
    eiVastapuoliLinkkia: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    vastapuolityyppi () {
      if (!this.vastapuoli.tyyppi) return null

      if (this.vastapuoliRooli === 'toimittaja') {
        return 'Toimittaja'
      } else if (this.vastapuoli.tyyppi == 'H') {
        return `Henkilö${this.vastapuoliRooli}`
      } else if (this.vastapuoli.tyyppi == 'Y') {
        return `Yritys${this.vastapuoliRooli}`
      } else {
        return this.vastapuoliRooli.charAt(0).toUpperCase() + this.vastapuoliRooli.slice(1)
      }
    },
    tunnustyyppi () {
      if (!this.vastapuoli.tyyppi) return null

      if (this.vastapuoli.tyyppi == 'H') {
        return 'Hetu'
      } else if (this.vastapuoli.tyyppi == 'Y') {
        return 'Y-tunnus'
      } else {
        return 'Tunnus'
      }
    },
  }
}

</script>

<style>

</style>
