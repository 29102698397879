<template>
  <v-form
    ref="form"
    v-model="valid"
    class="white--text"
    @submit.prevent
  >
    <v-row>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="sopimusnumero">
                Sopimusnumero
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="sopimusnumero"
                v-model="value.sopimusnumero"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="tila">
                Tila
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-select
                id="tila"
                v-model="value.tila"
                :items="tilat"
                item-text="label"
                item-value="terms"
                multiple
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="asiakas">
                Asiakas
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="asiakas"
                v-model="value.asiakas"
                color="white"
                :placeholder="$t('common:formSearchSopimus.nameIdCustomerNo')"
                persistent-placeholder
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow v-if="!$store.state.user.vainYksiPaamies">
            <FormTableCell :header="(true)">
              <FormTableLabel for="paamiehet">
                {{ $t('common:creditors') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-theme-provider dark>
                <AutocompletePaamies
                  :multiple="(true)"
                  :tapaus="value"
                  label=""
                  tarkistettava-oikeus="onlinerestapi.onlinerestapi_sopimus"
                />
              </v-theme-provider>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="viite">
                {{ $t('common:invoiceReference') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="viite"
                v-model="value.viite"
                type="number"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="viitteemme">
                {{ $t('common:ourReference') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="viitteemme"
                v-model="value.viitteemme"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="viitteenne">
                {{ $t('common:yourReference') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="viitteenne"
                v-model="value.viitteenne"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="toimittaja">
                {{ $t('common:validity') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-select
                v-model="value.voimassa"
                :items="voimassaolot"
                item-text="label"
                item-value="terms"
                :dark="true"
                multiple
              ></v-select>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="alkamispaiva">
                {{ $t('common:startingDate') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <InputfieldDatePicker
                  id="alkamispaivaAlkaen"
                  v-model="value.alkamispaivaAlkaen"
                  :label="$t('common:starting')"
                  color="white"
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <InputfieldDatePicker
                  id="alkamispaivaPaattyen"
                  v-model="value.alkamispaivaPaattyen"
                  :label="$t('common:ending')"
                  color="white"
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="loppumispaiva">
                {{ $t('common:endingDate') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <InputfieldDatePicker
                  id="loppumispaivaAlkaen"
                  v-model="value.loppumispaivaAlkaen"
                  :label="$t('common:starting')"
                  color="white"
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <InputfieldDatePicker
                  id="loppumispaivaPaattyen"
                  v-model="value.loppumispaivaPaattyen"
                  :label="$t('common:ending')"
                  color="white"
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="maksuehto">
                {{ $t('common:termsOfPayment') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="maksuehto"
                v-model="value.maksuehto"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="lainatyyppi">
                {{ $t('common:formSearchSopimus.loanType') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="lainatyyppi"
                v-model="value.lainatyyppi"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="sopimuslaji">
                {{ $t('common:typeOfContract') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="sopimuslaji"
                v-model="value.sopimuslaji"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <!-- sopimusryhmaominaisuus ei vielä käytössä -->
          <!-- <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="sopimusryhma">
                Sopimusryhma
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="sopimusryhma"
                v-model="value.sopimusryhma"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow> -->
        </FormTable>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'
import AutocompletePaamies from '@/components/AutocompletePaamies'
import FormTable from '@/components/FormTable'
import FormTableRow from '@/components/FormTableRow'
import FormTableCell from '@/components/FormTableCell'
import FormTableLabel from '@/components/FormTableLabel'
import InputfieldDatePicker from '@/components/InputfieldDatePicker'
import {
  SelectablesSopimusTilat,
  SelectablesSopimusVoimassaolot,
} from '@/utils/selectables'

export default {
  name: 'FormSearchSopimus',
  components: {
    AutocompletePaamies,
    FormTable,
    FormTableRow,
    FormTableCell,
    FormTableLabel,
    InputfieldDatePicker,
  },
  mixins: [FormMixin],
  data () {
    return {
      tilat: SelectablesSopimusTilat,
      voimassaolot: SelectablesSopimusVoimassaolot,
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
