var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "formAsetuksetYhteystiedot" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { md: "12" } },
            [
              _c(
                "v-expansion-panels",
                { attrs: { accordion: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        [
                          _c("CardTitle", {
                            attrs: {
                              color: "black--text",
                              text: _vm.$t("contactInformation"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("FormAsetuksetPaamiesosoite", {
                            attrs: {
                              verkkolaskuoperaattorit:
                                _vm.verkkolaskuoperaattorit,
                              "button-text": "",
                              "salli-poisto": false,
                            },
                            model: {
                              value: _vm.asetukset.yhteydenottoosoite,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.asetukset,
                                  "yhteydenottoosoite",
                                  $$v
                                )
                              },
                              expression: "asetukset.yhteydenottoosoite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        [
                          _c("CardTitle", {
                            attrs: {
                              color: "black--text",
                              text: _vm.$t("settings.invoicingAddress"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("FormAsetuksetPaamiesosoite", {
                            attrs: {
                              verkkolaskuoperaattorit:
                                _vm.verkkolaskuoperaattorit,
                              "button-text": _vm.$t(
                                "common:settings.addInvoicingAddress"
                              ),
                              "salli-poisto": true,
                            },
                            model: {
                              value: _vm.asetukset.laskutusosoite,
                              callback: function ($$v) {
                                _vm.$set(_vm.asetukset, "laskutusosoite", $$v)
                              },
                              expression: "asetukset.laskutusosoite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        [
                          _c("CardTitle", {
                            attrs: {
                              color: "black--text",
                              text: _vm.$t("settings.addressInLetter"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("FormAsetuksetPaamiesosoite", {
                            attrs: {
                              verkkolaskuoperaattorit:
                                _vm.verkkolaskuoperaattorit,
                              "button-text": _vm.$t(
                                "common:settings.addAddressInLetter"
                              ),
                              "salli-poisto": true,
                            },
                            model: {
                              value: _vm.asetukset.kirjeosoite,
                              callback: function ($$v) {
                                _vm.$set(_vm.asetukset, "kirjeosoite", $$v)
                              },
                              expression: "asetukset.kirjeosoite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4 pa-3", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              attrs: { large: "", color: "primary" },
              on: { click: _vm.tallenna },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common:save")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }