import { endOfMonth, startOfMonth, subMonths } from 'date-fns'
import { suodataTyhjanMerkkijononSisaltavatKentat } from '@/utils/misc'

export const Raporttilomake = class {
  constructor (preset = {}) {
    this.paamies = []
    this.raportti = {}
    this.asetukset = {} // Raportin tyyppikohtaiset asetukset

    Object.assign(this, preset)
  }

  suodatetutAsetuksetPostData () {
    return suodataTyhjanMerkkijononSisaltavatKentat(this.asetukset)
  }
}

const TYYPPI_RESKONTRARAPORTTI = 'TYYPPI_RESKONTRARAPORTTI'
const TYYPPI_KTL_RAPORTTI = 'TYYPPI_KTL_RAPORTTI'

export const Raportti = class {
  static get TYYPPI_RESKONTRARAPORTTI () {
    return TYYPPI_RESKONTRARAPORTTI
  }

  static get TYYPPI_KTL_RAPORTTI () {
    return TYYPPI_KTL_RAPORTTI
  }

  constructor (preset = {}) {
    this.id = null
    this.nimi = ''
    this.tyyppi = ''

    Object.assign(this, preset)
  }
}

export const ReskontraRaportti = class extends Raportti {
  static get oletusasetukset () {
    const nykyhetki = new Date()
    const edellinenKk = subMonths(nykyhetki, 1)
    return {
      alkaen: startOfMonth(edellinenKk).toISOString().substring(0, 10),
      loppuen: endOfMonth(edellinenKk).toISOString().substring(0, 10),
    }
  }

  constructor (preset = {}) {
    super({})
    this.tyyppi = Raportti.TYYPPI_RESKONTRARAPORTTI

    Object.assign(this, preset)
  }
}

export const KtlRaportti = class extends Raportti {
  static get KAYTA_TUNNISTETTA_VAIHTOEHDOT () {
    return [
      {
        value: 'pm_viite',
        selite: 'PM-viite (oletus)'
      },
      {
        value: 'viitteemme',
        selite: 'Laskun viitteemme-kentän arvo'
      },
    ]
  }

  static get KTL_RIVINVAIHTO_VAIHTOEHDOT () {
    return [
      {
        value: 'LF',
        selite: 'LF (oletus)'
      },
      {
        value: 'CRLF',
        selite: 'CRLF'
      },
    ]
  }

  static get oletusasetukset () {
    const nykyhetki = new Date()
    const edellinenKk = subMonths(nykyhetki, 1)
    return {
      alkaen: startOfMonth(edellinenKk).toISOString().substring(0, 10),
      loppuen: endOfMonth(edellinenKk).toISOString().substring(0, 10),
      kayta_pankkitunnus: '',
      kayta_tilinumero: '',
      kayta_tunnistetta: 'pm_viite',
      ktl_rivinvaihto: 'LF',
      ktl_kirjauspaiva_tilityksesta: ''
    }
  }

  constructor (preset = {}) {
    super({})
    this.nimi = 'KTL-tilityskooste'
    this.tyyppi = Raportti.TYYPPI_KTL_RAPORTTI

    Object.assign(this, preset)
  }
}
