import { HttpError } from '@/utils/errors'
import {
  doRequestWithTimeout,
} from '@/utils/misc'
import BaseModule from '@/store/modules/baseModule'
import { Raporttilomake } from '@/class/Raportti'

export default class RaportitUusi extends BaseModule {
  state () {
    return {
      ...super.state(),
      apiName: 'Raportit',
      luotuRaportti: {
        id: null,
        luotu: null,
        nimi: '',
        valmis: false,
      },
      raporttilomake: new Raporttilomake(),
      reskontraraportit: [],
    }
  }

  getters () {
    return {
      ...super.getters()
    }
  }

  mutations () {
    return {
      palautaAlkutilaan (state) {
        state.luotuRaportti = {
          id: null,
          luotu: null,
          nimi: '',
          valmis: false,
        }
        state.raporttilomake = new Raporttilomake()
        if (this.state.user.vainYksiPaamies) state.raporttilomake.paamies = [this.state.user.valittuPaamies]
      },
      setLuotuRaportti (state, payload) {
        state.luotuRaportti = payload
      },
      setRaporttilomake (state, payload) {
        state.raporttilomake = payload
      },
      setRaporttilomakeAsetukset (state, payload) {
        state.raporttilomake.asetukset = payload
      },
      setRaporttilomakeRaportti (state, payload) {
        state.raporttilomake.raportti = payload
      },
      setReskontraraportit (state, payload) {
        state.reskontraraportit = payload
      },
    }
  }

  actions () {
    return {
      async haeReskontraraportit ({ commit, getters }) {
        const request = await doRequestWithTimeout(
          getters.apiModule.getInstance('ReskontraRaportti'),
          {
            method: 'GET',
            url: '',
          },
          'doSingleRequest',
        )

        if (!request.success) throw new HttpError(request)

        commit('setReskontraraportit', request.result.body)
      },
      async luoReskontraRaportti ({ commit, rootState, state, getters }) {
        const request = await doRequestWithTimeout(
          getters.api,
          {
            method: 'POST',
            url: '',
            body: {
              client_id: rootState.socketIo.clientId,
              paamiehet: state.raporttilomake.paamies.map(paamies => paamies.id),
              raportti: state.raporttilomake.raportti.id,
              ...state.raporttilomake.asetukset,
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new HttpError(request)

        // Ei haluta ylikirjoittaa mahdollisesti jo saapuneen valmistusilmoituksen mukana tullutta dataa
        commit('setLuotuRaportti',
          Object.assign(state.luotuRaportti, {
            id: request.result.body.id,
            nimi: state.raporttilomake.raportti.nimi,
          })
        )
      },
      async luoKtlRaportti ({ commit, rootState, state, getters }) {
        const request = await doRequestWithTimeout(
          getters.api,
          {
            method: 'POST',
            url: 'ktl_raportti_luo/',
            body: {
              client_id: rootState.socketIo.clientId,
              paamies: state.raporttilomake.paamies[0].id,
              ...state.raporttilomake.suodatetutAsetuksetPostData(),
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new HttpError(request)

        // Ei haluta ylikirjoittaa mahdollisesti jo saapuneen valmistusilmoituksen mukana tullutta dataa
        commit('setLuotuRaportti',
          Object.assign(state.luotuRaportti, {
            id: request.result.body.id,
            nimi: state.raporttilomake.raportti.nimi,
          })
        )
      },
    }
  }
}
