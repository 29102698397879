<template>
  <v-form
    ref="form"
    v-model="valid"
    class="pt-3"
    @submit.prevent
  >
    <v-text-field
      v-model="valueMutable"
      v-bind="$attrs"
      :label="label"
      clearable
      dark
      single-line
      prepend-inner-icon="search"
      color="white"
      :class="inputClass"
      :hint="inputHint"
      @click:append-outer="$emit('click:append-outer')"
    />
  </v-form>
</template>

<script>

export default {
  name: 'FormSearch',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    label: {
      type: String,
      required: false,
      default () {
        return 'Hae'
      },
    },
    inputHint: {
      type: String,
      required: false,
      default: '',
    },
    debounceDelay: {
      type: Number,
      required: false,
      default () {
        return 300
      },
    },
    wide: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      valid: true,
      valueMutable: '',
      debounceTimeout: '',
    }
  },
  computed: {
    hasValue () {
      if (!this.valueMutable) return false
      if (!this.valueMutable.length) return false

      return true
    },

    inputClass () {
      return [
        'searchQuery',
        'px-1',
        this.hasValue ? 'searchQuery--hasValue ' : null,
        this.wide ? 'searchQuery--wide' : null,
      ]
    }
  },
  watch: {
    valueMutable (val) {
      clearTimeout(this.debounceTimeout)

      this.debounceTimeout = setTimeout(() => {
        this.$emit('input', val)
      }, this.debounceDelay)
    },
  },
  created () {
    this.valueMutable = this.value
  },
  methods: {
    setSearchQuery (hakusana) {
      this.valueMutable = hakusana
    }
  }
}

</script>

<style lang="scss">

  .searchQuery {
    width: 320px;
    border-radius: .3em;
    transition: background-color 200ms ease;


    &--hasValue {
      background-color: rgba(0,0,0,.3);
    }

    &--wide {
      width: 400px;
    }

    .v-input__slot {
      margin: 0;
      color: white;
    }

    .v-messages__message {
      margin: 4px 0;
    }
  }

</style>
