import { t } from './lang'
export class HttpError extends Error {
  constructor (request, message = t('errors.HttpError')) {
    super(message)
    this.request = request
    this.name = 'HttpError'
    this.message = message
  }
}

export class AuthenticationError extends HttpError {
  constructor (request, message = t('errors.AuthenticationError')) {
    super(request, message)
    this.name = 'AuthenticationError'
  }
}

export class PasswordResetExpiredError extends Error {
  constructor (message = t('errors.PasswordResetExpiredError')) {
    super(message)
    this.name = 'PasswordResetExpiredError'
  }
}

export class TimeoutError extends Error {
  static get TIMEOUT_VIESTI () {
    return t('errors.TimeoutError')
  }

  constructor (aikakatkaistuPromise, message = TimeoutError.TIMEOUT_VIESTI) {
    super(message)
    this.promise = aikakatkaistuPromise
    this.name = 'TimeoutError'
    this.message = message
  }
}

export class SallittuKokoYlitettyError extends Error {
  static get SALLITTU_KOKO_YLITETTY_VIESTI () {
    return t('errors.SallittuKokoYlitettyError')
  }

  constructor (message = SallittuKokoYlitettyError.SALLITTU_KOKO_YLITETTY_VIESTI, extra = '') {
    super(message)
    this.name = 'SallittuKokoYlitettyError'
    this.message = message
    this.extra = extra
  }
}

export class ValidointiError extends Error {
  constructor (message = t('errors.ValidointiError')) {
    super(message)
    this.name = 'ValidointiError'
  }
}

export class BaseTilastoparametriError extends Error {
  constructor (tilasto, sarake, message) {
    super(message)
    this.name = 'BaseTilastoparametriError'
    this.tilasto = tilasto
    this.sarake = sarake
    this.message = message
  }
}

export class TilastoparametriSarakeError extends BaseTilastoparametriError {
  constructor (tilasto, sarake, message = t('errors.TilastoparametriSarakeError')) {
    super(tilasto, sarake, message)
    this.name = 'TilastoparametriSarakeError'
  }
}

export class TilastoparametriHakukenttaError extends BaseTilastoparametriError {
  constructor (tilasto, sarake, message = t('errors.TilastoparametriHakukenttaError')) {
    super(tilasto, sarake, message)
    this.name = 'TilastoparametriHakukenttaError'
  }
}

export class TilastoparametriOtsikkoError extends BaseTilastoparametriError {
  constructor (tilasto, sarake, message = 'Sarakkeella ei ole otsikkoa.') {
    super(tilasto, sarake, message)
    this.name = 'TilastoparametriOtsikkoError'
  }
}

export class TilastoparametriTyyppiError extends BaseTilastoparametriError {
  constructor (tilasto, sarake, message = 'Sarakkeella ei ole tyyppiä.') {
    super(tilasto, sarake, message)
    this.name = 'TilastoparametriTyyppiError'
  }
}
