var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timeline" },
    [
      _vm.showTitle || _vm.enableDirectionToggle
        ? _c(
            "header",
            { staticClass: "timeline__header" },
            [
              _c(
                "v-row",
                { staticClass: "mb-6", attrs: { "align-content": "center" } },
                [
                  _vm.showTitle
                    ? _c(
                        "v-col",
                        { staticClass: "timeline__headerCol pr-1 shrink" },
                        [
                          _c(
                            "h3",
                            { staticClass: "timelineItem__title" },
                            [
                              _vm.title
                                ? [_vm._v(" " + _vm._s(_vm.title) + " ")]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("common:timeline.title")
                                        ) +
                                        " "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.enableDirectionToggle
                    ? _c(
                        "v-col",
                        { staticClass: "timeline__headerCol grow" },
                        [
                          _c("v-tooltip", {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: _vm.directionButtonClass,
                                            attrs: { icon: "" },
                                            on: { click: _vm.toggleDirection },
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("arrow_upward"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "common:timeline.directionButtonTitle"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2736470007
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.enableZoom
        ? _c("v-slider", {
            attrs: {
              min: "1",
              max: "3",
              "prepend-icon": "zoom_out",
              "append-icon": "zoom_in",
            },
            on: { "click:prepend": _vm.zoomOut, "click:append": _vm.zoomIn },
            model: {
              value: _vm.zoom,
              callback: function ($$v) {
                _vm.zoom = $$v
              },
              expression: "zoom",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "timeline__items" },
        _vm._l(_vm.itemsParsed, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "timelineItem",
              class: item.itemClass,
            },
            [
              _c("StatusIcon", {
                staticClass: "timelineItem__icon status-icon--rounded",
                attrs: {
                  size: "small",
                  icon: item.icon,
                  "bg-color": item.color,
                  pointer: false,
                },
              }),
              _c("div", {
                staticClass: "timelineItem__line timelineItem__line--top",
              }),
              _c("div", {
                staticClass: "timelineItem__line timelineItem__line--bottom",
              }),
              _c("div", { class: _vm.arrowClass }),
              _c(
                "div",
                { staticClass: "timelineItem__content" },
                [
                  item.header
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "linelineItem__header caption mb-0 font-weight-bold grey--text darken-2",
                        },
                        [
                          _vm._v(" " + _vm._s(item.header) + " "),
                          item.chipText
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "ma-0 ml-1",
                                  attrs: {
                                    small: true,
                                    color: item.chipColor,
                                    "text-color": "white",
                                    disabled: true,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.chipText) + " ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showItemTitles && item.title
                    ? _c("h3", { staticClass: "timelineItem__title" }, [
                        _vm._v(" " + _vm._s(item.title) + " "),
                      ])
                    : _vm._e(),
                  item.text
                    ? _c("p", { class: item.textClass }, [
                        _c(
                          "span",
                          {
                            on: {
                              contextmenu: function ($event) {
                                $event.preventDefault()
                                item.textLink
                                  ? _vm.avaaMenuContext(
                                      $event,
                                      { id: item.textLink.params.id },
                                      item.textLink.name
                                    )
                                  : {}
                              },
                            },
                          },
                          [
                            item.textLink
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "inlineList__link",
                                    attrs: { to: item.textLink },
                                  },
                                  [
                                    _c("nl2br", {
                                      attrs: { tag: "span", text: item.text },
                                    }),
                                  ],
                                  1
                                )
                              : _c("nl2br", {
                                  attrs: { tag: "span", text: item.text },
                                }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  item.navItems.length
                    ? _c("v-menu", {
                        staticClass: "timelineItem__menu",
                        attrs: { "nudge-bottom": "40" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "ma-0",
                                        attrs: { icon: "" },
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(item.navIcon)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c(
                                    "v-list",
                                    _vm._l(item.navItems, function (navItem) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: navItem.index,
                                          on: {
                                            click: function ($event) {
                                              return navItem.action(item)
                                            },
                                          },
                                        },
                                        [
                                          navItem.icon
                                            ? _c(
                                                "v-list-item-avatar",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(navItem.icon)
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          navItem.label
                                            ? _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(navItem.label) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
      _c("CardDialog", {
        attrs: {
          title: _vm.$t("common:editNote"),
          "dialog-auki": _vm.muistiinpanoMuokkaaDialogAuki,
          "scroll-to-bottom": true,
          "content-class": "px-4 py-2",
        },
        on: {
          close: function ($event) {
            _vm.muistiinpanoMuokkaaDialogAuki = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function () {
              return [
                _c("FormUusiViesti", {
                  attrs: {
                    loading: _vm.muistiinpanoMuokkaaFormLoading,
                    "focus-on-update": true,
                    icon: "note",
                    "submit-btn-tooltip-text": "$t('common:save')",
                  },
                  on: { submit: _vm.muistiinpanoMuokkaaSubmit },
                  model: {
                    value: _vm.muistiinpanoMuokkaaFormData,
                    callback: function ($$v) {
                      _vm.muistiinpanoMuokkaaFormData = $$v
                    },
                    expression: "muistiinpanoMuokkaaFormData",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("MenuContext", {
        attrs: {
          "onko-nakyvissa": _vm.menuContextNakyvissa,
          "position-x": _vm.menuContextX,
          "position-y": _vm.menuContextY,
          vaihtoehdot: _vm.menuContextVaihtoehdot,
        },
        on: {
          avaaValilehteen: _vm.avaaUudessaValilehdessa,
          "update:return-value": _vm.suljeMenuContext,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }