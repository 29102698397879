<template>
  <div>
    <v-autocomplete
      ref="autocompleteLaskurivi"
      v-model="autocompleteValue"
      :label="$t('common:autocompleteLaskurivi.invoiceRowSearch')"
      :items="laskuriviItems"
      :loading="autocompleteLoading"
      :search-input.sync="laskuriviSearchQuery"
      :hide-no-data="(!laskuriviSearchQuery || autocompleteLoading)"
      item-text="autocomplete_selite"
      item-value="id"
      prepend-inner-icon="search"
      :disabled="!lasku.paamies.id"
      :placeholder="placeholder"
      persistent-placeholder
      outlined
      return-object
      :hint="inputHint"
      @input="kasitteleValinta"
    >
      <template
        v-slot:item="{ item }"
      >
        <template>
          <v-list-item-content>
            <v-list-item-title>{{ item.autocomplete_selite }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
      <template v-slot:no-data>
        <p class="pa-4">
          {{ $t('common:autocompleteLaskurivi.searchFoundNoInvoiceRows') }}
        </p>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import Laskurivi from '@/class/Laskurivi'
import permission from '@/plugins/permission'
import { Laskutyypit } from '@/utils/constants'

export default {
  name: 'AutocompleteLaskurivi',
  props: {
    lasku: {
      type: Object,
      required: true,
    },
    tyyppi: {
      type: String,
      required: false,
      default () {
        return Laskutyypit.LASKU
      },
      validator: (tyyppi) => {
        return Object.values(Laskutyypit).includes(tyyppi)
      }
    },
    inputHint: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
  },
  data () {
    return {
      autocompleteValue: {},
      debounceDelay: 300,
      debounceTimeout: '',
      laskuriviItems: [],
      autocompleteLoading: false,
      laskuriviSearchQuery: '',
    }
  },
  computed: {
    placeholder () {
      if (!this.lasku.paamies.id) {
        if (this.tyyppi === Laskutyypit.OSTOLASKU) {
          return this.$t('common:autocompleteLaskurivi.firstSelectReceiver')
        }

        return this.$t('common:autocompleteLaskurivi.firstSelectInvoicer')
      } else {
        if (
          this.tyyppi === Laskutyypit.OSTOLASKU ||
          !permission.checkSinglePerm('onlinerestapi.onlinerestapi_tuoterekisteri')
        ) {
          return this.$t('common:autocompleteLaskurivi.searchInvoiceRowsAdd')
        }

        return this.$t('common:autocompleteLaskurivi.searchInvoiceOrProductRowsAdd')
      }
    }
  },
  watch: {
    async laskuriviSearchQuery (hakusana) {
      clearTimeout(this.debounceTimeout)

      if (!hakusana) {
        this.autocompleteLoading = false
        return
      }
      this.autocompleteLoading = true

      this.debounceTimeout = setTimeout(async () => {
        this.laskuriviItems = []
        let tuotteet = []

        try {
          // Käsitellään laskusisällöt vain jos tuotteista ei löydy tuloksia tai ei ole lupaa tuoterekisteriin
          let requestLaskusisallot = this.haeLaskusisaltoja(hakusana)
          if (permission.checkSinglePerm('onlinerestapi.onlinerestapi_tuoterekisteri')) {
            const requestTuotteet = await this.haeTuotteita(hakusana)
            if (!requestTuotteet.success) throw new this.$HttpError(requestTuotteet)
            tuotteet = requestTuotteet.result.body.results
          }

          if (tuotteet.length) {
            this.laskuriviItems = tuotteet
          } else {
            requestLaskusisallot = await requestLaskusisallot
            if (!requestLaskusisallot.success) throw new this.$HttpError(requestLaskusisallot)
            this.laskuriviItems = requestLaskusisallot.result.body.results
          }
        } catch (e) {
          this.$sentryCaptureCustom(e)
        } finally {
          this.autocompleteLoading = false
        }
      }, this.debounceDelay)
    },
  },
  methods: {
    async haeTuotteita (hakusana) {
      const query = new URLSearchParams()
      query.append('q_haku_autocomplete', hakusana)
      query.append('paamies', this.$store.state.user.valittuPaamies.id)

      const request = await this.$doRequestWithTimeout(
        this.$api.Tuotteet,
        {
          method: 'GET',
          query: query.toString(),
        },
        'pushRequest',
      )

      return request
    },
    async haeLaskusisaltoja (hakusana) {
      const query = new URLSearchParams()
      query.append('q_haku', hakusana)
      query.append('paamies', this.$store.state.user.valittuPaamies.id)
      const apiModule = this.valitseApiModuuli()

      const request = await this.$doRequestWithTimeout(
        this.$api[apiModule],
        {
          method: 'GET',
          query: query.toString(),
        },
        'pushRequest',
      )

      return request
    },
    valitseApiModuuli() {
      if (this.tyyppi === Laskutyypit.OSTOLASKU) {
        return 'OstolaskuLaskusisalto'
      } else if (this.tyyppi === Laskutyypit.SOPIMUS) {
        return 'SopimusLaskusisalto'
      } else {
        return 'Laskusisalto'
      }
    },
    kasitteleValinta () {
      const valittuRivi = this.autocompleteValue
      delete valittuRivi.id // Ei haluta muokata olemassa olevia rivejä
      this.$emit('autocomplete-input', this.valittuRivi)
      this.lasku.rivit.push(new Laskurivi(valittuRivi))
      this.$nextTick(() => {
        this.autocompleteValue = null
      })
    }
  }
}
</script>

<style>

</style>
