var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.renderoiKomponentti
    ? _c("div", { staticClass: "osoiteKappale" }, [
        _vm.nimi && _vm.naytaNimi
          ? _c("span", { staticClass: "osoiteKappale__rivi" }, [
              _c(
                "span",
                {
                  staticClass: "osoiteKappale__item  osoiteKappale__item--nimi",
                },
                [_vm._v(_vm._s(_vm.nimi))]
              ),
            ])
          : _vm._e(),
        _vm.saaja
          ? _c("span", { staticClass: "osoiteKappale__rivi" }, [
              _c(
                "span",
                {
                  staticClass: "osoiteKappale__item  osoiteKappale__item--nimi",
                },
                [_vm._v(_vm._s(_vm.saaja))]
              ),
            ])
          : _vm._e(),
        _vm.postiosoite
          ? _c("span", { staticClass: "osoiteKappale__rivi" }, [
              _c(
                "span",
                {
                  staticClass:
                    "osoiteKappale__item osoiteKappale__item--postiosoite",
                },
                [_vm._v(_vm._s(_vm.postiosoite))]
              ),
            ])
          : _vm._e(),
        _vm.postinumero || _vm.postitoimipaikka
          ? _c("span", { staticClass: "osoiteKappale__rivi" }, [
              _vm.postinumero
                ? _c(
                    "span",
                    {
                      staticClass:
                        "osoiteKappale__item osoiteKappale__item--postinumero",
                    },
                    [_vm._v(_vm._s(_vm.postinumero))]
                  )
                : _vm._e(),
              _vm.postitoimipaikka
                ? _c(
                    "span",
                    {
                      staticClass:
                        "osoiteKappale__item osoiteKappale__item--postitoimipaikka",
                    },
                    [_vm._v(_vm._s(_vm.postitoimipaikka))]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }