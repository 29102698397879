var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { staticClass: "px-6 pb-6", attrs: { md: "6" } }, [
        _c(
          "div",
          { staticClass: "pa-2" },
          [
            _vm.naytaAlkaenPaattyen
              ? _c(
                  "div",
                  { staticClass: "comboField" },
                  [
                    _c("InputfieldDatePicker", {
                      attrs: { label: "Alkaen" },
                      model: {
                        value: _vm.raporttilomake.asetukset.alkaen,
                        callback: function ($$v) {
                          _vm.$set(_vm.raporttilomake.asetukset, "alkaen", $$v)
                        },
                        expression: "raporttilomake.asetukset.alkaen",
                      },
                    }),
                    _c("span", { staticClass: "comboField__separator" }, [
                      _vm._v("–"),
                    ]),
                    _c("InputfieldDatePicker", {
                      attrs: { label: "Päättyen" },
                      model: {
                        value: _vm.raporttilomake.asetukset.loppuen,
                        callback: function ($$v) {
                          _vm.$set(_vm.raporttilomake.asetukset, "loppuen", $$v)
                        },
                        expression: "raporttilomake.asetukset.loppuen",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._t("vasen-sarake"),
          ],
          2
        ),
      ]),
      _c("v-col", { staticClass: "px-6 pb-6", attrs: { md: "6" } }, [
        _c("div", { staticClass: "pa-2" }, [_vm._t("oikea-sarake")], 2),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }