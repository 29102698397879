<template>
  <div class="pp-container">
    <v-dialog v-model="palvelutasoVertailu" class="dialog-palvelutasot">
      <v-card class="dialog-card">
        <div class="dialog-header">
          <h1>{{ $t("common:features") }}</h1>
        </div>

        <div class="dialog-lists">
          <v-list>
            <v-list-item class="list-header"
              ><h2>{{ valittavatPalvelusopimukset[0].nimi }}</h2></v-list-item
            >
            <v-list-item
              v-for="ominaisuudet in valittavatPalvelusopimukset[0].ominaisuudet
                .ominaisuudet_palvelutaso"
              :key="ominaisuudet.teksti"
            >
              <v-list-item-avatar>
                <v-icon v-if="ominaisuudet.sisaltyy_sopimukseen" class="check2">
                  mdi-check
                </v-icon>
                <v-icon
                  v-if="!ominaisuudet.sisaltyy_sopimukseen"
                  class="close2"
                >
                  mdi-close
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="list-item-text">{{
                ominaisuudet.teksti
              }}</v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item class="list-header"
              ><h2>{{ valittavatPalvelusopimukset[1].nimi }}</h2></v-list-item
            >
            <v-list-item
              v-for="ominaisuudet in valittavatPalvelusopimukset[1].ominaisuudet
                .ominaisuudet_palvelutaso"
              :key="ominaisuudet.teksti"
            >
              <v-list-item-avatar>
                <v-icon v-if="ominaisuudet.sisaltyy_sopimukseen" class="check2">
                  mdi-check
                </v-icon>
                <v-icon
                  v-if="!ominaisuudet.sisaltyy_sopimukseen"
                  class="close2"
                >
                  mdi-close
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="list-item-text">{{
                ominaisuudet.teksti
              }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div class="dialog-close">
          <v-btn color="primary" @click="palvelutasoVertailu = false">{{
            $t("common:close")
          }}</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <div class="pp-subheader1">{{ $t("common:testForFreeNoCommitment") }}</div>
    <div class="pp-subheader2">
      {{ $t("common:openBillsWillBeTakenCareOf") }}
    </div>

    <div class="pp-switch-container">
      <span>{{ $t("common:invoicing") }}</span>
      <v-chip
        :class="{ active: laskutusjakso === laskutusjaksoKuukausi }"
        class="chip"
        @click="laskutusjakso = laskutusjaksoKuukausi"
        >{{ $t("common:monthUpperCase") }}</v-chip
      >
      <v-divider vertical></v-divider>
      <v-badge content="Säästä X%" color="success">
        <v-chip
          :class="{ active: laskutusjakso === laskutusjaksoVuosi }"
          class="chip"
          @click="laskutusjakso = laskutusjaksoVuosi"
          >{{ $t("common:yearUpperCase") }}</v-chip
        >
      </v-badge>
    </div>

    <div class="pp-main-content">
      <div class="pp-info-container">
        <v-chip class="popular" color="transparent"></v-chip>
        <div class="pp-info-box ib-special">
          <div class="ib-header">
            <v-divider></v-divider>
          </div>

          <div class="info-main">
            <div>{{ $t("common:invoicing") }}</div>
            <div>{{ $t("common:productAndCustomerRegistry") }}</div>
            <div>{{ $t("common:timedAndCreditNotes") }}</div>
            <div>{{ $t("common:reportsAndStatistics") }}</div>
            <div>{{ $t("common:paymentControl") }}</div>
            <div>{{ $t("common:automaticPaymentReminders") }}</div>
            <div>{{ $t("common:invoiceFinancing") }}</div>
          </div>
          <div class="info-bottom"></div>
        </div>
      </div>

      <div class="pp-info-container">
        <v-chip class="popular" color="transparent"></v-chip>
        <div class="pp-info-box ib-real ib-1">
          <div class="ib-header">
            <h2>{{ valittavatPalvelusopimukset[0].nimi }}</h2>
            <div>
              <h1 v-if="laskutusjakso === laskutusjaksoKuukausi">
                {{ valittavatPalvelusopimukset[0].kkhinta }}€/{{
                  $t("common:monthShorthand")
                }}
              </h1>
              <h1 v-if="laskutusjakso === laskutusjaksoVuosi">
                {{ valittavatPalvelusopimukset[0].vuosihinta }}€/{{
                  $t("common:yearLowerCase")
                }}
              </h1>
            </div>

            <v-divider class="ib1-divider"></v-divider>
          </div>

          <div class="info-main">
            <v-icon class="check">mdi-check</v-icon>
            <v-icon class="check">mdi-check</v-icon>
            <v-icon class="check">mdi-check</v-icon>
            <v-icon class="check">mdi-check</v-icon>
            <v-icon class="close">mdi-close</v-icon>
            <v-icon class="close">mdi-close</v-icon>
            <v-icon class="close">mdi-close</v-icon>
          </div>
          <div class="info-bottom">
            <v-btn
              outlined
              color="primary"
              @click="
                $emit('nextStep');
                valitsePalvelutaso(valittavatPalvelusopimukset[0]);
                valitseLaskutusjakso(laskutusjakso);
              "
              >{{ $t("common:serviceApplication:startForFree") }}</v-btn
            >
            <v-chip
              outlined
              color="primary"
              @click="palvelutasoVertailu = true"
              >{{
                $t("common:serviceApplication:seeAllContractFeatures")
              }}</v-chip
            >
          </div>
        </div>
      </div>

      <div class="pp-info-container">
        <v-chip class="popular" color="primary">{{
          $t("common:serviceApplication:mostPopular")
        }}</v-chip>
        <div class="pp-info-box ib-real ib-2">
          <div class="ib-header">
            <h2>{{ valittavatPalvelusopimukset[1].nimi }}</h2>
            <div>
              <h1 v-if="laskutusjakso === laskutusjaksoKuukausi">
                {{ valittavatPalvelusopimukset[1].kkhinta }}€/kk
              </h1>
              <h1 v-if="laskutusjakso === laskutusjaksoVuosi">
                {{ valittavatPalvelusopimukset[1].vuosihinta }}€/vuosi
              </h1>
            </div>
            <v-divider class="ib2-divider"></v-divider>
          </div>

          <div class="info-main">
            <v-icon class="check">mdi-check</v-icon>
            <v-icon class="check">mdi-check</v-icon>
            <v-icon class="check">mdi-check</v-icon>
            <v-icon class="check">mdi-check</v-icon>
            <v-icon class="check">mdi-check</v-icon>
            <v-icon class="check">mdi-check</v-icon>
            <v-icon class="check">mdi-check</v-icon>
          </div>
          <div class="info-bottom">
            <v-btn
              class="successlight"
              @click="
                $emit('nextStep');
                valitsePalvelutaso(valittavatPalvelusopimukset[1]);
                valitseLaskutusjakso(laskutusjakso);
              "
              >{{ $t("common:serviceApplication:startForFree") }}</v-btn
            >
            <v-chip
              outlined
              color="white"
              @click="palvelutasoVertailu = true"
              >{{
                $t("common:serviceApplication:seeAllContractFeatures")
              }}</v-chip
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--
<v-card
    class="palvelutaso__card pa-4 grey lighten-5"
    :class="'palvelutaso__card--valittu'"
    @click="$emit('click')"
  >
    <h6
      class="text-h6 text-center palvelutaso__otsikko text-uppercase"
      :class="ominaisuudet.otsikon_vari"
    >
      {{ otsikko }}
    </h6>
    <h2 class="mt-4 mb-8">
      <span class="text-h4">{{ kkhinta | formatSum }}</span>
      <span class="text-h5">/ kuukausi tai</span>
    </h2>
    <h2 class="mt-4 mb-8">
      <span class="text-h4">{{ vuosihinta | formatSum }}</span>
      <span class="text-h5">/ vuosi</span>
    </h2>
    <ul class="palvelutaso__list">
      <li
        v-for="o in ominaisuudet.ominaisuudet_palvelutaso"
        :key="o.teksti"
        class="palvelutaso__list__item"
        :class="!o.sisaltyy_sopimukseen ? 'palvelutaso__list__item--red' : ''"
      >
        {{ o.teksti }}
      </li>
    </ul>
  </v-card>
  -->
</template>

<script>
import { mapState } from "vuex";
import { PaamiesPalveluhakemus } from "@/class/Paamies";
export default {
  name: "PalvelusopimusPalvelutaso",
  /* props: {
    kkhinta: {
      type: [String, Number],
      required: true,
    },
    vuosihinta: {
      type: [String, Number],
      required: true,
    },
    otsikko: {
      type: String,
      required: true,
    },
    ominaisuudet: {
      type: Object,
      required: true,
    },
    
  }, */
  data() {
    return {
      laskutusjaksoKuukausi: PaamiesPalveluhakemus.LASKUTUSJAKSO_KUUKAUSI,
      laskutusjaksoVuosi: PaamiesPalveluhakemus.LASKUTUSJAKSO_VUOSI,
      laskutusjakso: PaamiesPalveluhakemus.LASKUTUSJAKSO_KUUKAUSI,
      palvelutasoVertailu: false,
    };
  },
  computed: {
    ...mapState({
      palvelusopimus: (state) => state.palveluhakemus.paamies.palvelusopimus,
      kayttaja: (state) => state.palveluhakemus.kayttaja,
      valittavatPalvelusopimukset: (state) =>
        state.palveluhakemus.valittavatPalvelusopimukset,
    }),
  },
  methods: {
    valitsePalvelutaso(palvelutasoid) {
      this.$store.commit(
        "palveluhakemus/setPaamiesPalvelusopimus",
        palvelutasoid
      );
    },

    valitseLaskutusjakso(laskutusjakso) {
      this.$store.commit("palveluhakemus/setLaskutusjakso", laskutusjakso);
    },
  },
};
</script>

<style lang="scss" scoped>
.pp-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.pp-switch-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.pp-main-content {
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 1rem;
}
.pp-info-box {
  height: 55vh;
  width: 30vh;
  display: grid;
  grid-template-rows: 1fr 3fr 1fr;
}
.ib-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ib-special {
  width: 20vh;
}
.ib-real {
  border-radius: 25px;
}
.ib-1 {
  border: 1px solid var(--v-primary-base);
  color: var(--v-primary-base);
}
.ib1-divider {
  width: 90%;
  border-color: var(--v-primary-base);
}
.ib-2 {
  color: white;
  background-color: var(--v-primary-base);
}
.ib2-divider {
  width: 90%;
  border-color: white;
}
.pp-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popular {
  position: relative;
  bottom: -16px;
}
.popular::before {
  background-color: var(--v-primary-base) !important;
}

.check {
  align-self: center;
  border-radius: 50%;
  width: fit-content;
  background-color: var(--v-successlight-base);
  z-index: 2;
  padding: 2px;
}
.check2 {
  align-self: center;
  border-radius: 50%;
  width: 100%;
  background-color: var(--v-successlight-base);
  z-index: 2;
  padding: 2px;
}
.close {
  align-self: center;
  border-radius: 50%;
  width: fit-content;
  background-color: var(--v-errorlight-base);
  z-index: 2;
  padding: 2px;
}
.close2 {
  align-self: center;
  border-radius: 50%;
  width: 100%;
  background-color: var(--v-errorlight-base);
  z-index: 2;
  padding: 2px;
}
.info-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.info-main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.active {
  background-color: var(--v-primary-base) !important;
  color: white;
}
.chip {
  cursor: pointer;
}
.dialog-palvelutasot {
  max-height: 90vh;
}
.dialog-card {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  color: var(--v-primary-base);
}
.dialog-header {
  align-self: center;
  margin-bottom: 1rem;
}
.dialog-lists {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-height: 40%;
  overflow-y: scroll;
  color: var(--v-primary-base);
}
.list-header {
  color: var(--v-primary-base) !important;
}
.list-item-text {
  color: var(--v-primary-base) !important;
  font-weight: bold;
}
.dialog-close {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.palvelutaso {
  &__card:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  &__card--valittu {
    background-color: lighten(#000, 40);
  }

  &__otsikko {
    background-color: var(--v-primary-base);
    margin: -16px -16px 0 -16px;
    padding: 16px 16px 8px 16px;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__item:before {
      margin-right: 4px;
      padding: 2px;
      font-family: "Material Icons";
      content: "check";
      border-radius: 100%;
      background-color: #4caf50; // green darken-1
    }

    &__item {
      line-height: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;
      vertical-align: middle;

      &--red:before {
        background-color: #e64a19; // deep-orange darken-2
      }
    }
  }
}
</style>
