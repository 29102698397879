<template>
  <div
    v-if="logoUrl"
    class="logo"
  >
    <router-link
      v-if="enableLink"
      :to="{ name: 'tyopoyta' }"
      class="logo__wrap"
    >
      <img
        class="logo__img"
        :style="maxWidth ? { maxWidth: maxWidth } : {}"
        :src="logoUrl"
        alt="Logo"
      >
    </router-link>
    <div
      v-else
      class="logo__wrap"
    >
      <img
        class="logo__img"
        :style="maxWidth ? { maxWidth: maxWidth } : {}"
        :src="logoUrl"
        alt="Logo"
      >
    </div>
  </div>
</template>

<script>

export default {
  name: 'Logo',
  props: {
    logoKey: {
      type: String,
      required: false,
      default () {
        return process.env.VUE_APP_LOGO
      },
    },
    enableLink: {
      type: Boolean,
      required: false,
      default () {
        return true
      },
    },
    small: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    maxWidth: {
      type: String,
      required: false,
      default () {
        return ''
      },
    }
  },
  computed: {
    logoUrl () {
      if (!this.logoKey) return null

      let file = (this.small) ? 'logo-' + this.logoKey + '-small.png' : 'logo-' + this.logoKey + '.png'

      return require('@/assets/logos/' + file)
    }
  },
}

</script>

<style lang="scss" scoped>

  .logo {
    display: block;

    &__wrap {
      display: block;
    }

    &__img {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
    }
  }

</style>
