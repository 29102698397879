import Vue from 'vue'
import VueRouter from 'vue-router'
import api from '@/plugins/api'
import store from '@/store'
import permission from '@/plugins/permission'
// import { getTheme } from '@/utils/color-themes'
import BlankView from '@/views/Blank.vue'
import Kirjaudu from '@/views/Kirjaudu.vue'
import KirjauduUudelleenohjaus from '@/views/KirjauduUudelleenohjaus.vue'
import Error404 from '@/views/Error404.vue'
import Error403 from '@/views/Error403.vue'
import Tyopoyta from '@/views/Tyopoyta.vue'
import UusiSalasana from '@/views/UusiSalasana.vue'

Vue.use(VueRouter)

const yleiset = [
  {
    path: '/',
    name: 'etusivu',
    component: BlankView,
    redirect: { name: 'kirjaudu' },
    meta: {
      requiresAuth: false,
      showInMenus: false,
    },
  },
  /**
   * dummy-routea voi käyttää "disabloituun" router-linkiin,
   * jolle tulee antaa olemassa oleva route, jotta css ei mene rikki.
   * */
  {
    path: '/dummy',
    name: 'dummy',
    component: BlankView,
    meta: {
      requiresAuth: false,
      showInMenus: false,
    },
  },
  {
    path: '/kirjaudu',
    name: 'kirjaudu',
    component: Kirjaudu,
    meta: {
      requiresAuth: false,
      title: 'Kirjaudu',
      showInMenus: false,
      sidebarEnabled: false,
      showBackgroundImage: true,
    },
  },
  {
    path: '/kirjaudu_uudelleenohjaus',
    name: 'kirjaudu_uudelleenohjaus',
    component: KirjauduUudelleenohjaus,
    meta: {
      requiresAuth: false,
      title: 'Kirjaudutaan sisään',
      showInMenus: false,
      sidebarEnabled: false,
      showBackgroundImage: true,
    },
  },
  {
    path: '/uusi_salasana',
    name: 'uusi_salasana',
    component: UusiSalasana,
    meta: {
      requiresAuth: false,
      title: 'UusiSalasana',
      showInMenus: false,
      sidebarEnabled: false,
      showBackgroundImage: true,
    },
  },
  {
    path: '/asetukset/',
    name: 'asetukset',
    component: () => import(/* webpackChunkName: "laskutus" */ '@/views/Asetukset.vue'),
    meta: {
      title: 'Asetukset',
      showInMenus: false,
      // theme: 'purple',
      permissions: [
        'onlinerestapi.onlinerestapi_beta',
      ],
    },
  },
  {
    path: '/mfa',
    name: 'mfa',
    component: () => import('@/views/MFA.vue'),
    meta: {
      requiresAuth: true,
      title: 'Kaksivaiheinen tunnistautuminen',
      showInMenus: false,
      sidebarEnabled: false,
      showBackgroundImage: true,
    },
  },
  {
    path: '/403',
    name: 'error403',
    component: Error403,
    meta: {
      title: 'Ei käyttöoikeutta',
      showInMenus: false,
    },
  },
  {
    path: '/404',
    name: 'error404',
    component: Error404,
    meta: {
      title: 'Sivua ei löytynyt!',
      showInMenus: false,
    },
  },
  {
    path: '*',
    redirect: '/404',
    meta: {
      showInMenus: false,
    }
  },
]

const tyopoyta = {
  path: '/tyopoyta',
  name: 'tyopoyta',
  component: Tyopoyta,
  meta: {
    title: 'Työpöytä',
    // theme: 'purple',
    icon: 'anitta-icon-desktop',
    permissions: [
      'onlinerestapi.onlinerestapi_onlinepalvelu',
    ],
    showBackgroundImage: true,
    backgroundOverlayColor: '#6D358C',
    backgroundOpacity: 0.15,
    backgroundOverlayOpacity: 0.13,
  },
}

const laskutus = {
  path: '/laskutus',
  name: 'laskutus',
  component: BlankView,
  redirect: { name: 'laskutus-selaa' },
  meta: {
    title: 'Laskutus',
    icon: 'anitta-icon-invoice',
    permissions: [
      'onlinerestapi.onlinerestapi_onlinepalvelu',
      'onlinerestapi.onlinerestapi_lasku',
    ],
  },
  children: [
    {
      path: 'selaa',
      name: 'laskutus-selaa',
      component: () => import(/* webpackChunkName: "laskutus" */ '@/views/LaskutusSelaa.vue'),
      meta: {
        title: 'Laskutus',
      },
      props: {
        vuexModuleName: 'laskutusSelaa',
      }
    },
    {
      path: 'nayta/:id',
      name: 'laskutus-nayta',
      component: () => import(/* webpackChunkName: "laskutus" */ '@/views/LaskutusNayta.vue'),
      meta: {
        title: 'Lasku',
      },
    },
    {
      path: 'tuo',
      name: 'laskutus-tuo',
      component: () => import(/* webpackChunkName: "laskutus" */ '@/views/LaskutusTuo.vue'),
      meta: {
        title: 'Tuo aineistoa',
      },
    },
    {
      path: 'uusi',
      name: 'laskutus-uusi',
      component: () => import(/* webpackChunkName: "laskutus" */ '@/views/LaskutusUusi.vue'),
      meta: {
        title: 'Luo uusi lasku',
      },
    },
    {
      path: 'muokkaa',
      name: 'laskutus-muokkaa',
      component: () => import(/* webpackChunkName: "laskutus" */ '@/views/LaskutusMuokkaa.vue'),
      meta: {
        title: 'Muokkaa laskua',
        permissions: [
          'onlinerestapi.onlinerestapi_lasku_muokkaa',
        ],
      },
    },
  ],
}

const asiakas = {
  path: '/asiakas',
  name: 'laskutus-asiakas',
  component: BlankView,
  redirect: { name: 'laskutus-asiakas-selaa' },
  meta: {
    title: 'Asiakasrekisteri',
    icon: 'anitta-icon-customer-person',
    permissions: [
      'onlinerestapi.onlinerestapi_onlinepalvelu',
      'onlinerestapi.onlinerestapi_lasku',
    ],
  },
  children: [
    {
      path: 'asiakas-selaa',
      name: 'laskutus-asiakas-selaa',
      component: () => import(/* webpackChunkName: "laskutus" */ '@/views/LaskutusAsiakasSelaa.vue'),
      meta: {
        title: 'Asiakasrekisteri',
      },
      props: {
        vuexModuleName: 'laskutusAsiakasSelaa',
      }
    },
    {
      path: 'asiakas-nayta/:id',
      name: 'laskutus-asiakas-nayta',
      component: () => import(/* webpackChunkName: "laskutus" */ '@/views/LaskutusAsiakasNayta.vue'),
      meta: {
        title: 'Asiakas',
      },
    },
  ]
}

const ostolasku = {
  path: '/ostolasku',
  name: 'ostolasku',
  component: BlankView,
  redirect: { name: 'ostolasku-selaa' },
  meta: {
    title: 'Ostolaskut',
    icon: 'anitta-icon-invoice-purchase',
    permissions: [
      'onlinerestapi.onlinerestapi_onlinepalvelu',
      'ostolasku.onlinerestapi_ostolasku',
    ],
  },
  children: [
    {
      path: 'selaa',
      name: 'ostolasku-selaa',
      component: () => import(/* webpackChunkName: "ostolasku" */ '@/views/OstolaskuSelaa.vue'),
      meta: {
        title: 'Ostolaskut',
        // theme: 'blue-grey',
      },
      props: {
        vuexModuleName: 'ostolaskuSelaa',
      }
    },
    {
      path: 'nayta/:id',
      name: 'ostolasku-nayta',
      component: () => import(/* webpackChunkName: "ostolasku" */ '@/views/OstolaskuNayta.vue'),
      meta: {
        title: 'Ostolasku',
        // theme: 'blue-grey',
      },
    },
    {
      path: 'uusi',
      name: 'ostolasku-uusi',
      component: () => import(/* webpackChunkName: "ostolasku" */ '@/views/OstolaskuUusi.vue'),
      meta: {
        title: 'Luo uusi ostolasku',
        // theme: 'blue-grey',
      },
    },
    {
      path: 'toimittaja-selaa',
      name: 'ostolasku-toimittaja-selaa',
      component: () => import(/* webpackChunkName: "ostolasku" */ '@/views/OstolaskuToimittajaSelaa.vue'),
      meta: {
        title: 'Toimittajat',
        // theme: 'blue-grey',
      },
      props: {
        vuexModuleName: 'ostolaskuToimittajaSelaa',
      }
    },
    {
      path: 'toimittaja-nayta/:id',
      name: 'ostolasku-toimittaja-nayta',
      component: () => import(/* webpackChunkName: "ostolasku" */ '@/views/OstolaskuToimittajaNayta.vue'),
      meta: {
        title: 'Toimittaja',
        // theme: 'blue-grey',
      },
    },
    {
      path: 'ostolasku-maksutoimeksianto-selaa',
      name: 'ostolasku-maksutoimeksianto-selaa',
      component: () => import(/* webpackChunkName: "ostolasku" */ '@/views/OstolaskuMaksutoimeksiantoSelaa.vue'),
      meta: {
        title: 'Maksutoimeksiannot',
        // theme: 'blue-grey',
      },
      props: {
        vuexModuleName: 'ostolaskuMaksutoimeksiantoSelaa',
      }
    },
  ],
}

const perinta = {
  path: '/perinta',
  name: 'perinta',
  component: BlankView,
  redirect: { name: 'perinta-selaa' },
  meta: {
    title: 'Perintä',
    // theme: 'orange',
    showInMenus: true,
    icon: 'anitta-icon-lightning',
    permissions: [
      'onlinerestapi.onlinerestapi_onlinepalvelu',
      'onlinerestapi.onlinerestapi_toimeksianto',
    ],
  },
  children: [
    {
      path: 'nayta/:id',
      name: 'perinta-nayta',
      component: () => import(/* webpackChunkName: "perinta" */ '@/views/PerintaNayta.vue'),
      meta: {
        title: 'Toimeksianto',
        // theme: 'orange',
      },
    },
    {
      path: 'selaa/',
      name: 'perinta-selaa',
      component: () => import(/* webpackChunkName: "perinta" */ '@/views/PerintaSelaa.vue'),
      meta: {
        title: 'Selaa toimeksiantoja',
        // theme: 'orange',
      },
      props: {
        vuexModuleName: 'perintaSelaa',
      }
    },
    {
      path: 'uusi/',
      name: 'perinta-uusi',
      component: () => import(/* webpackChunkName: "perinta" */ '@/views/PerintaUusi.vue'),
      meta: {
        title: 'Luo uusi toimeksianto',
        // theme: 'orange',
      },
      props: {
        vuexModuleName: 'perintaSelaa',
      }
    },
  ],
}

const viestit = {
  path: '/viestit',
  name: 'viestit-selaa',
  component: () => import(/* webpackChunkName: "viestit" */ '@/views/ViestitSelaa.vue'),
  meta: {
    title: 'Viestit',
    // theme: 'pink',
    icon: 'anitta-icon-comment',
    permissions: [
      'onlinerestapi.onlinerestapi_viesti',
    ],
  },
  props: {
    vuexModuleName: 'viestitSelaa',
  }
}

const paamieshallinta = {
  path: '/paamieshallinta',
  name: 'paamieshallinta-selaa',
  component: () => import(/* webpackChunkName: "paamieshallinta" */ '@/views/PaamieshallintaSelaa.vue'),
  meta: {
    title: 'Päämieshallinta',
    // theme: 'blue',
    icon: 'anitta-icon-customer-company',
    permissions: [
      'onlinerestapi.onlinerestapi_onlinepalvelu',
      'onlinerestapi.onlinerestapi_paamieshallinta',
    ],
    staffOnly: true, // Tämän voi poistaa kun päämieshallinta saadaan käyttökuntoon normikäyttäjiä varten
  },
  props: {
    vuexModuleName: 'paamieshallintaSelaa',
  }
}

const tuoterekisteri = {
  path: '/tuoterekisteri',
  name: 'tuoterekisteri',
  component: BlankView,
  redirect: { name: 'tuoterekisteri-selaa' },
  meta: {
    title: 'Tuoterekisteri',
    icon: 'anitta-icon-product-category',
    permissions: [
      'onlinerestapi.onlinerestapi_onlinepalvelu',
      'onlinerestapi.onlinerestapi_tuoterekisteri',
    ],
  },
  children: [
    {
      path: 'selaa',
      name: 'tuoterekisteri-selaa',
      component: () => import(/* webpackChunkName: "tuoterekisteri" */ '@/views/TuoterekisteriSelaa.vue'),
      meta: {
        title: 'Tuoterekisteri',
      },
      props: {
        vuexModuleName: 'tuoterekisteriSelaa',
      }
    },
    {
      path: 'tuoteryhma-selaa',
      name: 'tuoterekisteri-tuoteryhma-selaa',
      component: () => import(/* webpackChunkName: "tuoterekisteri" */ '@/views/TuoterekisteriTuoteryhmaSelaa.vue'),
      meta: {
        title: 'Tuoteryhmät',
      },
      props: {
        vuexModuleName: 'tuoterekisteriTuoteryhmaSelaa',
      }
    },
  ]
}

const sopimus = {
  path: '/sopimus',
  name: 'sopimus',
  component: BlankView,
  redirect: { name: 'sopimus-selaa' },
  meta: {
    title: 'Toistuvaislaskutus',
    icon: 'anitta-icon-repeat-invoice',
    permissions: [
      'onlinerestapi.onlinerestapi_onlinepalvelu',
      'onlinerestapi.onlinerestapi_sopimus',
    ],
  },
  children: [
    {
      path: 'selaa',
      name: 'sopimus-selaa',
      component: () => import(/* webpackChunkName: "sopimus" */ '@/views/SopimusSelaa.vue'),
      meta: {
        title: 'Toistuvaislaskutus',
        // theme: 'blue',
      },
      props: {
        vuexModuleName: 'sopimusSelaa',
      }
    },
    {
      path: 'nayta/:id',
      name: 'sopimus-nayta',
      component: () => import(/* webpackChunkName: "sopimus" */ '@/views/SopimusNayta.vue'),
      meta: {
        title: 'Toistuva lasku',
        // theme: 'blue',
      },
    },
    {
      path: 'tallenna',
      name: 'sopimus-tallenna',
      component: () => import(/* webpackChunkName: "sopimus" */ '@/views/SopimusTallenna.vue'),
      meta: {
        title: 'Luo uusi toistuva lasku',
        // theme: 'blue',
      },
    },
  ],
}

const raportit = {
  path: '/raportit',
  name: 'raportit',
  component: BlankView,
  redirect: { name: 'raportit-selaa' },
  meta: {
    title: 'Raportit',
    icon: 'anitta-icon-report',
    permissions: [
      'onlinerestapi.onlinerestapi_onlinepalvelu',
      'onlinerestapi.onlinerestapi_raportti',
    ],
  },
  children: [
    {
      path: 'selaa',
      name: 'raportit-selaa',
      component: () => import(/* webpackChunkName: "raportit" */ '@/views/RaportitSelaa.vue'),
      meta: {
        title: 'Kertaraportit',
        // theme: 'blue-grey',
      },
      props: {
        vuexModuleName: 'raportitSelaa',
      }
    },
    {
      path: 'uusi',
      name: 'raportit-uusi',
      component: () => import(/* webpackChunkName: "raportit" */ '@/views/RaportitUusi.vue'),
      meta: {
        title: 'Luo kertaraportti',
        // theme: 'blue-grey',
      },
    },
    {
      path: 'kuukausiraportti-selaa',
      name: 'raportit-kuukausiraportti-selaa',
      component: () => import(/* webpackChunkName: "raportit" */ '@/views/RaportitKuukausiraporttiSelaa.vue'),
      meta: {
        title: 'Kuukausiraportit',
        // theme: 'blue-grey',
        permissions: [
          'onlinerestapi.onlinerestapi_raporttilahetys',
        ]
      },
      props: {
        vuexModuleName: 'raportitKuukausiraporttiSelaa',
      }
    },
  ],
}

const tilasto = {
  path: '/tilasto',
  name: 'tilasto',
  component: () => import('@/views/TilastoNayta.vue'),
  meta: {
    title: 'Tilasto',
    // theme: process.env.VUE_APP_CLASS === 'creditvisor' ? 'creditvisor' : 'blue',
    icon: 'anitta-icon-raports',
    permissions: [
      'onlinerestapi.onlinerestapi_tilasto',
    ],
  }
}

const tilitys = {
  path: '/tilitys',
  name: 'tilitys',
  component: BlankView,
  redirect: { name: 'tilitys-selaa' },
  meta: {
    title: 'Tilitys',
    icon: 'anitta-icon-settlement',
    permissions: [
      'onlinerestapi.onlinerestapi_tilitys',
    ],
  },
  children: [
    {
      path: 'selaa',
      name: 'tilitys-selaa',
      component: () => import(/* webpackChunkName: "tilitys" */ '@/views/TilitysSelaa.vue'),
      meta: {
        title: 'Tilitys',
        // theme: 'blue-grey',
      },
      props: {
        vuexModuleName: 'tilitysSelaa',
      }
    },
  ],
}

const rahoitus = {
  // Ei toistaiseksi omia routeja. Tämä on kuitenkin valittava moduuli, jonka mukana oleminen
  // env-määrityksessä vaikuttaa siihen, mitä käyttöliittymässä näytetään.
  path: '/ei-ole-olemassa',
  meta: {
    showInMenus: false,
  },
}

const kayttajat = {
  path: '/kayttajat',
  name: 'kayttajat',
  component: BlankView,
  redirect: { name: 'kayttajat-selaa' },
  meta: {
    title: 'Käyttäjät',
    icon: 'anitta-icon-users',
    permissions: [
      'onlinerestapi.onlinerestapi_kayttajat',
    ],
    staffOnly: true,
  },
  children: [
    {
      path: 'selaa',
      name: 'kayttajat-selaa',
      component: () => import('@/views/KayttajatSelaa.vue'),
      meta: {
        title: 'Käyttäjät',
        // theme: 'pink',
        staffOnly: true,
      },
      props: {
        vuexModuleName: 'kayttajatSelaa',
      }
    },
  ],
}

// Toistaiseksi valittavissa vain Uuvalle.
// Palveluhakemus tarvitsee muokata konfiguroitavaksi, mikäli se halutaan laajempaan käyttöön.
const palveluhakemus = {
  path: '/ph',
  component: BlankView,
  redirect: { name: 'palveluhakemus-0' },
  meta: {
    // theme: 'purple',
    requiresAuth: false,
    title: 'Luo tunnukset',
    showInMenus: false,
    sidebarEnabled: false,
  },
  children: [
    /*{
      path: '0',
      name: 'palveluhakemus-0',
      component: () => import('@/views/PalveluhakemusAloita.vue'),
      meta: {
        // theme: 'purple',
        requiresAuth: false,
        showInMenus: false,
        sidebarEnabled: false,
        title: 'Luo tunnukset',
        showBackgroundImage: true,
      }
    }, */
    {
      path: '0',
      name: 'palveluhakemus-0',
      component: () => import(/* webpackChunkName: "palveluhakemus" */ '@/views/Palveluhakemus.vue'),
      meta: {
        // theme: 'purple',
        requiresAuth: false,
        showInMenus: false,
        sidebarEnabled: false,
        showBackgroundImage: true,
        title: 'Luo tunnukset',
      }
    },
    {
      path: '2',
      name: 'palveluhakemus-2',
      component: () => import(/* webpackChunkName: "palveluhakemus" */ '@/views/PalveluhakemusTunnistauduttu.vue'),
      meta: {
        // theme: 'purple',
        requiresAuth: false,
        showInMenus: false,
        sidebarEnabled: false,
        showBackgroundImage: true,
        title: 'Luo tunnukset',
      }
    },
    {
      path: '3',
      name: 'palveluhakemus-3',
      component: () => import(/* webpackChunkName: "palveluhakemus" */ '@/views/PalveluhakemusValtuutettu.vue'),
      meta: {
        // theme: 'purple',
        requiresAuth: false,
        showInMenus: false,
        sidebarEnabled: false,
        showBackgroundImage: true,
        title: 'Luo tunnukset',
      }
    },
  ]
}

const valittavissaOlevatModuulit = {
  tyopoyta,
  laskutus,
  asiakas,
  sopimus,
  tuoterekisteri,
  ostolasku,
  paamieshallinta,
  palveluhakemus, // Kehitetty Uuvalle. Varmista valittavuus ennen kuin asetat muulle asiakkaalle.
  perinta,
  rahoitus,
  raportit,
  viestit,
  tilasto,
  tilitys,
  kayttajat,
}

const haeValitutModuulitYmparistomuuttujasta = () => {
  const valitutModuulit = process.env.VUE_APP_ROUTES.split(',')

  let kaytossaOlevatModuulit = [...yleiset]

  if (valitutModuulit.length && valitutModuulit[0] === '*') {
    kaytossaOlevatModuulit = [...kaytossaOlevatModuulit, ...Object.values(valittavissaOlevatModuulit)]
  } else {
    for (const moduuli of valitutModuulit) {
      const valittuModuuli = valittavissaOlevatModuulit[moduuli]
      kaytossaOlevatModuulit.push(valittuModuuli)
    }
  }

  return kaytossaOlevatModuulit
}

const Router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: haeValitutModuulitYmparistomuuttujasta()
})

// Route navigation guards
Router.beforeEach(async (to, from, next) => {
  if (to.name === 'dummy') return

  asetaDokumentinOtsake(to.meta.title)

  // Sivut jotka vaativat sisäänkirjautumisen
  if (typeof to.meta.requiresAuth === 'undefined' || to.meta.requiresAuth === true) {
    const access = localStorage.getItem('accessToken')
    const refresh = localStorage.getItem('refreshToken')
    let userDataLadattu = store.getters['user/dataLoaded']
    // Jos valitun päämiehen oikeuksia ei löydy storesta, on mahdollista että valittu päämies on
    // luotu sisäänkirjautumisen jälkeen. Oikeudet pitää tällöin päivittää varmuuden vuoksi.
    const valittuPaamiesEiPerms = store.getters["user/valittuPaamies"]
      && store.getters['user/tarkistetaankoPaamieskohtaisetOikeudet']
      && !store.getters['user/getValittuPaamiesPerms'].length
    const poikkeukset = ['error403', 'mfa']

    // Load user details
    if (
      !poikkeukset.includes(to.name) && access && refresh
      && (!userDataLadattu || valittuPaamiesEiPerms)
    ) {
    // If user data is not yet loaded, we need to wait until this request is finished due to the permission checks
      const response = await api.User.doRequest({ method: 'GET' })
      userDataLadattu = response.success
    }

    // Ohjataan käyttäjä kirjautumissivulle, jos ei ole tokenia
    if (!access || !refresh) {
      let redirectUrl = process.env.VUE_APP_LOGOUT_REDIRECT_URL
      if (redirectUrl) {
        redirectUrl += `?next=${to.path}`
        window.location.replace(redirectUrl)

        return
      }

      next({
        name: 'kirjaudu',
        replace: true,
        query: { 'next': to.path },
      })
    // Ei oikeuksia kyseiselle sivulle
    } else if (!permission.match(to)) {
      next({
        name: 'error403',
        replace: true,
      })
    // Onnistunut valtuutettu pyyntö
    } else {
      // haetaan vain jos käyttäjän tiedot on haettu
      if (userDataLadattu) asetaYleisenStorenTiedot()

      next()
    }
  // Sivut jotka eivät vaadi sisäänkirjautumista
   } else if (to.name === 'palveluhakemus-1' && !from.name) {
    // Ei sallita suoraa navigointia sivulle 'palveluhakemus-1'.
    next({
      name: 'palveluhakemus-0',
      replace: true,
    }) 
  } else {
    next()
  }
})

Router.afterEach((to, from) => {
  if (
    permission.checkSinglePerm('onlinerestapi.onlinerestapi_viesti') &&
    store.getters['user/dataLoaded']
  ) {
    api.UserLukemattomatViestitKpl.doRequest({ method: 'GET' })
  }
})

const asetaDokumentinOtsake = (reitinOtsake) => {
  let title = (typeof reitinOtsake !== 'undefined') ? reitinOtsake + ' - ' : ''
  title += process.env.VUE_APP_INSTANCE_TITLE
  document.title = title
}

const asetaYleisenStorenTiedot = () => {
  if (store.state.yleinen.ALVTunnusItems.length === 0) {
    store.dispatch('yleinen/haeALVTunnukset')
  }
  if (store.state.yleinen.maat.length === 0) {
    store.dispatch('yleinen/haeMaavaihtoehdot')
  }
  if (store.state.yleinen.verkkolaskuoperaattorit.length === 0) {
    store.dispatch('yleinen/haeVerkkolaskuoperaattorit')
  }
  if (permission.checkSinglePerm('ostolasku.onlinerestapi_ostolasku')) {
    if (store.state.yleinen.ostotiliItems.length === 1 && store.state.yleinen.ostotiliItems[0] === null) {
      store.dispatch('yleinen/haeOstotilit')
    }
    if (store.state.yleinen.tiliointitiliItems.length === 0) {
      store.dispatch('yleinen/haeTiliointitilit')
    }
  }
}

export default Router
