<template>
  <v-form
    ref="formKuukausiraportti"
    v-model="valid"
  >
    <Spinner v-if="muokattavaKuukausiraporttiLataamassa" />
    <template v-else>
      <v-row
        v-if="!$store.state.user.vainYksiPaamies"
        align="center"
      >
        <v-col>
          <AutocompletePaamies
            v-if="!kuukausiraportti.id"
            ref="autocompletePaamies"
            v-model="kuukausiraportti"
            :label="$t('common:creditor')"
            :tapaus="kuukausiraportti"
            tarkistettava-oikeus="onlinerestapi.onlinerestapi_raporttilahetys"
          />
          <v-text-field
            v-else
            v-model="kuukausiraportti.paamies.nimi"
            :label="$t('common:creditor')"
            :disabled="(true)"
            class="required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3 class="mb-6">
            {{ $t('common:formKuukausiraportti.reportSettings') }}
          </h3>
          <v-select
            v-model="kuukausiraportti.raportti"
            :label="$t('common:report')"
            type="text"
            :items="raporttiItems"
            item-value="id"
            item-text="nimi"
            return-object
            :menu-props="{ zIndex: '999' }"
            :rules="[$validationRules.required]"
            :loading="esitiedotLataamassa"
            :no-data-text="$t('common:formKuukausiraportti.chooseCreditorMsg')"
            class="required mb-4"
          />
          <div class="comboField">
            <v-text-field
              v-model="kuukausiraportti.alkamispaiva"
              :label="$t('common:reportDays')"
              class="comboField__field required v-input--text-right"
              type="number"
              :prefix="$t('common:prefixMonth')"
              suffix="."
              :rules="[$validationRules.required, $validationRules.isInteger, validoiPaiva]"
              :hint="!raportinPaivatKattaaKokoKuukauden ? $t('common:formKuukausiraportti.daysNotWholeMonthMsg') : ''"
            />
            <span class="comboField__separator">&ndash;</span>
            <v-text-field
              v-model="kuukausiraportti.loppumispaiva"
              class="comboField__field required v-input--text-right"
              type="number"
              :suffix="$t('common:suffixDay')"
              :rules="[$validationRules.required, $validationRules.isInteger, validoiPaiva]"
              :hint="!raportinPaivatKattaaKokoKuukauden ? $t('common:formKuukausiraportti.daysNotWholeMonthMsg') : ''"
            />
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.kuukausiraporttiInfo.startAndEndDate')"
            />
          </div>
          <h3 class="mt-6 mb-4">
            {{ $t('common:formKuukausiraportti.createReport') }}
          </h3>
          <div class="comboField">
            <v-text-field
              v-model="kuukausiraportti.ajopaiva"
              :label="$t('common:day')"
              class="comboField__field required v-input--text-right"
              type="number"
              placeholder="1"
              persistent-placeholder
              :prefix="$t('common:prefixMonth')"
              :suffix="$t('common:suffixDay')"
              :rules="[$validationRules.required, $validationRules.isInteger, validoiPaiva]"
            />
            <span class="comboField__separator required"></span>
            <v-text-field
              v-model="kuukausiraportti.ajotunti"
              :label="$t('common:hour')"
              class="comboField__field required"
              type="number"
              :prefix="$t('common:prefixTime')"
              placeholder="8"
              persistent-placeholder
              :rules="[$validationRules.required, $validationRules.isInteger, validoiAjotunti]"
            />
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.kuukausiraporttiInfo.runDayAndHour')"
            />
          </div>
          <div class="comboField">
            <v-checkbox
              v-model="kuukausiraportti.vain_arkena"
              class="mt-1"
              :label="$t('common:onlyWeekdays')"
            />
            <TooltipInfo
              icon-class="align-self-start ml-2"
              icon-color="primary"
              :text="$t('common:infotekstit.kuukausiraporttiInfo.onlyWeekdays')"
            />
          </div>
          <!-- Saatekirjeen valinta otettu pois käytöstä tammikuussa 2022 -->
          <!-- <v-select
            v-model="kuukausiraportti.saatekirje"
            :label="$t('common:formKuukausiraportti.reportCompletionMsg')"
            type="text"
            :items="saatekirjeItems"
            item-value="id"
            item-text="nimi"
            :menu-props="{ zIndex: '999' }"
            :rules="[$validationRules.required]"
            :loading="esitiedotLataamassa"
            :no-data-text="!(kuukausiraportti.paamies && kuukausiraportti.paamies.id) ? $t('common:formKuukausiraportti.chooseCreditorMsg') : $t('common:formKuukausiraportti.noCoverContactService')"
            class="required my-4"
          /> -->
          <v-combobox
            v-model="kuukausiraportti.emailOsoitteet"
            :items="[]"
            :delimiters="[',', ';', ' ']"
            chips
            append-icon=""
            multiple
            deletable-chips
            :label="$t('common:emailTo')"
            :placeholder="$t('common:formKuukausiraportti.giveOneOrMoreEmailMsg')"
            persistent-placeholder
            type="text"
            class="required"
            :rules="[$validationRules.emailArray, $validationRules.requiredArrayLength]"
          />
          <v-menu
            v-model="seuraavaAjopaivaMenu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <div class="comboField">
                <v-text-field
                  :label="$t('common:nextReport')"
                  :value="$date(kuukausiraportti.seuraava_ajopaiva)"
                  readonly
                  class="mt-1 pt-1 required"
                  :class="seuraavaRaporttiClassArr"
                  :rules="[$validationRules.required]"
                  v-on="on"
                />
                <TooltipInfo
                  icon-class="align-self-start ml-2"
                  icon-color="primary"
                  :text="$t('common:infotekstit.kuukausiraporttiInfo.nextRunDate')"
                />
              </div>
            </template>
            <template v-slot:default>
              <v-date-picker
                v-model="kuukausiraportti.seuraava_ajopaiva"
                :locale="$languageLocale()"
                first-day-of-week="1"
                no-title
                class="mt-1 pt-1"
                :hint="$t('common:infotekstit.kuukausiraporttiInfo.nextRunDate')"
                @change="seuraavaAjopaivaMenu = false"
              />
            </template>
          </v-menu>
        </v-col>
      </v-row>
      <v-row
        class="mt-4 pa-3"
        justify="end"
      >
        <v-btn
          large
          class="mr-4"
          @click="$emit('close')"
        >
          {{ $t('common:cancel') }}
        </v-btn>
        <v-btn
          large
          color="success"
          :disabled="!valid || loading"
          @click="tallenna"
        >
          {{ $t('common:save') }}
        </v-btn>
      </v-row>
    </template>
  </v-form>
</template>

<script>

import Kuukausiraportti from '@/class/Kuukausiraportti'
import AutocompletePaamies from '@/components/AutocompletePaamies'

export default {
  name: 'Formkuukausiraportti',
  components: {
    AutocompletePaamies,
  },
  props: {
    onAuki: {
      type: Boolean,
      required: true,
    },
    muokattavaKuukausiraportti: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      esitiedotLataamassa: false,
      kuukausiraportti: new Kuukausiraportti(),
      muokattavaKuukausiraporttiLataamassa: false,
      laskemassaSeuraavaaAjopaivaa: false,
      loading: false,
      odotusaikaYlitetty: false,
      seuraavaAjopaivaMenu: false,
      valid: true,
      raportit: [],
      saatekirjeet: [],
      seuraava_ajopaivaHover: false,
      seuraavaRaporttiClassArr: [],
      vain_arkenaHover: false,
    }
  },
  computed: {
    raporttiItems () {
      return this.raportit || []
    },
    saatekirjeItems () {
      return this.saatekirjeet || []
    },
    kuukausiraporttiJson () {
      return JSON.stringify(this.kuukausiraportti)
    },
    raportinPaivatKattaaKokoKuukauden () {
      return (
        (Number(this.kuukausiraportti.alkamispaiva) === 1 && Number(this.kuukausiraportti.loppumispaiva) === 31) ||
        Number(this.kuukausiraportti.alkamispaiva) === Number(this.kuukausiraportti.loppumispaiva) + 1
      )
    }
  },
  watch: {
    onAuki: {
      immediate: true,
      handler (auki) {
        if (auki) {
          this.odotusaikaYlitetty = false
          setTimeout(() => {
            // Oletetaan että dataan muutoksia tekevät pyynnöt on saatu ladattua ja tiedot päivitettyä
            // kahdessa sekunnissa
            this.odotusaikaYlitetty = true
          }, 2000)
          if (this.$store.state.user.vainYksiPaamies) {
            this.kuukausiraportti.paamies = this.$store.state.user.valittuPaamies
            this.haeEsitiedot()
          } else {
            this.$nextTick().then(() => {
              this.$refs.formKuukausiraportti.resetValidation()

              if (!this.muokattavaKuukausiraportti.id && this.$refs.autocompletePaamies) {
                this.kuukausiraportti.paamies = this.$store.state.user.valittuPaamies
                // Asetetaan päämiehen nimi hakukenttään, kun päämies on haettu arvo storesta
                setTimeout(() => {
                  this.$refs.autocompletePaamies.paivitaPaamiehenTiedotHakukenttaan(
                    this.$store.state.user.valittuPaamies
                  )
                }, 0)
              }

              if (this.kuukausiraportti.paamies && this.kuukausiraportti.paamies.id) this.haeEsitiedot()
            })
          }
        } else {
          this.kuukausiraportti = new Kuukausiraportti()
        }
      }
    },
    /**
     * Kun watcher asetetaan seuraamaan kokonaista objektia, ja kokonainen objekti korvataan, ovat
     * watcherin uusi ja vanha arvo samoja. Käyttämällä computed propertya ja json-muunnosta, saadaan
     * ongelma kierrettyä. Ks.: https://github.com/vuejs/vue/issues/2164#issuecomment-542766308
     */
    kuukausiraporttiJson: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        val = JSON.parse(val)
        oldVal = oldVal ? JSON.parse(oldVal) : {}

        if (val.paamies) {
          this.kuukausiraportti.paamies_id = val.paamies.id

          if (val.paamies.id && (!oldVal.paamies || val.paamies.id !== oldVal.paamies.id)) {
            this.haeEsitiedot()
          } else if (!val.paamies.id) {
            this.raportit = []
            this.saatekirjeet = []
          }
        }

        if (val.raportti && val.raportti.id) this.kuukausiraportti.raportti_id = val.raportti.id
        if (val.saatekirje && val.saatekirje.id) this.kuukausiraportti.saatekirje_id = val.saatekirje.id

        if (
          val.paamies &&
          val.paamies.id &&
          this.kuukausiraportti.raportti_id &&
          val.alkamispaiva &&
          Number.isInteger(Number(val.alkamispaiva)) &&
          val.loppumispaiva &&
          Number.isInteger(Number(val.loppumispaiva)) &&
          val.ajopaiva &&
          Number.isInteger(Number(val.ajopaiva)) &&
          val.ajopaiva >= 1 &&
          val.ajopaiva <= 31 &&
          Number.isInteger(Number(val.ajotunti)) &&
          val.ajotunti >= 8 &&
          val.ajotunti <= 21 &&
          (
            !oldVal.raportti_id ||
            val.ajopaiva !== oldVal.ajopaiva ||
            val.ajotunti !== oldVal.ajotunti ||
            val.vain_arkena !== oldVal.vain_arkena
          )
        ) {
          if (
            (
              !this.muokattavaKuukausiraportti.id &&
              !this.muokattavaKuukausiraportti.onKopioitavaPohja
            ) ||
            this.odotusaikaYlitetty
          ) this.laskeSeuraavaAjopaiva()
        }
      }
    },
    'muokattavaKuukausiraportti.id': {
      immediate: true,
      handler (id) {
        if (id) {
          this.haeMuokattavaKuukausiRaportti()
        }
      }
    },
  },
  methods: {
    validoiAjotunti (arvo) {
      return arvo >= 8 && arvo <= 21 || this.$t('common:formKuukausiraportti.timeMustBeBetween')
    },
    validoiPaiva (arvo) {
      return arvo >= 1 && arvo <= 31 || this.$t('common:formKuukausiraportti.dayMustBeBetween')
    },
    async haeMuokattavaKuukausiRaportti() {
      try {
        this.muokattavaKuukausiraporttiLataamassa = true
        const request = await this.$doRequestWithTimeout(
          this.$api.RaportitKuukausiraportti,
          {
            method: 'GET',
            url: `${this.muokattavaKuukausiraportti.id}`,
          },
          'pushRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.kuukausiraportti = new Kuukausiraportti(request.result.body)
        if (this.muokattavaKuukausiraportti.onKopioitavaPohja) {
          this.kuukausiraportti.id = null
          this.$nextTick(() => {
            setTimeout(() => {
                this.$refs.autocompletePaamies.paivitaPaamiehenTiedotHakukenttaan(
                  this.kuukausiraportti.paamies
                )
              }, 0)
          })
        }
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:formKuukausiraportti.monthlyReportSearchFailed'))
      } finally {
        this.muokattavaKuukausiraporttiLataamassa = false
      }
    },
    async haeEsitiedot () {
      try {
        this.esitiedotLataamassa = true
        const request = await this.$doRequestWithTimeout(
          this.$api.RaportitKuukausiraportti,
          {
            method: 'GET',
            url: `esitiedot/${this.kuukausiraportti.paamies.id}`,
          },
          'pushRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.raportit = request.result.body.raportit
        // Huom. jos päämiehellä ei ole yhtään olemassa olevaa kuukausiraporttia, saatekirjeet
        // palautuu rajapinnasta tyhjänä listana.
        this.saatekirjeet = request.result.body.saatekirjeet
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:formKuukausiraportti.monthlyReportFormSearchFailed'))
      } finally {
        this.esitiedotLataamassa = false
      }
    },
    async tallenna () {
      if (!this.$refs.formKuukausiraportti.validate()) return

      const httpMethod = this.kuukausiraportti.id ? 'PUT' : 'POST'
      const url = this.kuukausiraportti.id ? `${this.kuukausiraportti.id}/` : ''

      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.RaportitKuukausiraportti,
          {
            method: httpMethod,
            url: url,
            body: this.kuukausiraportti.getPostData(),
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.$emit('kuukausiraportti-tallennettu', request.result.body)
        this.$emit('close')
        this.kuukausiraportti = new Kuukausiraportti()
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:formKuukausiraportti.monthlyReportSaveFailed'))
      }
    },
    async laskeSeuraavaAjopaiva () {
      this.laskemassaSeuraavaaAjopaivaa = true
      const httpMethod = this.kuukausiraportti.id ? 'PUT' : 'POST'
      const url = this.kuukausiraportti.id ? `${this.kuukausiraportti.id}/laske_seuraava_ajopaiva/` : 'x/laske_seuraava_ajopaiva/'

      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.RaportitKuukausiraportti,
          {
            method: httpMethod,
            url: url,
            body: this.kuukausiraportti.getPostData({ vainAjopaivanLaskeminen: true }),
          },
          'pushRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.kuukausiraportti.seuraava_ajopaiva = request.result.body.seuraava_ajoaika.substr(0, 10)
        this.valayta(this.seuraavaRaporttiClassArr)
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:formKuukausiraportti.calcNextRunDateFailed'))
      } finally {
        this.laskemassaSeuraavaaAjopaivaa = false
      }
    },
    valayta (classArr) {
      for (var i = 0; i < classArr.length; i++) {
        if (classArr[i] === 'taustavalahdys') {
          classArr.splice(i, 1)
          i--
        }
      }
      setTimeout(() => {
        classArr.push('taustavalahdys')
      }, 50)
    },
  }
}

</script>

<style lang="scss" scoped>

 .v-text-field, .v-select {
   padding-top: 0px;
   margin-top: 4px;
 }

 .hidden {
   visibility: hidden;
 }

 @keyframes valayta {
    0%   { background-color: initial !important; }
    50%  { background-color: var(--v-secondary-lighten1); border-radius: 5px !important; }
    100% { background-color: initial !important;}
  }

  .taustavalahdys {
    animation-name: valayta;
    animation-duration: 0.2s;
  }

</style>
