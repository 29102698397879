var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "offCanvasLasku" },
    [
      _c("OffCanvasToolbar", {
        attrs: {
          "toolbar-title": "Laskun tiedot",
          "to-name": "laskutus-nayta",
          "to-id": _vm.value ? _vm.value.id : null,
          "tooltip-title": "lasku",
        },
        on: {
          "close-button-click": function ($event) {
            return _vm.$emit("close-button-click")
          },
        },
      }),
      _c(
        "v-container",
        { staticClass: "grey lighten-5 px-8" },
        [
          _c("OffCanvasDetailsLasku", {
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "px-8" },
        [
          _c("Timeline", {
            attrs: { items: _vm.value.aikajana },
            on: {
              "msg-mark-as-unread": function ($event) {
                return _vm.$emit("msg-mark-as-unread")
              },
              "msg-mark-as-read": function ($event) {
                return _vm.$emit("msg-mark-as-unread")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }