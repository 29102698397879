<template>
  <v-row
    justify="center"
    class="pa-6"
  >
    <span
      class="title"
    >
      {{ otsikko }}
    </span>
  </v-row>
</template>

<script>

export default {
  props: {
    otsikko: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
