<template>
  <v-tooltip
    top
    :disabled="!infoteksti"
  >
    <template #activator="{ on }">
      <v-btn
        :class="kaikkiLuokat"
        fab
        small
        depressed
        :color="taustavari"
        v-on="on"
        @click="$emit('click')"
      >
        <v-icon color="ikoniVari">
          {{ ikoni }}
        </v-icon>
      </v-btn>
    </template>
    <template #default>
      {{ infoteksti }}
    </template>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    ikoni: {
      type: String,
      required: true,
    },
    taustavari: {
      type: String,
      required: false,
      default: 'primary',
    },
    ikoniVari: {
      type: String,
      required: false,
      default: 'white',
    },
    luokat: {
      type: Array,
      required: false,
      default: () => ['ma-0', 'ml-2'],
    },
    infoteksti: {
      type: String,
      required: false,
      default: '',
    },
    naytaAina: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    kaikkiLuokat () {
      const luokat = this.luokat
      if (!this.naytaAina) luokat.push('hidden-child')
      return luokat
    }
  },
}
</script>
