var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "formSopimusHyvita",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-select", {
                staticClass: "required",
                attrs: {
                  label: _vm.$t("common:type"),
                  type: "text",
                  items: _vm.tyyppiItems,
                  "menu-props": { zIndex: "999" },
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.value.tyyppi,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "tyyppi", $$v)
                  },
                  expression: "value.tyyppi",
                },
              }),
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": true,
                    "max-width": "290",
                    "nudge-bottom": "40",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              {
                                staticClass: "required",
                                attrs: {
                                  label: _vm.$t("common:paymentDate"),
                                  value: _vm.$date(_vm.value.maksupaiva),
                                  rules: [_vm.$validationRules.required],
                                },
                              },
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.alkamispaivaMenuAuki,
                    callback: function ($$v) {
                      _vm.alkamispaivaMenuAuki = $$v
                    },
                    expression: "alkamispaivaMenuAuki",
                  },
                },
                [
                  [
                    _c("v-date-picker", {
                      attrs: {
                        locale: _vm.$languageLocale(),
                        "first-day-of-week": "1",
                        "no-title": "",
                      },
                      on: {
                        change: function ($event) {
                          _vm.maksupaivaMenu = false
                        },
                      },
                      model: {
                        value: _vm.value.maksupaiva,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "maksupaiva", $$v)
                        },
                        expression: "value.maksupaiva",
                      },
                    }),
                  ],
                ],
                2
              ),
              _c("v-select", {
                staticClass: "required",
                attrs: {
                  label: _vm.$t("common:direction"),
                  type: "text",
                  items: _vm.suuntaItems,
                  "item-value": "value",
                  "item-text": "selite",
                  "menu-props": { zIndex: "999" },
                  rules: [_vm.$validationRules.required],
                },
                model: {
                  value: _vm.value.suunta,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "suunta", $$v)
                  },
                  expression: "value.suunta",
                },
              }),
              _c("v-text-field", {
                staticClass: "required",
                attrs: {
                  type: "number",
                  label: _vm.$t("common:taxableAmount"),
                  required: "",
                  rules: [
                    _vm.$validationRules.requiredLoose,
                    _vm.$validationRules.zeroOrBigger,
                  ],
                },
                model: {
                  value: _vm.value.summa,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "summa", $$v)
                  },
                  expression: "value.summa",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }