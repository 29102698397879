<template>
  <DefList :bordered="(true)">
    <DefListItem>
      <template v-slot:label>
        Nimi
      </template>
      <template
        v-if="value.nimi"
        v-slot:value
      >
        {{ value.nimi }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.nro">
      <template v-slot:label>
        Toimittajanumero
      </template>
      <template v-slot:value>
        {{ value.nro }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.id">
      <template v-slot:label>
        {{ $sovellusIdOtsake }}
      </template>
      <template v-slot:value>
        {{ value.id }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.tunnus">
      <template v-slot:label>
        Tunnus
      </template>
      <template v-slot:value>
        {{ value.tunnus }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.tyyppi">
      <template v-slot:label>
        Tyyppi
      </template>
      <template v-slot:value>
        {{ value.tyyppi }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.paamies">
      <template v-slot:label>
        Päämies
      </template>
      <template v-slot:value>
        {{ value.paamies.nimi }}
      </template>
    </DefListItem>
    <template v-if="value.postiosoite">
      <DefListItem>
        <template v-slot:label>
          Postisoite
        </template>
        <template v-slot:value>
          {{ value.postiosoite.osoite }}
          <br>{{ value.postiosoite.numero }} {{ value.postiosoite.toimipaikka }}
        </template>
      </DefListItem>
    </template>
    <template v-if="value.verkkolaskuosoite">
      <DefListItem>
        <template v-slot:label>
          Verkkolaskuosoite
        </template>
        <template v-slot:value>
          {{ value.verkkolaskuosoite.verkkolaskuosoite }}
        </template>
      </DefListItem>
      <DefListItem>
        <template v-slot:label>
          Verkkolaskuoperaattori
        </template>
        <template v-slot:value>
          {{ value.verkkolaskuosoite.operaattori }}
        </template>
      </DefListItem>
    </template>
  </DefList>
</template>

<script>

export default {
  name: 'OffCanvasDetailsToimittaja',
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
