var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pp-container" },
    [
      _c(
        "v-dialog",
        {
          staticClass: "dialog-palvelutasot",
          model: {
            value: _vm.palvelutasoVertailu,
            callback: function ($$v) {
              _vm.palvelutasoVertailu = $$v
            },
            expression: "palvelutasoVertailu",
          },
        },
        [
          _c("v-card", { staticClass: "dialog-card" }, [
            _c("div", { staticClass: "dialog-header" }, [
              _c("h1", [_vm._v(_vm._s(_vm.$t("common:features")))]),
            ]),
            _c(
              "div",
              { staticClass: "dialog-lists" },
              [
                _c(
                  "v-list",
                  [
                    _c("v-list-item", { staticClass: "list-header" }, [
                      _c("h2", [
                        _vm._v(_vm._s(_vm.valittavatPalvelusopimukset[0].nimi)),
                      ]),
                    ]),
                    _vm._l(
                      _vm.valittavatPalvelusopimukset[0].ominaisuudet
                        .ominaisuudet_palvelutaso,
                      function (ominaisuudet) {
                        return _c(
                          "v-list-item",
                          { key: ominaisuudet.teksti },
                          [
                            _c(
                              "v-list-item-avatar",
                              [
                                ominaisuudet.sisaltyy_sopimukseen
                                  ? _c("v-icon", { staticClass: "check2" }, [
                                      _vm._v(" mdi-check "),
                                    ])
                                  : _vm._e(),
                                !ominaisuudet.sisaltyy_sopimukseen
                                  ? _c("v-icon", { staticClass: "close2" }, [
                                      _vm._v(" mdi-close "),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              { staticClass: "list-item-text" },
                              [_vm._v(_vm._s(ominaisuudet.teksti))]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "v-list",
                  [
                    _c("v-list-item", { staticClass: "list-header" }, [
                      _c("h2", [
                        _vm._v(_vm._s(_vm.valittavatPalvelusopimukset[1].nimi)),
                      ]),
                    ]),
                    _vm._l(
                      _vm.valittavatPalvelusopimukset[1].ominaisuudet
                        .ominaisuudet_palvelutaso,
                      function (ominaisuudet) {
                        return _c(
                          "v-list-item",
                          { key: ominaisuudet.teksti },
                          [
                            _c(
                              "v-list-item-avatar",
                              [
                                ominaisuudet.sisaltyy_sopimukseen
                                  ? _c("v-icon", { staticClass: "check2" }, [
                                      _vm._v(" mdi-check "),
                                    ])
                                  : _vm._e(),
                                !ominaisuudet.sisaltyy_sopimukseen
                                  ? _c("v-icon", { staticClass: "close2" }, [
                                      _vm._v(" mdi-close "),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              { staticClass: "list-item-text" },
                              [_vm._v(_vm._s(ominaisuudet.teksti))]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "dialog-close" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.palvelutasoVertailu = false
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("common:close")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "pp-subheader1" }, [
        _vm._v(_vm._s(_vm.$t("common:testForFreeNoCommitment"))),
      ]),
      _c("div", { staticClass: "pp-subheader2" }, [
        _vm._v(" " + _vm._s(_vm.$t("common:openBillsWillBeTakenCareOf")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "pp-switch-container" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("common:invoicing")))]),
          _c(
            "v-chip",
            {
              staticClass: "chip",
              class: {
                active: _vm.laskutusjakso === _vm.laskutusjaksoKuukausi,
              },
              on: {
                click: function ($event) {
                  _vm.laskutusjakso = _vm.laskutusjaksoKuukausi
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common:monthUpperCase")))]
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-badge",
            { attrs: { content: "Säästä X%", color: "success" } },
            [
              _c(
                "v-chip",
                {
                  staticClass: "chip",
                  class: {
                    active: _vm.laskutusjakso === _vm.laskutusjaksoVuosi,
                  },
                  on: {
                    click: function ($event) {
                      _vm.laskutusjakso = _vm.laskutusjaksoVuosi
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("common:yearUpperCase")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "pp-main-content" }, [
        _c(
          "div",
          { staticClass: "pp-info-container" },
          [
            _c("v-chip", {
              staticClass: "popular",
              attrs: { color: "transparent" },
            }),
            _c("div", { staticClass: "pp-info-box ib-special" }, [
              _c("div", { staticClass: "ib-header" }, [_c("v-divider")], 1),
              _c("div", { staticClass: "info-main" }, [
                _c("div", [_vm._v(_vm._s(_vm.$t("common:invoicing")))]),
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("common:productAndCustomerRegistry"))),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("common:timedAndCreditNotes"))),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("common:reportsAndStatistics"))),
                ]),
                _c("div", [_vm._v(_vm._s(_vm.$t("common:paymentControl")))]),
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("common:automaticPaymentReminders"))),
                ]),
                _c("div", [_vm._v(_vm._s(_vm.$t("common:invoiceFinancing")))]),
              ]),
              _c("div", { staticClass: "info-bottom" }),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pp-info-container" },
          [
            _c("v-chip", {
              staticClass: "popular",
              attrs: { color: "transparent" },
            }),
            _c("div", { staticClass: "pp-info-box ib-real ib-1" }, [
              _c(
                "div",
                { staticClass: "ib-header" },
                [
                  _c("h2", [
                    _vm._v(_vm._s(_vm.valittavatPalvelusopimukset[0].nimi)),
                  ]),
                  _c("div", [
                    _vm.laskutusjakso === _vm.laskutusjaksoKuukausi
                      ? _c("h1", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.valittavatPalvelusopimukset[0].kkhinta
                              ) +
                              "€/" +
                              _vm._s(_vm.$t("common:monthShorthand")) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.laskutusjakso === _vm.laskutusjaksoVuosi
                      ? _c("h1", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.valittavatPalvelusopimukset[0].vuosihinta
                              ) +
                              "€/" +
                              _vm._s(_vm.$t("common:yearLowerCase")) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("v-divider", { staticClass: "ib1-divider" }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "info-main" },
                [
                  _c("v-icon", { staticClass: "check" }, [_vm._v("mdi-check")]),
                  _c("v-icon", { staticClass: "check" }, [_vm._v("mdi-check")]),
                  _c("v-icon", { staticClass: "check" }, [_vm._v("mdi-check")]),
                  _c("v-icon", { staticClass: "check" }, [_vm._v("mdi-check")]),
                  _c("v-icon", { staticClass: "close" }, [_vm._v("mdi-close")]),
                  _c("v-icon", { staticClass: "close" }, [_vm._v("mdi-close")]),
                  _c("v-icon", { staticClass: "close" }, [_vm._v("mdi-close")]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "info-bottom" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.$emit("nextStep")
                          _vm.valitsePalvelutaso(
                            _vm.valittavatPalvelusopimukset[0]
                          )
                          _vm.valitseLaskutusjakso(_vm.laskutusjakso)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("common:serviceApplication:startForFree"))
                      ),
                    ]
                  ),
                  _c(
                    "v-chip",
                    {
                      attrs: { outlined: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.palvelutasoVertailu = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "common:serviceApplication:seeAllContractFeatures"
                          )
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pp-info-container" },
          [
            _c(
              "v-chip",
              { staticClass: "popular", attrs: { color: "primary" } },
              [_vm._v(_vm._s(_vm.$t("common:serviceApplication:mostPopular")))]
            ),
            _c("div", { staticClass: "pp-info-box ib-real ib-2" }, [
              _c(
                "div",
                { staticClass: "ib-header" },
                [
                  _c("h2", [
                    _vm._v(_vm._s(_vm.valittavatPalvelusopimukset[1].nimi)),
                  ]),
                  _c("div", [
                    _vm.laskutusjakso === _vm.laskutusjaksoKuukausi
                      ? _c("h1", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.valittavatPalvelusopimukset[1].kkhinta
                              ) +
                              "€/kk "
                          ),
                        ])
                      : _vm._e(),
                    _vm.laskutusjakso === _vm.laskutusjaksoVuosi
                      ? _c("h1", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.valittavatPalvelusopimukset[1].vuosihinta
                              ) +
                              "€/vuosi "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("v-divider", { staticClass: "ib2-divider" }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "info-main" },
                [
                  _c("v-icon", { staticClass: "check" }, [_vm._v("mdi-check")]),
                  _c("v-icon", { staticClass: "check" }, [_vm._v("mdi-check")]),
                  _c("v-icon", { staticClass: "check" }, [_vm._v("mdi-check")]),
                  _c("v-icon", { staticClass: "check" }, [_vm._v("mdi-check")]),
                  _c("v-icon", { staticClass: "check" }, [_vm._v("mdi-check")]),
                  _c("v-icon", { staticClass: "check" }, [_vm._v("mdi-check")]),
                  _c("v-icon", { staticClass: "check" }, [_vm._v("mdi-check")]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "info-bottom" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "successlight",
                      on: {
                        click: function ($event) {
                          _vm.$emit("nextStep")
                          _vm.valitsePalvelutaso(
                            _vm.valittavatPalvelusopimukset[1]
                          )
                          _vm.valitseLaskutusjakso(_vm.laskutusjakso)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("common:serviceApplication:startForFree"))
                      ),
                    ]
                  ),
                  _c(
                    "v-chip",
                    {
                      attrs: { outlined: "", color: "white" },
                      on: {
                        click: function ($event) {
                          _vm.palvelutasoVertailu = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "common:serviceApplication:seeAllContractFeatures"
                          )
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }