var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "nav-main" }, [
    _vm.navItems.length
      ? _c(
          "ul",
          { staticClass: "nav-main__list" },
          _vm._l(_vm.navItems, function (item) {
            return _c(
              "li",
              { key: item.index, staticClass: "nav-main__item" },
              [
                _c(
                  "router-link",
                  {
                    class: item.disabled
                      ? _vm.linkClassDisabled
                      : _vm.linkClass,
                    attrs: {
                      to: item.disabled
                        ? { name: "dummy" }
                        : { name: item.name },
                    },
                  },
                  [
                    _c("v-tooltip", {
                      attrs: {
                        right: "",
                        disabled: _vm.showTitles && !item.disabled,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    { staticClass: "nav-main__iconWrap" },
                                    on
                                  ),
                                  [
                                    item.meta.icon
                                      ? _c("span", {
                                          staticClass: "nav-main__icon",
                                          class: item.meta.icon,
                                        })
                                      : _vm._e(),
                                    _c("Badge", {
                                      model: {
                                        value: item.meta.badge,
                                        callback: function ($$v) {
                                          _vm.$set(item.meta, "badge", $$v)
                                        },
                                        expression: "item.meta.badge",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.disabled
                                        ? _vm.$t("notAvailable")
                                        : item.meta.title
                                    ) +
                                    " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("v-tooltip", {
                      attrs: { right: "", disabled: !item.disabled },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showTitles,
                                          expression: "showTitles",
                                        },
                                      ],
                                      staticClass: "nav-main__title",
                                    },
                                    on
                                  ),
                                  [_vm._v(_vm._s(item.meta.title))]
                                ),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("notAvailable")) + " "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }