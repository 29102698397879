import { HttpError } from '@/utils/errors'
import {
  doRequestWithTimeout,
} from '@/utils/misc'
import BaseModule from '@/store/modules/baseModule'
import { Toimeksianto } from '@/class/Toimeksianto'

export default class PerintaUusi extends BaseModule {
  state () {
    return {
      ...super.state(),
      apiName: 'Toimeksiannot',
      kenttienValinnat: {},
      luotuToimeksianto: {},
      toimeksianto: new Toimeksianto(true)
    }
  }

  getters () {
    return {
      ...super.getters()
    }
  }

  mutations () {
    return {
      setKenttienValinnat (state, payload) {
        state.kenttienValinnat = payload
      },
      setLuotuToimeksianto (state, payload) {
        state.luotuToimeksianto = payload
      },
      setToimeksianto (state, payload) {
        state.toimeksianto = payload
      },
    }
  }

  actions () {
    return {
      async haeVelallisenKenttienValinnat ({ commit, getters }, velallinen_id = '') {
        const queryParams = new URLSearchParams()

        if (velallinen_id) {
          queryParams.append('velallinen_id', velallinen_id)
        }

        const request = await doRequestWithTimeout(
          getters.apiModule.getInstance('Velalliset'),
          {
            url: 'toiminnot/kenttien_valinnat/',
            method: 'GET',
            query: queryParams.toString(),
          },
        )

        const kenttienValinnat = JSON.parse(JSON.stringify(request.result.body))
        commit('setKenttienValinnat', kenttienValinnat)
      },
      async luoToimeksianto ({ commit, state, getters }) {
        const request = await doRequestWithTimeout(
          getters.api,
          {
            method: 'POST',
            url: '',
            body: state.toimeksianto.getPostData(),
          },
          'doSingleRequest',
        )

        if (!request.success) throw new HttpError(request)

        commit('setLuotuToimeksianto', request.result.body)
      },
      lahetaLiitteet ({ state, dispatch }) {
        return Promise.all(state.toimeksianto.tiedostot.map(async (tiedosto) => {
          return await dispatch('lahetaAsiakirja', tiedosto)
        }))
      },
      /**
       * Actionin käytön edellytyksenä on se, että toimeksianto on luotu.
       */
      async lahetaAsiakirja ({ state, getters }, tiedosto) {
        const formData = new FormData()
        formData.set('attachment_file', tiedosto)

        const request = await doRequestWithTimeout(
          getters.apiModule.getInstance('Toimeksianto'),
          {
            method: 'POST',
            url: 'toiminnot/asiakirja/',
            body: formData,
            params: {
              id: state.luotuToimeksianto.id
            }
          },
          'doRequest',
        )

        if (!request.success) throw new HttpError(request)

        return request
      }
    }
  }

}
