var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-6 pt-4 primary" },
    [
      _c(
        "v-tabs",
        {
          attrs: {
            "background-color": "primary",
            color: "grey lighten-4",
            "slider-color": "transparent",
            "show-arrows": "mobile",
          },
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        _vm._l(_vm.tabs, function (item) {
          return _c(
            "v-tab",
            { key: item.index, attrs: { href: "#" + item.id, ripple: false } },
            [_c("p", { style: _vm.styleClass }, [_vm._v(_vm._s(item.label))])]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }