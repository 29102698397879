<template>
  <ul
    v-if="languages.length"
    class="languageSwitch"
  >
    <li
      v-for="item in getLanguagesNotSelected" 
      :key="item"
      class="languageSwitch__item"
    >
      <v-list-item @click="changeLanguage(item)">
        <v-list-item-avatar>
          {{ item.toUpperCase() }}
        </v-list-item-avatar>
        <v-list-item-title>Vaihda kieli</v-list-item-title>
      </v-list-item>
    </li>
  </ul>
</template>

<script>

import i18next from 'i18next'

export default {
  name: 'LanguageSwitch',
  data () {
    return {
      languages: [
        'fi',
        'en',
      ],
    }
  },
  computed: {
    getLanguagesNotSelected () {
      return this.languages.filter(item => {
        if (item === localStorage.getItem('lngI18next')) return false
        return item
      })
    },
  },
  methods: {
    async changeLanguage (lang) {
      try {
        localStorage.setItem('fallbackLngI18next', i18next.language)
        const lngLocaleI18next = lang.toLowerCase() + '-' + lang.toUpperCase()
        localStorage.setItem('lngLocaleI18next', lngLocaleI18next)
        const i18return = await this.$i18n.i18next.changeLanguage(lang)
        localStorage.setItem('lngI18next', lang)
        window.location.reload(true)
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    }
  },

}

</script>

<style lang="scss" scoped>

  .languageSwitch {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
      display: block;
      flex: 0 0 50%;
    }

    &__link {
      display: block;
      text-transform: uppercase;
      color: inherit;
      padding: 1em .5em;
      text-align: center;

      &:hover {
        background: rgba(0,0,0,.05);
      }
    }
  }

</style>
