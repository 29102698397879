import BaseSelaa from '@/store/modules/baseSelaa'

export default class OstolaskuMaksutoimeksiantoSelaa extends BaseSelaa {
  state () {
    return {
      ...super.state(),
      apiName: 'OstolaskuMaksutoimeksiannot',
    }
  }
}
