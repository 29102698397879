<template>
  <v-autocomplete
    :ref="reference"
    v-model="tuote.tuoteryhma"
    :items="tuoteryhmaItems"
    :loading="tuoteryhmaLoading"
    :search-input.sync="tuoteryhmaSearchQuery"
    :hide-no-data="(!tuoteryhmaSearchQuery || tuoteryhmaLoading)"
    :placeholder="!tuoteryhmaSearchQuery && !tuoteryhmaItems.length ? 'Ei tuoteryhmiä. Luo ensin ainakin yksi tuoteryhmä.' : ''"
    item-text="nimi"
    item-value="id"
    :label="$t('common:productGroup.productGroupHeader')"
    return-object
    :class="required ? 'required' : ''"
    :rules="rules"
    @click:append="uusiTuoteryhma"
  >
    <template
      v-slot:item="{ item }"
    >
      <template>
        <v-list-item-content>
          <v-list-item-title>{{ item.nimi }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" @click="uusiTuoteryhma" v-on="on" v-bind="attrs" >
            add
          </v-icon>
          <v-btn @click="uusiTuoteryhma" v-on="on" v-bind="attrs" >
            {{ $t('common:productGroup.addProductGroup') }}
          </v-btn>
        </template>
        <span>{{ $t('common:productGroup.searchFoundNoProductGroups') }}</span>
      </v-tooltip>
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:append>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" @click="uusiTuoteryhma" v-on="on" v-bind="attrs" >
            add
          </v-icon>
        </template>
        <span>Lisää tuoteryhmä</span>
      </v-tooltip>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AutocompleteTuoteryhma',
  model: {
    prop: 'tuote',
  },
  props: {
    tuote: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    reference: {
      type: String,
      required: false,
      default: () => "autocompleteTuoteryhma",
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data () {
    return {
      debounceDelay: 500,
      debounceTimeout: '',
      tuoteryhmaItems: [],
      tuoteryhmaLoading: false,
      tuoteryhmaSearchQuery: '',
    }
  },
  computed: {
    ...mapState([
    'yleinen',
    ]),
  },
  watch: {
    async tuoteryhmaSearchQuery (nimi, vanhaNimi) {
      if (nimi == null) return

      clearTimeout(this.debounceTimeout)

      this.tuoteryhmaItems = []
      this.tuoteryhmaLoading = true

      this.debounceTimeout = setTimeout(() => {
        this.haeTuoteryhmat(nimi)
      }, this.debounceDelay)
    },
    'tuote.paamies.id': {
      handler (id) {
        if (id) this.haeTuoteryhmat()
      }
    },
  },
  methods: {
    async uusiTuoteryhma () {
      this.$emit('uusiTuoteryhma')
    },
    async haeTuoteryhmat (nimi = '') {
      try {
        if (!this.tuote.paamies) return

        const query = new URLSearchParams()
        if (nimi) query.append('nimi', nimi)
        query.append('paamies', this.tuote.paamies.id)
        query.append('psize', 50)

        const request = await this.$doRequestWithTimeout(
          this.$api.Tuoteryhmat,
          {
            method: 'GET',
            query: query.toString(),
          },
          'pushRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.tuoteryhmaItems = request.result.body.results
      } catch (e) {
        this.$sentryCaptureCustom(e)
      } finally {
        this.tuoteryhmaLoading = false
      }
    }
  }
}
</script>

<style>

</style>
