var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "grey lighten-4" },
    [
      _vm.showBackgroundImage
        ? _c("BgImg", {
            attrs: {
              img: _vm.backgroundImage,
              "pos-x": _vm.backgroundImagePosX,
              "pos-y": _vm.backgroundImagePosY,
              opacity: _vm.backgroundOpacity,
              "overlay-color": _vm.backgroundOverlayColor,
              "overlay-opacity": _vm.backgroundOverlayOpacity,
            },
          })
        : _vm._e(),
      _c("VersionRibbon", { attrs: { text: _vm.ribbonText } }),
      _vm.sidebarEnabled ? _c("SidebarLeft") : _vm._e(),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }