import {
  doRequestWithTimeout,
  suodataTyhjanMerkkijononSisaltavatKentat,
  isNumeric,
} from '@/utils/misc'
import api from '@/plugins/api'

export const Asiakas = class {
  constructor (preset = {}) {
    this.asiakasryhmaasiakasrekisteri_set = [] // Read-only
    this._emailosoite_set = []
    this.etunimi = '' // vain henkilöasiakkaat
    this.id = null
    this.kieli = 'fi-fi'
    this.lahetystapa = ''
    this.muistiinpano_set = [] // Read-only
    this.nimi = ''
    this.nro = ''
    this.oletusmaksuehto = 14
    this.oletustoimitustapa = ''
    this.oletusvapaateksti = ''
    this.oletusviitteemme = ''
    this.oletusviitteenne = ''
    this.oletusviivkorko = 7 // Read-only
    this.paamies_id = null
    this.postiosoite = new AsiakasPostiosoite()
    this.puhelin = new AsiakasPuhelin()
    this.postiosoite_set = [] // Read-only
    this.sukunimi = '' // vain henkilöasiakkaat
    this.tunnus = ''
    this.tyyppi = ''
    this.verkkolaskuosoite = new AsiakasVerkkolaskuosoite()

    Object.assign(this, preset)
    if (this.tyyppi === 'H') {
      const nimet = this.nimi.split(' ')
      this.sukunimi = nimet.shift()
      this.etunimi = nimet.join(' ')
    }

    // osoitteet on eroteltu pilkulla
    if (preset.emailosoite) {
      this.emailosoite_set = preset.emailosoite.email.split(',').filter(eo => eo).map(
        eo => ({ email: eo })
      )

      delete this.emailosoite
    } else if (preset.emailosoite_set) {
      this._emailosoite_set = preset.emailosoite_set.map(eo => new AsiakasEmail(eo))
    }

    if (preset.lahetystapa === 'verkkolasku_b2c') this.lahetystapa = 'verkkolasku'
    this.postiosoite = new AsiakasPostiosoite(preset.postiosoite)
    this.puhelin = new AsiakasPuhelin(preset.puhelin)
    this.verkkolaskuosoite = new AsiakasVerkkolaskuosoite(preset.verkkolaskuosoite)
  }

  get emailosoite_set () {
    return this._emailosoite_set
  }

  set emailosoite_set (emailosoitteet) {
    this._emailosoite_set = emailosoitteet.map(eo => new AsiakasEmail(eo))
  }

  getPostData () {
    const postData = {
      kieli: this.kieli,
      nimi: this.nimi,
      nro: this.nro,
      paamies_id: this.paamies_id,
      postiosoite: this.postiosoite.getPostData(),
      tunnus: this.tunnus,
      tyyppi: this.tyyppi,
      lahetystapa: this.lahetystapa,
      oletustoimitustapa: this.oletustoimitustapa,
      oletusvapaateksti: this.oletusvapaateksti,
      oletusviitteemme: this.oletusviitteemme,
      oletusviitteenne: this.oletusviitteenne,
    }

    if (this.tyyppi === 'H') {
      postData.nimi = this.sukunimi.trim() + ' ' + this.etunimi.trim()
    }

    if (this.emailosoite_set.length && this.emailosoite_set.filter(eo => eo.email).length) {
      postData.emailosoite = this.emailosoite_set[0].getPostData()

      // kootaan kaikki emailosoitteet yhteen kenttään
      postData.emailosoite.email = this.emailosoite_set.map(eo => eo.email).filter(eo => eo).join(',')
    }

    delete postData.emailosoite_set

    if (!this.puhelin.numero) {
      delete postData.puhelin
    } else {
      postData.puhelin = this.puhelin.getPostData()
    }

    if (!this.verkkolaskuosoite.verkkolaskuosoite) {
      delete postData.verkkolaskuosoite
    } else {
      postData.verkkolaskuosoite = this.verkkolaskuosoite.getPostData()
    }

    if (!isNaN(parseInt(this.oletusmaksuehto))) {
      postData.oletusmaksuehto_write = this.oletusmaksuehto
    }

    return postData
  }

  static async haeAsiakkaanKenttienValinnat (asiakas_id = '') {
    const queryParams = new URLSearchParams()

    if (asiakas_id) {
      queryParams.append('asiakas_id', asiakas_id)
    }

    const request = await doRequestWithTimeout(
      api.Asiakkaat,
      {
        url: 'toiminnot/kenttien_valinnat/',
        method: 'GET',
        query: queryParams.toString(),
      },
    )

    const kenttienValinnat = JSON.parse(JSON.stringify(request.result.body))
    return kenttienValinnat
  }

  validate () {
    const virheet = []

    // katsotaan vain pakolliset kentät
    if (!this.nimi) virheet.push('nimi')
    if (!this.kieli) virheet.push('kieli')
    if (!isNumeric(this.paamies_id)) virheet.push('paamies_id')

    const poVirheet = this.postiosoite.validate()
    if (poVirheet) virheet.push(...poVirheet)

    if (this.verkkolaskuosoite.operaattori || this.verkkolaskuosoite.verkkolaskuosoite) {
      const vloVirheet = this.verkkolaskuosoite.validate()
      if (vloVirheet) virheet.push(...vloVirheet)
    }

    return virheet
  }
}

export const AsiakasOsoite = class {
  constructor (preset = {}) {
    this.id = ''
    this.asiakas_id = ''
    this.prioriteetti = ''
    this.saaja = ''

    Object.assign(this, preset)
    //this.valiaikainen_alkaen = ''  // TODO toteutus suunniteltava
    //this.valiaikainen_loppuen = '' // TODO toteutus suunniteltava
  }

  getPostData (ilmanAsiakasId = true) {
    const postData = this
    if (ilmanAsiakasId) delete postData.asiakas_id
    return suodataTyhjanMerkkijononSisaltavatKentat(this)
  }
}

export const AsiakasPostiosoite = class extends AsiakasOsoite {
  constructor (preset = {}) {
    super({})
    this.maa = 'FI'
    this.nimi = ''
    this.numero = ''
    this.osoite = ''
    this.toimipaikka = ''

    Object.assign(this, preset)
  }

  validate () {
    let virheet = []

    // katsotaan vain pakolliset kentät
    if (!this.maa) virheet.push('maa')
    if (!this.nimi) virheet.push('nimi')
    if (!this.numero) virheet.push('numero')
    if (!this.osoite) virheet.push('osoite')
    if (!this.toimipaikka) virheet.push('toimipaikka')

    return virheet
  }
}

export const AsiakasEmail = class extends AsiakasOsoite {
  constructor (preset = {}) {
    super({})
    this.email = ''

    Object.assign(this, preset)
  }
}

export const AsiakasPuhelin = class extends AsiakasOsoite {
  constructor (preset = {}) {
    super({})
    this.numero = ''

    Object.assign(this, preset)
  }
}

export const AsiakasVerkkolaskuosoite = class extends AsiakasOsoite {
  constructor (preset = {}) {
    super({})
    this.operaattori = ''
    this.verkkolaskuosoite = ''
    // this.verkkolaskutyyppi = ''

    Object.assign(this, preset)
  }

  validate () {
    const virheet = []

    if (!this.operaattori) virheet.push('operaattori')
    if (!this.verkkolaskuosoite) virheet.push('verkkolaskuosoite')

    return virheet
  }
}
