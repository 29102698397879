import permission from '@/plugins/permission'
import { doRequestWithTimeout } from "@/utils/misc";
import api from "@/plugins/api";
import router from "@/utils/router";
import { oletusVirheenkasittely } from "@/utils/misc";

/**
 * Käyttäjään liittyvät tiedot ja oikeudet päivitetään sisäänkirjautumisen yhteydessä. Lisäksi
 * tiedot päivitetään mikäli 'valittuPaamies' on päämies, jolla ei ole lainkaan oikeuksia tai
 * päämiestä ei löydy 'permissionsPaamies'-objektista. Näin voi käydä, jos päämies on luotu
 * nykyisen session aikana. Tarkistukset tehdään navigoinnin yhteydessä (router.js) ja
 * päämieslistauksen haussa (AutocompletePaamies.vue).
 */

export default {
  namespaced: true,
  state: {
    username: '',
    firstName: '',
    lastName: '',
    naytettavaNimi: '',
    email: '',
    vainYksiPaamies: true,
    lukemattomatViestit: 0,
    permissions: [],
    permissionsPaamies: {},
    valittuPaamies: {},
    palvelusopimukset: [],
    tiivisNakyma: localStorage.getItem('tiivisNakyma') === 'true',
    isSuperuser: false,
    isStaff: false,
    tekeytyminenKaynnissa: false,
  },
  getters: {
    dataLoaded: state => {
      return (state.username.length > 0)
    },
    valittuPaamies: state => {
      return state.valittuPaamies
    },
    salliPaamiehenTyhjennys: state => {
      return (
        permission.checkSinglePerm('onlinerestapi.onlinerestapi_paamieshallinta') ||
        !state.vainYksiPaamies
      )
    },
    paamiehenPalvelusopimus: state => {
      return state.palvelusopimukset.filter(
        ps => ps.id === state.valittuPaamies.palvelusopimus_id
      )[0]
    },
    getValittuPaamiesPerms: state => {
      const { valittuPaamies } = state
      if (
        !valittuPaamies
        || !valittuPaamies.id
        || !(valittuPaamies.id in state.permissionsPaamies)
      ) { return [] }

      return state.permissionsPaamies[valittuPaamies.id]
    },
    tarkistetaankoPaamieskohtaisetOikeudet: state => {
      return !state.isStaff && !state.isSuperuser
    },
  },
  mutations: {
    setUsername (state, payload) {
      state.username = payload
    },
    setFirstName (state, payload) {
      state.firstName = payload
    },
    setLastName (state, payload) {
      state.lastName = payload
    },
    setNaytettavaNimi (state, payload) {
      state.naytettavaNimi = payload
    },
    setEmail (state, payload) {
      state.email = payload
    },
    setLukemattomatViestit (state, payload) {
      state.lukemattomatViestit = payload
    },
    setVainYksiPaamies (state, payload) {
      state.vainYksiPaamies = payload
    },
    setDetails (state, payload) {
      state.details = payload
    },
    setPermissions (state, payload) {
      state.permissions = payload
    },
    setPermissionsPaamies (state, payload) {
      state.permissionsPaamies = payload
    },
    setValittuPaamies (state, payload) {
      state.valittuPaamies = payload || {}
    },
    setValittuPaamiesMaksutiedot (state, payload) {
      state.valittuPaamies.maksutieto.iban = payload.iban
      state.valittuPaamies.maksutieto.bic = payload.bic
    },
    setValittuPaamiesPaamiesasetukset (state, payload) {
      state.valittuPaamies.viestikirjeeseen = payload.viestikirjeeseen
    },
    setValittuPaamiesPalvelusopimusId (state, payload) {
      state.valittuPaamies.palvelusopimus_id = payload.palvelusopimusId
    },
    setTiivisNakyma (state, payload) {
      state.tiivisNakyma = payload
      localStorage.setItem('tiivisNakyma', payload)
    },
    setIsSuperuser (state, payload) {
      state.isSuperuser = payload
    },
    setIsStaff (state, payload) {
      state.isStaff = payload
    },
    setTekeytyminenKaynnissa (state, payload) {
      state.tekeytyminenKaynnissa = payload
    },
    setPalvelusopimukset (state, payload) {
      state.palvelusopimukset = payload
    },
  },
  actions: {
    setDetails ({ commit }, payload) {
      return new Promise((resolve) => {
        commit('setUsername', payload.username)
        commit('setFirstName', payload.first_name)
        commit('setLastName', payload.last_name)
        commit('setNaytettavaNimi', payload.naytettava_nimi)
        commit('setEmail', payload.email)
        commit('setLukemattomatViestit', payload.lukemattomat_viestit_kpl)
        commit('setVainYksiPaamies', payload.vain_yksi_paamies)
        commit('setPermissions', payload.permissions)
        commit('setPermissionsPaamies', payload.permissions_paamies)
        commit('setIsSuperuser', payload.is_superuser)
        commit('setIsStaff', payload.is_staff)
        commit('setTekeytyminenKaynnissa', payload.tekeytyminen_kaynnissa)
        if (!this.state.user.valittuPaamies.id) commit('setValittuPaamies', payload.oletuspaamies)

        resolve()
      })
    },

    clearDetails ({ commit }) {
      return new Promise((resolve) => {
        commit('setUsername', '')
        commit('setFirstName', '')
        commit('setLastName', '')
        commit('setNaytettavaNimi', '')
        commit('setEmail', '')
        commit('setLukemattomatViestit', 0)
        commit('setVainYksiPaamies', true)
        commit('setValittuPaamies', {})
        commit('setPermissions', [])
        commit('setPermissionsPaamies', {})
        commit('setIsSuperuser', false)
        commit('setIsStaff', false)
        commit('setTekeytyminenKaynnissa', false)

        resolve()
      })
    },

    async haePalvelusopimukset ({ commit }) {
      const request = await doRequestWithTimeout(
        api.Palvelusopimus,
        {},
        'doSingleRequest',
      )

      if (!request.success) throw new this.$HttpError(request)

      commit('setPalvelusopimukset', request.result.body)
    },

    async tekeydy ({ dispatch }, payload) {
      const res = await doRequestWithTimeout(
        api.TokenTekeydy,
        {
          method: 'GET',
          params: { id: payload.kayttajaId }
        }
      )
      if (!res.success) throw new this.$HttpError(res)

      localStorage.setItem('tekeytyjaAccess', localStorage.getItem('accessToken'))
      localStorage.setItem('tekeytyjaRefresh', localStorage.getItem('refreshToken'))

      localStorage.setItem('accessToken', res.result.body.access)
      localStorage.setItem('refreshToken', res.result.body.refresh)

      dispatch('clearDetails')

      if (router.currentRoute.name !== payload.siirrySivulle) {
         router.replace({ name: payload.siirrySivulle })
      }
    },

    async lopetaTekeytyminen ({ dispatch }, payload) {
      localStorage.setItem('accessToken', localStorage.getItem('tekeytyjaAccess'))
      localStorage.setItem('refreshToken', localStorage.getItem('tekeytyjaRefresh'))

      localStorage.removeItem('tekeytyjaAccess')
      localStorage.removeItem('tekeytyjaRefresh')

      dispatch('clearDetails')

      if (router.currentRoute.name !== payload.siirrySivulle) {
        router.replace({ name: payload.siirrySivulle })
        return
      }

      // haetaan käyttäjän tiedot jos ollaan samalla sivulle kuin minne oltaisiin siirrytty
      const res = await doRequestWithTimeout(
        api.User,
        { method: 'GET' }
      )
      if (!res.success) throw new this.$HttpError(res)
    },
  },
}
