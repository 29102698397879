import colors from 'vuetify/lib/util/colors'

// TODO otetaan kommentit pois kun värit on päätetty

// const general = {
//   warning: colors.yellow.darken3,
//   info: colors.cyan.lighten1,
//   success: colors.green.darken1,
//   error: colors.deepOrange.darken3,
// }

// const themes = [
//   {
//     id: 'cyan',
//     colors: {
//       primary: colors.cyan.darken2,
//       secondary: colors.cyan.lighten1,
//       accent: colors.cyan.lighten1,
//       ...general,
//     },
//   },
//   {
//     id: 'blue',
//     colors: {
//       primary: colors.indigo.darken1,
//       secondary: colors.indigo.lighten3,
//       accent: colors.indigo.lighten3,
//       ...general,
//     },
//   },
//   {
//     id: 'blue-grey',
//     colors: {
//       primary: colors.blueGrey.darken1,
//       secondary: colors.blueGrey.lighten4,
//       accent: colors.blueGrey.lighten4,
//       ...general,
//     },
//   },
//   {
//     id: 'orange',
//     colors: {
//       primary: colors.orange.darken4,
//       secondary: colors.orange.darken1,
//       accent: colors.orange.lighten1,
//       ...general,
//     },
//   },
//   {
//     id: 'pink',
//     colors: {
//       primary: colors.pink.darken2,
//       secondary: colors.pink.lighten3,
//       accent: colors.pink.lighten3,
//       ...general,
//     },
//   },
//   {
//     id: 'purple',
//     colors: {
//       primary: '#3f3759',
//       secondary: colors.deepPurple.lighten3,
//       accent: colors.deepPurple.lighten3,
//       ...general,
//     },
//   },

const themes = [
  {
    id: 'creditvisor',
    colors: {
      primary: '#f7a600',
      secondary: '#f9f9f9',
      accent: '#f9f9f9',
      warning: colors.yellow.darken3,
      info: colors.cyan.lighten1,
      success: colors.green.darken1,
      error: colors.deepOrange.darken3,
    },
  },
  {
    id: 'uuva',
    colors: {
      primary: '#3f3759',
      secondary: colors.deepPurple.lighten3,
      accent: colors.deepPurple.lighten3,
      warning: colors.yellow.darken3,
      info: colors.cyan.lighten1,
      successdark: '#102A43',
      success: colors.green.darken1,
      successlight: '#A6F2DC',
      errordark: '#610316',
      error: colors.deepOrange.darken3,
      errorlight: '#FFADAF'
    },
  },
]

const getTheme = (id) => {
  const teema = themes.find(theme => theme.id === id)
  if (!teema) throw new Error('Teemaa ei löydy')
  return teema.colors
}

export {
  themes,
  getTheme,
}
