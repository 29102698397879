<template>
  <DefList :bordered="(true)">
    <DefListItem v-if="value.tuoteryhma">
      <template v-slot:label>{{ $t('common:productGroup.productGroupHeader') }}</template>
      <template v-slot:value>
        {{ value.tuoteryhma.nimi }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>{{ $t('common:code') }}</template>
      <template v-slot:value>
        {{ value.koodi }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.paamies">
      <template v-slot:label>{{ $t('common:creditor') }}</template>
      <template v-slot:value>
        {{ value.paamies.nimi }} ({{ value.paamies.id }})
      </template>
    </DefListItem>
    <DefListItem v-if="value.selite">
      <template v-slot:label>{{ $t('common:definition') }}</template>
      <template v-slot:value>
        {{ value.selite }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.vapaateksti">
      <template v-slot:label>{{ $t('common:freeText') }}</template>
      <template v-slot:value>
        {{ value.vapaateksti }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>{{ $sovellusIdOtsake }}</template>
      <template v-slot:value>
        {{ value.id }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>{{ $t('common:uPrice') }}</template>
      <template v-slot:value>
        {{ value.ahinta | formatSumToFixed2 }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.laatu">
      <template v-slot:label>{{ $t('common:quantityUnit') }}</template>
      <template v-slot:value>
        {{ value.laatu }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.alvtunnus">
      <template v-slot:label>{{ $t('common:vatId') }}</template>
      <template v-slot:value>
        {{ value.alvtunnus.tunnus }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>{{ $t('common:currency') }}</template>
      <template v-slot:value>
        {{ value.valuutta }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.nykyinen_varastotilanne">
      <template v-slot:label>{{ $t('common:stockStatus') }}</template>
      <template v-slot:value>
        {{ value.nykyinen_varastotilanne }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.hankintahinta">
      <template v-slot:label>{{ $t('common:purchasePrice') }}</template>
      <template v-slot:value>
        {{ value.hankintahinta | formatSumToFixed2 }}
      </template>
    </DefListItem>
  </DefList>
</template>

<script>

export default {
  name: 'OffCanvasDetailsTuote',
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
