<template>
  <div
    v-if="iconClass"
    :class="classObj"
    v-on="eventListener"
    @click.stop.prevent="onClick"
  >
    <div class="status-icon__wrap">
      <span :class="iconClass" />
      <span
        v-if="hoverIcon"
        :class="hoverIconClass"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'StatusIcon',
  props: {
    type: {
      type: String,
      required: false,
      default () {
        return 'square'
      },
    },
    size: {
      type: String,
      required: false,
      default () {
        return 'small'
      },
    },
    icon: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    hoverIcon: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    bgColor: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    eventListener: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    pointer: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    classObj () {
      return [
        'status-icon',
        (this.type) ? 'status-icon--' + this.type : '',
        (this.size) ? 'status-icon--' + this.size : '',
        (this.hoverIcon) ? 'status-icon--hasHoverIcon' : '',
        (this.bgColor) ? this.bgColor : 'orange',
        (this.pointer) ? 'status-icon--pointer': '',
      ]
    },
    iconClass () {
      return [
        'status-icon__icon',
        'anitta-icon-' + this.icon,
      ]
    },
    hoverIconClass () {
      return [
        'status-icon__icon status-icon__icon--hover',
        'anitta-icon-' + this.hoverIcon,
      ]
    },
  },
  methods: {
    onClick (e) {
      this.$emit('click', e)
    }
  },
}

</script>

<style lang="scss" scoped>

  .status-icon {
    width: 2.2em;
    height: 2.2em;
    border-radius: .4em;

    &--pointer {
      cursor: pointer;
    }

    &--medium {
      width: 3.2em;
      height: 3.2em;
    }

    &--large {
      width: 4.5em;
      height: 4.5em;
    }

    &--rounded {
      border-radius: 10em;
    }

    &--hasHoverIcon {
      &:hover {
        .status-icon__icon {
          opacity: 0;
        }

        .status-icon__icon--hover {
          opacity: 1;
        }
      }
    }

    &__wrap {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__icon {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 1.3em;
      transition: all 200ms ease;

      .status-icon--medium & {
        font-size: 1.9em;
      }

      .status-icon--large & {
        font-size: 2.8em;
      }

      &--hover {
        opacity: 0;
      }
    }
  }

</style>
