var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stepperFooter px-4" },
    [
      _c(
        "div",
        { staticClass: "stepperFooter__col stepperFooter__col--left pa-2" },
        [_vm._t("left")],
        2
      ),
      _c(
        "div",
        { staticClass: "stepperFooter__col stepperFooter__col--center pa-2" },
        [_vm._t("center")],
        2
      ),
      _c(
        "div",
        { staticClass: "stepperFooter__col stepperFooter__col--right pa-2" },
        [_vm._t("right")],
        2
      ),
      _c("Slot"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }