import Vue from 'vue'
import store from '@/store'

const getRoutePermissions = (route) => {
  // Get permissions for this route
  const routePermissions = (route.meta && route.meta.permissions) ? route.meta.permissions : []
  let parentPermissions = []

  // Inherit permissions from the parent routes
  if (route.matched) {
    for (const matchedRoute of route.matched) {
      if (matchedRoute.meta && matchedRoute.meta.permissions) {
        parentPermissions = [
          ...parentPermissions,
          ...matchedRoute.meta.permissions,
        ]
      }
    }
  }

  return [
    ...routePermissions,
    ...parentPermissions,
  ]
}

const getUserPerms = (userPermissions) => {
  return (userPermissions.length) ? userPermissions : store.state.user.permissions
}

const match = (route, userPermissions = []) => {
  userPermissions = getUserPerms(userPermissions)

  try {
    const requiredPermissions = getRoutePermissions(route)

    if (!requiredPermissions.length) return true

    for (const permission of requiredPermissions) {
      // console.log('Matching for: ' + permission)

      if (!userPermissions.some(userPermission => userPermission === permission)) throw new Error('Permission check failed: ' + permission)

      if (store.getters['user/tarkistetaankoPaamieskohtaisetOikeudet']) {
        const paamiesPerms = store.getters['user/getValittuPaamiesPerms']
        // Tarkistetaan käyttäjän päämieskohtaiset oikeudet mikäli käyttäjä ei ole henkilökuntaa
        if (!paamiesPerms.some(userPermission => userPermission === permission)) throw new Error('Permission check failed: ' + permission)
      }
    }

    return true
  } catch (e) {
    // console.warn(e)

    return false
  }
}

const checkSinglePerm = (permToCheck, userPermissions = []) => {
  userPermissions = getUserPerms(userPermissions)
  if (!userPermissions.some(userPermission => userPermission === permToCheck)) return false
  if (store.getters['user/tarkistetaankoPaamieskohtaisetOikeudet']) {
    const paamiesPerms = store.getters['user/getValittuPaamiesPerms']
    // Tarkistetaan käyttäjän päämieskohtaiset oikeudet mikäli käyttäjä ei ole henkilökuntaa
    if (!paamiesPerms.some(userPermission => userPermission === permToCheck)) return false
  }

  return true
}

const permissionModule = {
  match,
  checkSinglePerm,
}

Vue.prototype.$permission = permissionModule

export default permissionModule
