<template>
  <div>
    <h3 :class="titleClass">
      Laskutuksen yhteenveto
    </h3>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th></th>
            <th>Summa</th>
            <th>Kpl</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Avoinna</td>
            <td>{{ toimittaja.avoinna_summa | formatSumToFixed2 }}</td>
            <td>{{ toimittaja.avoinna_kpl }}</td>
          </tr>
          <tr>
            <td>Hyväksytty maksuun</td>
            <td>{{ toimittaja.hyvaksytty_maksuun_summa | formatSumToFixed2 }}</td>
            <td>{{ toimittaja.hyvaksytty_maksuun_kpl }}</td>
          </tr>
          <tr>
            <td>Maksettu</td>
            <td>{{ toimittaja.maksettu_summa | formatSumToFixed2 }}</td>
            <td>{{ toimittaja.maksettu_kpl }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>

export default {
  name: 'ToimittajaLaskutuksenYhteenveto',
  props: {
    toimittaja: {
      type: Object,
      required: true,
    },
    titleClass: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
  },
}

</script>

<style lang="scss" scoped>

</style>

