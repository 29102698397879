<template>
  <tr
    ref="tableRow"
    :class="classObj"
    @click="$emit('click', this)"
  >
    <slot />
  </tr>
</template>

<script>

export default {
  name: 'TableRow',
  props: {
    subRow: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    clickable: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    fade: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    bold: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    selectedItem: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
    checked: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    checkedAlternativeColor: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    red: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    green: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    yellow: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    customContextmenu: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    data: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
  },
  computed: {
    classObj () {
      return [
        'table__row',
        this.subRow ? 'table__row--sub' : null,
        this.enableFade ? 'table__row--fade' : null,
        this.bold ? 'table__row--bold' : null,
        this.clickable ? 'table__row--clickable' : null,
        this.checked ? 'table__row--checked' : null,
        this.checkedAlternativeColor ? 'table__row--checked-alternative-color' : null,
        this.red ? 'table__row--red' : null,
        this.green ? 'table__row--green' : null,
        this.yellow ? 'table__row--yellow' : null,
      ]
    },
    enableFade () {
      if (this.fade) return true
      if (!this.selectedItem.id) return false
      if (!this.data.id) return false

      if (this.selectedItem.id != this.data.id) return true

      return false
    },
  },
  mounted () {
    if (this.customContextmenu) {
      this.$refs.tableRow.addEventListener('contextmenu', this.contextmenu)
    }
  },
  methods: {
    contextmenu (e) {
      this.$emit('contextmenu', e)
    }
  }
}

</script>

<style lang="scss" scoped>

  .table__row {
    &--fade {
      opacity: .25;
    }

    &--bold {
      > td {
        font-weight: bold;
      }
    }

    &--red {
      background: lighten(maroon, 70) !important;

      &:hover {
        background: lighten(maroon, 64) !important;
      }
    }

    &--green {
      background: lighten(green, 70) !important;

      &:hover {
        background: lighten(green, 64) !important;
      }
    }

    &--yellow {
      background: #fffde7 !important;

      &:hover {
        background: darken(#fffde7, 6) !important;
      }
    }

    &--checked {
      background-color: var(--v-accent-lighten3) !important;

      &:hover {
        background: var(--v-accent-lighten2) !important;
      }
    }

    &--checked-alternative-color {
      background-color: var(--v-accent-lighten1) !important;

      &:hover {
        background: var(--v-accent-darken1) !important;
      }
    }
  }

</style>
