<template>
  <v-menu
    :disabled="disabled"
    bottom
    nudge-bottom="40"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="disabled"
        color="primary"
        v-on="on"
        :dark="!disabled"
      >
      <v-icon left>more_vert</v-icon>
        Toiminnot <template v-if="itemCount > 0">({{ itemCount }} kpl)</template>
      </v-btn>
    </template>
    <template v-slot:default>
      <v-list>
        <v-list-item
          :disabled="!hyvaksyMaksuunSallittu"
          @click="$emit('hyvaksyMaksuun')"
        >
          <v-list-item-avatar>
            <v-icon>check</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            Hyväksy maksuun
          </v-list-item-title>
        </v-list-item>
        <v-list-item
        :disabled="!hylkaaLaskuSallittu"
          @click="$emit('hylkaa')"
        >
          <v-list-item-avatar>
            <v-icon>cancel</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            Hylkää lasku
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!palautaAvoimeksiSallittu"
          @click="$emit('palautaAvoimeksi')"
        >
          <v-list-item-avatar>
            <v-icon>keyboard_return</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            Palauta avoimeksi
          </v-list-item-title>
        </v-list-item>
        <v-list-item
            :disabled="!vieMaksuunSallittu"
            @click="$emit('vieMaksuun')"
          >
          <v-list-item-avatar>
            <v-icon>euro</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            Vie maksuun
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </v-menu>
</template>

<script>

export default {
  name: 'MenuSelectedMessages',
  props: {
    currentTab: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    itemCount: {
      type: Number,
      required: false,
      default () {
        return null
      },
    },
    hyvaksyMaksuunSallittu: {
      type: Boolean,
      required: true,
    },
    hylkaaLaskuSallittu: {
      type: Boolean,
      required: true,
    },
    palautaAvoimeksiSallittu: {
      type: Boolean,
      required: true,
    },
    vieMaksuunSallittu: {
      type: Boolean,
      required: true,
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
