<template>
  <div>
    <v-autocomplete
      ref="autocompleteYTJHaku"
      v-model="autocompleteValue"
      :label="$t('common:autocompleteYTJHaku.companySearch')"
      :items="yritysItems"
      :loading="autocompleteLoading"
      :search-input.sync="searchQuery"
      :hide-no-data="(!searchQuery || autocompleteLoading)"
      item-text="autocomplete_selite"
      item-value="tunnus"
      prepend-inner-icon="search"
      :placeholder="placeholder"
      persistent-placeholder
      outlined
      return-object
      :hint="inputHint"
      @input="kasitteleValinta"
    >
      <template
        v-slot:item="{ item }"
      >
        <template>
          <v-list-item-content>
            <v-list-item-title>{{ item.autocomplete_selite }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
      <template v-slot:no-data>
        <p class="pa-4 autocomplete-ytj-haku__no-data-text">
          {{ $t('common:autocompleteYTJHaku.searchFoundNoCompanies') }}
          <br>
          {{ $t('common:autocompleteYTJHaku.dataIsSearchedFromMsg') }}
          <ul>
            <li>{{ $t('common:autocompleteYTJHaku.ltdCompanies') }}</li>
            <li>{{ $t('common:autocompleteYTJHaku.housingLtdCompanies') }}</li>
            <li>{{ $t('common:autocompleteYTJHaku.cooperatives') }}</li>
            <li>{{ $t('common:autocompleteYTJHaku.insuranceCorporations') }}</li>
            <li>{{ $t('common:autocompleteYTJHaku.publicLtdCompanies') }}</li>
          </ul>
        </p>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

export default {
  name: 'AutocompleteYTJHaku',
  props: {
    tyyppi: {
      type: String,
      required: false,
      default () {
        return 'lasku'
      },
      validator: (arvo) => {
        return ['lasku', 'ostolasku', 'sopimus'].includes(arvo)
      }
    },
    inputHint: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    placeholder: {
      type: String,
      required: false,
      default () {
        return this.$t('common:autocompleteYTJHaku.searchWithCompanyIdOrName')
      },
    }
  },
  data () {
    return {
      autocompleteValue: {},
      debounceDelay: 300,
      debounceTimeout: '',
      yritysItems: [],
      autocompleteLoading: false,
      searchQuery: '',
    }
  },
  watch: {
    async searchQuery (hakusana) {
      clearTimeout(this.debounceTimeout)

      if (!hakusana) {
        this.autocompleteLoading = false
        return
      }

      this.autocompleteLoading = true

      this.debounceTimeout = setTimeout(async () => {
        this.yritysItems = []

        try {
          let request = await this.haeYTJsta(hakusana)
          if (!request.success && request.result.status !== 404) throw new this.$HttpError(request)
          this.yritysItems = this.kasitteleYTJTulokset(request.result.body.results)
        } catch (e) {
          this.$oletusVirheenkasittely(e)
        } finally {
          this.autocompleteLoading = false
        }
      }, this.debounceDelay)
    },
  },
  methods: {
    async haeYTJsta (hakusana) {
      const query = new URLSearchParams()
      const regex = new RegExp('[0-9]{7}-[0-9]{1}', 'g')
      hakusana = hakusana.trim()
      if (hakusana.match(regex)) {
        query.append('businessId', hakusana)
      } else {
        query.append('name', hakusana)
      }

      const request = await this.$doRequestWithTimeout(
        this.$apiUlkoinen.YTJ,
        {
          method: 'GET',
          query: query.toString(),
        },
        'pushRequest',
      )

      return request
    },
    kasitteleYTJTulokset (tulokset) {
      return tulokset.map((result) => {
        return (this.kasitteleYTJTulos(result))
      })
    },
    kasitteleYTJTulos (result) {
      const autocomplete_selite = `${result.name} | Y-tunnus: ${result.businessId}`

      const yritys = {
        autocomplete_selite,
        nimi: result.name,
        tunnus: result.businessId,
        yhtiomuoto: result.companyForm,
        tyyppi: 'Y',
      }

      if (result.addresses && result.addresses.length) {
        let address = null
        for (const addr of result.addresses) {
          if (addr.type == 2) {
            if (!address) {
              address = addr
            } else if (address.registrationDate <= addr.registrationDate) {
              address = addr
            }
          }
        }
        yritys.postiosoite = {
          osoite: address.street,
          numero: address.postCode,
          toimipaikka: address.city,
        }
      }

      return yritys
    },
    async kasitteleValinta (yritys) {
      let valittuYritys = null
      // Kun tulos on haettu nimellä, haetaan täydet tiedot saadulla Y-tunnuksella
      if (!yritys.addresses) {
        try {
          this.autocompleteLoading = true
          const request = await this.haeYTJsta(yritys.tunnus)
          if (!request.success && request.result.status !== 404) throw new this.$HttpError(request)
          valittuYritys = this.kasitteleYTJTulos(request.result.body.results[0])
        } catch(e) {
          this.$oletusVirheenkasittely(e)
        } finally {
          this.autocompleteLoading = false
        }
      } else {
        valittuYritys = yritys
      }

      this.$emit('autocomplete-input', valittuYritys)
      this.$nextTick(() => {
        this.autocompleteValue = null
      })
    }
  }
}
</script>

<style lang="scss">

.autocomplete-ytj-haku {
  &__no-data-text {
    max-width: 400px;
  }
}

</style>
