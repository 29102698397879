var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", _vm._g({ staticClass: "defList__item" }, _vm.eventListener), [
    _c(
      "span",
      {
        staticClass: "defList__label",
        class: _vm.tiivisNakyma
          ? _vm.labelClass + " defList__label--tiivis"
          : _vm.labelClass,
      },
      [_vm._t("label")],
      2
    ),
    _c(
      "span",
      {
        staticClass: "defList__value",
        class: _vm.tiivisNakyma
          ? _vm.valueClass + " defList__value--tiivis"
          : _vm.valueClass,
      },
      [_vm._t("value", [_vm._v(" - ")])],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }