<template>
  <div v-if="paamiesosoite">
    <v-row>
      <v-col
        md="12"
        class="py-0"
      >
        <v-text-field
          v-model="paamiesosoite.puhelin"
          :label="$t('common:phone')"
          outlined
        />
        <v-text-field
          v-model="paamiesosoite.email"
          :label="$t('common:emailAddress')"
          :rules="[$validationRules.email]"
          outlined
        />
        <v-text-field
          v-model="paamiesosoite.vastaanottaja"
          :label="$t('common:recipient')"
          :rules="[$validationRules.required]"
          class="required"
          outlined
        />
        <v-text-field
          v-model="paamiesosoite.saaja"
          :label="$t('common:receiverOrSpecifier')"
          outlined
        />
        <v-text-field
          v-model="paamiesosoite.postiosoite"
          :label="$t('common:address')"
          :rules="[$validationRules.required]"
          class="required"
          outlined
        />
        <v-row>
          <v-col
            md="5"
            class="py-1"
          >
            <v-text-field
              v-model="paamiesosoite.postinumero"
              :label="$t('common:zipCode')"
              :rules="[$validationRules.required]"
              class="required"
              outlined
            />
          </v-col>
          <v-col
            md="7"
            class="py-1"
          >
            <v-text-field
              v-model="paamiesosoite.postitoimipaikka"
              :label="$t('common:postalDistrict')"
              :rules="[$validationRules.required]"
              class="required"
              outlined
            />
          </v-col>
        </v-row>
        <v-select
          v-model="paamiesosoite.maa"
          :label="$t('common:country')"
          type="text"
          :items="maat"
          item-text="selite"
          item-value="value"
          :menu-props="{ zIndex: '999', auto: true }"
          :rules="[$validationRules.required]"
          class="required"
          outlined
        />
        <v-text-field
          v-model="paamiesosoite.verkkolasku_osoite"
          :label="$t('common:eInvoiceAddress')"
          :error-messages="verkkolaskuosoiteErrors"
          outlined
        />
        <v-autocomplete
          v-model="paamiesosoite.verkkolasku_operaattori"
          class="py-1"
          clearable
          :items="verkkolaskuoperaattorit"
          :filter="verkkolaskuoperaattoriFilter"
          item-text="autocomplete_selite"
          item-value="id"
          :label="$t('common:eInvoiceOperator')"
          :error-messages="verkkolaskuoperaattoriErrors"
          outlined
        ></v-autocomplete>
        <v-btn
          v-if="salliPoisto"
          color="info"
          small
          @click="poistaOsoite"
        >
          {{ $t("common:settings.useContactAddress") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <p class="text-subtitle-1">
      {{ $t("common:settings.usingContactAddress") }}
    </p>
    <v-btn
      color="primary"
      small
      @click="lisaaOsoite"
    >
      {{ buttonText }}
    </v-btn>
  </div>
</template>

<script>
import { Paamiesosoite } from '@/class/Paamies'
import { mapState } from 'vuex'

export default {
  name: 'FormAsetuksetPaamiesosoite',
  components: {},
  props: {
    value: {
      type: Paamiesosoite,
      default: null,
    },
    verkkolaskuoperaattorit: {
      type: Array,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    salliPoisto: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      verkkolaskuosoiteErrors: [],
      verkkolaskuoperaattoriErrors: [],
      initialPaamiesosoite: new Paamiesosoite(),
    }
  },
  computed: {
    ...mapState({
      maat: (state) => state.yleinen.maat,
    }),
    paamiesosoite: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    'paamiesosoite.verkkolasku_osoite': {
      handler() {
        this.validateVerkkolaskuosoite()
      },
    },
    'paamiesosoite.verkkolasku_operaattori': {
      handler() {
        this.validateVerkkolaskuosoite()
      },
    },
  },
  created() { },
  methods: {
    validateVerkkolaskuosoite() {
      if (!this.paamiesosoite) {
        return
      }

      if (
        this.paamiesosoite.verkkolasku_osoite &&
        !this.paamiesosoite.verkkolasku_operaattori
      ) {
        this.verkkolaskuoperaattoriErrors = [
          this.$t('common:inputAlsoEInvoiceOperator'),
        ]
      } else {
        this.verkkolaskuoperaattoriErrors = []
      }

      if (
        !this.paamiesosoite.verkkolasku_osoite &&
        this.paamiesosoite.verkkolasku_operaattori
      ) {
        this.verkkolaskuosoiteErrors = [
          this.$t('common:inputAlsoEInvoiceAddress'),
        ]
      } else {
        this.verkkolaskuosoiteErrors = []
      }
    },
    verkkolaskuoperaattoriFilter(item, hakusana) {
      const id = item.id.toLowerCase()
      const nimi = item.nimi.toLowerCase()
      const hakuteksti = hakusana.toLowerCase()

      return id.indexOf(hakuteksti) > -1 || nimi.indexOf(hakuteksti) > -1
    },
    poistaOsoite() {
      // Jätetään vanha osoite talteen
      this.initialPaamiesosoite = this.paamiesosoite
      this.paamiesosoite = null
    },
    lisaaOsoite() {
      this.paamiesosoite = this.initialPaamiesosoite
    },
  },
}
</script>

<style lang="scss" scoped>
.v-text-field,
.v-select {
  padding-top: 0px;
  margin-top: 4px;
}
</style>
