<template>
  <div class="offCanvasToimeksianto">
    <v-toolbar
      color="primary"
      flat
      class="px-1"
    >
      <v-btn
        icon
        :dark="true"
        @click="$emit('close-button-click')"
      >
        <v-icon>clear</v-icon>
      </v-btn>

      <template v-if="value.toimeksiantovelallinen_set">
        <v-toolbar-title>
          {{ $t('common:offCanvasToimeksianto.assignmentData') }}
        </v-toolbar-title>
      </template>

      <v-spacer />

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="(typeof value.id != 'undefined')"
            icon
            :dark="true"
            class="white v-btn--has-bg primary--text"
            :to="{
              name: 'perinta-nayta',
              params: {
                id: value.id,
              },
            }"
            v-on="on"
          >
            <v-icon>open_in_new</v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <span>{{ $t('common:openAssignment') }}</span>
        </template>
      </v-tooltip>
    </v-toolbar>
    <v-container class="grey lighten-5 px-6">
      <OffCanvasDetailsToimeksianto v-model="value" />
    </v-container>
    <v-container class="px-9">
      <Timeline
        :items="value.aikajana"
        @msg-mark-as-unread="$emit('msg-mark-as-unread')"
        @msg-mark-as-read="$emit('msg-mark-as-unread')"
      />
    </v-container>
  </div>
</template>

<script>

import ClickOutside from 'vue-click-outside'
import Timeline from '@/components/Timeline'
import OffCanvasDetailsToimeksianto from '@/components/OffCanvasDetailsToimeksianto'

export default {
  name: 'OffCanvasToimeksianto',
  directives: {
    ClickOutside,
  },
  components: {
    Timeline,
    OffCanvasDetailsToimeksianto,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
