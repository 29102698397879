var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "formSeliteMuokkaukselle",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-row", [_vm._t("default")], 2),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "grow" },
            [
              _c("v-textarea", {
                staticClass: "pt-0 mt-0",
                attrs: {
                  rows: "1",
                  label: "Selite",
                  rules: [_vm.$validationRules.required],
                  "hide-details": true,
                  required: "",
                  "auto-grow": "",
                  placeholder: _vm.placeholder,
                  "persistent-placeholder": "",
                  "validate-on-blur": false,
                },
                model: {
                  value: _vm.value.selite,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "selite", $$v)
                  },
                  expression: "value.selite",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4 px-3", attrs: { justify: "end" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-0 mr-4",
              attrs: { color: "", disabled: _vm.loading, loading: _vm.loading },
              on: { click: _vm.peruutaMuutokset },
            },
            [_vm._v(" Peruuta muutokset ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-0",
              attrs: {
                type: "submit",
                color: "success",
                disabled: !_vm.valid || _vm.loading,
                loading: _vm.loading,
              },
            },
            [_vm._v(" Tallenna muutokset ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }