<template>
  <div class="offCanvasOstolasku">
    <v-toolbar
      color="primary"
      flat
      class="px-1"
    >
      <v-btn
        :dark="true"
        icon
        @click="$emit('close-button-click')"
      >
        <v-icon>clear</v-icon>
      </v-btn>

      <v-toolbar-title>
        Laskun tiedot
      </v-toolbar-title>

      <v-spacer />

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="(typeof value.id != 'undefined')"
            :dark="true"
            class="white v-btn--has-bg primary--text"
            icon
            :to="{
              name: 'ostolasku-nayta',
              params: {
                id: value.id,
              },
            }"
            v-on="on"
          >
            <v-icon>open_in_new</v-icon>
          </v-btn>
        </template>
        <template v-slot:default>
          <span>Avaa lasku</span>
        </template>
      </v-tooltip>
    </v-toolbar>
    <v-container class="grey lighten-5 px-8">
      <OffCanvasDetailsOstolasku v-model="value" />
    </v-container>
    <v-container class="px-8">
      <Timeline
        :items="value.aikajana"
        @msg-mark-as-unread="$emit('msg-mark-as-unread')"
        @msg-mark-as-read="$emit('msg-mark-as-unread')"
      />
    </v-container>
  </div>
</template>

<script>

import ClickOutside from 'vue-click-outside'
import Timeline from '@/components/Timeline'
import OffCanvasDetailsOstolasku from '@/components/OffCanvasDetailsOstolasku'

export default {
  name: 'OffCanvasOstolasku',
  directives: {
    ClickOutside,
  },
  components: {
    Timeline,
    OffCanvasDetailsOstolasku,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
