var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tyopoyta" }, [
    _c(
      "div",
      { staticClass: "t-header sticky" },
      [
        _c(
          "v-toolbar",
          {
            staticClass: "px-2",
            attrs: { color: "primary", flat: "", tabs: "" },
          },
          [
            _vm.$route.meta.title
              ? _c("v-toolbar-title", [
                  _vm._v(
                    " Tervetuloa " + _vm._s(_vm.user.naytettavaNimi) + " "
                  ),
                ])
              : _vm._e(),
            _c("v-spacer"),
          ],
          1
        ),
      ],
      1
    ),
    !_vm.paamiehet || !_vm.paamiehet.length
      ? _c(
          "div",
          { staticClass: "t-spinner" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "d-flex justify-center align-center" },
                  [
                    !_vm.paamiehet || !_vm.paamiehet.length
                      ? _c("Spinner", {
                          attrs: {
                            "div-class": "text-center mt-12",
                            justify: "center",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", { attrs: { id: "t-content" } }, [
          _c(
            "div",
            { attrs: { id: "tc-filter" } },
            [
              _c(
                "v-alert",
                {
                  staticClass: "ma-4",
                  attrs: {
                    value: Boolean(_vm.palvelusopimustrialPaattyy),
                    type: "info",
                  },
                },
                [
                  _vm._v(
                    " Kokeilujakso päättyy " +
                      _vm._s(_vm.$date(_vm.palvelusopimustrialPaattyy)) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-alert",
                {
                  staticClass: "ma-4",
                  attrs: {
                    value: Boolean(_vm.palvelusopimusMaksamatta),
                    type: "error",
                  },
                },
                [
                  _vm._v(
                    " Cloud-tilisi lasku on maksamatta. Tilisi suljetaan " +
                      _vm._s(_vm.$date(_vm.palvelusopimusMaksamatta)) +
                      " ellei laskua makseta. "
                  ),
                ]
              ),
              _c(
                "v-alert",
                {
                  staticClass: "ma-4",
                  attrs: {
                    value: Boolean(_vm.palvelusopimusPaattyy),
                    type: "warning",
                  },
                },
                [
                  _vm._v(
                    " Cloud-tilisi tilaus on loppumassa " +
                      _vm._s(_vm.$date(_vm.palvelusopimusPaattyy)) +
                      ". Loppumisen jälkeen tilin tietoihin ei pääse enää käsiksi. "
                  ),
                ]
              ),
              !_vm.user.vainYksiPaamies
                ? _c(
                    "v-row",
                    { staticClass: "pb-1" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "paamies-card pa-6 pt-8 d-flex flex-column flex-grow-1 tyopoyta__gradient",
                            },
                            [
                              _c("CardTitle", {
                                attrs: { text: "Päämies", center: true },
                              }),
                              [
                                !_vm.user.vainYksiPaamies
                                  ? _c("v-autocomplete", {
                                      staticClass: "tyopoyta__gradient",
                                      attrs: {
                                        items: _vm.paamiehet,
                                        "search-input": _vm.paamiesSearchQuery,
                                        "hide-no-data": "",
                                        "item-text": _vm.getPaamiesNimi,
                                        "item-value": "id",
                                        placeholder: "Kirjoita hakusana",
                                        "persistent-placeholder": "",
                                        "return-object": "",
                                        outlined: "",
                                        "hide-details": "",
                                        dense: "",
                                      },
                                      on: {
                                        "update:searchInput": function (
                                          $event
                                        ) {
                                          _vm.paamiesSearchQuery = $event
                                        },
                                        "update:search-input": function (
                                          $event
                                        ) {
                                          _vm.paamiesSearchQuery = $event
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        _vm._s(item.nimi) +
                                                          " (" +
                                                          _vm._s(item.id) +
                                                          ")"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        322820903
                                      ),
                                      model: {
                                        value: _vm.valittavaPaamies,
                                        callback: function ($$v) {
                                          _vm.valittavaPaamies = $$v
                                        },
                                        expression: "valittavaPaamies",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { attrs: { id: "tc-btns" } }, [
            _vm.$permission.checkSinglePerm("onlinerestapi.onlinerestapi_lasku")
              ? _c(
                  "div",
                  { attrs: { id: "tc-btns-inner" } },
                  [
                    _c("TyopoytaPikanappain", {
                      attrs: {
                        text: "Uusi lasku",
                        icon: "invoice-add",
                        to: { name: "laskutus-uusi" },
                      },
                    }),
                    _c("TyopoytaPikanappain", {
                      attrs: {
                        text: "Uusi asiakas",
                        icon: "customer-person",
                        to: {
                          name: "laskutus-asiakas-selaa",
                          params: {
                            uusiAsiakas: true,
                          },
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            { attrs: { id: "tc-myynti" } },
            [
              _c("TyopoytaMyynninKuvaaja", {
                attrs: { "lasku-myynti-data": _vm.laskuMyyntiData },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "b-combo" },
            [
              [
                _c(
                  "v-card",
                  { staticClass: "l-container" },
                  [
                    _c(
                      "v-tabs",
                      [
                        _c("v-tab", { staticClass: "primary--text" }, [
                          _vm._v(" Laskutus "),
                        ]),
                        _c("v-tab", { staticClass: "primary--text" }, [
                          _vm._v(" Perintä "),
                        ]),
                        _c("v-tab", { staticClass: "primary--text" }, [
                          _vm._v(" Rahoitus "),
                        ]),
                        _c(
                          "v-tab-item",
                          [
                            _c("v-card", { attrs: { flat: "" } }, [
                              _c("div", { staticClass: "c-laskut" }, [
                                _vm.laskutusKaytossa &&
                                _vm.$permission.checkSinglePerm(
                                  "onlinerestapi.onlinerestapi_lasku"
                                )
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-card",
                                          { staticClass: "tyopoyta__gradient" },
                                          [
                                            _vm.ladataanLaskutilastoja
                                              ? _c("Spinner", {
                                                  attrs: {
                                                    justify: "center",
                                                    teksti:
                                                      "Ladataan tilastoja",
                                                  },
                                                })
                                              : [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", {
                                                        attrs: {
                                                          cols: "1",
                                                          lg: "1",
                                                        },
                                                      }),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "8",
                                                            lg: "8",
                                                          },
                                                        },
                                                        [
                                                          _vm.paamiehellaEiLaskutilastoDataa
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "font-no-data text-center pa-12",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Ei ole dataa. "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c("ChartPie", {
                                                                staticClass:
                                                                  "pa-0 tyopoyta__chart-pie",
                                                                attrs: {
                                                                  "chart-data":
                                                                    _vm.laskutilastoData,
                                                                },
                                                              }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-tab-item",
                          [
                            _c("v-card", { attrs: { flat: "" } }, [
                              _vm.perintaKaytossa &&
                              _vm.$permission.checkSinglePerm(
                                "onlinerestapi.onlinerestapi_toimeksianto"
                              )
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "tyopoyta__gradient" },
                                        [
                                          _vm.ladataanPerintatilastoja
                                            ? _c("Spinner", {
                                                attrs: {
                                                  justify: "center",
                                                  teksti: "Ladataan tilastoja",
                                                },
                                              })
                                            : [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c("v-col", {
                                                      attrs: {
                                                        cols: "1",
                                                        lg: "2",
                                                      },
                                                    }),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "8",
                                                          lg: "8",
                                                        },
                                                      },
                                                      [
                                                        _vm.paamiehellaEiPerintatilastoDataa
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "font-no-data text-center pa-12",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Ei ole dataa. "
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "div",
                                                              [
                                                                _c("ChartPie", {
                                                                  staticClass:
                                                                    "pa-0 tyopoyta__chart-pie",
                                                                  attrs: {
                                                                    "chart-data":
                                                                      _vm.perintatilastoData,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                        _c("v-tab-item", [
                          _c("div", { staticClass: "b-rahoitus" }, [
                            _vm.rahoitusKaytossa &&
                            (_vm.ladataanRahoitettavanYhteenvetoa ||
                              _vm.rahoitettavanYhteenveto.length)
                              ? _c(
                                  "div",
                                  { staticClass: "p-container" },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "tyopoyta__gradient p-container",
                                      },
                                      [
                                        _vm.ladataanRahoitettavanYhteenvetoa
                                          ? [
                                              _c("v-skeleton-loader", {
                                                staticClass: "mb-4",
                                                attrs: {
                                                  type: "heading",
                                                  "min-height": "40",
                                                  width: "100%",
                                                },
                                              }),
                                              _c(
                                                "v-container",
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "7" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-skeleton-loader",
                                                            {
                                                              attrs: {
                                                                type: "table-row@4",
                                                                "max-height":
                                                                  "260",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-skeleton-loader",
                                                            {
                                                              attrs: {
                                                                type: "image",
                                                                "max-height":
                                                                  "260",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm.rahoitettavanYhteenveto.length
                                          ? [
                                              _c("RahoitettavanYhteenveto", {
                                                attrs: {
                                                  rahoitusdata:
                                                    _vm
                                                      .rahoitettavanYhteenveto[0]
                                                      .limiitti,
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ]),
    _vm.user.isSuperuser ? _c("div", { attrs: { id: _vm.versio } }) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }