<template>
  <v-form
    v-model="valid"
    @submit.prevent="submit"
  >
    <v-text-field
      v-model="value.username"
      label="Käyttäjätunnus"
      :rules="[$validationRules.required]"
      outlined
      required
      autofocus
    />
    <v-text-field
      v-model="value.password"
      label="Salasana"
      :append-icon="(showPassword ? 'visibility_off' : 'visibility')"
      :type="(showPassword ? 'text' : 'password')"
      :rules="[$validationRules.required]"
      outlined
      required
      @click:append="showPassword = !showPassword"
    />
    <v-btn
      type="submit"
      :disabled="!valid || loading"
      :loading="loading"
      block
      large
      color="success"
      class="ml-0"
    >
      Kirjaudu
    </v-btn>
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'FormLogin',
  mixins: [FormMixin],
  data () {
    return {
      showPassword: false,
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
