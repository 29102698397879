<template>
  <v-form
    ref="formSopimusLaskutusjaksoLisaa"
    v-model="valid"
    outlined
    @submit.prevent="submit"
  >
    <v-row>
      <v-col
        cols="11"
        class="px-4 pt-2"
      >
        <v-menu
          v-model="alkamispaivaMenuAuki"
          :close-on-content-click="false"
          max-width="290"
          nudge-bottom="40"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :label="$t('common:lasku.firstInvoicePeriodStartDate')"
              :value="$date(value.laskutusjakso_alkamispaiva)"
              outlined
              :rules="[$validationRules.required]"
              class="required"
              v-on="on"
            />
          </template>
          <template>
            <v-date-picker
              v-model="value.laskutusjakso_alkamispaiva"
              :locale="$languageLocale()"
              first-day-of-week="1"
              no-title
              @change="alkamispaivaMenuAuki = false"
            />
          </template>
        </v-menu>
        <v-text-field
          v-model="value.maara"
          type="number"
          :label="$t('common:lasku.invoicePeriodsAmount')"
          outlined
          :rules="[$validationRules.zeroOrBigger]"
        />
      </v-col>
      <v-col
        cols="1"
        class="ml-n3 pa-0"
      >
        <div class="py-10"></div>
        <v-btn
          icon
          class="margin-t-6px"
          @click="laskutusjaksotKasvata"
        >
          <v-icon color="primary">
            add_circle
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="laskutusjaksotVahenna"
        >
          <v-icon color="primary">
            remove_circle
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'
import TiivisNakymaMixin from '@/mixins/TiivisNakymaMixin'

export default {
  name: 'FormSopimusLaskutusjaksoLisaa',
  mixins: [
    FormMixin,
    TiivisNakymaMixin,
  ],
  props: {
    sopimus: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      alkamispaivaMenuAuki: false,
    }
  },
  watch: {
    onAuki: {
      immediate: true,
      handler (arvo) {
        if (arvo) {
          this.$nextTick(() => {
            this.$refs.formSopimusLaskutusjaksoLisaa.resetValidation()
          })
          this.value.maara = this.sopimus.sopimuslaskutusjakso_luo_initial_data.maara
          this.value.laskutusjakso_alkamispaiva = this.sopimus.sopimuslaskutusjakso_luo_initial_data.laskutusjakso_alkamispaiva
        }
      }
    }
  },
  methods: {
    asetaLaskutusjaksoLkm (index) {
      this.value.maara = index + 1
    },
    laskutusjaksotKasvata () {
      this.value.maara++
    },
    laskutusjaksotVahenna () {
      this.value.maara--
    },
  }
}

</script>

<style lang="scss" scoped>

.margin-t-6px {
  margin-top: 6px;
}

</style>
