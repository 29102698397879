<template>
  <v-form
    ref="formAsiakasCSV"
    v-model="valid"
    @submit.prevent="submit"
  >
    <v-row>
      <v-col class="px-4">
        <AutocompletePaamies
          v-if="!$store.state.user.vainYksiPaamies"
          ref="autocompletePaamies"
          v-model="csvLomake"
          label="Päämies"
          :tapaus="csvLomake"
          tarkistettava-oikeus="onlinerestapi.onlinerestapi_lasku"
          :validointi-saannot="[$validationRules.requiredObject]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-4">
        <v-file-input
          v-model="csvLomake.csv"
          accept=".csv"
          label="CSV-tiedosto"
          :rules="[$validationRules.required]"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row
      class="mt-4 pa-3 px-4"
      justify="end"
    >
      <v-btn
        large
        class="mr-4"
        @click="$emit('close')"
      >
        Peruuta
      </v-btn>
      <v-btn
        large
        color="success"
        :disabled="!valid || loading"
        @click="luoAsiakkaatTiedostosta"
      >
        Tuo tiedostosta
      </v-btn>
    </v-row>
    <v-divider class="my-8 mx-1" />
    <v-row
      class="mt-4 py-3 px-4"
      justify="end"
    >
      <v-btn
        small
        class="mr-4"
        color="primary"
        @click="naytaVaihtoehdot = !naytaVaihtoehdot"
      >
        {{ vaihtoehdotTeksti }}
        <v-icon
          right
          small
        >
          {{ naytaVaihtoehdot ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
        </v-icon>
      </v-btn>
      <v-btn
        href="asiakas-esimerkki.csv"
        download
        small
        color="primary"
      >
        Lataa esimerkki-csv
        <v-icon
          right
          small
        >
          download
        </v-icon>
      </v-btn>
    </v-row>
    <template v-if="naytaVaihtoehdot">
      <v-row>
        <v-col class="px-4">
          <v-card
            class="mb-4 pa-4"
            color="info"
          >
            <v-icon
              dark
              class="mr-2"
            >
              info
            </v-icon>
            <span class="subtitle-2 white--text">Huom! Vaihtoehdot ovat selaamista varten. Niiden valinta ei vaikuta aineiston tuontiin.</span>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-4">
          <v-autocomplete
            label="maa"
            :items="maat"
            dense
            hint="Vakiona FI"
            persistent-hint
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-4">
          <v-autocomplete
            label="tyyppi"
            hint="Henkiö tai yritys"
            persistent-hint
            :items="tyypit"
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-4">
          <v-autocomplete
            label="kieli"
            :items="kielet"
            dense
            hint="Vakiona fi-fi"
            persistent-hint
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-4">
          <v-autocomplete
            label="operaattori"
            hint="Verkkolaskun operaattori (csv:lle vain operaattoritunnus)"
            persistent-hint
            :items="operaattorit"
            item-text="autocomplete_selite"
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-4">
          <v-autocomplete
            label="lahetystapa"
            :items="lahetystavat"
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </template>
  </v-form>
</template>

<script>
import Papa from 'papaparse'
import { Asiakas } from '@/class/Asiakas'
import FormMixin from '@/mixins/FormMixin'
import AutocompletePaamies from '@/components/AutocompletePaamies'
import { asiakasKentat } from '@/utils/constants'

export default {
  name: 'FormAsiakasCSV',
  components: {
    AutocompletePaamies,
  },
  mixins: [FormMixin],
  data () {
    return {
      csvLomake: { paamies: this.$store.state.user.valittuPaamies } ,
      lataaTiedostoa: false,
      naytaKentat: false,
      tuoCSVDialogAuki: false,
      tyypit: ['H', 'Y'],
      maat: [],
      kielet: [],
      operaattorit: [],
      lahetystavat: [],
      naytaVaihtoehdot: false,
    }
  },
  computed: {
    vaihtoehdotTeksti () {
      if (this.naytaVaihtoehdot) return 'Piilota vaihtoehdot'

      return 'Näytä vaihtoehdot'
    }
  },
  watch: {
    onAuki: {
      immediate: true,
      handler (arvo) {
        if (arvo) {
          this.$nextTick().then(() => {
            this.$refs.formAsiakasCSV.resetValidation()
          })
          if (!this.$store.state.user.vainYksiPaamies) {
             this.$nextTick().then(() => {
            // Asetetaan päämiehen nimi hakukenttään, kun päämies on haettu arvo storesta
              setTimeout(() => {
                this.$refs.autocompletePaamies.paivitaPaamiehenTiedotHakukenttaan(
                  this.$store.state.user.valittuPaamies
                )
              }, 0)
            })
          }
        }
      }
    },
  },
  created () {
    this.haeVerkkolaskuoperaattorit()
    this.haeVaihtoehdot()
  },
  methods: {
    async haeVerkkolaskuoperaattorit () {
      try {
        const response = await this.$doRequestWithTimeout(
          this.$api.Finvoice,
          { url: 'finvoice_operaattori_nimi' },
          'doSingleRequest',
        )

        if (!response.success) throw new this.$HttpError(response)

        this.operaattorit = response.result.body
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Verkkolaskuoperaattoreiden haku epäonnistui!')
      }
    },

    async haeVaihtoehdot () {
      const vaihtoehdot = await Asiakas.haeAsiakkaanKenttienValinnat()

      this.maat = vaihtoehdot.maat.map(m => m.value).sort()
      this.kielet = vaihtoehdot.kielet.map(k => k.value)
      this.lahetystavat = vaihtoehdot.lahetystavat.map(lt => lt.value)
    },

    async luoAsiakkaatTiedostosta () {
      let asiakkaat
      this.lataaTiedostoa = true

      asiakkaat = Papa.parse(
        await this.csvLomake.csv.text(),
        {
          header: true,
          transformHeader: (h) => {
            // poistetaan pakollisten kenttien *
            return h.replace(/\*/g, '')
          },
          skipEmptyLines: 'greedy', // skippaa tyhjät rivit
          transform: (arvo) => {
            return arvo.trim()
          },
        }
      ).data

      let kentat
      try {
        kentat = Object.keys(asiakkaat[0]).filter(kentta => !asiakasKentat.includes(kentta))
      } catch(e) {
        this.$naytaVirheilmoitus('CSV-tiedosto on virheellinen')
        this.lataaTiedostoa = false
        return
      }

      try {
        // tarkistetaan että on vain oikeita kenttiä
        if (kentat.length > 0) {
          throw new Error(`CSV-tiedostossa on ylimääräisiä kenttiä: ${kentat.join(', ')}`)
        }

        // kenttien uudelleennimeämistä
        asiakkaat.forEach(a => {
          a.postiosoite = {
            nimi: a.nimi,
            osoite: a.osoite,
            numero: a.postinumero,
            toimipaikka: a.toimipaikka,
            maa: a.maa
          }

          if (a.operaattori || a.verkkolaskuosoite) {
            const operaattori = this.operaattorit.filter(o => a.operaattori === o.id)[0]

            if (!operaattori) throw new Error('Verkkolaskuoperaattoria ei löydy')

            a.verkkolaskuosoite = {
              verkkolaskuosoite: a.verkkolaskuosoite,
              operaattori: operaattori.id
            }
          }

          a.puhelin = { numero: a.puhelin }
          a.emailosoite = { email: a.emailosoite }
          a.paamies_id = this.csvLomake.paamies.id

          return a
        })

        const asiakkaatPostData = asiakkaat.map(asiakas => {
          const a = new Asiakas(asiakas)
          const virheet = a.validate()

          if (virheet.length) throw new Error(`Seuraavissa kentissä oli virhe: ${virheet.join(', ')}`)

          return a.getPostData()
        })

        const response = await this.$doRequestWithTimeout(
          this.$api.Asiakkaat,
          {
            method: 'POST',
            url: '',
            body: asiakkaatPostData,
          },
        )

        if (response.success) {
          this.$naytaOnnistumisilmoitus(
            `Luotu ${response.result.body.length} uutta asiakasta.`
          )

          this.csvLomake = {}
          this.$emit('asiakkaita-tallennettu')
          this.$emit('close')
        } else {
          this.$naytaDjangonVirheet(response)
        }
      } catch(e) {
        this.$oletusVirheenkasittely(e)
      }

      this.lataaTiedostoa = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
