<template>
  <v-form
    v-model="valid"
    @submit.prevent="submit"
  >
    <v-row>
      <v-col
        md="12"
        class="py-0"
      >
        <v-text-field
          v-model="value.iban"
          label="IBAN"
          type="text"
          class="mt-1 pt-1 required"
          :rules="[$validationRules.required, $validationRules.isValidIBAN]"
          :validate-on-blur="true"
          outlined
        />
        <v-text-field
          v-model="value.bic"
          label="BIC"
          type="text"
          class="mt-1 pt-1 required"
          :rules="[$validationRules.required, $validationRules.isValidBIC]"
          :validate-on-blur="true"
          outlined
        />
      </v-col>
    </v-row>
    <v-row
      class="mt-4 pa-3"
      justify="end"
    >
      <v-btn
        large
        color="success"
        class="ml-4"
        type="submit"
        :disabled="!valid"
      >
        {{ $t('common:save') }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'FormAsetuksetMaksutiedot',
  mixins: [FormMixin],
}

</script>

<style lang="scss" scoped>

 .v-text-field, .v-select {
   padding-top: 0px;
   margin-top: 4px;
 }

</style>
