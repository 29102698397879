var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "formVieMaksuun",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "max-width": "290",
            "nudge-bottom": "40",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        attrs: {
                          label: "Maksupäivä",
                          value: _vm.$date(_vm.value.maksupaiva),
                          readonly: "",
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.maksupaivaMenu,
            callback: function ($$v) {
              _vm.maksupaivaMenu = $$v
            },
            expression: "maksupaivaMenu",
          },
        },
        [
          [
            _c("v-date-picker", {
              attrs: {
                locale: "fi-FI",
                "first-day-of-week": "1",
                "no-title": "",
              },
              on: {
                change: function ($event) {
                  _vm.maksupaivaMenu = false
                },
              },
              model: {
                value: _vm.value.maksupaiva,
                callback: function ($$v) {
                  _vm.$set(_vm.value, "maksupaiva", $$v)
                },
                expression: "value.maksupaiva",
              },
            }),
          ],
        ],
        2
      ),
      _c("v-select", {
        staticClass: "required",
        attrs: {
          label: "Ostotili",
          items: _vm.ostotiliItems,
          "item-text": _vm.muotoileTiliItem,
          "item-value": "id",
          rules: [_vm.$validationRules.required],
        },
        model: {
          value: _vm.value.tili_id,
          callback: function ($$v) {
            _vm.$set(_vm.value, "tili_id", $$v)
          },
          expression: "value.tili_id",
        },
      }),
      !_vm.ostolaskut.length
        ? _c("v-text-field", {
            attrs: {
              type: "text",
              label: "Summa",
              rules: [_vm.$validationRules.numberOrEmpty],
              suffix: "€",
            },
            model: {
              value: _vm.value.summa,
              callback: function ($$v) {
                _vm.$set(_vm.value, "summa", $$v)
              },
              expression: "value.summa",
            },
          })
        : _c("v-text-field", {
            attrs: { disabled: "", type: "text", label: "Summa", suffix: "€" },
            model: {
              value: _vm.ostolaskutAvoinnaYht,
              callback: function ($$v) {
                _vm.ostolaskutAvoinnaYht = $$v
              },
              expression: "ostolaskutAvoinnaYht",
            },
          }),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }