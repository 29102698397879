<template>
  <v-form
    ref="formLiite"
    v-model="valid"
    @submit.prevent="submit"
  >
    <slot :form="value" />
    <v-file-input
      v-model="value.tiedosto"
      :rules="[$validationRules.required]"
      :error-messages="validoinninVirheilmoitukset"
    />
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="submitDisabloitu"
      :submit-loading="loading"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'
import { haeKuvatiedostonUlottuvuudet } from '@/utils/misc'

export default {
  name: 'FormLiite',
  mixins: [FormMixin],
  props: {
    tarkistaLogonKuvasuhde: {
      type: Boolean,
      required: false,
      default:false,
    },
  },
  data () {
    return {
      validoinninVirheilmoitukset: [],
    }
  },
  computed: {
    submitDisabloitu () {
      return !this.valid
    }
  },
  watch: {
    onAuki: {
      immediate: true,
      handler (arvo) {
        if (arvo) {
          this.$nextTick(() => {
            this.$refs.formLiite.resetValidation()
          })
        }
      }
    },
    'value.tiedosto': {
      async handler (val) {
        if (val && this.tarkistaLogonKuvasuhde) {
          try {
            this.validoinninVirheilmoitukset = []

            if (
              !val.type.includes('jpg') &&
              !val.type.includes('jpeg') &&
              !val.type.includes('png')
            ) {
              this.validoinninVirheilmoitukset.push(this.$t('common:formLiite.fileFormatJpgPng'))
              return
            }

            const ulottuvuudet = await haeKuvatiedostonUlottuvuudet(val)

            if (ulottuvuudet.height > ulottuvuudet.width) {
              this.validoinninVirheilmoitukset.push(this.$t('common:formLiite.logoTooHighMsg'))
            } else if (ulottuvuudet.height / ulottuvuudet.width < 1 / 8 ) {
              this.validoinninVirheilmoitukset.push(this.$t('common:formLiite.logoTooWideMsg'))
            }
          } catch (e) {
            this.$oletusVirheenkasittely(e)
          }
        }
      }
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
