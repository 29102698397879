var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { staticClass: "grey lighten-4 my-4 px-2", attrs: { flat: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _vm.uusiBtnPermission
            ? _c("LomakenappiTekstiJaIkoni", {
                attrs: {
                  ikoni: _vm.ikoni,
                  taustavari: _vm.taustavari,
                  teksti: _vm.teksti,
                  infoteksti: _vm.infoteksti,
                  naytaAina: _vm.naytaAina,
                  to:
                    _vm.uusiBtnConfig && _vm.uusiBtnConfig.toName
                      ? { name: _vm.uusiBtnConfig.toName }
                      : null,
                },
                on: { click: _vm.clickHandler },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }