import api from '@/plugins/api'
import { HttpError, SallittuKokoYlitettyError } from '@/utils/errors'
import {
  doRequestWithTimeout,
} from '@/utils/misc'
import BaseModule from '@/store/modules/baseModule'
import _ from 'lodash'
import { MaksimiUploadTiedostoKoko } from '@/utils/constants'
import { Ostolasku } from '@/class/Lasku'

export default class OstolaskuNayta extends BaseModule {
  state () {
    return {
      ...super.state(),
      ostolasku: {
        aikajana: [],
        avoinna: null,
        epitieto: {},
        ikoni_ja_vari: {
          ikoni: null,
          vari: null,
        },
        laskuliite_set: [],
        laskusisalto_set: [],
        nro: '',
        paamies: {
          nimi: '',
        },
        tapahtuma_set_laskukirjauksilla: [],
        tila: {},
      },
      kasiteltavanTiliointirivinAlkupArvo: null,
      laskurivejaMuokattuFormData: {},
      hyvitaFormData: {},
      laskunKuvaData: null,
      liitteetFormData: {},
      muokattavaOstolasku: new Ostolasku(),
      poistettavaTiedosto: {},
      ostotiliItems: [],
      uusiMuistiinpanoFormData: {},
      uusiViestiFormData: {},
      vieMaksuunFormData: {},
    }
  }

  mutations () {
    return {
      setOstolasku (state, payload) {
        state.ostolasku = payload
      },
      setKasiteltavanTiliointirivinAlkupArvo (state, payload) {
        state.kasiteltavanTiliointirivinAlkupArvo = payload
      },
      setLaskurivejaMuokattuFormData (state, payload) {
        state.laskurivejaMuokattuFormData = payload
      },
      setHyvitaFormData (state, payload) {
        state.hyvitaFormData = payload
      },
      setLaskunKuvaData (state, payload) {
        state.laskunKuvaData = payload
      },
      setLiitteetFormData (state, payload) {
        state.liitteetFormData = payload
      },
      setMuokattavaOstolasku (state, payload) {
        state.muokattavaOstolasku = payload
      },
      setPoistettavaTiedosto (state, payload) {
        state.poistettavaTiedosto = payload
      },
      setOstotiliItems (state, payload) {
        state.ostotiliItems = payload
      },
      setUusiMuistiinpanoFormData (state, payload) {
        state.uusiMuistiinpanoFormData = payload
      },
      setUusiViestiFormData (state, payload) {
        state.uusiViestiFormData = payload
      },
      setVieMaksuunFormData (state, payload) {
        state.vieMaksuunFormData = payload
      },
      
    }
  }
  actions () {
    return {
      async clearDetails ({ commit }) {
        commit('setKasiteltavanTiliointirivinAlkupArvo', {})
        commit('setLaskurivejaMuokattuFormData', {})
        commit('setHyvitaFormData', {})
        commit('setLaskunKuvaData', {})
        commit('setLiitteetFormData', {})
        commit('setMuokattavaOstolasku', {})
        commit('setPoistettavaTiedosto', {})
        commit('setOstotiliItems', {})
        commit('setUusiMuistiinpanoFormData', {})
        commit('setUusiViestiFormData', {})
        commit('setVieMaksuunFormData', {})
      },
      async loadItem ({ dispatch, commit, state }, sroute) {
        const request = await doRequestWithTimeout(
          api.Ostolasku,
          {
            method: 'GET',
            params: {
              id: sroute.params.id
            },
          },
          'pushRequest',
        )
        if (!request.success) throw new HttpError(request)

        const ostolasku = Object.assign(
          state.ostolasku,
          request.result.body,
          { laskunumero: request.result.body.nro }
        )
        commit('setOstolasku', ostolasku)
        await dispatch('kasitteleLaskunKuva')
      },
      async kasitteleLaskunKuva ({ commit, state }) {
        const laskunKuva = _.find(state.ostolasku.laskuliite_set, ['tyyppi', 'laskun kuva'])
        if (!laskunKuva) return

        const request = await doRequestWithTimeout(
          api.Ostolaskut,
          {
            url: `toiminnot/hae_liite/${laskunKuva.id}/`,
            parser: 'arrayBuffer',
          },
        )
        const blob = new Blob([request.result.body], { type: 'application/pdf' })
        commit('setLaskunKuvaData', URL.createObjectURL(blob))
      },
      async suodataLaskunPaamiehenTilit ({ commit, state, rootState }) {
        const kayttajanPaamiestenTilit = rootState.yleinen.ostotiliItems
        const laskunPaamiehenTilit = kayttajanPaamiestenTilit.filter(tili => {
          return tili.paamies_set.includes(state.ostolasku.paamies.id)
        })
        commit('setOstotiliItems', laskunPaamiehenTilit)
      },

      async liitteetSubmit ({ dispatch, state }) {
        const formData = new FormData()
  
        let liitteetLkm = 0
        for (var tiedosto of state.liitteetFormData.tiedostot) {
          if (tiedosto.size > MaksimiUploadTiedostoKoko) throw new SallittuKokoYlitettyError(SallittuKokoYlitettyError.SALLITTU_KOKO_YLITETTY_VIESTI, tiedosto.name)
          liitteetLkm++
          formData.append(tiedosto.name, tiedosto)
        }

        const request = await doRequestWithTimeout(
          api.Ostolasku,
          {
            method: 'POST',
            url: 'toiminnot/tuo_liite/',
            body: formData,
            params: {
              id: state.ostolasku.id
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new HttpError(request)
        dispatch('paivitaLasku', request.result.body)
        return (liitteetLkm === 1 ? 'Liite onnistuneesti lisätty.' : 'Liitteet onnistuneesti lisätty.')
      },

      paivitaLasku ({ commit }, paivitettyLasku) {
        const ostolasku = Object.assign({}, paivitettyLasku, { laskunumero: paivitettyLasku.nro })
        commit('setOstolasku', ostolasku)
      },

      async viestiSubmit ({ dispatch, state, commit }) {
        const requestBody = {
          sisalto: state.uusiViestiFormData.message,
        }

        const request = await doRequestWithTimeout(
          api.Ostolasku,
          {
            method: 'POST',
            url: 'toiminnot/viesti/',
            body: requestBody,
            params: {
              id: state.ostolasku.id,
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new HttpError(request)

        dispatch('paivitaLasku', request.result.body)
        commit('setUusiViestiFormData', {})
      },

      async muistiinpanoSubmit ({ dispatch, state, commit }) {
        const requestBody = {
          sisalto: state.uusiMuistiinpanoFormData.message,
        }
        const request = await dispatch('lahetaMuistiinpano', requestBody)

        if (!request.success) throw new HttpError(request)

        dispatch('paivitaLasku', request.result.body)
        commit('setUusiMuistiinpanoFormData', {})
      },

      async lahetaMuistiinpano ({ state }, requestBody) {
        return doRequestWithTimeout(
          api.Ostolasku,
          {
            method: 'POST',
            url: 'toiminnot/muistiinpano/',
            body: requestBody,
            params: {
              id: state.ostolasku.id,
            }
          },
          'doSingleRequest',
        )
      },

      async teeOstolaskuToiminto ({ dispatch, state }, { endpoint, body = {}} ) {
        const request = await doRequestWithTimeout(
          api.Ostolasku,
          {
            method: 'POST',
            url: `toiminnot/${endpoint}/`,
            body: body,
            params: {
              id: state.ostolasku.id,
            }
          },
          'doSingleRequest',
        )
        if (!request.success) throw new HttpError(request)

        dispatch('paivitaLasku', request.result.body)
      },

      async tilitaOstolaskut ({ state } ) {
        const body = {
          tili_id: state.vieMaksuunFormData.tili_id,
          paamies_id: state.ostolasku.paamies.id,
        }
        const request = await doRequestWithTimeout(
          api.OstolaskuTilita,
          {
            method: 'POST',
            url: '',
            body: body,
          },
          'doSingleRequest',
        )

        if (!request.success) throw new HttpError(request)
      },

      async lahetaMuutokset ({ state } ) {
        const request = await doRequestWithTimeout(
          api.Ostolasku,
          {
            method: 'PUT',
            url: ' ',
            body: state.muokattavaOstolasku.getPostData(true),
            params: {
              id: state.muokattavaOstolasku.id
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new HttpError(request)
        return request.result.body
      },

      async kasitteleLaskurivejaMuokattuSubmit ({ dispatch, state, commit } ) {
        const seliteRequestBody = {
          sisalto: `[Laskurivejä muokattu:] ${state.laskurivejaMuokattuFormData.selite}`,
        }
        const muistiinpanoRequest = await dispatch('lahetaMuistiinpano', seliteRequestBody)
        if (!muistiinpanoRequest.success) throw new HttpError(muistiinpanoRequest)
        dispatch('paivitaLasku', muistiinpanoRequest.result.body)
        commit('setLaskurivejaMuokattuFormData', {})
      },

      async tallennaTiliointirivi ({ }, param ) {
        let {
          rivi,
          uusi,
        } = param
        const kasiteltyAlvp =  typeof rivi.alvp === 'string' ? rivi.alvp.replace(',', '.') : rivi.alvp
        rivi.alvp = parseFloat(kasiteltyAlvp)
  
        const request = await doRequestWithTimeout(
          api.Tiliointi,
          {
            method: uusi ? 'POST' : 'PUT',
            url: '',
            body: rivi,
            params: {
              id: rivi.id,
            }
          },
          'doSingleRequest',
        )
        if (!request.success) throw new HttpError(request)
      },
    }
  }
}
