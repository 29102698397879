/**
 * https://stackoverflow.com/questions/48327261/way-to-make-inheritance-in-vuex-modules/56790942#56790942
 */

import { HttpError } from '@/utils/errors'
import {
  doRequestWithTimeout,
  oletusVirheenkasittely,
} from '@/utils/misc'
import BaseModule from '@/store/modules/baseModule'

export default class BaseSelaa extends BaseModule {
  state () {
    return {
      ...super.state(),
      currentTab: 'kaikki',
      items: [],
      itemsTotal: 0,
      loading: false,
      loadingSummary: true,
      loadingOffCanvas: false,
      pagesTotal: 0,
      pagination: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['id'],
        sortDesc: [true],
      },
      searchPanelOpen: false,
      searchQuery: '',
      searchTerms: {},
      selectedItem: { item: {} },
      showOffCanvas: false,
      tabs: [],
    }
  }

  mutations () {
    return {
      resetView (state) {
        state.items = []
        state.itemsTotal = 0
        state.loadingOffCanvas = false
        state.pagesTotal = 0
        state.pagination = {
          itemsPerPage: state.pagination.itemsPerPage,
          page: 1,
          sortBy: state.pagination.sortBy,
          sortDesc: state.pagination.sortDesc,
        }
        state.searchPanelOpen = false
        state.searchQuery = ''
        state.searchTerms = {}
        state.selectedItem = { item: {} }
        state.showOffCanvas = false
      },
      resetViewViewKohtainen () {
        // Määritä perivässä luokassa, muutoin no-op
      },
      setCurrentTab (state, payload) {
        state.currentTab = payload
      },
      setItems (state, payload) {
        state.items = payload
      },
      setItemsTotal (state, payload) {
        state.itemsTotal = payload
      },
      setLoading (state, payload) {
        state.loading = payload
      },
      setLoadingSummary (state, payload) {
        state.loadingSummary = payload
      },
      setLoadingOffCanvas (state, payload) {
        state.loadingOffCanvas = payload
      },
      setPagesTotal (state, payload) {
        state.pagesTotal = payload
      },
      setPagination (state, payload) {
        state.pagination = payload
      },
      setSearchPanelOpen (state, payload) {
        state.searchPanelOpen = payload
      },
      setSearchQuery (state, payload) {
        state.searchQuery = payload
      },
      setSearchTerms (state, payload) {
        state.searchTerms = payload
      },
      setSelectedItem (state, payload) {
        state.selectedItem = payload
      },
      setShowOffCanvas (state, payload) {
        if (typeof payload === 'undefined') {
          state.showOffCanvas = !state.showOffCanvas
        } else {
          state.showOffCanvas = payload
        }
      },
    }
  }

  actions () {
    return {
      async setSelectedItem ({ commit, getters }, { item, silent = false }) {
        try {
          if (!silent) commit('setLoadingOffCanvas', true)

          if (item.id === undefined) {
            commit('setSelectedItem', { item: {} })
          } else {
            commit('setShowOffCanvas', true)

            const api = getters.api
            const request = await doRequestWithTimeout(
              api,
              {
                method: 'GET',
                url: ':id/off_canvas/',
                params: {
                  id: item.id
                }
              }
            )

            if (!request.success) throw new HttpError(request)

            commit('setSelectedItem', { item: Object.assign({}, request.result.body) })
          }
        } catch (e) {
          oletusVirheenkasittely(e)
        } finally {
          commit('setLoadingOffCanvas', false)
        }
      },
      async setItems (
        { commit, getters, state }, {
          query,
          silent = false,
          toggleLoadingSummary = true,
        }
      ) {
        try {
          if (!silent) commit('setLoading', true)
          if (toggleLoadingSummary) commit('setLoadingSummary', true)

          const api = getters.api
          for (const pendingRequest of api.queue) {
            pendingRequest.cancelled = true
          }

          const request = await doRequestWithTimeout(
            api,
            {
              method: 'GET',
              url: '',
              query: query,
            },
            'doRequest',
            300000, // 5 min
          )

          if (!request.success && !request.cancelled) throw new HttpError(request)

          const { result } = request

          if (!request.cancelled) {
            commit('setItems', result.body.results)
            commit('setItemsTotal', result.body.count)
            commit('setPagesTotal', Math.ceil(result.body.count / state.pagination.itemsPerPage))
          }
        } catch (e) {
          oletusVirheenkasittely(e)
        } finally {
          commit('setLoading', false)
          commit('setLoadingSummary', false)
        }
      },
      resetView ({ commit }) {
        commit('resetView')
        commit('resetViewViewKohtainen')
      }
    }
  }
}
