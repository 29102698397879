<template>
  <v-form
    ref="formSopimusLaskutusaihe"
    v-model="valid"
    @submit.prevent="submit"
  >
    <SopimusLaskutusaiheTable
      :laskutusaihe="value"
      :laskutusjaksot="laskutusjaksot"
    />
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      :submit-teksti="tallennuspainikkeenTeksti"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'
import SopimusLaskutusaiheTable from './SopimusLaskutusaiheTable.vue'
import { Kirjoitusmoodit } from '@/utils/constants'

export default {
  name: 'FormSopimusLaskutusaihe',
  components: { SopimusLaskutusaiheTable },
  mixins: [FormMixin],
  props: {
    laskutusjaksot: {
      type: Array,
      required: true,
    },
    kirjoitusmoodi: {
      type: String,
      required: true,
      validator: (arvo) => [Kirjoitusmoodit.LUOMINEN, Kirjoitusmoodit.MUOKKAUS].includes(arvo)
    },
  },
  computed: {
    tallennuspainikkeenTeksti () {
      return this.kirjoitusmoodi === Kirjoitusmoodit.LUOMINEN ? this.$t('common:lasku.createInvoiceSubject') : this.$t('common:saveChanges')
    }
  },
  watch: {
    onAuki: {
      immediate: true,
      handler (arvo) {
        if (arvo) {
          this.$nextTick(() => {
            this.$refs.formSopimusLaskutusaihe.resetValidation()
          })
        }
      }
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
