<template>
  <DefList :bordered="(true)">
    <DefListItem>
      <template v-slot:label>
        {{ $t('common:status') }}
      </template>
      <template
        v-if="value.tila && value.ikoni_ja_vari"
        v-slot:value
      >
        <StatusText
          :text="value.tila.selite"
          :color="value.ikoni_ja_vari.vari"
        />
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        {{ $t('common:contractNumber') }}
      </template>
      <template
        v-if="value.nro"
        v-slot:value
      >
        {{ value.nro }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>{{ $sovellusIdOtsake }}</template>
      <template v-slot:value>
        {{ value.id }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        {{ $t('common:customer') }}
      </template>
      <template
        v-if="value.asiakas"
        v-slot:value
      >
        {{ value.asiakas.nimi }} <template v-if="value.asiakas.nro">
          ({{ value.asiakas.nro }})
        </template>
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        {{ $t('common:creditor') }}
      </template>
      <template
        v-if="value.paamies"
        v-slot:value
      >
        {{ value.paamies.nimi }} <template v-if="value.paamies.id">
          ({{ value.paamies.id }})
        </template>
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        {{ $t('common:startingDate') }}
      </template>
      <template
        v-if="value.alkamispaiva"
        v-slot:value
      >
        {{ $date(value.alkamispaiva) }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        {{ $t('common:endingDate') }}
      </template>
      <template
        v-slot:value
      >
        <span v-if="value.loppumispaiva">{{ $date(value.loppumispaiva) }}</span>
        <span v-else>{{ $t('common:validForNow') }}</span>
      </template>
    </DefListItem>
    <DefListItem v-if="value.laskutuspaiva && value.seuraava_laskutuspaiva">
      <template v-slot:label>
        {{ $t('common:nextInvoiceDate') }}
      </template>
      <template v-slot:value>
        {{ $date(value.seuraava_laskutuspaiva) }}
      </template>
    </DefListItem>
    <DefListItem v-else-if="value.erapaiva && value.seuraava_erapaiva">
      <template v-slot:label>
        {{ $t('common:nextDueDate') }}
      </template>
      <template v-slot:value>
        {{ $date(value.seuraava_erapaiva) }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        {{ $t('common:reference') }}
      </template>
      <template
        v-if="value.viite"
        v-slot:value
      >
        {{ value.viite }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.viitteemme">
      <template v-slot:label>
        {{ $t('common:ourReference') }}
      </template>
      <template v-slot:value>
        {{ value.viitteemme }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.viitteenne">
      <template v-slot:label>
        {{ $t('common:yourReference') }}
      </template>
      <template v-slot:value>
        {{ value.viitteenne }}
      </template>
    </DefListItem>
    <!-- <DefListItem>
      <template v-slot:label>
        Eräpäivä
      </template>
      <template
        v-if="value.erapaiva"
        v-slot:value
      >
        <strong v-if="value.onko_myohassa">
          {{ $date(value.erapaiva) }} (myöhässä)
        </strong>
        <template v-else>
          {{ $date(value.erapaiva) }}
        </template>
      </template>
    </DefListItem> -->
  </DefList>
</template>

<script>

import StatusText from '@/components/StatusText'

export default {
  name: 'OffCanvasDetailsLasku',
  components: {
    StatusText,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
