var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { class: _vm.rowClass, attrs: { justify: _vm.rowJustify } },
    [
      _c(
        "v-btn",
        {
          attrs: { large: _vm.large },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.peruutaTeksti) + " ")]
      ),
      _c(
        "v-btn",
        {
          staticClass: "ml-4",
          attrs: {
            type: "submit",
            color: "success",
            disabled: _vm.submitDisabled,
            large: _vm.large,
            loading: _vm.submitLoading,
          },
        },
        [_vm._v(" " + _vm._s(_vm.submitTeksti) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }