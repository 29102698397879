<template>
  <v-form
    ref="formTuoteryhma"
    v-model="valid"
  >
    <v-row
      v-if="!this.$store.state.user.vainYksiPaamies"
      align="center"
    >
      <v-col>
        <v-text-field
          v-if="tuoteryhma.id"
          v-model="tuoteryhma.paamies.nimi"
          :label="$t('common:creditor')"
          :disabled="(true)"
          class="required"
        />
        <AutocompletePaamies
          v-else
          ref="autocompletePaamies"
          v-model="tuoteryhma"
          :label="$t('common:creditor')"
          :tapaus="tuoteryhma"
          :validointi-saannot="[$validationRules.requiredObject]"
          tarkistettava-oikeus="onlinerestapi.onlinerestapi_tuoterekisteri"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="tuoteryhma.nimi"
          :label="$t('common:name')"
          class="required"
          :rules="[$validationRules.required]"
        />
      </v-col>
    </v-row>
    <v-row
      class="mt-4 pa-3"
      justify="end"
    >
      <v-btn
        large
        class="mr-4"
        @click="$emit('close')"
      >
        {{ $t('common:cancel') }}
      </v-btn>
      <v-btn
        large
        color="success"
        :disabled="!valid || loading"
        @click="tallenna"
      >
        {{ $t('common:save') }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>

import _ from 'lodash'
import { mapState } from 'vuex'
import { Tuoteryhma } from '@/class/Tuoterekisteri'
import { AutocompletePaamies } from '@/components'
import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'FormTuoteryhma',
  components: {
    AutocompletePaamies,
  },
  mixins: [FormMixin],
  props: {
    muokattavaTuoteryhma: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      tuoteryhma: new Tuoteryhma,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
  },
  watch: {
    onAuki: {
      immediate: true,
      handler () {
        this.$nextTick().then(() => {
          this.$refs.formTuoteryhma.resetValidation()
        })

        if (!this.muokattavaTuoteryhma.id) {
          this.tuoteryhma = new Tuoteryhma()
          if (this.$store.state.user.vainYksiPaamies) {
            this.tuoteryhma.paamies = this.$store.state.user.valittuPaamies
          } else {
            this.$nextTick().then(() => {
              if (this.$refs.autocompletePaamies) {
                this.tuoteryhma.paamies = this.user.valittuPaamies
                // Asetetaan päämiehen nimi hakukenttään, kun päämies on haettu arvo storesta
                setTimeout(() => {
                  this.$refs.autocompletePaamies.paivitaPaamiehenTiedotHakukenttaan(
                    this.user.valittuPaamies
                  )
                }, 0)
              }
            })
          }
        }
      }
    },
    'tuoteryhma.paamies.id': {
      deep: true,
      immediate: true,
      handler (id) {
        if (id) {
          this.tuoteryhma.paamies_id = id
        }
      }
    },
    'muokattavaTuoteryhma.id': {
      immediate: true,
      handler (id) {
        if (id) {
          this.tuoteryhma = new Tuoteryhma(_.cloneDeep(this.muokattavaTuoteryhma))
        }
      }
    },
  },
  methods: {
    async tallenna () {
      if (!this.$refs.formTuoteryhma.validate()) return

      const httpMethod = this.tuoteryhma.id ? 'PUT' : 'POST'
      const url = this.tuoteryhma.id ? `${this.tuoteryhma.id}/` : ''

      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Tuoteryhmat,
          {
            method: httpMethod,
            url: url,
            body: this.tuoteryhma.getPostData(),
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.$emit('tuoteryhmaTallennettu', request.result.body)
        this.$emit('close')
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:product.saveProductFailed'))
      }
    },
  },
}

</script>

<style lang="scss" scoped>

 .v-text-field, .v-select {
   padding-top: 0px;
   margin-top: 4px;
 }

 .hidden {
   visibility: hidden;
 }

</style>
