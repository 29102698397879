<template>
  <v-tooltip
    v-if="tekstiOnPitka"
    bottom
  >
    <template v-slot:activator="{ on }">
      <span
        v-on="on"
      >
        {{ lyhennettyPerustietoteksti }}
      </span>
    </template>
    <p class="tooltip-hover">
      {{ teksti }}
    </p>
  </v-tooltip>
  <span v-else>{{ teksti }}</span>
</template>

<script>
export default {
  props: {
    teksti: {
      type: String,
      required: true,
    },
  },
  computed: {
    tekstiOnPitka () {
      return this.teksti.length > 100
    },
    lyhennettyPerustietoteksti () {
      return this.teksti.substr(0, 98) + '...'
    }
  }
}
</script>

<style>

  .tooltip-hover {
    width: 400px;
  }

</style>
