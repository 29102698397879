import { Lasku } from '@/class/Lasku'
import { t } from './lang'
import { onkoModuuliKaytossa } from '@/utils/misc'

export const SelectablesToimeksiantoTilat = [
  {
    id: 'perinnassa',
    label: t('inCollection'),
    terms: [
      {
        key: 'tila',
        value: 'VP',
      },
    ],
  },
  {
    id: 'oikeudellisessa_perinnassa',
    label: t('inLegalRecovery'),
    terms: [
      {
        key: 'tila',
        value: 'OP',
      },
    ],
  },
  {
    id: 'ulosotossa',
    label: t('inExecution'),
    terms: [
      {
        key: 'tila',
        value: 'UO',
      },
    ],
  },
  {
    id: 'maksettu',
    label: t('paid'),
    terms: [
      {
        key: 'tila',
        value: 'LO',
      },
    ],
  },
  {
    id: 'myyty',
    label: t('selectables.sold'),
    terms: [
      {
        key: 'tila',
        value: 'MY',
      },
    ],
  },
  {
    id: 'peruttu',
    label: t('canceled'),
    terms: [
      {
        key: 'tila',
        value: 'XX',
      },
      {
        key: 'tila',
        value: 'PE',
      },
    ],
  }
]

export const SelectablesToimeksiantoTyypit = [
  {
    id: 'perinta',
    label: t('selectables.collection'),
    terms: [
      {
        key: 'tyyppi',
        value: 'P',
      },
    ],
  },
  {
    id: 'jalkiperinta',
    label: t('selectables.recovery'),
    terms: [
      {
        key: 'tyyppi',
        value: 'J',
      },
    ],
  },
]

export const SelectablesVelallinenTyypit = [
  {
    id: 'henkilö',
    label: t('selectables.person'),
    terms: [
      {
        key: 'velallinen__tyyppi',
        value: 'H',
      },
    ],
  },
  {
    id: 'yritys',
    label: t('selectables.company'),
    terms: [
      {
        key: 'velallinen__tyyppi',
        value: 'Y',
      },
    ],
  },
]

export const SelectablesAsiakasTyypit = [
  {
    id: 'yritys',
    label: t('selectables.company'),
    terms: [
      {
        key: 'tyyppi',
        value: 'Y',
      },
    ],
  },
  {
    id: 'henkilö',
    label: t('selectables.person'),
    terms: [
      {
        key: 'tyyppi',
        value: 'H',
      },
    ],
  },
]

export const SelectablesAsiakasTyypitLaskulle = [
  {
    id: 'yritys',
    label: t('selectables.company'),
    terms: [
      {
        key: 'asiakas__tyyppi',
        value: 'Y',
      },
    ],
  },
  {
    id: 'henkilö',
    label: t('selectables.person'),
    terms: [
      {
        key: 'asiakas__tyyppi',
        value: 'H',
      },
    ],
  },
]

export const SelectablesToimittajaTyypit = [
  {
    id: 'yritys',
    label: t('selectables.company'),
    terms: [
      {
        key: 'toimittaja__tyyppi',
        value: 'Y',
      },
    ],
  },
  {
    id: 'henkilö',
    label: t('selectables.person'),
    terms: [
      {
        key: 'toimittaja__tyyppi',
        value: 'H',
      },
    ],
  },
]

export const SelectablesLaskuTilat = [
  {
    id: 'luonnos',
    label: t('selectables.draft'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_LUONNOS,
      },
    ],
  },
  {
    id: 'lahettamatta',
    label: t('selectables.notSent'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_LAHETTAMATTA,
      },
    ],
  },
  {
    id: 'avoinna',
    label: t('open'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_AVOINNA,
      },
    ],
  },
  {
    id: 'maksettu',
    label: t('paid'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_MAKSETTU,
      },
    ],
  },
  {
    id: 'perinnassa',
    label: t('inCollection'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_PERINNASSA,
      },
    ],
  },
  {
    id: 'keskeytetty',
    label: t('selectables.stopped'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_KESKEYTETTY,
      },
    ],
  },
  {
    id: 'peruttu',
    label: t('canceled'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_PERUTTU,
      },
    ],
  },
  {
    id: 'kuittauksessa',
    label: t('selectables.inAcknowledgment'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_KUITTAUKSESSA,
      },
    ],
  },
  {
    id: 'myyty',
    label: t('selectables.sold'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_MYYTY,
      },
    ],
  },
]

export const SelectablesOstolaskuTilat = [
  {
    id: 'avoinna',
    label: t('open'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_AVOINNA,
      },
    ],
  },
  {
    id: 'hyvaksytty',
    label: t('acceptedForPayment'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_HYVAKSYTTY_MAKSUUN,
      },
    ],
  },
  {
    id: 'maksettu',
    label: t('paid'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_MAKSETTU,
      },
    ],
  },
  {
    id: 'peruttu',
    label: t('canceled'),
    terms: [
      {
        key: 'tila',
        value: Lasku.TILA_PERUTTU,
      },
    ],
  },
]

export const SelectablesSopimusTilat = [
  {
    id: 'aktiivinen',
    label: t('active2'),
    terms: [
      {
        key: 'tila',
        value: 'A',
      },
    ],
  },
  {
    id: 'myyty',
    label: t('selectables.sold'),
    terms: [
      {
        key: 'tila',
        value: 'M',
      },
    ],
  },
  {
    id: 'paattynyt',
    label: t('selectables.finished'),
    terms: [
      {
        key: 'tila',
        value: 'P',
      },
    ],
  },
  {
    id: 'hyvaksyttava',
    label: t('selectables.mustBeAccepted'),
    terms: [
      {
        key: 'tila',
        value: 'S',
      },
    ],
  },
  {
    id: 'hylatty',
    label: t('selectables.abandoned'),
    terms: [
      {
        key: 'tila',
        value: 'H',
      },
    ],
  },
  {
    id: 'eraannytetty',
    label: t('selectables.overdue'),
    terms: [
      {
        key: 'tila',
        value: 'E',
      },
    ],
  },
]

export const SelectablesSopimusVoimassaolot = [
  {
    id: 'kaikki',
    label: t('selectables.allContracts'),
    terms: [],
  },
  {
    id: 'toistaiseksi',
    label: t('selectables.validForNow'),
    terms: [
      {
        key: 'voimassa',
        value: true,
      },
    ],
  },
  {
    id: 'paattyvat',
    label: t('selectables.fixedTermAndExpired'),
    terms: [
      {
        key: 'voimassa',
        value: false,
      },
    ],
  },
]

export const SelectablesRaporttiTilat = [
  {
    id: 'kesken',
    label: t('inProgress'),
    terms: [
      {
        key: 'tila',
        value: 'K',
      },
    ],
  },
  {
    id: 'valmis',
    label: t('ready'),
    terms: [
      {
        key: 'tila',
        value: 'V',
      },
    ],
  },
]

export const SelectablesTilitysTyypit = [
  {
    id: 'paamies',
    label: t('creditor'),
    terms: [
      {
        key: 'tyyppi',
        value: 'PM',
      },
    ],
  },
  {
    id: 'palautus',
    label: t('selectables.return'),
    terms: [
      {
        key: 'tyyppi',
        value: 'PA',
      },
    ],
  },
  {
    id: 'virtuaalitili',
    label: t('selectables.virtualAccount'),
    terms: [
      {
        key: 'tyyppi',
        value: 'PV',
      },
    ],
  },
  ...(onkoModuuliKaytossa('rahoitus') ? [{
    id: 'rahoitus',
    label: t('selectables.financing'),
    terms: [
      {
        key: 'tyyppi',
        value: 'RA',
      },
    ],
  }] : []),
  {
    id: 'ostolasku',
    label: t('selectables.purchaseInvoice'),
    terms: [
      {
        key: 'tyyppi',
        value: 'OS',
      },
    ],
  },
  {
    id: 'tiliointi',
    label: t('selectables.accounting'),
    terms: [
      {
        key: 'tyyppi',
        value: 'TI',
      },
    ],
  },
]
