<template>
  <v-form
    ref="formTuote"
    v-model="valid"
  >
    <v-row
      align="center"
    >
      <v-col>
        <v-text-field
          v-if="yleinen.muokattavaTuote.id"
          v-model="yleinen.muokattavaTuote.paamies.nimi"
          :label="$t('common:creditor')"
          :disabled="(true)"
          class="required"
        />
        <AutocompletePaamies
          v-else-if="!$store.state.user.vainYksiPaamies"
          ref="autocompletePaamies"
          v-model="yleinen.muokattavaTuote"
          :label="$t('common:creditor')"
          :tapaus="yleinen.muokattavaTuote"
          tarkistettava-oikeus="onlinerestapi.onlinerestapi_tuoterekisteri"
          @autocomplete-input="tarkistaKoodi"
        />
        <div>
        <v-text-field
          v-model="yleinen.muokattavaTuote.tuoteryhma.nimi"
          v-show="false"
          :rules="[$validationRules.required]"
          class="required"
        />
        <v-text-field
          v-if="yleinen.muokattavaTuote.tuoteryhma.id"
          v-model="yleinen.muokattavaTuote.tuoteryhma.nimi"
          :label="$t('common:productGroup.productGroupHeader')"
          clearable
          :readonly="(true)"
          :rules="[$validationRules.required]"
          class="required"
          @input="tyhjennaTuoteryhma"
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" @click="uusiTuoteryhma" v-on="on" v-bind="attrs" >
                  add
                </v-icon>
              </template>
              <span>Lisää tuoteryhmä</span>
            </v-tooltip>
          </template>
        </v-text-field>
        <AutocompleteTuoteryhma
          v-else
          ref="autocompleteTuoteryhma"
          reference="autocompleteTuoteryhma"
          :tuote="yleinen.muokattavaTuote"
          append-icon="add"
          @uusiTuoteryhma="uusiTuoteryhma"
          :rules="[$validationRules.required]"
          required="required"
        />
        </div>
        <v-text-field
          v-model="yleinen.muokattavaTuote.koodi"
          :label="$t('common:code')"
          :rules="[$validationRules.required]"
          class="required"
          :error-messages="koodiErrors"
          @input="tarkistaKoodi($event)"
        />
        <v-text-field
          v-model="yleinen.muokattavaTuote.selite"
          :label="$t('common:definition')"
        />
        <v-text-field
          v-model="yleinen.muokattavaTuote.vapaateksti"
          :label="$t('common:freeText')"
        />
        <div class="py-4"></div>
        <v-text-field
          v-model="yleinen.muokattavaTuote.ahinta"
          :label="$t('common:unitPrice')"
          type="number"
          :rules="[$validationRules.required, $validationRules.maxDecimalPlaces(yleinen.muokattavaTuote.ahinta, 2)]"
          class="required"
        />
        <v-switch
          v-model="yleinen.muokattavaTuote.sisalv"
          class="ml-4 mt-0"
          :label="$t('common:general.ifPriceIncludesVat')"
        />
        <v-text-field
          v-model="yleinen.muokattavaTuote.laatu"
          :label="$t('common:quantityUnit')"
        />
        <v-autocomplete 
          ref="refAlvtunnusId"
          v-model="yleinen.muokattavaTuote.alvtunnus_id" 
          type="text"
          :items="yleinen.ALVTunnusItems" 
          :label="$t('common:vatId')" 
          item-text="tunnus"
          item-value="id"
          :rules="[$validationRules.required]"
          class="required"
        >
        </v-autocomplete>
        <v-text-field
          v-model="yleinen.muokattavaTuote.hankintahinta"
          type="number"
          :label="$t('common:purchasePrice')"
          :rules="[$validationRules.required, $validationRules.maxDecimalPlaces(yleinen.muokattavaTuote.hankintahinta, 2)]"
        />
        <v-text-field
          v-model="yleinen.muokattavaTuote.varastotilanne"
          :label="$t('common:stockStatus')"
          type="text"
          :rules="[$validationRules.numberOrEmpty]"
        />
      </v-col>
    </v-row>
    <v-row
      class="mt-4 pa-3"
      justify="end"
    >
      <v-btn
        large
        class="mr-4"
        @click="$emit('close')"
      >
        {{ $t('common:cancel') }}
      </v-btn>
      <v-btn
        large
        color="success"
        :disabled="!valid || loading"
        @click="tallenna"
      >
        {{ $t('common:save') }}
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>

import _ from 'lodash'
import { mapState } from 'vuex'
import { Tuote } from '@/class/Tuoterekisteri'
import {
  AutocompletePaamies,
  AutocompleteTuoteryhma,
 } from '@/components'
 import FormMixin from '@/mixins/FormMixin'
import { MaksimiTuoteId } from '@/utils/constants'

export default {
  name: 'FormTuote',
  components: {
    AutocompletePaamies,
    AutocompleteTuoteryhma,
  },
  mixins: [FormMixin],
  props: {
    laskurivi: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      koodiErrors: [],
      tuote: new Tuote,
      tuoteryhmaItems: [],
    }
  },
  computed: {
    ...mapState([
    'yleinen',
    ]),
    ...mapState({
      user: state => state.user,
      ALVTunnusItems: state => state.yleinen.ALVTunnusItems,
    }),
  },
  watch: {
    onAuki: {
      immediate: true,
      handler () {
        this.$nextTick().then(() => {
          this.$refs.formTuote.resetValidation()
        })
        if (!this.yleinen.muokattavaTuote.id) {
          this.yleinen.muokattavaTuote = new Tuote()
          if (this.$store.state.user.vainYksiPaamies) {
            this.yleinen.muokattavaTuote.paamies = this.$store.state.user.valittuPaamies
            // Tyhjennyksellä saadaan aikaan päämiehen tuoteryhmien haku
            this.tyhjennaTuoteryhma()
          } else {
            this.$nextTick().then(() => {
              if (this.$refs.autocompletePaamies) {
                this.yleinen.muokattavaTuote.paamies = this.user.valittuPaamies
                // Asetetaan päämiehen nimi hakukenttään, kun päämies on haettu arvo storesta
                setTimeout(() => {
                  this.$refs.autocompletePaamies.paivitaPaamiehenTiedotHakukenttaan(
                    this.user.valittuPaamies
                  )
                }, 0)

                this.tarkistaKoodi()
              }
            })
          }
        } else {
          this.tarkistaKoodi()
          if (this.yleinen.muokattavaTuote.alvtunnus) {
            this.yleinen.muokattavaTuote.alvtunnus_id = this.yleinen.muokattavaTuote.alvtunnus.id
          }
        }

        if (!_.isEmpty(this.laskurivi)) {
          this.yleinen.muokattavaTuote = new Tuote(this.laskurivi)
          if (this.laskurivi.alvtunnus.id) this.yleinen.muokattavaTuote.alvtunnus_id = this.laskurivi.alvtunnus.id
        }
      }
    },
    'yleinen.muokattavaTuote.id': {
      immediate: true,
      handler (id) {
        if (id) {
          this.yleinen.muokattavaTuote = new Tuote(_.cloneDeep(this.yleinen.muokattavaTuote))
          if (this.yleinen.muokattavaTuote.alvtunnus) {
            this.yleinen.muokattavaTuote.alvtunnus_id = this.yleinen.muokattavaTuote.alvtunnus.id
          }
        }
      }
    },
  },
  methods: {
    async uusiTuoteryhma () {
      if (this.$refs.autocompleteTuoteryhma && this.$refs.autocompleteTuoteryhma.$refs && 
      this.$refs.autocompleteTuoteryhma.$refs.autocompleteTuoteryhma) {
        //tyhjennetään tuoteryhmän valikko
        this.$refs.autocompleteTuoteryhma.$refs.autocompleteTuoteryhma.blur()
      }
      this.$emit('uusiTuoteryhma')
    },
    async uusiTuoteryhmaSelaaNaytolta () {
      this.$emit('uusiTuoteryhmaSelaaNaytolta')
    },
    async tallenna () {
      if (!this.$refs.formTuote.validate()) return
      try {
        this.tyhjennaKopioidunTuotteenId()
        const httpMethod = this.yleinen.muokattavaTuote.id ? 'PUT' : 'POST'
        const url = this.yleinen.muokattavaTuote.id ? `${this.yleinen.muokattavaTuote.id}/` : ''
        let tmpTuote = new Tuote(this.yleinen.muokattavaTuote)

        const request = await this.$doRequestWithTimeout(
          this.$api.Tuotteet,
          {
            method: httpMethod,
            url: url,
            body: tmpTuote.getPostData(),
          },
          'doSingleRequest',
        )
        if (!request.success) throw new this.$HttpError(request)

        this.$emit('tuoteTallennettu', request.result.body)
        this.$emit('close')
      } catch (e) {
        this.$oletusVirheenkasittely(e, this.$t('common:product.saveProductFailed'))
      }
    },
    async tarkistaKoodi () {
      this.koodiErrors = []

      if (!this.yleinen.muokattavaTuote.koodi || !this.yleinen.muokattavaTuote.paamies || !this.yleinen.muokattavaTuote.paamies.id) return

      const query = new URLSearchParams()
      query.append('method', 'tuotekooditarkistus')
      query.append('koodi', this.yleinen.muokattavaTuote.koodi)
      query.append('paamies_id', this.yleinen.muokattavaTuote.paamies.id)
      if (this.yleinen.muokattavaTuote.id) query.append('tuote_id', this.yleinen.muokattavaTuote.id)

      const request = await this.$doRequestWithTimeout(
        this.$api.Tuotteet,
        {
          url: 'toiminnot/tarkista',
          query: query.toString(),
        },
        'pushRequest',
      )

      const koodiVapaana = request.result.body
      if (!koodiVapaana && this.yleinen.muokattavaTuote.koodi) {
        this.koodiErrors = [this.$t('common:product.productCodeAlreadyUsed')]
      } else {
        this.koodiErrors = []
      }
    },
    tyhjennaTuoteryhma () {
      this.yleinen.muokattavaTuote.tuoteryhma = {}
      this.$nextTick().then(() => {
        if (this.$refs.autocompleteTuoteryhma) {
          this.$refs.autocompleteTuoteryhma.haeTuoteryhmat()
        }
      })
    },
    tyhjennaKopioidunTuotteenId () {
      if (this.yleinen.muokattavaTuote.id >= MaksimiTuoteId) {
        this.yleinen.muokattavaTuote.id = null
      }
    },
  },
}

</script>

<style lang="scss" scoped>

 .v-text-field, .v-select {
   padding-top: 0px;
   margin-top: 4px;
 }

 .hidden {
   visibility: hidden;
 }

</style>
