var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "white--text",
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "asianumeroAlkaen" } },
                            [_vm._v(" Asianumero ")]
                          ),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("v-text-field", {
                              staticClass: "comboField__field",
                              attrs: {
                                id: "asianumeroAlkaen",
                                type: "number",
                                label: _vm.$t("common:starting"),
                                color: "white",
                                clearable: "",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.asianumeroAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "asianumeroAlkaen", $$v)
                                },
                                expression: "value.asianumeroAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("v-text-field", {
                              staticClass: "comboField__field",
                              attrs: {
                                id: "asianumeroPaattyen",
                                type: "number",
                                label: _vm.$t("common:ending"),
                                color: "white",
                                clearable: "",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.asianumeroPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "asianumeroPaattyen", $$v)
                                },
                                expression: "value.asianumeroPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "kirjauspaivaAlkaen" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common:transactionDate")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "kirjauspaivaAlkaen",
                                label: _vm.$t("common:starting"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.kirjauspaivaAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "kirjauspaivaAlkaen", $$v)
                                },
                                expression: "value.kirjauspaivaAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "kirjauspaivaPaattyen",
                                label: _vm.$t("common:ending"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.kirjauspaivaPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.value,
                                    "kirjauspaivaPaattyen",
                                    $$v
                                  )
                                },
                                expression: "value.kirjauspaivaPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "laskunErapaivaAlkaen" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "common:formSearchPerinta.invoiceDueDate"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "laskunErapaivaAlkaen",
                                label: _vm.$t("common:starting"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.laskunErapaivaAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.value,
                                    "laskunErapaivaAlkaen",
                                    $$v
                                  )
                                },
                                expression: "value.laskunErapaivaAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "laskunErapaivaPaattyen",
                                label: _vm.$t("common:ending"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.laskunErapaivaPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.value,
                                    "laskunErapaivaPaattyen",
                                    $$v
                                  )
                                },
                                expression: "value.laskunErapaivaPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "laskunViite" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:invoiceRef")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "laskunViite",
                              type: "text",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.laskunViite,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "laskunViite", $$v)
                              },
                              expression: "value.laskunViite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "laskunumero" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:invoiceNo")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "laskunumero",
                              type: "text",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.laskunumero,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "laskunumero", $$v)
                              },
                              expression: "value.laskunumero",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "laskupaivaAlkaen" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:invoiceDate")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "laskupaivaAlkaen",
                                label: _vm.$t("common:starting"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.laskupaivaAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "laskupaivaAlkaen", $$v)
                                },
                                expression: "value.laskupaivaAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("InputfieldDatePicker", {
                              attrs: {
                                label: _vm.$t("common:ending"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.laskupaivaPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "laskupaivaPaattyen", $$v)
                                },
                                expression: "value.laskupaivaPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "paamies" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("common:creditor")) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "paamies",
                              color: "white",
                              label: _vm.$t(
                                "common:formSearchPerinta.nameOrNumber"
                              ),
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.paamies,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "paamies", $$v)
                              },
                              expression: "value.paamies",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "perinnanViite" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "common:formSearchPerinta.collectionRef"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "perinnanViite",
                              type: "number",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.perinnanViite,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "perinnanViite", $$v)
                              },
                              expression: "value.perinnanViite",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "sopimusnumero" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common:contractNumber")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "sopimusnumero",
                              type: "number",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.sopimusnumero,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "sopimusnumero", $$v)
                              },
                              expression: "value.sopimusnumero",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "tila" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("common:status")) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-select", {
                            attrs: {
                              id: "tila",
                              items: _vm.tilat,
                              "item-text": "label",
                              "item-value": "terms",
                              multiple: "",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.tila,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "tila", $$v)
                              },
                              expression: "value.tila",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "tyyppi" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("common:type")) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-select", {
                            attrs: {
                              id: "tyyppi",
                              items: _vm.tyypit,
                              "item-text": "label",
                              "item-value": "terms",
                              multiple: "",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.tyyppi,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "tyyppi", $$v)
                              },
                              expression: "value.tyyppi",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "velallinen" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:debtor")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "velallinen",
                              label: _vm.$t(
                                "common:formSearchPerinta.nameSocialIdorYid"
                              ),
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.velallinen,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "velallinen", $$v)
                              },
                              expression: "value.velallinen",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "asiakkaanTyyppi" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common:debtor")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-select", {
                            attrs: {
                              id: "velallinenTyyppi",
                              items: _vm.velallinenTyypit,
                              "item-text": "label",
                              "item-value": "terms",
                              multiple: "",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.velallinenTyyppi,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "velallinenTyyppi", $$v)
                              },
                              expression: "value.velallinenTyyppi",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }