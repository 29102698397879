<template>
  <v-tooltip
    open-delay="500"
    bottom
  >
    <template v-slot:activator="{ on }">
      <DefListItem
        :label-class="labelClass"
        :value-class="valueClass"
        :event-listener="on"
      >
        <template v-slot:label>
          <slot name="label" />
        </template>
        <template v-slot:value>
          <slot name="value" />
        </template>
      </DefListItem>
    </template>
    <template
      v-slot:default
    >
      <p class="tooltip-hover">
        {{ tooltip }}
      </p>
    </template>
  </v-tooltip>
</template>

<script>

import DefListItem from '@/components/DefListItem'

export default {
  name: 'DefListTooltipItem',
  components: {
    DefListItem,
  },
  props: {
    ...DefListItem.props,
    tooltip: {
      type: String,
      required: true,
    }
  },
}

</script>

<style scoped>

.tooltip-hover {
  max-width: 400px;
  margin: 0;
  padding: 0;
}

</style>

