<template>
  <v-form
    v-model="valid"
    @submit.prevent="submit"
  >
    <slot name="default"></slot>
    <slot name="painikkeet">
      <v-row
        class="mt-4 pa-3"
        justify="center"
      >
        <v-btn
          block
          class="mx-4"
          large
          color="success"
          :disabled="!valid"
          @click="$emit('seuraava')"
        >
          Seuraava
        </v-btn>
      </v-row>
    </slot>
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'FormPalveluhakemusBase',
  mixins: [FormMixin],
}

</script>

<style lang="scss" scoped>

</style>
