var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "div",
        { staticClass: "comboField" },
        [
          _c("v-menu", {
            attrs: { "close-on-content-click": false, "max-width": "290" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-text-field",
                      _vm._g(
                        {
                          attrs: {
                            label: _vm.$t("common:startingDate"),
                            value: _vm.$date(_vm.value.alkamispaiva),
                            readonly: "",
                          },
                        },
                        on
                      )
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function () {
                  return [
                    _c("v-date-picker", {
                      attrs: {
                        locale: _vm.$languageLocale(),
                        "first-day-of-week": "1",
                        "no-title": "",
                      },
                      on: {
                        change: function ($event) {
                          _vm.alkamispaivaMenu = false
                        },
                      },
                      model: {
                        value: _vm.value.alkamispaiva,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "alkamispaiva", $$v)
                        },
                        expression: "value.alkamispaiva",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.alkamispaivaMenu,
              callback: function ($$v) {
                _vm.alkamispaivaMenu = $$v
              },
              expression: "alkamispaivaMenu",
            },
          }),
          _c("span", { staticClass: "comboField__separator" }, [_vm._v("–")]),
          _c("v-menu", {
            attrs: { "close-on-content-click": false, "max-width": "290" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-text-field",
                      _vm._g(
                        {
                          attrs: {
                            label: _vm.$t("common:endingDate"),
                            value: _vm.$date(_vm.value.loppumispaiva),
                            readonly: "",
                            rules: [_vm.validoiPaattymispaiva],
                            placeholder: _vm.$t("common:validForNow"),
                            "persistent-placeholder": "",
                            clearable: "",
                          },
                          on: {
                            "click:clear": function ($event) {
                              _vm.value.loppumispaiva = null
                            },
                          },
                        },
                        on
                      )
                    ),
                  ]
                },
              },
              {
                key: "default",
                fn: function () {
                  return [
                    _c("v-date-picker", {
                      attrs: {
                        locale: _vm.$languageLocale(),
                        "first-day-of-week": "1",
                        "no-title": "",
                      },
                      on: {
                        change: function ($event) {
                          _vm.paattymispaivaMenu = false
                        },
                      },
                      model: {
                        value: _vm.value.loppumispaiva,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "loppumispaiva", $$v)
                        },
                        expression: "value.loppumispaiva",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.paattymispaivaMenu,
              callback: function ($$v) {
                _vm.paattymispaivaMenu = $$v
              },
              expression: "paattymispaivaMenu",
            },
          }),
        ],
        1
      ),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: _vm.$t("common:definition"),
          rules: [_vm.$validationRules.required],
        },
        model: {
          value: _vm.value.selite,
          callback: function ($$v) {
            _vm.$set(_vm.value, "selite", $$v)
          },
          expression: "value.selite",
        },
      }),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
          "submit-teksti": _vm.$t("common:saveChanges"),
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }