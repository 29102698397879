var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: "Sähköpostiosoite",
          type: "email",
          rules: [_vm.$validationRules.required, _vm.$validationRules.email],
          outlined: "",
          required: "",
        },
        model: {
          value: _vm.value.email,
          callback: function ($$v) {
            _vm.$set(_vm.value, "email", $$v)
          },
          expression: "value.email",
        },
      }),
      _c(
        "v-btn",
        {
          staticClass: "ml-0",
          attrs: {
            type: "submit",
            disabled: !_vm.valid || _vm.loading,
            loading: _vm.loading,
            block: "",
            large: "",
            color: "success",
          },
        },
        [_vm._v(" Lähetä ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }