var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper" },
    [
      _vm.ehto
        ? [
            _c(
              "router-link",
              {
                class: _vm.linkkiClass,
                attrs: { to: { name: _vm.to.name, params: _vm.to.params } },
              },
              [_vm._t("default")],
              2
            ),
          ]
        : _vm.renderoiIlmanLinkkia
        ? [_vm._t("default")]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }