import Vue from 'vue'

const ibantools = require('ibantools')
import { laskeDesimaalipaikat } from '@/utils/misc'

const validationRules = {
  required: v => !!v || 'Pakollinen tieto puuttuu',
  requiredLoose: v => (v !== null && v !== undefined && v !== '') || 'Pakollinen tieto puuttuu',
  email: v => /.+@.+\..{2,}/.test(v) || v === '' || 'Tarkista sähköpostiosoitteen muotoilu',
  emailArray: v => (Array.isArray(v) ? v.every(i => /.+@.+\..{2,}/.test(i)) : v === '') || 'Tarkista sähköpostiosoitteen muotoilu',
  zeroOrBigger: v => v >= 0 || 'Arvon tulee olla 0 tai suurempi',
  biggerThanZero: v => v > 0 || 'Arvon tulee olla suurempi kuin 0',
  smallerThanOrEqualTo: (v, threshold) => v <= threshold || `Arvo ei voi olla suurempi kuin ${threshold}`,
  requiredLength: (v, threshold = 1) => v.length >= threshold || `Syötä vähintään ${threshold} merkkiä`,
  requiredArrayLength: (v, threshold = 1) => Array.isArray(v) && v.length >= threshold || `Syötä vähintään ${threshold}`,
  requiredObject: v => v && Object.keys(v).length != 0 || 'Pakollinen tieto puuttuu',
  tooManyCharacters: (v, threshold) => v <= threshold || `Sallittu merkkimäärä (${threshold}) ylitetty`,
  numberOrEmpty: v => (v === undefined || !isNaN(v)) || 'Syötä numero tai jätä tyhjäksi',
  isInteger: v => (Number.isInteger(Number(v)) || !v) || 'Arvon tulee olla kokonaisluku',
  isValidIBAN: v => ibantools.isValidIBAN(typeof v === 'string' ? v.replace(/\s+/g, '') : v) || 'Epäkelpo IBAN',
  isValidBIC: v => ibantools.isValidBIC(v) || 'Epäkelpo BIC',
  maxDecimalPlaces: (v, paikat) => { return laskeDesimaalipaikat(v) <= paikat  || `Anna korkeintaan ${paikat} desimaalipaikkaa` },
  notIsNaN: v => !isNaN(v) || 'Anna numeerinen arvo.',
}

// Create Vue plugin
const plugin = {
  install() {
    Vue.validationRules = validationRules
    Vue.prototype.$validationRules = validationRules
  }
}

Vue.use(plugin)

export default validationRules
