var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      dense: "",
      items: _vm.items,
      headers: _vm.tableHeadersAsiakasMuistiinpanot,
      "hide-default-footer": _vm.items.length <= 5,
      "items-per-page": 5,
      "no-data-text": "Ei muistiinpanoja",
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("TableRow", [
              _c("td", [_vm._v(" " + _vm._s(item.lahettaja) + " ")]),
              _c("td", { staticClass: "text-no-wrap text-right" }, [
                _vm._v(
                  " " + _vm._s(_vm.$date(item.aika, "D.M.YYYY HH:mm")) + " "
                ),
              ]),
              _c("td", { staticClass: "text-no-wrap text-right" }, [
                item.lasku
                  ? _c(
                      "span",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "laskutus-nayta",
                                params: { id: item.lasku.id },
                              },
                            },
                          },
                          [_vm._v(" Lasku " + _vm._s(item.lasku.nro) + " ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("td", { staticClass: "text-wrap max-width" }, [
                _vm._v(" " + _vm._s(item.sisalto) + " "),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }