<template>
  <DefList :bordered="(true)">
    <DefListItem v-if="value.nimi">
      <template v-slot:label>Nimi</template>
      <template v-slot:value>
        {{ value.nimi }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.paamies">
      <template v-slot:label >Päämies</template>
      <template v-slot:value>
        {{ value.paamies.nimi }} ({{ value.paamies.id }})
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>{{ $sovellusIdOtsake }}</template>
      <template v-slot:value>
        {{ value.id }}
      </template>
    </DefListItem>
  </DefList>
</template>

<script>

export default {
  name: 'OffCanvasDetailsTuoteryhma',
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
