import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
// eslint-disable-next-line
import { i18n, getLanguageLocale, getLanguage, getFlexmonsterKaannokset } from './utils/lang'

import './plugins/api'
import './plugins/apiUlkoinen'
import './plugins/permission'
import './plugins/validationRules'
import './plugins/date-fns'
import { format as dateFnsFormat } from 'date-fns'
import './plugins/sweetalert'
import './plugins/analytics'
import './plugins/toastification'
import router from './utils/router'
import store from './store/'
import Dinero from 'dinero.js'
import './utils/filters/formatSum'
import formatSum from '@/utils/filters/formatSum'
import './utils/filters/formatSumToFixed2'
import formatSumToFixed2 from '@/utils/filters/formatSumToFixed2'
import './utils/filters/cleanLineBreaks'
import Nl2br from 'vue-nl2br'
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import VueSocketIOExt from 'vue-socket.io-extended'
import VModal from 'vue-js-modal'
import { io } from 'socket.io-client'
import {
  AuthenticationError,
  HttpError,
  PasswordResetExpiredError,
  SallittuKokoYlitettyError,
  ValidointiError,
} from '@/utils/errors'
import {
  doRequestWithTimeout,
  muotoileDesimaaliluku,
  naytaDjangonVirheet,
  palautaDjangonVirheet,
  naytaInfoilmoitus,
  naytaOnnistumisilmoitus,
  naytaTiedostonlataamisdialog,
  naytaVaroitusilmoitus,
  naytaVirheilmoitus,
  oletusVirheenkasittely,
  onkoUiVirhe,
  rivitaEmail,
  sentryCaptureCustom,
} from '@/utils/misc'
// Global components
import CardDialog from '@/components/CardDialog'
import CardDialogModal from '@/components/CardDialogModal'
import CardPahoittelu from '@/components/CardPahoittelu'
import CardTitle from '@/components/CardTitle'
import CardVaroitus from '@/components/CardVaroitus'
import DataTableLaskuliite from '@/components/DataTableLaskuliite'
import DefList from '@/components/DefList'
import DefListItem from '@/components/DefListItem'
import DefListTooltipItem from '@/components/DefListTooltipItem'
import DenseToggleButton from '@/components/DenseToggleButton'
import InlineList from '@/components/InlineList'
import InlineListItem from '@/components/InlineListItem'
import MenuContext from '@/components/MenuContext'
import PerustietoTooltip from '@/components/PerustietoTooltip'
import PeruutaHyvaksyPainikerivi from '@/components/PeruutaHyvaksyPainikerivi'
import RouterLinkEhdollinen from '@/components/RouterLinkEhdollinen'
import Spinner from '@/components/Spinner'
import StatusIcon from '@/components/StatusIcon'
import TableRow from '@/components/TableRow'
import TooltipInfo from '@/components/TooltipInfo'
import TransitionSlidePanel from '@/components/TransitionSlidePanel'
import ViewTopBarBase from '@/components/ViewTopBarBase'
import { vfmPlugin } from 'vue-final-modal'

Vue.component('CardDialog', CardDialog)
Vue.component('CardDialogModal', CardDialogModal)
Vue.component('CardPahoittelu', CardPahoittelu)
Vue.component('CardTitle', CardTitle)
Vue.component('CardVaroitus', CardVaroitus)
Vue.component('DataTableLaskuliite', DataTableLaskuliite)
Vue.component('DefList', DefList)
Vue.component('DefListItem', DefListItem)
Vue.component('DefListTooltipItem', DefListTooltipItem)
Vue.component('DenseToggleButton', DenseToggleButton)
Vue.component('InlineList', InlineList)
Vue.component('InlineListItem', InlineListItem)
Vue.component('MenuContext', MenuContext)
Vue.component('nl2br', Nl2br)
Vue.component('PeruutaHyvaksyPainikerivi', PeruutaHyvaksyPainikerivi)
Vue.component('PerustietoTooltip', PerustietoTooltip)
Vue.component('RouterLinkEhdollinen', RouterLinkEhdollinen)
Vue.component('Spinner', Spinner)
Vue.component('StatusIcon', StatusIcon)
Vue.component('TableRow', TableRow)
Vue.component('TooltipInfo', TooltipInfo)
Vue.component('TransitionSlidePanel', TransitionSlidePanel)
Vue.component('ViewTopBarBase', ViewTopBarBase)

const VUE_APP_SENTRY_URL = process.env.VUE_APP_SENTRY_URL
const VUE_APP_SENTRY_BYPASS = parseInt(process.env.VUE_APP_SENTRY_BYPASS)

if (VUE_APP_SENTRY_URL) {
  Sentry.init({
    dsn: VUE_APP_SENTRY_URL,
    release: 'vue-anitta-online@' + process.env.VUE_APP_PACKAGE_VERSION,
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
    beforeSend: event => {
      if (VUE_APP_SENTRY_BYPASS) {
        console.log('VUE_APP_SENTRY_BYPASS asetettu päälle -> ohitetaan Sentry-pyyntö.')
        return null
      }

      return event
    }
  })
} else {
  console.log('main.js: Ympäristömuuttujaa VUE_APP_SENTRY_URL ei ole määritetty')
}

// Globaalit poikkeuskäsittelijät
Vue.config.errorHandler = function (error, vm, info) {
  console.log('main.js: Vue.config.errorHandler')
  const tags = {
    vueInfo: info,
    vueComponent: vm ? vm.$vnode.tag : null,
    handler: 'Vue.config.errorHandler',
  }
  sentryCaptureCustom(error, {}, tags)
}

window.onerror = function (msg, url, line, col, error) {
  console.log('main.js: window.onerror')
  const tags = {
    handler: 'window.onerror',
  }
  sentryCaptureCustom(error, {}, tags)
}

window.onunhandledrejection = function (event) {
  console.log('main.js: unhandledrejection')
  console.log('event', event)
  const tags = {
    handler: 'unhandledrejection',
  }

  sentryCaptureCustom(event.reason, {}, tags)
  event.preventDefault()
}

// Kustomoidut poikkeukset
Vue.prototype.$AuthenticationError = AuthenticationError
Vue.prototype.$HttpError = HttpError
Vue.prototype.$PasswordResetExpiredError = PasswordResetExpiredError
Vue.prototype.$SallittuKokoYlitettyError = SallittuKokoYlitettyError
Vue.prototype.$ValidointiError = ValidointiError

// Globaalit funktiot
Vue.prototype.$dateFnsFormat = dateFnsFormat
Vue.prototype.$doRequestWithTimeout = doRequestWithTimeout
Vue.prototype.$formatSum = formatSum
Vue.prototype.$formatSumToFixed2 = formatSumToFixed2
Vue.prototype.$muotoileDesimaaliluku = muotoileDesimaaliluku
Vue.prototype.$naytaInfoilmoitus = naytaInfoilmoitus
Vue.prototype.$naytaOnnistumisilmoitus = naytaOnnistumisilmoitus
Vue.prototype.$naytaTiedostonlataamisdialog = naytaTiedostonlataamisdialog
Vue.prototype.$naytaVaroitusilmoitus = naytaVaroitusilmoitus
Vue.prototype.$naytaVirheilmoitus = naytaVirheilmoitus
Vue.prototype.$rivitaEmail = rivitaEmail
Vue.prototype.$oletusVirheenkasittely = oletusVirheenkasittely
Vue.prototype.$onkoUiVirhe = onkoUiVirhe
Vue.prototype.$sentry = Sentry
Vue.prototype.$sentryCaptureCustom = sentryCaptureCustom
Vue.prototype.$naytaDjangonVirheet = naytaDjangonVirheet
Vue.prototype.$palautaDjangonVirheet = palautaDjangonVirheet
Vue.prototype.$languageLocale = getLanguageLocale
Vue.prototype.$language = getLanguage
Vue.prototype.$flexmonsterKaannokset = getFlexmonsterKaannokset

// Globaalit muuttujat
Vue.prototype.$sovellusIdOtsake = `${process.env.VUE_APP_INSTANCE_TITLE}-ID`

// Socket.io
const VUE_APP_SOCKETIO_URL = process.env.VUE_APP_SOCKETIO_URL
if (VUE_APP_SOCKETIO_URL) {
  const socket = io(VUE_APP_SOCKETIO_URL)
  socket.onerror((error) => {
    console.log('socket error', error)
    sentryCaptureCustom(error, {}, { handler: 'socket.onerror' })
  })
  Vue.use(VueSocketIOExt, socket, { store })
}
Vue.use(VModal, {
  dynamic: true, 
  injectModalsContainer: true,
});
Vue.use(vfmPlugin)

// Dinero config
Dinero.globalLocale = 'fi-FI'
Dinero.defaultCurrency = 'EUR'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  i18n: i18n,
  render: h => h(App),
}).$mount('#app')
