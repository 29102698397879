<template>
  <v-card
    class="pa-6"
    :class="cardClass"
  >
    <v-row class="mx-0">
      <v-col
        md="3"
        class="pa-0 pr-4"
      >
        <slot name="col1">
          <OsapuoltenTiedotKappale
            v-if="paamies"
            :paamies="paamies"
            :vastapuoli="vastapuoli"
            :ei-vastapuoli-linkkia="eiVastapuoliLinkkia"
            :vastapuoli-rooli="vastapuoliRooli"
            :vastapuoli-route="vastapuoliRoute"
          >
            <template v-slot:vastaanottaja>
              <slot name="vastaanottaja" />
            </template>
          </OsapuoltenTiedotKappale>
        </slot>
      </v-col>
      <v-col
        md="3"
        class="py-0 pl-0 pr-6"
      >
        <slot name="col2" />
      </v-col>
      <v-col
        md="3"
        class="py-0 px-3"
      >
        <slot name="col3" />
      </v-col>
      <v-col
        md="3"
        class="py-0 pl-6 pr-0"
      >
        <slot name="col4" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import OsapuoltenTiedotKappale from './OsapuoltenTiedotKappale.vue'
export default {
  name: 'TietueenPerustiedotCard',
  components: {
    OsapuoltenTiedotKappale
  },
  props: {
    ...OsapuoltenTiedotKappale.props,
    cardClass: {
      type: String,
      required: false,
      default () {
        return 'pa-6'
      },
    },
    tietue: {
      type: Object,
      required: false,
      default () {
        return null
      },
    },
  },
}
</script>

<style>

</style>
