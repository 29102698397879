<template>
  <v-app class="grey lighten-4">
    <BgImg
      v-if="showBackgroundImage"
      :img="backgroundImage"
      :pos-x="backgroundImagePosX"
      :pos-y="backgroundImagePosY"
      :opacity="backgroundOpacity"
      :overlay-color="backgroundOverlayColor"
      :overlay-opacity="backgroundOverlayOpacity"
    />
    <VersionRibbon :text="ribbonText" />
    <SidebarLeft v-if="sidebarEnabled" />

    <v-main>
      <v-container
        class="pa-0"
        fluid
      >
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import BgImg from '@/components/BgImg'
import VersionRibbon from '@/components/VersionRibbon'
import SidebarLeft from '@/components/SidebarLeft'

export default {
  name: 'App',
  components: {
    BgImg,
    VersionRibbon,
    SidebarLeft,
  },
  data () {
    return {
      backgroundImage: undefined,
      backgroundImagePosX: undefined,
      backgroundImagePosY: undefined,
      ribbonText: '',
    }
  },
  computed: {
    showBackgroundImage () {
      return (typeof this.$route.meta.showBackgroundImage !== 'undefined') ? this.$route.meta.showBackgroundImage : false
    },
    backgroundOpacity () {
      return (typeof this.$route.meta.backgroundOpacity !== 'undefined') ? this.$route.meta.backgroundOpacity : 1
    },
    backgroundOverlayColor () {
      return (typeof this.$route.meta.backgroundOverlayColor !== 'undefined') ? this.$route.meta.backgroundOverlayColor : process.env.VUE_APP_LOGIN_BACKGROUND_IMAGE_OVERLAY_COLOR
    },
    backgroundOverlayOpacity () {
      return (typeof this.$route.meta.backgroundOverlayOpacity !== 'undefined') ? this.$route.meta.backgroundOverlayOpacity : process.env.VUE_APP_LOGIN_BACKGROUND_IMAGE_OVERLAY_OPACITY
    },
    sidebarEnabled () {
      return (typeof this.$route.meta.sidebarEnabled !== 'undefined') ? this.$route.meta.sidebarEnabled : true
    },
  },
  mounted () {
    this.backgroundImage = (process.env.VUE_APP_LOGIN_BACKGROUND_IMAGE) ? process.env.VUE_APP_LOGIN_BACKGROUND_IMAGE : undefined
    this.backgroundImagePosX = (process.env.VUE_APP_LOGIN_BACKGROUND_IMAGE_POS_X) ? process.env.VUE_APP_LOGIN_BACKGROUND_IMAGE_POS_X : undefined
    this.backgroundImagePosY = (process.env.VUE_APP_LOGIN_BACKGROUND_IMAGE_POS_Y) ? process.env.VUE_APP_LOGIN_BACKGROUND_IMAGE_POS_Y : undefined

    this.ribbonText = (process.env.VUE_APP_DEV_MODE == 1) ? 'Testi' : null
  },
}
</script>

<style lang="scss">

  @import '@/assets/anitta-icons/style.scss';
  @import '@/assets/scss/vuetifyCustomStyles.scss';

  body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .strikethrough {
    text-decoration: line-through;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .max-width {
    width: 100%;

    &--1200 {
      max-width: 1200px
    }
  }

  .break-all {
    word-break: break-all;
  }


  /* Poistaa nuolipainikkeet numerokentistä */
  input {
    &[type="number"] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .sticky {
    z-index: 5;
    position: sticky;
    top: 0;

    &--bottom {
      top: auto;
      bottom: 0;
    }
  }

  .comboField {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    &__field {
      flex: 1 1 auto;
    }

    &__separator {
      padding: 0 1em;
    }
  }

  .shadow--inset {
    box-shadow: inset 0 0 2em rgba(0,0,0,.2);
  }

  .icon {
    &--inline {
      vertical-align: middle;
      margin-right: .4em;
    }
  }

  .visibility-hidden {
    visibility: hidden;
  }

  .hidden-child {
    visibility: hidden;
    transition: 0s visibility;
  }

  .show-hidden-child-on-hover:hover .hidden-child {
    visibility: visible;
    transition-delay: 50ms;
  }

  /* Laskupaperia muistuttavan kortin pohja */
  .paper {
    margin: auto;
    max-width: 1200px;
    background: #fff;
    border-radius: .3em;
    box-shadow: 0 0 1.5em rgba(0,0,0,.2);

    &__title {
      display: block;
      font-weight: bold;
      font-size: 2.2em;
      line-height: 1;
      margin-bottom: 1em;
      text-transform: uppercase;
    }

    .v-text-field {
      margin-top: 0;
    }
  }

  #app {
    background-color: map-get($grey, 'lighten-4');
  }

  /* Sweetalert-kustomoinnit */
  div > .swal2-title {
    margin-top: 8px;
    margin-bottom: 0px;
    font-size: 16px;
  }

  div > .swal2-content {
    margin: 4px 0;
    margin-top: 16px;
    font-size: 16px;
  }


  .teksti-iframe {
  height: 70vh;
  border: 0;
}

</style>
