var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tekstiOnPitka
    ? _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  return [
                    _c("span", _vm._g({}, on), [
                      _vm._v(
                        " " + _vm._s(_vm.lyhennettyPerustietoteksti) + " "
                      ),
                    ]),
                  ]
                },
              },
            ],
            null,
            false,
            1126558253
          ),
        },
        [
          _c("p", { staticClass: "tooltip-hover" }, [
            _vm._v(" " + _vm._s(_vm.teksti) + " "),
          ]),
        ]
      )
    : _c("span", [_vm._v(_vm._s(_vm.teksti))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }