<template>
  <v-tooltip
    content-class="body-1 pa-4"
    max-width="400"
    bottom
  >
    <template v-slot:activator="{ on }">
      <v-icon
        class="tooltip-info__icon-hover"
        :class="iconClass"
        :color="iconColor"
        v-on="on"
      >
        info
      </v-icon>
    </template>
    <template v-slot:default>
      <span>{{ text }}</span>
    </template>
  </v-tooltip>
</template>

<script>

export default {
  name: 'TooltipInfo',
  props: {
    iconClass: {
      type: String,
      default: '',
    },
    iconColor: {
     type: String,
      required: false,
      default () {
        return ''
      },
    },
    text: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
  },
}

</script>

<style lang="scss" scoped>

.tooltip-info {
  &__icon-hover:hover {
    opacity: 0.7;
  }
}
</style>
