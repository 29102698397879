var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "error404" },
    [
      _c(
        "div",
        { staticClass: "sticky" },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary", flat: "", tabs: "" } },
            [
              _vm.$route.meta.title
                ? _c("v-toolbar-title", [
                    _vm._v(" " + _vm._s(_vm.$route.meta.title) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-container", { attrs: { fluid: "" } }, [
        _c("p", [
          _c("strong", [
            _vm._v(
              "Sinulla ei ole tarvittavia käyttöoikeuksia pyydetyn toiminnon suorittamiseksi!"
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }