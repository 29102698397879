/**
 * Omien järjestelmien ulkopuolisia yhteyksiä muodostava API-moduuli.
 */

import Vue from 'vue'
import fetch from '@apicase/adapter-fetch'
import { ApiService } from '@apicase/services'

const YTJ = new ApiService({
  adapter: fetch,
  url: 'https://avoindata.prh.fi/bis/v1',
  mode: 'cors',
})

const apiModule = {
  YTJ,
}
// Create Vue plugin
const apiPlugin = {
  install () {
    Vue.apiUlkoinen = apiModule
    Vue.prototype.$apiUlkoinen = apiModule
  }
}

Vue.use(apiPlugin)

export default apiModule
