<template>
  <v-data-table
    dense
    :items="items"
    :headers="tableHeadersAsiakasMuistiinpanot"
    :hide-default-footer="items.length <= 5"
    :items-per-page="5"
    no-data-text="Ei muistiinpanoja"
  >
    <template v-slot:item="{ item }">
      <TableRow>
        <td>
          {{ item.lahettaja }}
        </td>
        <td class="text-no-wrap text-right">
          {{ $date(item.aika, 'D.M.YYYY HH:mm') }}
        </td>
        <td class="text-no-wrap text-right">
          <span v-if="item.lasku">
            <router-link
              :to="{ name: 'laskutus-nayta', params: { id: item.lasku.id } }"
            >
              Lasku {{ item.lasku.nro }}
            </router-link>
          </span>
        </td>
        <td class="text-wrap max-width">
          {{ item.sisalto }}
        </td>
      </TableRow>
    </template>
  </v-data-table>
</template>

<script>

import { TableHeadersAsiakasMuistiinpanotLaskunLuonnille } from '@/utils/tableHeaders'

export default {
  name: 'AsiakasMuistiinpanotDatatable',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      tableHeadersAsiakasMuistiinpanot: TableHeadersAsiakasMuistiinpanotLaskunLuonnille,
    }
  },
}
</script>

<style>

</style>
