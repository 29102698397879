<template>
  <div class="offCanvasLasku">
    <OffCanvasToolbar
      :toolbar-title="$t('common:offCanvasSopimus.contractInfo')"
      to-name="sopimus-nayta"
      :to-id="value ? value.id : null"
      tooltip-title="sopimus"
      @close-button-click="$emit('close-button-click')"
    />
    <v-container class="grey lighten-5 px-8">
      <OffCanvasDetailsSopimus v-model="value" />
    </v-container>
    <v-container class="px-8">
      <SopimusYhteenveto
        :title-text="$t('common:offCanvasSopimus.summaryOfOpen')"
        :sopimus="value"
      />
    </v-container>
  </div>
</template>

<script>

import ClickOutside from 'vue-click-outside'
import OffCanvasDetailsSopimus from '@/components/OffCanvasDetailsSopimus'
import OffCanvasToolbar from '@/components/OffCanvasToolbar'
import SopimusYhteenveto from '@/components/SopimusYhteenveto'

export default {
  name: 'OffCanvasSopimus',
  directives: {
    ClickOutside,
  },
  components: {
    OffCanvasDetailsSopimus,
    OffCanvasToolbar,
    SopimusYhteenveto,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
