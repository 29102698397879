import BaseOptimizedCountSelaa from "@/store/modules/baseOptimizedCountSelaa";
import { HttpError } from '@/utils/errors'
import { doRequestWithTimeout } from '@/utils/misc'

export default class RaportitSelaa extends BaseOptimizedCountSelaa {
  state () {
    return {
      ...super.state(),
      apiName: 'Raportit',
      searchTerms: { paamies: [] },
    }
  }

  mutations () {
    return {
      ...super.mutations(),
      resetViewViewKohtainen (state) {
        state.searchTerms = { paamies: [] }
      }
    }
  }

  actions () {
    return {
      ...super.actions(),
      async lataaRaportti ({ getters }, id) {
        // jostain syystä tätä kutsutaan ilman parametria?
        if (!id) {
          console.log('RaportitSelaa.lataaRaportti(kutsuttu undefined):lla')
          return
        }

        const api = getters.apiModule.getInstance('Raportti')

        const request = await doRequestWithTimeout(
          api,
          {
            params: {
              id: '' + id,
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new HttpError(request)

        return request
      }
    }
  }
}
