<template>
  <div class="welcometext">
    <template v-if="vueAppClass === 'uuva'">
      <CardTitle text="Tervetuloa" />
      <p class="subtitle-1">
        Tervetuloa käyttämään Uuva Cloudia.
      </p>
      <p>
        <span v-if="oikeusAnittaOnlineen">Uuva Cloudilla hoidat jatkossa kaiken saman, ja paljon enemmän, kuin vanhalla Uuva Onlinella. </span>Uuva Cloud on käyttäjälähtöisesti kehitetty – pyrimme täyttämään haastavimmatkin tarpeesi laskun elinkaaren hallintaan liittyen.
      </p>
      <p>
        Kuulisimme mielellämme, mitä mieltä olet. <a
          :href="palautelomakelinkki"
          target="_blank"
        >Voit antaa meille palautetta</a> ja näin auttaa palvelun kehittämisessä.
      </p>
      <p v-if="anittaOnlineLinkki && oikeusAnittaOnlineen">
        Siirry vanhaan <a :href="anittaOnlineLinkki">Uuva Onlineen</a>.
      </p>
    </template>
    <h1
      v-else
      class="mb-6"
    >
      Tervetuloa käyttämään {{ instanceTitle }} -palvelua.
    </h1>
  </div>
</template>

<script>

export default {
  name: 'WelcomeText',
  data () {
    return {
      instanceTitle: '',
      palautelomakelinkki: '',
      vueAppClass: '',
      anittaOnlineLinkki: ''
    }
  },
  computed: {
    oikeusAnittaOnlineen () {
      return this.$permission.checkSinglePerm('anitta_user.onlinepalvelu')
    }
  },
  mounted () {
    this.instanceTitle = process.env.VUE_APP_INSTANCE_TITLE
    // Ohjataan Anitta Cloudin palautteeseen mikäli palautelomakelinkkiä ei ole määritelty
    this.palautelomakelinkki = process.env.VUE_APP_PALAUTELOMAKELINKKI ? process.env.VUE_APP_PALAUTELOMAKELINKKI : "https://docs.google.com/forms/d/e/1FAIpQLScaOSqN7e-04av3Vmj_IbsWbJ65YF4sDJqOulJ0G9_5YnnFfQ/viewform?usp=sf_link"
    this.vueAppClass = process.env.VUE_APP_CLASS
    this.anittaOnlineLinkki = process.env.VUE_APP_ANITTA_ONLINE_URL || ''
  },
}

</script>

<style lang="scss" scoped>

</style>
