import { Velallinen } from '@/class/Velallinen'

const TILA_VAPAAEHTOINEN_PERINTA = 'VP'
const TILA_OIKEUDELLINEN_PERINTA = 'OP'
const TILA_ULOSOTTOPERINTA = 'UO'
const TILA_LOPPUUN_PERITTY = 'LO'
const TILA_MYYTY = 'MY'
const TILA_LOPETETTU = 'XX'
const TILA_PERUTTU = 'PE'

export const Toimeksianto = class {
  static get TILA_VAPAAEHTOINEN_PERINTA () {
    return TILA_VAPAAEHTOINEN_PERINTA
  }

  static get TILA_OIKEUDELLINEN_PERINTA () {
    return TILA_OIKEUDELLINEN_PERINTA
  }

  static get TILA_ULOSOTTOPERINTA () {
    return TILA_ULOSOTTOPERINTA
  }

  static get TILA_LOPPUUN_PERITTY () {
    return TILA_LOPPUUN_PERITTY
  }

  static get TILA_MYYTY () {
    return TILA_MYYTY
  }

  static get TILA_LOPETETTU () {
    return TILA_LOPETETTU
  }

  static get TILA_PERUTTU () {
    return TILA_PERUTTU
  }

  // Read-only -kenttiä ei lähetetä luonnin tai muokkauksen yhteydessä backendiin
  constructor (lisaaTyhjaRivi = false) {
    this.id = null
    this.muistutetaanko = false // Halutaanko perintätoimiston muistuttavan saatavista
    this.muistutettu = false // Onko laskuttaja lähettänyt muistutuksen
    this.paamies = {}
    this.pm_asiakasnumero = ''
    this.rivit = []
    this.saatavalaji = 'laskusaatava'
    this.tiedostot = []
    this.velallinen = new Velallinen()

    if (lisaaTyhjaRivi) this.rivit.push(new Saatavarivi())
  }

  lisaaRivi (rowIndex) {
    if (typeof rowIndex !== 'undefined') {
      // Saatavarivin lisäys olemassaolevalla rivillä oltaessa käyttäjän painaessa enteriä.
      this.rivit.splice(rowIndex + 1, 0, new Saatavarivi())
    } else {
      this.rivit.push(new Saatavarivi())
    }
  }

  poistaRivi (rowIndex) {
    this.rivit.splice(rowIndex, 1)
  }

  getPostData () {
    const data = {
      erapaiva: this.erapaiva,
      laskupaiva: this.laskupaiva,
      muistutetaanko: this.muistutetaanko,
      muistutettu: this.muistutettu,
      pm_asiakasnumero: this.pm_asiakasnumero,
      saatavalaji: this.saatavalaji,
      saatavarivi_set: this.rivitPostDataMuotoon(),
      toimeksiantovelallinen_set: [this.velallinen.getPostData()],
      paamies_id: this.paamies.id,
    }

    return data
  }

  rivitPostDataMuotoon () {
    const rivitPostData = []
    for (const r of this.rivit) {
      rivitPostData.push(r.getPostData({ muistutettu: this.muistutettu }))
    }

    return rivitPostData
  }
}

const HENKILO_MAX_VIIVKORKO = 7

export const Saatavarivi = class {
  static get HENKILO_MAX_VIIVKORKO () {
    return HENKILO_MAX_VIIVKORKO
  }

  // Read-only -kenttiä ei lähetetä luonnin tai muokkauksen yhteydessä backendiin
  constructor (preset = {}) {
    this.id = null
    this.erapaiva = null
    this.laskunumero = ''
    this.laskupaiva = null
    this.muistutuskulu = null
    this.muistutuspaiva = null
    this.muistutuserapaiva = null
    this.paaoma = null
    this.pm_viite = ''
    this.selite = ''
    this.viivastyskorko = null

    Object.assign(this, preset)
  }

  isEmpty () {
    return (!this.laskunumero && !this.laskupaiva && !this.erapaiva && !this.pm_viite && !this.paaoma)
  }

  getPostData (config = {
    lahetaId: false,
    muistutettu: false,
  }) {
    const postData = this

    if (!config.lahetaId) delete postData.id

    // Poistetaan kentät kun ei olla muistutettu. Jos taas kentässä ei ole arvoa kun on muistutettu,
    // backend palauttaa ui_virheen
    if (!this.muistutuskulu) delete postData.muistutuskulu
    if (!this.muistutuspaiva) delete postData.muistutuspaiva
    if (!this.muistutuserapaiva) delete postData.muistutuserapaiva

    return postData
  }
}
