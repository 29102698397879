var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: _vm.$t("common:statistics.reportName"),
          rules: [_vm.$validationRules.required],
        },
        model: {
          value: _vm.value.nimi,
          callback: function ($$v) {
            _vm.$set(_vm.value, "nimi", $$v)
          },
          expression: "value.nimi",
        },
      }),
      _c("v-textarea", {
        staticClass: "required",
        attrs: {
          label: _vm.$t("common:statistics.descriptionReport"),
          rules: _vm.kuvausSaannot,
          rows: "2",
        },
        model: {
          value: _vm.value.kuvaus,
          callback: function ($$v) {
            _vm.$set(_vm.value, "kuvaus", $$v)
          },
          expression: "value.kuvaus",
        },
      }),
      _vm.naytaNakyvyysvalinnat
        ? _c("v-checkbox", {
            staticClass: "mb-n4",
            attrs: {
              label: _vm.$t("common:statistics.reportToCreditors"),
              error: _vm.molemmatValittuVirhe,
            },
            model: {
              value: _vm.value.paamiehille,
              callback: function ($$v) {
                _vm.$set(_vm.value, "paamiehille", $$v)
              },
              expression: "value.paamiehille",
            },
          })
        : _vm._e(),
      _vm.naytaNakyvyysvalinnat
        ? _c("v-checkbox", {
            staticClass: "mb-n2",
            attrs: {
              label: _vm.$t("common:statistics.reportToAdmins"),
              error: _vm.molemmatValittuVirhe,
              "error-messages": _vm.molemmatValittuVirhe
                ? [_vm.$t("common:statistics.reportToAdmins")]
                : [],
            },
            model: {
              value: _vm.value.paakayttajille,
              callback: function ($$v) {
                _vm.$set(_vm.value, "paakayttajille", $$v)
              },
              expression: "value.paakayttajille",
            },
          })
        : _vm._e(),
      _c(
        "v-btn",
        {
          staticClass: "mt-5",
          attrs: {
            type: "submit",
            color: "success",
            disabled: !_vm.valid,
            loading: _vm.loading,
          },
        },
        [_vm._v(" " + _vm._s(_vm.submitTeksti) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }