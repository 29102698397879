<template>
  <div>
    <v-form ref="laskuriviTable">
      <table
        class="riviTable"
      >
        <thead>
          <!-- TODO Rivi responsiivisemmaksi, nyt jää overflow'n takia piiloon pienillä näytöillä -->
          <tr>
            <th
              class="riviTable__cell riviTable__cell--header"
              scope="col"
            >
              {{ $t('common:code') }}
            </th>
            <th
              class="riviTable__cell riviTable__cell--header riviTable__cell--fill riviTable__cell--required"
              scope="col"
            >
              {{ $t('common:definition') }}
            </th>
            <th
              :class="[...thClassArr, 'riviTable__cell--required']"
              scope="col"
            >
              {{ $t('common:amount') }}
            </th>
            <th
              :class="thClassArr"
              scope="col"
            >
              {{ $t('common:quantityUnit') }}
            </th>
            <th
              :class="[...thClassArr, 'riviTable__cell--required']"
              scope="col"
            >
              {{ $t('common:uPrice') }}
            </th>
            <th
              :class="thClassArr"
              scope="col"
            >
              {{ $t('common:discountPercentShort') }}
            </th>
            <th
              class="riviTable__cell riviTable__cell--header text-right"
              scope="col"
            >
              {{ $t('common:inclVAT') }}
            </th>
            <th
              :class="[...thClassArr, tyyppi === laskutyypit.SOPIMUS ? 'riviTable__cell--required' : '']"
              scope="col"
            >
              {{ $t('common:vatId') }}
            </th>
            <th
              :class="thClassArr"
              scope="col"
            >
              {{ $t('common:taxFree') }}
            </th>
            <th
              :class="thClassArr"
              scope="col"
            >
              {{ $t('common:tax') }}
            </th>
            <th
              :class="thClassArr"
              scope="col"
            >
              {{ $t('common:taxable') }}
            </th>
            <th
              class="riviTable__cell riviTable__cell--header text-right"
              scope="col"
            >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in lasku.rivit"
            :key="item.index"
            @input="lahetaEventKayttajasyote"
          >
            <td :class="[...tdClassArr, 'riviTable__cell--min-width-medium']">
              <v-text-field
                v-model="item.koodi"
                type="text"
                hide-details
                :counter="item.koodi.length > 255 ? 255 : undefined"
                :rules="[$validationRules.tooManyCharacters(item.koodi.length, 255)]"
                :disabled="disabled"
                @keydown.enter="lasku.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.selite"
                type="text"
                :counter="item.selite.length > 1023 ? 1023 : undefined"
                :rules="[$validationRules.required, $validationRules.tooManyCharacters(item.selite.length, 1023)]"
                required
                class="required"
                hide-details
                :disabled="disabled"
                @keydown.enter="lasku.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.maara"
                type="number"
                :rules="[$validationRules.required]"
                required
                reverse
                hide-details
                :disabled="disabled"
                @keydown.enter="lasku.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.laatu"
                type="text"
                reverse
                hide-details
                :disabled="disabled"
                @keydown.enter="lasku.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.ahinta"
                type="number"
                :rules="tyyppi === laskutyypit.SOPIMUS ? [$validationRules.required, $validationRules.maxDecimalPlaces(item.ahinta, 2)] : [$validationRules.required]"
                required
                reverse
                hide-details
                :disabled="disabled"
                @keydown.enter="lasku.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.alennusp"
                type="number"
                reverse
                hide-details
                :rules="[
                  $validationRules.zeroOrBigger,
                  $validationRules.smallerThanOrEqualTo(item.alennusp, 100),
                  $validationRules.maxDecimalPlaces(item.alennusp, 2),
                ]"
                :disabled="disabled"
                @keydown.enter="lasku.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-checkbox
                v-model="item.sisalv"
                class="riviTable__cell--checkbox"
                hide-details
                :disabled="disabled"
                @keydown.enter="lasku.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-select
                v-model="item.alvtunnus"
                hide-details
                item-text="tunnus"
                item-value="id"
                :items="ALVTunnusItems"
                reverse
                return-object
                :rules="tyyppi === laskutyypit.SOPIMUS ? [$validationRules.requiredObject] : []"
                :disabled="disabled"
                @input="paivitaALVPros($event, item)"
                @keydown.enter="lasku.lisaaRivi(index)"
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.verotonMuotoiltu"
                type="text"
                disabled
                reverse
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.veroMuotoiltu"
                type="text"
                disabled
                reverse
              />
            </td>
            <td :class="tdClassArr">
              <v-text-field
                v-model="item.verollinenMuotoiltu"
                type="text"
                disabled
                reverse
              />
            </td>
            <td class="riviTable__cell--btn">
              <LomakenappiIkonilla
                v-if="salliPoistaminen"
                ikoni="delete"
                :infoteksti="$t('lasku.deleteRow')"
                nayta-aina
                @click="poistaRivi(index)"
              />
            </td>
            <td class="riviTable__cell--btn">
              <LomakenappiIkonilla
                v-if="tuoterekisteri && $permission.checkSinglePerm('onlinerestapi.onlinerestapi_tuoterekisteri')"
                ikoni="anitta-icon-product-add-filled"
                :infoteksti="$t('lasku.addToProducts')"
                nayta-aina
                @click="avaaUudenTuotteenLuominen(item)"
              />
            </td>
          </tr>
        </tbody>
        <tfoot
          v-if="naytaYhteenveto"
          class="riviTable__footer"
        >
          <tr>
            <td>
              <v-btn
                v-if="naytaUusiRiviBtn"
                color="primary"
                @click="lasku.lisaaRivi()"
              >
                <v-icon
                  left
                  icon
                >
                  library_add
                </v-icon>
                {{ $t('common:lasku.newRow') }}
              </v-btn>
            </td>
            <th
              class="riviTable__cell riviTable__cell--footer"
              colspan="8"
              scope="row"
            >
              {{ $t('common:total') }}
            </th>
            <td class="riviTable__cell riviTable__cell--footer">
              {{ lasku.veroton | formatSumToFixed2 }}
            </td>
            <td class="riviTable__cell riviTable__cell--footer">
              {{ lasku.vero | formatSumToFixed2 }}
            </td>
            <td class="riviTable__cell riviTable__cell--footer">
              {{ lasku.verollinen | formatSumToFixed2 }}
            </td>
            <td class="riviTable__cell riviTable__cell--footer"></td>
          </tr>
        </tfoot>
      </table>
    </v-form>


    <!-- Dialog: Uusi tuote / Muokkaa tuotetta -->
    <CardDialog
      :title="$t('product.newProduct')"
      :dialog-auki="tuoteDialogAuki"
      title-class="px-12 py-6"
      content-class="px-12 py-4"
      width="600"
      @close="(tuoteDialogAuki = false)"
    >
      <FormTuote
        :on-auki="tuoteDialogAuki"
        :laskurivi="laskuriviTuoteLomakkeelle"
        @close="(tuoteDialogAuki = false)"
        @tuoteTallennettu="naytaTuotteenTallennuksenIlmoitus"
      />
    </CardDialog>
  </div>
</template>

<script>

import FormTuote from '@/components/FormTuote'
import LomakenappiIkonilla from '@/components/LomakenappiIkonilla'
import { Laskutyypit } from '@/utils/constants'

export default {
  name: 'LaskuriviTable',
  components: {
    FormTuote,
    LomakenappiIkonilla,
  },
  model: {
    prop: 'lasku'
  },
  props: {
    lasku: {
      type: Object,
      required: true,
    },
    tyyppi: {
      type: String,
      required: false,
      default () {
        return Laskutyypit.LASKU
      },
      validator: (tyyppi) => {
        return Object.values(Laskutyypit).includes(tyyppi)
      }
    },
    tuoterekisteri: {
      type: Boolean,
      default: true,
    },
    salliPoistaminen: {
      type: Boolean,
      default: true,
    },
    naytaUusiRiviBtn: {
      type: Boolean,
      default: true,
    },
    naytaYhteenveto: {
      type: Boolean,
      default: true,
    },
    cellSize: {
      type: String,
      required: false,
      validator: (arvo) => ['small', 'medium'].includes(arvo),
      default () {
        return 'medium'
      }
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      laskuriviTuoteLomakkeelle: {},
      laskutyypit: Laskutyypit,
      tuoteDialogAuki: false,
    }
  },
  computed: {
    ALVTunnusItems () {
      return this.$store.state.yleinen.ALVTunnusItems
    },
    thClassArr () {
      return [
        'riviTable__cell',
        'riviTable__cell--header',
        'text-right',
        this.cellSize === 'small' ? 'riviTable__cell--small' : 'riviTable__cell--medium',
      ]
    },
    tdClassArr () {
      return [
        'riviTable__cell',
        this.cellSize === 'small' ? 'riviTable__cell--small' : 'riviTable__cell--medium',
      ]
    },
  },
  methods: {
    poistaRivi (rowIndex) {
      if (!this.lasku.rivit[rowIndex].isEmpty()) {
        this.$swal({
          text: this.$t('common:lasku.sureDeleteInvoiceRow'),
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: this.$t('common:lasku.deleteInvoiceRow'),
          cancelButtonText: this.$t('common:cancel'),
        }).then(result => {
          if (!result.value) return

          this.lasku.poistaRivi(rowIndex)
          this.lahetaEventKayttajasyote()
        })
      } else {
        this.lasku.poistaRivi(rowIndex)
        this.lahetaEventKayttajasyote()
      }
    },
    paivitaALVPros (alvtunnus, rivi) {
      rivi.alvp = alvtunnus.alvp
    },
    avaaUudenTuotteenLuominen (item) {
      this.laskuriviTuoteLomakkeelle = item
      this.tuoteDialogAuki = true
    },
    naytaTuotteenTallennuksenIlmoitus () {
      this.$naytaOnnistumisilmoitus(this.$t('common:product.productSaved'))
    },
    lahetaEventKayttajasyote() {
      this.$emit('kayttajasyote')
    },
    validate () {
      return this.$refs.laskuriviTable.validate()
    }
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/riviTableStyles.scss';

</style>
