<template>
  <v-data-table
    :items="items"
    :headers="tableHeadersToimeksiantoKirje"
    :hide-default-footer="items.length <= 10"
    :dense="tiivisNakyma"
  >
    <template v-slot:item="{ item }">
      <TableRow>
        <template v-if="item.lahetysaika">
          <td>
            <a @click="haeKirje(item)">{{ item.kirjepohja.nimi }}</a>
          </td>
          <td class="text-no-wrap">
            {{ $date(item.lahetysaika) }}
          </td>
          <td class="text-right">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  :small="tiivisNakyma"
                  v-on="on"
                  @click="haeKirje(item)"
                >
                  <v-icon :small="tiivisNakyma">
                    anitta-icon-file-download
                  </v-icon>
                </v-btn>
              </template>
              <template
                v-slot:default
              >
                <span>{{ $t('common:dataTableToimeksiantoKirje.downloadLetter') }}</span>
              </template>
            </v-tooltip>
          </td>
        </template>
      </TableRow>
    </template>
  </v-data-table>
</template>

<script>

import { TableHeadersToimeksiantoKirje } from '@/utils/tableHeaders'
import TiivisNakymaMixin from '@/mixins/TiivisNakymaMixin'

export default {
  name: 'DataTableToimeksiantoKirje',
  mixins: [TiivisNakymaMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    toimeksianto: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      tableHeadersToimeksiantoKirje: TableHeadersToimeksiantoKirje,
    }
  },
  methods: {
    async haeKirje (item) {
      const { id } = item
      try {
        const options = {
          parser: 'arrayBuffer',
          url: `toiminnot/hae_kirje/${id}/`,
          params: {
            id: this.toimeksianto.id
          }
        }

        const request = await this.$doRequestWithTimeout(
          this.$api.Toimeksianto,
          options,
        )

        if (request && !request.success) throw new this.$HttpError(request)

        this.$naytaTiedostonlataamisdialog({
          fileData: request.result.body,
          filename: `$t('common:assignment')-${this.toimeksianto.id}-${item.kirjepohja.nimi}`,
          headers: request.result.headers,
        })
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
  }
}

</script>

<style lang="scss" scoped>

</style>
