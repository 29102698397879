<template>
  <div
    v-if="currentTab !== 'kaikki'"
    class="px-5 py-4"
  >
    <v-icon
      class="mr-2"
      color="white"
    >
      info
    </v-icon>
    <span class="white--text">{{ $t('common:noteTabAffectsSearch') }}</span>
  </div>
</template>

<script>
export default {
  name: 'InfoValittuValilehti',
  props: {
    currentTab: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>

</style>