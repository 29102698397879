<template>
  <v-btn
    :color="color"
    class="ml-2"
    :to="to"
    @click="click"
  >
    <v-icon
      v-if="icon"
      left
    >
      {{ icon }}
    </v-icon>
    <slot>
      {{ $t('common:button') }}
    </slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default () {
        return 'primary'
      },
    },
    click: {
      type: Function,
      required: false,
      default () {
        return null
      },
    },
    icon: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    to: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
}
</script>

<style>

</style>
