<script>
import { Pie, mixins } from 'vue-chartjs'

export default {
  extends: Pie,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,

      required: false,
      default() {
        return {}
      },
    },
  },

  computed: {
    options() {
      return {
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let thisLabel = data.labels[tooltipItem.index]

              return thisLabel
            },
          },
        },
        legend: {
          position: 'left',
        },
        responsive: true,
      }
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}
</script>
