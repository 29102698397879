<template>
  <v-form
    ref="form"
    v-model="valid"
    class="white--text"
    @submit.prevent
  >
    <v-row>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="asianumeroAlkaen">
                Asianumero
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <v-text-field
                  id="asianumeroAlkaen"
                  v-model="value.asianumeroAlkaen"
                  class="comboField__field"
                  type="number"
                  :label="$t('common:starting')"
                  color="white"
                  clearable
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <v-text-field
                  id="asianumeroPaattyen"
                  v-model="value.asianumeroPaattyen"
                  class="comboField__field"
                  type="number"
                  :label="$t('common:ending')"
                  color="white"
                  clearable
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="kirjauspaivaAlkaen">
                {{ $t('common:transactionDate') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <InputfieldDatePicker
                  id="kirjauspaivaAlkaen"
                  v-model="value.kirjauspaivaAlkaen"
                  :label="$t('common:starting')"
                  color="white"
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <InputfieldDatePicker
                  id="kirjauspaivaPaattyen"
                  v-model="value.kirjauspaivaPaattyen"
                  :label="$t('common:ending')"
                  color="white"
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="laskunErapaivaAlkaen">
                {{ $t('common:formSearchPerinta.invoiceDueDate') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <InputfieldDatePicker
                  id="laskunErapaivaAlkaen"
                  v-model="value.laskunErapaivaAlkaen"
                  :label="$t('common:starting')"
                  color="white"
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <InputfieldDatePicker
                  id="laskunErapaivaPaattyen"
                  v-model="value.laskunErapaivaPaattyen"
                  :label="$t('common:ending')"
                  color="white"
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="laskunViite">
                {{ $t('common:invoiceRef') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="laskunViite"
                v-model="value.laskunViite"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="laskunumero">
                {{ $t('common:invoiceNo') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="laskunumero"
                v-model="value.laskunumero"
                type="text"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="laskupaivaAlkaen">
                {{ $t('common:invoiceDate') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <InputfieldDatePicker
                  id="laskupaivaAlkaen"
                  v-model="value.laskupaivaAlkaen"
                  :label="$t('common:starting')"
                  color="white"
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <InputfieldDatePicker
                  v-model="value.laskupaivaPaattyen"
                  :label="$t('common:ending')"
                  color="white"
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="paamies">
                {{ $t('common:creditor') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="paamies"
                v-model="value.paamies"
                color="white"
                :label="$t('common:formSearchPerinta.nameOrNumber')"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="perinnanViite">
                {{ $t('common:formSearchPerinta.collectionRef') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="perinnanViite"
                v-model="value.perinnanViite"
                type="number"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="sopimusnumero">
                {{ $t('common:contractNumber') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="sopimusnumero"
                v-model="value.sopimusnumero"
                type="number"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="tila">
                {{ $t('common:status') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-select
                id="tila"
                v-model="value.tila"
                :items="tilat"
                item-text="label"
                item-value="terms"
                multiple
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="tyyppi">
                {{ $t('common:type') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-select
                id="tyyppi"
                v-model="value.tyyppi"
                :items="tyypit"
                item-text="label"
                item-value="terms"
                multiple
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="velallinen">
                {{ $t('common:debtor') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-text-field
                id="velallinen"
                v-model="value.velallinen"
                :label="$t('common:formSearchPerinta.nameSocialIdorYid')"
                color="white"
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="asiakkaanTyyppi">
                {{ $t('common:debtor') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-select
                id="velallinenTyyppi"
                v-model="value.velallinenTyyppi"
                :items="velallinenTyypit"
                item-text="label"
                item-value="terms"
                multiple
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'
import { SelectablesToimeksiantoTilat, SelectablesToimeksiantoTyypit, SelectablesVelallinenTyypit } from '@/utils/selectables'
import FormTable from '@/components/FormTable'
import FormTableRow from '@/components/FormTableRow'
import FormTableCell from '@/components/FormTableCell'
import FormTableLabel from '@/components/FormTableLabel'
import InputfieldDatePicker from '@/components/InputfieldDatePicker'

export default {
  name: 'FormSearchPerinta',
  components: {
    FormTable,
    FormTableRow,
    FormTableCell,
    FormTableLabel,
    InputfieldDatePicker,
  },
  mixins: [FormMixin],
  data () {
    return {
      tilat: SelectablesToimeksiantoTilat,
      tyypit: SelectablesToimeksiantoTyypit,
      velallinenTyypit: SelectablesVelallinenTyypit,
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
