var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefList",
    { attrs: { bordered: true } },
    [
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v(" Nimi ")]
              },
              proxy: true,
            },
            _vm.value.nimi
              ? {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.nimi) + " ")]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _vm.value.nro
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v(" Toimittajanumero ")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.nro) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3743912077
            ),
          })
        : _vm._e(),
      _vm.value.id
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.$sovellusIdOtsake) + " ")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.id) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2702295723
            ),
          })
        : _vm._e(),
      _vm.value.tunnus
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v(" Tunnus ")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.tunnus) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1786942572
            ),
          })
        : _vm._e(),
      _vm.value.tyyppi
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v(" Tyyppi ")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.tyyppi) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1733586604
            ),
          })
        : _vm._e(),
      _vm.value.paamies
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v(" Päämies ")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.paamies.nimi) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1827470913
            ),
          })
        : _vm._e(),
      _vm.value.postiosoite
        ? [
            _c("DefListItem", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [_vm._v(" Postisoite ")]
                    },
                    proxy: true,
                  },
                  {
                    key: "value",
                    fn: function () {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.value.postiosoite.osoite) + " "
                        ),
                        _c("br"),
                        _vm._v(
                          _vm._s(_vm.value.postiosoite.numero) +
                            " " +
                            _vm._s(_vm.value.postiosoite.toimipaikka) +
                            " "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2323712489
              ),
            }),
          ]
        : _vm._e(),
      _vm.value.verkkolaskuosoite
        ? [
            _c("DefListItem", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [_vm._v(" Verkkolaskuosoite ")]
                    },
                    proxy: true,
                  },
                  {
                    key: "value",
                    fn: function () {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.value.verkkolaskuosoite.verkkolaskuosoite
                            ) +
                            " "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1458823303
              ),
            }),
            _c("DefListItem", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [_vm._v(" Verkkolaskuoperaattori ")]
                    },
                    proxy: true,
                  },
                  {
                    key: "value",
                    fn: function () {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.value.verkkolaskuosoite.operaattori) +
                            " "
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2908752169
              ),
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }