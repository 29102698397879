import api from '@/plugins/api'
import { HttpError } from '@/utils/errors'
import { sentryCaptureCustom } from '@/utils/misc'
import store from '@/store'

export const Paamies = class {
   static async haePaamiesTiedotLuontitoiminnoille (paamies_id) {
    try {
      const query = new URLSearchParams()

      const request = await api.Paamies.pushRequest({
        url: `${paamies_id}/`,
        method: 'GET',
        query: query.toString(),
      })

      if (!request.success) throw new HttpError(request, 'Päämiestietojen haku epäonnistui')

      const paamies = request.result.body
      store.commit('user/setValittuPaamies', paamies)

      return paamies
    } catch (e) {
      sentryCaptureCustom(e)
    }
  }
}

export const PaamieshallintaPaamies = class {
  constructor (preset = {}) {
    this.bic = ''
    this.iban = ''
    this.email = ''
    this.emailOsoitteet = []
    this.id = null // Read-only
    this.maa = 'FI'
    this.nimi = ''
    this.osoite = ''
    this.paamies_id = null
    this.paamiespohja = {} // Read-only; Varaus tulevaa toimintoa varten
    this.postinumero = ''
    this.postiosoite_set = [] // Read-only
    this.puhelin = ''
    this.saaja = ''
    this.toimipaikka = ''
    this.tunnus = ''
    this.verkkolasku_operaattori = ''
    this.verkkolasku_osoite = ''
    this.viestikirjeeseen = ''
    this.palvelusopimustrial_paattyy = null
    this.palvelusopimus_maksamatta = null
    this.piilota_asiakas_ja_velallinen = null
    this.palvelusopimus_paattyy = null

    this._kasittele_preset(preset)
  }

  /**
   * getter ja setter ovat olemassa päämieshallintaa varten. Voidaan käyttää AutocompletePaamiesta
   * päämiehen luomisessa ja muokkauksessa niin, että käsitellään arvoa järkevän nimisessä
   * muuttujassa ('paamiespohja'). Päämiespohjan valinta ei ole toistaiseksi käytössä, vaan Anitta
   * valitsee pohjan käyttäjän perusteella.
   */
  get paamies () {
    return this.paamiespohja
  }

  set paamies (paamies) {
    this.paamiespohja = paamies
  }

  getPostData () {
    const postData = JSON.parse(JSON.stringify(this))
    // if (this.paamiespohja.id) postData.paamiespohja_id = this.paamiespohja.id

    postData.email = this.emailOsoitteet.join(';')

    // Read-only-muuttujat
    delete postData.postiosoite_set
    delete postData.paamiespohja

    return postData
  }

  _kasittele_preset (preset) {
    let paamiesosoite = {}
    if (preset.paamiesosoite_kolmas_osapuoli) {
      paamiesosoite = preset.paamiesosoite_kolmas_osapuoli
      delete preset.paamiesosoite_kolmas_osapuoli
    }
    if (preset.bic_output) {
      this.bic = preset.bic_output
      delete preset.bic_output
    }
    if (preset.iban_output) {
      this.iban = preset.iban_output
      delete preset.iban_output
    }

    Object.assign(this, preset, paamiesosoite)
    this.emailOsoitteet = this.email ? this.email.split(';') : []
  }
}


const PALVELUSOPIMUS_LAHETYSTAVAT = [
  {
    value: 'verkkolasku',
    label: 'Verkkolasku'
  },
  {
    value: 'email',
    label: 'Sähköposti'
  }
]

export const PaamiesPalveluhakemus = class {
  static get LAHETYSTAVAT () {
    return PALVELUSOPIMUS_LAHETYSTAVAT
  }
  static get LASKUTUSJAKSO_KUUKAUSI () {
    return 'KUUKAUSI'
  }
  static get LASKUTUSJAKSO_VUOSI () {
    return 'VUOSI'
  }

  constructor (preset = {}) {
    this.bic = ''
    this.email = ''
    this.iban = ''
    this.laskutus_email = ''
    this.lahetystapa = 'email'
    this.maa = 'FI'
    this.nimi = ''
    this.osoite = ''
    this.paamies_id = null
    this.palvelusopimus = {
      laskutusjakso: '',
      ominaisuudet: {},
    }
    this.postinumero =
    this.puhelin = ''
    this.saaja = ''
    this.toimipaikka = ''
    this.tunnus = ''
    this.verkkolasku_operaattori = ''
    this.verkkolasku_osoite = ''
    this.www = ''

    Object.assign(this, preset)
  }

  getPostData () {
    const postData = {
      bic: this.bic,
      iban: this.iban,
      nimi: this.nimi,
      palvelusopimus_id: this.palvelusopimus.id,
      laskutusjakso: this.palvelusopimus.laskutusjakso,
      tunnus: this.tunnus,
      paamiesosoite_set: [{
        email: this.email,
        postinumero: this.postinumero,
        postiosoite: this.osoite,
        postitoimipaikka: this.toimipaikka,
        puh: this.puhelin,
        saaja: this.saaja,
        verkkolasku_operaattori: this.verkkolasku_operaattori,
        verkkolasku_osoite: this.verkkolasku_osoite,
        www: this.www,
      }],
      laskutus_email: this.laskutus_email,
    }

    return postData
  }
}

export const Paamiesosoite = class {

  constructor (preset = {}) {
    this.vastaanottaja = ''
    this.saaja = ''
    this.postiosoite = ''
    this.postitoimipaikka = ''
    this.postinumero = ''
    this.maa = 'FI'
    this.email = ''
    this.verkkolasku_osoite = ''
    this.verkkolasku_operaattori = ''
    this.puhelin = ''

    // Puhelinnumerolle käytetään frontissa eri nimeä kuin backendissä
    if (preset) {
      this.puhelin = preset.puh
      delete preset.puh
    }

    Object.assign(this, preset)
  }

  getPostData () {
    return {
      vastaanottaja: this.vastaanottaja,
      saaja: this.saaja,
      postiosoite: this.postiosoite,
      postitoimipaikka: this.postitoimipaikka,
      postinumero: this.postinumero,
      maa: this.maa,
      puh: this.puhelin,
      email: this.email,
      verkkolasku_operaattori: this.verkkolasku_operaattori,
      verkkolasku_osoite: this.verkkolasku_osoite,
    }
  }
}

