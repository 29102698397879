<template>
  <v-menu
    :disabled="disabled"
    bottom
    nudge-bottom="40"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="disabled"
        color="primary"
        v-on="on"
        :dark="!disabled"
      >
      <v-icon left>more_vert</v-icon>
        Toiminnot <template v-if="itemCount > 0">({{ itemCount }} kpl)</template>
      </v-btn>
    </template>
    <template v-slot:default>
      <v-list>
        <v-list-item
          @click="$emit('markCheckedAsRead')"
        >
          <v-list-item-avatar>
            <v-icon>check</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            Merkitse valitut käsitellyiksi
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          @click="$emit('markCheckedAsUnRead')"
        >
          <v-list-item-avatar>
            <v-icon>clear</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            Merkitse valitut käsittelemättömiksi
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </v-menu>
</template>

<script>

export default {
  name: 'MenuSelectedMessages',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    itemCount: {
      type: Number,
      required: false,
      default () {
        return null
      },
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
