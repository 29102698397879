<template>
  <v-form
    v-model="valid"
    @submit.prevent="submit"
  >
    <v-menu
      v-model="maksupaivaMenu"
      :close-on-content-click="false"
      max-width="290"
      nudge-bottom="40"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          label="Maksupäivä"
          :value="$date(value.maksupaiva)"
          readonly
          placeholder="Tänään"
          persistent-placeholder
          v-on="on"
        />
      </template>
      <template>
        <v-date-picker
          v-model="value.maksupaiva"
          :max="maxDate"
          locale="fi-FI"
          first-day-of-week="1"
          no-title
          @change="maksupaivaMenu = false"
        />
      </template>
    </v-menu>
    <v-text-field
      v-model="value.selite"
      type="text"
      label="Selite"
    />
    <v-checkbox
      v-model="value.hyvityslasku"
      label="Luo hyvityslasku"
    ></v-checkbox>
    <v-text-field
      v-model="value.summa"
      type="text"
      label="Summa"
      :placeholder="summaPlaceholder()"
      persistent-placeholder
      :rules="[$validationRules.numberOrEmpty]"
      suffix="€"
    />
    <PeruutaHyvaksyPainikerivi
      :submit-disabled="!valid || loading"
      :submit-loading="loading"
      @close="$emit('close')"
    />
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'
import formatSumToFixed2 from '@/utils/filters/formatSumToFixed2'

export default {
  name: 'FormLaskuHyvita',
  mixins: [FormMixin],
  props: {
    lasku: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      maksupaivaMenu: false,
    }
  },
  computed: {
    maxDate() {
      return new Date().toISOString().substr(0, 10)
    },
  },
  methods: {
    summaPlaceholder () {
      return `Koko lasku (${formatSumToFixed2(this.lasku.avoinna)})`
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
