var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OffCanvasToolbar", {
        attrs: {
          "toolbar-title": "Kayttajan tiedot",
          "tooltip-title": "kayttaja",
        },
        on: {
          "close-button-click": function ($event) {
            return _vm.$emit("close-button-click")
          },
        },
      }),
      _c(
        "v-container",
        { staticClass: "grey lighten-5 px-8" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.naytaTekeytymisnappi
                    ? _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.lukittu },
                          on: { click: _vm.tekeydy },
                        },
                        [_vm._v(" Tekeydy käyttäjäksi ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }