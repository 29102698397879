var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "white--text",
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          !_vm.$store.state.user.vainYksiPaamies
            ? _c(
                "v-col",
                { staticClass: "px-8 py-4", attrs: { md: "4" } },
                [
                  _c(
                    "FormTable",
                    [
                      _c(
                        "FormTableRow",
                        [
                          _c(
                            "FormTableCell",
                            { attrs: { header: true } },
                            [
                              _c(
                                "FormTableLabel",
                                { attrs: { for: "paamies" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("common:creditor")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "FormTableCell",
                            [
                              _c(
                                "v-theme-provider",
                                { attrs: { dark: "" } },
                                [
                                  _c("AutocompletePaamies", {
                                    attrs: {
                                      tapaus: _vm.value,
                                      label: "",
                                      "tarkistettava-oikeus":
                                        "onlinerestapi.onlinerestapi_tilitys",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "luotu" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("common:settlementDate")) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("FormTableCell", [
                        _c(
                          "div",
                          { staticClass: "comboField" },
                          [
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "luotuAlkaen",
                                label: _vm.$t("common:starting"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.luotuAlkaen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "luotuAlkaen", $$v)
                                },
                                expression: "value.luotuAlkaen",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "comboField__separator" },
                              [_vm._v("–")]
                            ),
                            _c("InputfieldDatePicker", {
                              attrs: {
                                id: "luotuPaattyen",
                                label: _vm.$t("common:ending"),
                                color: "white",
                                dark: true,
                              },
                              model: {
                                value: _vm.value.luotuPaattyen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "luotuPaattyen", $$v)
                                },
                                expression: "value.luotuPaattyen",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "tyyppi" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("common:type")) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-select", {
                            attrs: {
                              id: "tila",
                              items: _vm.tyypit,
                              "item-text": "label",
                              "item-value": "terms",
                              multiple: "",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.tyyppi,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "tyyppi", $$v)
                              },
                              expression: "value.tyyppi",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }