var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ilmoitussisalto-raportti-valmis" },
    [
      _c(
        "div",
        {
          staticClass: "Vue-Toastification__toast-body",
          attrs: { role: "alert" },
        },
        [
          _vm._v(
            " Raportti valmis: " +
              _vm._s(_vm.nimi) +
              " (id: " +
              _vm._s(_vm.raporttiId) +
              ") "
          ),
        ]
      ),
      _c(
        "v-btn",
        {
          staticClass: "ilmoitussisalto-raportti-valmis__btn",
          attrs: { color: "#26c6da", dark: "" },
          on: { click: _vm.lataaRaportti },
        },
        [_vm._v(" Lataa ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }