import Vue from 'vue'
import i18next from 'i18next'
import VueI18Next from '@panter/vue-i18next'
import translationResources from '../../public/locales/index.js'
import * as kaannokset from '@/locales'

/** Käännöksiä ei ole tehty, joten käytetään toistaiseksi oletuksena suomea. */
const fallbackLngI18next = 'fi'

Vue.use(VueI18Next)
i18next.init({
  initImmediate: false,
  ns: ['common'],
  defaultNS: 'common',
  fallbackLng: fallbackLngI18next,
  lng: localStorage.getItem('lngI18next'),
  resources: {
    ...translationResources,
  },
  whitelist: [
    'fi',
    'en',
  ]
})

export const i18n = new VueI18Next(i18next)

export function t (str) {
  return (i18n && i18n.t(str)) || str
}

// TODO tarkista ja dokumentoi missä kuuluu käyttää formaattia 'fi' ja missä 'fi-FI'
export function getLanguageLocale () {
  return localStorage.getItem('lngLocaleI18next') ? localStorage.getItem('lngLocaleI18next') : 'fi-FI'
}

export function getLanguage () {
  return localStorage.getItem('lngI18next') ? localStorage.getItem('lngI18next') : 'fi'
}

export function getFlexmonsterKaannokset () {
  return kaannokset['default']['kaannokset_' + getLanguage()]
}
