import { HttpError } from '@/utils/errors'
import BaseOptimizedCountSelaa from "@/store/modules/baseOptimizedCountSelaa";
import { TabsViestit } from '@/utils/tabs'

export default class ViestitSelaa extends BaseOptimizedCountSelaa {
  state () {
    return {
      ...super.state(),
      apiName: 'Viestit',
      currentTab: 'saapuneet',
      tabs: TabsViestit || [],
    }
  }

  actions () {
    return {
      ...super.actions(),
      async setSelectedItem ({ commit, getters }, { item, silent = false }) {
        try {
          if (!silent) commit('setLoadingOffCanvas', true)

          let selectedItem = { item: {} }

          if (item.id) {
            commit('setShowOffCanvas', true)

            let api
            let request

            if (item.lasku) {
              api = getters.apiModule.getInstance('Lasku')
              request = await api.pushRequest({
                method: 'GET',
                url: 'off_canvas/',
                params: {
                  id: item.lasku.id,
                },
              })
            } else if (item.ostolasku) {
              api = getters.apiModule.getInstance('Ostolasku')
              request = await api.pushRequest({
                method: 'GET',
                url: 'off_canvas/',
                params: {
                  id: item.ostolasku.id,
                },
              })
            } else if (item.toimeksianto) {
              api = getters.apiModule.getInstance('Toimeksianto')
              request = await api.pushRequest({
                method: 'GET',
                url: 'off_canvas/',
                params: {
                  id: item.toimeksianto,
                },
              })
            }

            if (!request.success) throw new HttpError(request)

            selectedItem = Object.assign({ item: {} }, {
              item: {
                ...item,
                tapausData: (request.result) ? request.result.body : {},
              }
            })
          }

          commit('setSelectedItem', selectedItem)
        } catch (e) {
          this.$oletusVirheenkasittely(e)
        } finally {
          commit('setLoadingOffCanvas', false)
        }
      },
    }
  }
}
