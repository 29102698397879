var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentTab !== "kaikki"
    ? _c(
        "div",
        { staticClass: "px-5 py-4" },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { color: "white" } }, [
            _vm._v(" info "),
          ]),
          _c("span", { staticClass: "white--text" }, [
            _vm._v(_vm._s(_vm.$t("common:noteTabAffectsSearch"))),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }