<template>
  <v-toolbar
    class="grey lighten-4 my-4 px-2"
    flat
  >
    <v-row
      justify="center"
    >
      <LomakenappiTekstiJaIkoni
        v-if="uusiBtnPermission"
        :ikoni="ikoni"
        :taustavari="taustavari"
        :teksti="teksti"
        :infoteksti="infoteksti"
        :naytaAina="naytaAina"
        @click="clickHandler"
        :to="uusiBtnConfig && uusiBtnConfig.toName ? { name: uusiBtnConfig.toName } : null"
      >
      </LomakenappiTekstiJaIkoni>
    </v-row>
  </v-toolbar>
</template>

<script>

import LomakenappiTekstiJaIkoni from '@/components/LomakenappiTekstiJaIkoni'

export default {
  components: {
    LomakenappiTekstiJaIkoni,
  },
  props: {
    ikoni: {
      type: String,
      required: true,
    },
    taustavari: {
      type: String,
      required: false,
      default: 'primary',
    },
    ikoniVari: {
      type: String,
      required: false,
      default: 'white',
    },
    luokat: {
      type: Array,
      required: false,
      default: () => ['ma-0', 'ml-2'],
    },
    teksti: {
      type: String,
      required: false,
      default: '',
    },
    infoteksti: {
      type: String,
      required: false,
      default: '',
    },
    naytaAina: {
      type: Boolean,
      required: false,
      default: false,
    },
    uusiBtnConfig: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    kaikkiLuokat () {
      const luokat = this.luokat
      if (!this.naytaAina) luokat.push('hidden-child')
      return luokat
    },
    uusiBtnPermission () {
      if (!this.uusiBtnConfig) {
        return true
      }
      return this.uusiBtnConfig && this.uusiBtnConfig.permission !== false
    },
  },
  methods: {
    clickHandler () {
      this.$emit('click', this)
    }
  },
}
</script>
