
<template>
  <div class="timeline">
    <!-- Header -->
    <header
      v-if="showTitle || enableDirectionToggle"
      class="timeline__header"
    >
      <v-row
        class="mb-6"
        align-content="center"
      >
        <v-col
          v-if="showTitle"
          class="timeline__headerCol pr-1 shrink"
        >
          <h3 class="timelineItem__title">
            <template v-if="title">
              {{ title }}
            </template>
            <template v-else>
              {{ $t('common:timeline.title') }}
            </template>
          </h3>
        </v-col>
        <v-col
          v-if="enableDirectionToggle"
          class="timeline__headerCol grow"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :class="directionButtonClass"
                @click="toggleDirection"
                v-on="on"
              >
                <v-icon>arrow_upward</v-icon>
              </v-btn>
            </template>
            <template v-slot:default>
              <span>{{ $t('common:timeline.directionButtonTitle') }}</span>
            </template>
          </v-tooltip>
        </v-col>
      </v-row>
    </header>

    <!-- Zoom -->
    <v-slider
      v-if="enableZoom"
      v-model="zoom"
      min="1"
      max="3"
      prepend-icon="zoom_out"
      append-icon="zoom_in"
      @click:prepend="zoomOut"
      @click:append="zoomIn"
    />

    <!-- Items -->
    <div class="timeline__items">
      <div
        v-for="item in itemsParsed"
        :key="item.id"
        class="timelineItem"
        :class="item.itemClass"
      >
        <StatusIcon
          class="timelineItem__icon status-icon--rounded"
          size="small"
          :icon="item.icon"
          :bg-color="item.color"
          :pointer="(false)"
        />
        <div class="timelineItem__line timelineItem__line--top"></div>
        <div class="timelineItem__line timelineItem__line--bottom"></div>
        <div :class="arrowClass"></div>

        <div class="timelineItem__content">
          <p
            v-if="item.header"
            class="linelineItem__header caption mb-0 font-weight-bold grey--text darken-2"
          >
            {{ item.header }}

            <v-chip
              v-if="item.chipText"
              :small="(true)"
              :color="item.chipColor"
              text-color="white"
              :disabled="(true)"
              class="ma-0 ml-1"
            >
              {{ item.chipText }}
            </v-chip>
          </p>
          <h3
            v-if="showItemTitles && item.title"
            class="timelineItem__title"
          >
            {{ item.title }}
          </h3>
          <p
            v-if="item.text"
            :class="item.textClass"
          >
            <span
              @contextmenu.prevent="item.textLink ? avaaMenuContext(
                $event,
                { id : item.textLink.params.id },
                item.textLink.name,
              ) : {}"
            >
              <router-link
                v-if="item.textLink"
                :to="item.textLink"
                class="inlineList__link"
              >
                <nl2br
                  tag="span"
                  :text="item.text"
                />
              </router-link>
              <nl2br
                v-else
                tag="span"
                :text="item.text"
              />
            </span>
          </p>

          <v-menu
            v-if="item.navItems.length"
            class="timelineItem__menu"
            nudge-bottom="40"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                class="ma-0"
                icon
                v-on="on"
              >
                <v-icon>{{ item.navIcon }}</v-icon>
              </v-btn>
            </template>
            <template v-slot:default>
              <v-list>
                <v-list-item
                  v-for="navItem in item.navItems"
                  :key="navItem.index"
                  @click="navItem.action(item)"
                >
                  <v-list-item-avatar v-if="navItem.icon">
                    <v-icon>{{ navItem.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title v-if="navItem.label">
                    {{ navItem.label }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </template>
          </v-menu>
        </div>
      </div>
    </div>

    <!-- Dialog: Muokkaa muistiinpanoa -->
    <CardDialog
      :title="$t('common:editNote')"
      :dialog-auki="muistiinpanoMuokkaaDialogAuki"
      :scroll-to-bottom="(true)"
      content-class="px-4 py-2"
      @close="(muistiinpanoMuokkaaDialogAuki = false)"
    >
      <template v-slot:footer>
        <FormUusiViesti
          v-model="muistiinpanoMuokkaaFormData"
          :loading="muistiinpanoMuokkaaFormLoading"
          :focus-on-update="(true)"
          icon="note"
          submit-btn-tooltip-text="$t('common:save')"
          @submit="muistiinpanoMuokkaaSubmit"
        />
      </template>
    </CardDialog>

    <!-- Dialog: Välilehti -->
    <MenuContext
      :onko-nakyvissa="menuContextNakyvissa"
      :position-x="menuContextX"
      :position-y="menuContextY"
      :vaihtoehdot="menuContextVaihtoehdot"
      @avaaValilehteen="avaaUudessaValilehdessa"
      @update:return-value="suljeMenuContext"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import formatSumToFixed2 from '@/utils/filters/formatSumToFixed2'
import cleanLineBreaks from '@/utils/filters/cleanLineBreaks'
import FormUusiViesti from '@/components/FormUusiViesti'
import MenuContextMixin from '@/mixins/MenuContextMixin'

export default {
  name: 'Timeline',
  components: {
    FormUusiViesti,
  },
  mixins: [MenuContextMixin],
  props: {
    title: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    items: {
      type: Array,
      required: false,
      default () {
        return []
      },
    },
    showDates: {
      type: Boolean,
      required: false,
      default () {
        return true
      },
    },
    showTitle: {
      type: Boolean,
      required: false,
      default () {
        return true
      },
    },
    showItemTitles: {
      type: Boolean,
      required: false,
      default () {
        return true
      },
    },
    showArrows: {
      type: Boolean,
      required: false,
      default () {
        return true
      },
    },
    enableZoom: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    enableDirectionToggle: {
      type: Boolean,
      required: false,
      default () {
        return true
      },
    },
    enableReply: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    salliMuistiinpanojenMuokkaus: {
      type: Boolean,
      required: false,
      default () {
        return true
      },
    },
  },
  data () {
    return {
      muistiinpanoMuokkaaDialogAuki: false,
      latestFirst: true,
      muistiinpanoMuokkaaFormData: {},
      muistiinpanoMuokkaaFormLoading: false,
      zoom: 2,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    }),
    directionButtonClass () {
      return  [
        'timeline__directionButton',
        'mb-0',
        this.latestFirst ? null : 'timeline__directionButton--rotated',
      ]
    },
    timelineClass () {
      return [
        'timeline',
        this.latestFirst ? null : 'timeline--rotated',
        this.showArrows ? 'timeline--arrows' : null,
      ]
    },
    arrowClass () {
      return [
        'timelineItem__arrow',
        this.latestFirst ? 'timelineItem__arrow--up' : 'timelineItem__arrow--down',
      ]
    },
    itemsParsed () {
      let parsedItems = this.items.map(item => {
        let itemId = ''
        let header = item.aikajana_dt ? (item.tyyppi === "PaamiesViesti" || item.tyyppi === "Muistiinpano" ? this.$date(item.aikajana_dt, 'D.M.YYYY HH:mm') : this.$date(item.aikajana_dt)) : ''
        let title = ''
        let text = ''
        let textLink = null
        let chipText = ''
        let itemClass = []
        let textClass = [
          'timelineItem__text',
          'mb-0',
        ]
        let navItems = []
        let navIcon = 'more_vert'

        switch (item.tyyppi) {
          case 'toimeksianto.seuraava': {
            header = ''
            title = this.$t('common:timeline.itemTitles.nextPhase')
            text = item.data.nimi + ' (' + this.$t('common:timeline.coming') + this.$date(item.aikajana_dt) + ')'

            break
          }

          case 'toimeksianto.luotu': {
            title = this.$t('common:timeline.itemTitles.assignmentCreated')

            break
          }

          case 'Kirje': {
            title = this.$t('common:timeline.itemTitles.letter')
            if (item.data.lahetystapa === 'email') {
              title = this.$t('common:timeline.itemTitles.email')
            }
            title += (item.data.kirjepohja) ? ': ' + item.data.kirjepohja.nimi : ''

            break
          }

          case 'ToimeksiantoHistoria': {
            title = item.data.vaihe.nimi

            break
          }

          case 'Tapahtuma': {
            title = this.$t('common:timeline.itemTitles.payment')
            title += (item.data.kirjattu) ? ': ' + formatSumToFixed2(item.data.kirjattu) : ''

            break
          }

          case 'Muistiinpano': {
            itemId = item.data.id
            header += (item.data.lahettaja && item.data.lahettaja.naytettava_nimi) ? ' – ' + item.data.lahettaja.naytettava_nimi : ''
            chipText = (item.data.lahettaja && item.data.lahettaja.henkilokuntatunniste) ? item.data.lahettaja.henkilokuntatunniste : ''
            title = this.$t('common:timeline.itemTitles.note')
            text = cleanLineBreaks(item.data.data)

            if (this.salliMuistiinpanojenMuokkaus) {
              navItems.push({
                  label: this.$t('common:timeline.edit'),
                  icon: 'edit',
                  action: this.avaaDialogMuistiinpanoMuokkaa,
              })

              navItems.push({
                  label: this.$t('common:timeline.delete'),
                  icon: 'delete',
                  action: this.poistaMuistiinpano,
              })
            }

            break
          }

          case 'Viesti': {
            header += (item.data.lahettaja && item.data.lahettaja.naytettava_nimi) ? ' – ' + item.data.lahettaja.naytettava_nimi : ''
            chipText = (item.data.lahettaja && item.data.lahettaja.henkilokuntatunniste) ? item.data.lahettaja.henkilokuntatunniste : ''
            title = this.$t('common:timeline.itemTitles.message')
            text = cleanLineBreaks(item.data.data)

            break
          }

          case 'PaamiesViesti': {
            itemId = item.data.paamiesviesti,
            header += (item.data.lahettaja && item.data.lahettaja.naytettava_nimi) ? ' – ' + item.data.lahettaja.naytettava_nimi : ''
            chipText = (item.data.lahettaja && item.data.lahettaja.henkilokuntatunniste) ? item.data.lahettaja.henkilokuntatunniste : ''
            title = this.$t('common:timeline.itemTitles.message')
            text = cleanLineBreaks(item.data.data)

            if (this.msgIsUnread(item)) {
              itemClass.push('timelineItem--highlight')
              textClass.push('timelineItem__text--bold')
            }

            // if (item.data.lahettaja.is_staff && item.data.lahettaja.username != this.user.username) {
            if (
              this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_viesti_kuittaus') &&
              item.data.lahettaja.is_staff
            ) {
              if (this.msgIsUnread(item)) {
                navItems.push({
                  label: this.$t('common:timeline.markHandled'),
                  icon: 'check',
                  action: this.msgMarkAsRead,
                })
              } else {
                navIcon = 'check'
                navItems.push({
                  label: this.$t('common:timeline.markUnhandled'),
                  icon: 'clear',
                  action: this.msgMarkAsUnread,
                })
              }
            }

            if (
              this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_viesti_lahetys') &&
              this.enableReply
            ) {
              navItems.push({
                label: this.$t('common:timeline.answer'),
                icon: 'comment',
                action: this.reply,
              })
            }

            break
          }

          case 'lasku.luotu': {
            title = this.$t('common:timeline.itemTitles.invoiceCreated')

            break
          }

          case 'lasku.erapaiva': {
            title = item.data.selite

            break
          }

          case 'LaskuHistoria': {
            title = item.data.tila
            text = (item.data.syy) ? item.data.syy : null

            if (item.data.toimeksianto) {
              text = this.$t('common:assignment') + ': ' + item.data.toimeksianto
              textLink = { name: 'perinta-nayta', params: { id: item.data.toimeksianto } }
            }

            break
          }
        }

        if (navItems.length) {
          itemClass.push('timelineItem--hasMenu')
        }

        return {
          id: item.aikajana_id,
          itemId: itemId,
          tyyppi: item.tyyppi,
          icon: item.ikoni,
          color: item.vari,
          header: header,
          chipText: chipText,
          chipColor: item.vari,
          title: title,
          text: text,
          textLink: textLink,
          itemClass: itemClass,
          textClass: textClass,
          navItems: navItems,
          navIcon: navIcon,
        }
      })

      if (this.enableDirectionToggle && this.latestFirst) {
        return parsedItems
      } else {
        return parsedItems.reverse()
      }
    }
  },
  watch: {
    latestFirst (val) {
      localStorage.timelineLatestFirst = val
    },
    zoom (val) {
      localStorage.timelineZoom = val
    },
  },
  mounted () {
    // Load direction config
    if (this.enableDirectionToggle) {
      if (typeof localStorage.timelineLatestFirst != 'undefined') {
        this.latestFirst = (localStorage.timelineLatestFirst == 'true')
       } else {
        localStorage.timelineLatestFirst = this.latestFirst
      }
    }

    // Load zoom config
    if (this.enableZoom) {
      if (typeof localStorage.timelineZoom != 'undefined') {
        this.zoom = localStorage.timelineZoom
      } else {
        localStorage.timelineZoom = this.zoom
      }
    } else {
      this.zoom = 3
    }

    // Keyboard listeners
    if (this.enableZoom) document.addEventListener('keydown', this.handleKeyPress)
  },

  destroyed () {
    // Remove keyboard listeners
    if (this.enableZoom) document.removeEventListener('keydown', this.handleKeyPress)
  },
  methods: {
    msgIsUnread (item) {
      if (item.tyyppi != 'PaamiesViesti' && item.tyyppi != 'Viesti') return false

      return (!item.data.luettu && item.data.lahettaja.is_staff)
    },
    async msgMarkAsRead (item) {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Viestit,
          {
            method: 'POST',
            url: 'toiminnot/merkitse_luetuksi/',
            body: {
              viestit: [
                item.itemId,
              ],
            },
          },
        )

        if (!request.success) throw new this.$HttpError(request)

        await this.$doRequestWithTimeout(
          this.$api.UserLukemattomatViestitKpl
        )

        this.$emit('msg-mark-as-read', item)
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    async msgMarkAsUnread (item) {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Viestit,
          {
            method: 'POST',
            url: 'toiminnot/merkitse_lukemattomaksi/',
            body: {
              viestit: [
                item.itemId,
              ],
            },
          },
        )

        if (!request.success) throw new this.$HttpError(request)

        await this.$doRequestWithTimeout(
          this.$api.UserLukemattomatViestitKpl
        )

        this.$emit('msg-mark-as-unread', item)
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    avaaDialogMuistiinpanoMuokkaa (muistiinpano) {
      this.muistiinpanoMuokkaaDialogAuki = true
      this.muistiinpanoMuokkaaFormData = {
        id: muistiinpano.itemId,
        message: muistiinpano.text,
      }
    },
    async muistiinpanoMuokkaaSubmit () {
      try {
        this.muistiinpanoMuokkaaFormLoading = true

        const requestBody = {
          data: this.muistiinpanoMuokkaaFormData.message,
        }

        const request = await this.$doRequestWithTimeout(
          this.$api.Muistiinpano,
          {
            method: 'PUT',
            url: '',
            body: requestBody,
            params: {
              id: this.muistiinpanoMuokkaaFormData.id,
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.$emit('muistiinpano-muokkaa-submit')
        this.muistiinpanoMuokkaaFormData = {}
        this.muistiinpanoMuokkaaDialogAuki = false

        this.$naytaOnnistumisilmoitus(this.$t('common:timeline.noteEditSaved'))
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      } finally {
        this.muistiinpanoMuokkaaFormLoading = false
      }
    },
    async poistaMuistiinpano (muistiinpano) {
      const result = await this.$swal({
          text: this.$t('common:timeline.sureDeleteNote'),
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: this.$t('common:deleteNote'),
          cancelButtonText: this.$t('common:cancel'),
        })

      if (!result.value) return

      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Muistiinpano,
          {
            method: 'DELETE',
            params: {
              id: muistiinpano.itemId
            },
          },
          'doRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        this.$emit('muistiinpano-poistettu')

        this.$naytaOnnistumisilmoitus(this.$t('common:timeline.noteDeleted'))
      } catch(e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    reply (item) {
      this.$emit('reply', item)
    },
    toggleDirection () {
      this.latestFirst = !this.latestFirst
    },
    handleKeyPress (e) {
      if (!this.enableZoom) return

      switch (e.keyCode) {
        // Minus
        case 109:
          this.zoomOut()
          break;

        // Plus
        case 107:
          this.zoomIn()
          break;
      }
    },
    zoomOut () {
      if (this.zoom == 1) return

      this.zoom--
    },
    zoomIn () {
      if (this.zoom == 3) return

      this.zoom++
    },
  },
}

</script>

<style lang="scss" scoped>

  $itemPadding: 1.3rem;
  $lineColor: lighten(#000, 85);
  $arrowSize: 1.3rem;

  .timeline {
    &__directionButton {
      margin-top: -6px;

      &--rotated {
        transform: rotate(180deg);
      }
    }

    &__items {
      margin: -$itemPadding 0;
    }
  }

  .timelineItem {
    position: relative;
    padding: $itemPadding 0;

    &__icon {
      z-index: 3;
      position: absolute;
      left: 0;
      top: $itemPadding;
    }

    &__content {
      padding: ($itemPadding / 2) 1em;
      margin-top: -($itemPadding / 2);
      margin-left: 3.5rem;

      .timelineItem--highlight & {
        background: lighten(#000, 95);
        border-radius: .5em;
      }

      .timelineItem--hasMenu & {
        padding-right: 4rem;
      }
    }

    &__text {
      &--bold {
        font-weight: bold;
      }
    }

    &__menu {
      position: absolute;
      right: $itemPadding / 2;
      top: $itemPadding;
    }

    &__line {
      z-index: 1;
      position: absolute;
      left: 1.1rem;
      width: .15rem;
      background: $lineColor;
      transform: translateX(-50%);

      &--top {
        top: 0;
        height: $itemPadding + 2rem;

        .timelineItem:first-child & {
          display: none;
        }
      }

      &--bottom {
        bottom: 0;
        height: calc(100% - 2rem);

        .timelineItem:last-child & {
          display: none;
        }
      }
    }

    &__arrow {
      z-index: 2;
      position: absolute;
      left: 1.1rem - ($arrowSize / 2);
      width: $arrowSize;
      height: $arrowSize / 2;

      .timelineItem:last-child & {
        display: none;
      }

      &--up {
        top: 3.8rem;
        transform: rotate(180deg);
      }

      &--down {
        bottom: -$itemPadding + .3rem;
      }

      &:after {
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        content: ' ';
        width: $arrowSize;
        height: $arrowSize;
        border: solid $arrowSize / 2 $lineColor;
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }

</style>
