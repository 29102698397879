var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "formSopimusLaskutusaihe",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("SopimusLaskutusaiheTable", {
        attrs: { laskutusaihe: _vm.value, laskutusjaksot: _vm.laskutusjaksot },
      }),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": !_vm.valid || _vm.loading,
          "submit-loading": _vm.loading,
          "submit-teksti": _vm.tallennuspainikkeenTeksti,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }