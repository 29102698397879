<template>
  <v-form
    ref="form"
    v-model="valid"
    class="white--text"
    @submit.prevent
  >
    <v-row>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="tila">
                {{ $t('common:status') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-select
                id="tila"
                v-model="value.tila"
                :items="tilat"
                item-text="label"
                item-value="terms"
                multiple
                clearable
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel v-if="!$store.state.user.vainYksiPaamies" for="paamiehet">
                {{ $t('common:creditors') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-theme-provider dark>
                <AutocompletePaamies
                  v-if="!$store.state.user.vainYksiPaamies"
                  :multiple="(true)"
                  :tapaus="value"
                  label=""
                  tarkistettava-oikeus="onlinerestapi.onlinerestapi_raportti"
                />
              </v-theme-provider>
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="alkamispaiva">
                {{ $t('common:starting') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <InputfieldDatePicker
                  id="alkaen"
                  v-model="value.alkaenAlkaen"
                  :label="$t('common:starting')"
                  color="white"
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <InputfieldDatePicker
                  id="loppuen"
                  v-model="value.alkaenPaattyen"
                  :label="$t('common:ending')"
                  color="white"
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="alkamispaiva">
                {{ $t('common:ending') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <InputfieldDatePicker
                  id="alkaen"
                  v-model="value.loppuenAlkaen"
                  :label="$t('common:starting')"
                  color="white"
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <InputfieldDatePicker
                  id="loppuen"
                  v-model="value.loppuenPaattyen"
                  :label="$t('common:ending')"
                  color="white"
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
      <v-col
        class="px-8 py-4"
        md="4"
      >
        <FormTable>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="luotu">
                {{ $t('common:created') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <div class="comboField">
                <InputfieldDatePicker
                  id="alkaen"
                  v-model="value.luotuAlkaen"
                  :label="$t('common:starting')"
                  color="white"
                  :dark="true"
                />
                <span class="comboField__separator">&ndash;</span>
                <InputfieldDatePicker
                  id="loppuen"
                  v-model="value.luotuPaattyen"
                  :label="$t('common:ending')"
                  color="white"
                  :dark="true"
                />
              </div>
            </FormTableCell>
          </FormTableRow>
          <FormTableRow>
            <FormTableCell :header="(true)">
              <FormTableLabel for="vain_yhteenveto">
                {{ $t('common:onlyTotal') }}
              </FormTableLabel>
            </FormTableCell>
            <FormTableCell>
              <v-checkbox
                id="vain_yhteenveto"
                v-model="value.vain_yhteenveto"
                :dark="true"
              />
            </FormTableCell>
          </FormTableRow>
        </FormTable>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'
import AutocompletePaamies from '@/components/AutocompletePaamies'
import FormTable from '@/components/FormTable'
import FormTableRow from '@/components/FormTableRow'
import FormTableCell from '@/components/FormTableCell'
import FormTableLabel from '@/components/FormTableLabel'
import InputfieldDatePicker from '@/components/InputfieldDatePicker'
import { SelectablesRaporttiTilat } from '@/utils/selectables'

export default {
  name: 'FormSearchRaportit',
  components: {
    AutocompletePaamies,
    FormTable,
    FormTableRow,
    FormTableCell,
    FormTableLabel,
    InputfieldDatePicker,
  },
  mixins: [FormMixin],
  data () {
    return {
      tilat: SelectablesRaporttiTilat,
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
