var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", { class: _vm.titleClass }, [_vm._v(" Laskutuksen yhteenveto ")]),
      _c("v-simple-table", {
        attrs: { dense: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c("th", [_vm._v("Summa")]),
                    _c("th", [_vm._v("Kpl")]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("Avoinna")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatSumToFixed2")(_vm.asiakas.avoinna_summa)
                        )
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.asiakas.avoinna_kpl))]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Maksettu")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatSumToFixed2")(
                            _vm.asiakas.maksettu_summa
                          )
                        )
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.asiakas.maksettu_kpl))]),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Perinnässä")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatSumToFixed2")(
                            _vm.asiakas.perinnassa_summa
                          )
                        )
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.asiakas.perinnassa_kpl))]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }