var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefList",
    { attrs: { bordered: true } },
    [
      _vm.value.nimi
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v("Nimi")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.nimi) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4285027340
            ),
          })
        : _vm._e(),
      _vm.value.paamies
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [_vm._v("Päämies")]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.value.paamies.nimi) +
                          " (" +
                          _vm._s(_vm.value.paamies.id) +
                          ") "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1778174441
            ),
          })
        : _vm._e(),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v(_vm._s(_vm.$sovellusIdOtsake))]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.value.id) + " ")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }