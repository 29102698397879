<template>
  <div class="ilmoitussisalto-raportti-valmis">
    <div
      role="alert"
      class="Vue-Toastification__toast-body"
    >
      Raportti valmis: {{ nimi }} (id: {{ raporttiId }})
    </div>
    <v-btn
      class="ilmoitussisalto-raportti-valmis__btn"
      color="#26c6da"
      dark
      @click="lataaRaportti"
    >
      Lataa
    </v-btn>
  </div>
</template>

<script>

import store from '@/store'

export default {
  props: {
    raporttiId: {
      type: [Number, String],
      required: true,
    },
    nimi: {
      type: String,
      required: true,
    },
    luotu: {
      type: String,
      required: true,
    },
  },
  methods: {
    async lataaRaportti () {
      try {
       const request = await store.dispatch('raportitSelaa/lataaRaportti', this.raporttiId)
       if (!request.success) throw new this.$HttpError(request)

       const lataamisConfig = {
          fileData: request.result.body,
          filename:  `${this.nimi}-${this.luotu.substr(0,10)}`,
          headers: request.result.headers,
        }
        this.$naytaTiedostonlataamisdialog(lataamisConfig)
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    }
  }
}
</script>

<style lang="scss">

.ilmoitussisalto-raportti-valmis {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__btn {
    margin-top: 8px;
    color: white;
  }
}

</style>
