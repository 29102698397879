import Vue from 'vue'

export default function cleanLineBreaks (str, config = { strict: false, truncate: false }) {
  if (str === null || typeof str === 'undefined') return null

  if (config.strict) {
    str = str.replace(/(\r\n|\r|\n)/g, ' ')
  } else {
    str = str.replace(/(\r\n|\r|\n){2,}/g, '$1\n')
  }

  if (config.truncate && str.length >= 70) {
    str = str.substring(0, 70) + '...'
  }

  return str
}

Vue.filter('cleanLineBreaks', cleanLineBreaks)
