import { render, staticRenderFns } from "./CardToimeksianto.vue?vue&type=template&id=501ed6a3&scoped=true&"
import script from "./CardToimeksianto.vue?vue&type=script&lang=js&"
export * from "./CardToimeksianto.vue?vue&type=script&lang=js&"
import style0 from "./CardToimeksianto.vue?vue&type=style&index=0&id=501ed6a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "501ed6a3",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VCol,VRow,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('501ed6a3')) {
      api.createRecord('501ed6a3', component.options)
    } else {
      api.reload('501ed6a3', component.options)
    }
    module.hot.accept("./CardToimeksianto.vue?vue&type=template&id=501ed6a3&scoped=true&", function () {
      api.rerender('501ed6a3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/CardToimeksianto.vue"
export default component.exports