<template>
  <div class="offCanvasTuote">
    <v-toolbar
      color="primary"
      flat
      tabs
    >
      <v-btn
        :dark="true"
        icon
        @click="$emit('close-button-click')"
      >
        <v-icon>clear</v-icon>
      </v-btn>

      <v-toolbar-title>
        Tuoteryhmän tiedot
      </v-toolbar-title>

      <v-spacer />
    </v-toolbar>
    <v-container class="grey lighten-5 px-8">
      <OffCanvasDetailsTuoteryhma v-model="value" />
    </v-container>
    <v-container class="px-8 pt-6">
      <v-row justify="center">
        <v-btn
          color="primary"
          class="my-2 list-btn"
          large
          @click="valitseTuoteryhmaMuokattavaksi"
        >
          <v-icon left>
            edit
          </v-icon>
          Muokkaa tuoteryhmää
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import ClickOutside from 'vue-click-outside'
import OffCanvasDetailsTuoteryhma from '@/components/OffCanvasDetailsTuoteryhma'

export default {
  name: 'OffCanvasTuote',
  directives: {
    ClickOutside,
  },
  components: {
    OffCanvasDetailsTuoteryhma
  },
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    valitseTuoteryhmaMuokattavaksi () {
      this.$emit('close-button-click')
      this.$emit('valitseTuoteryhmaMuokattavaksi', this.value)
    }
  }
}

</script>

<style lang="scss" scoped>

.list-btn {
  width: 268px;
}

</style>
