var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefList",
    { attrs: { bordered: true } },
    [
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.$t("common:status")) + " ")]
              },
              proxy: true,
            },
            _vm.value.tila && _vm.value.ikoni_ja_vari
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _c("StatusText", {
                        attrs: {
                          text: _vm.value.tila.selite,
                          color: _vm.value.ikoni_ja_vari.vari,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [
                  _vm._v(" " + _vm._s(_vm.$t("common:contractNumber")) + " "),
                ]
              },
              proxy: true,
            },
            _vm.value.nro
              ? {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.nro) + " ")]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v(_vm._s(_vm.$sovellusIdOtsake))]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.value.id) + " ")]
            },
            proxy: true,
          },
        ]),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.$t("common:customer")) + " ")]
              },
              proxy: true,
            },
            _vm.value.asiakas
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.value.asiakas.nimi) + " "),
                      _vm.value.asiakas.nro
                        ? [_vm._v(" (" + _vm._s(_vm.value.asiakas.nro) + ") ")]
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.$t("common:creditor")) + " ")]
              },
              proxy: true,
            },
            _vm.value.paamies
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.value.paamies.nimi) + " "),
                      _vm.value.paamies.id
                        ? [_vm._v(" (" + _vm._s(_vm.value.paamies.id) + ") ")]
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [
                  _vm._v(" " + _vm._s(_vm.$t("common:startingDate")) + " "),
                ]
              },
              proxy: true,
            },
            _vm.value.alkamispaiva
              ? {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$date(_vm.value.alkamispaiva)) + " "
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("DefListItem", {
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.$t("common:endingDate")) + " ")]
            },
            proxy: true,
          },
          {
            key: "value",
            fn: function () {
              return [
                _vm.value.loppumispaiva
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.$date(_vm.value.loppumispaiva))),
                    ])
                  : _c("span", [_vm._v(_vm._s(_vm.$t("common:validForNow")))]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.value.laskutuspaiva && _vm.value.seuraava_laskutuspaiva
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$t("common:nextInvoiceDate")) + " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$date(_vm.value.seuraava_laskutuspaiva)) +
                          " "
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              697323600
            ),
          })
        : _vm.value.erapaiva && _vm.value.seuraava_erapaiva
        ? _c("DefListItem", {
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _vm._v(" " + _vm._s(_vm.$t("common:nextDueDate")) + " "),
                  ]
                },
                proxy: true,
              },
              {
                key: "value",
                fn: function () {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$date(_vm.value.seuraava_erapaiva)) + " "
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          })
        : _vm._e(),
      _c("DefListItem", {
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.$t("common:reference")) + " ")]
              },
              proxy: true,
            },
            _vm.value.viite
              ? {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.viite) + " ")]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _vm.value.viitteemme
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(" " + _vm._s(_vm.$t("common:ourReference")) + " "),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.viitteemme) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              835402039
            ),
          })
        : _vm._e(),
      _vm.value.viitteenne
        ? _c("DefListItem", {
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$t("common:yourReference")) + " "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "value",
                  fn: function () {
                    return [_vm._v(" " + _vm._s(_vm.value.viitteenne) + " ")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              678380590
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }