var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "white--text",
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "nimi" } }, [
                            _vm._v(" Nimi "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "nimi",
                              type: "text",
                              label: "",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.nimi,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "nimi", $$v)
                              },
                              expression: "value.nimi",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "nro" } }, [
                            _vm._v(" " + _vm._s(_vm.nroOtsake) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: { id: "nro", clearable: "", dark: true },
                            model: {
                              value: _vm.value.nro,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "nro", $$v)
                              },
                              expression: "value.nro",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "puh" } }, [
                            _vm._v(" Puhelinnumero "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: { id: "puh", clearable: "", dark: true },
                            model: {
                              value: _vm.value.puh,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "puh", $$v)
                              },
                              expression: "value.puh",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "paamies" } }, [
                            _vm._v(" Päämies "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "paamies",
                              color: "white",
                              label: "Nimi tai numero",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.paamies,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "paamies", $$v)
                              },
                              expression: "value.paamies",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c(
                            "FormTableLabel",
                            { attrs: { for: "vastapuoliTyyppi" } },
                            [_vm._v(" " + _vm._s(_vm.tyyppiOtsake) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-select", {
                            attrs: {
                              id: "vastapuoliTyyppi",
                              items: _vm.asiakasTyypit,
                              "item-text": "label",
                              "item-value": "terms",
                              multiple: "",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.tyyppi,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "tyyppi", $$v)
                              },
                              expression: "value.tyyppi",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-8 py-4", attrs: { md: "4" } },
            [
              _c(
                "FormTable",
                [
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "tunnus" } }, [
                            _vm._v(" Tunnus "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "tunnus",
                              color: "white",
                              label: "Y-tunnus / Hetu",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.tunnus,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "tunnus", $$v)
                              },
                              expression: "value.tunnus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormTableRow",
                    [
                      _c(
                        "FormTableCell",
                        { attrs: { header: true } },
                        [
                          _c("FormTableLabel", { attrs: { for: "email" } }, [
                            _vm._v(" Sähköposti "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "FormTableCell",
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "email",
                              color: "white",
                              clearable: "",
                              dark: true,
                            },
                            model: {
                              value: _vm.value.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "email", $$v)
                              },
                              expression: "value.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }