var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "pt-3",
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-text-field",
        _vm._b(
          {
            class: _vm.inputClass,
            attrs: {
              label: _vm.label,
              clearable: "",
              dark: "",
              "single-line": "",
              "prepend-inner-icon": "search",
              color: "white",
              hint: _vm.inputHint,
            },
            on: {
              "click:append-outer": function ($event) {
                return _vm.$emit("click:append-outer")
              },
            },
            model: {
              value: _vm.valueMutable,
              callback: function ($$v) {
                _vm.valueMutable = $$v
              },
              expression: "valueMutable",
            },
          },
          "v-text-field",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }