<template>
  <div
    ref="wrap"
    class="offCanvasMessage"
  >
    <div>
      <v-toolbar
        color="primary"
        flat
        class="px-1"
      >
        <v-btn
          :dark="true"
          icon
          @click="$emit('close-button-click')"
        >
          <v-icon>clear</v-icon>
        </v-btn>

        <v-toolbar-title>
          {{ viestinTiedotTeksti }}
        </v-toolbar-title>

        <v-spacer />

        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-show="linkTooltip"
              :dark="true"
              icon
              class="white v-btn--has-bg primary--text"
              :to="linkRoute"
              v-on="on"
            >
              <v-icon>open_in_new</v-icon>
            </v-btn>
          </template>
          <template v-slot:default>
            <span>{{ linkTooltip }}</span>
          </template>
        </v-tooltip>
      </v-toolbar>
      <v-container class="grey lighten-5 px-8">
        <OffCanvasDetailsLasku
          v-if="value.lasku"
          v-model="value.tapausData"
        />
        <OffCanvasDetailsOstolasku
          v-else-if="value.ostolasku"
          v-model="value.tapausData"
        />
        <OffCanvasDetailsToimeksianto
          v-else-if="value.toimeksianto"
          v-model="value.tapausData"
        />
      </v-container>
    </div>
    <v-container
      ref="content"
      class="px-8"
    >
      <Timeline
        title="Viestihistoria"
        :items="messages"
        :show-title="(false)"
        :enable-direction-toggle="(false)"
        :show-item-titles="(false)"
        @msg-mark-as-unread="$emit('msg-mark-as-unread')"
        @msg-mark-as-read="$emit('msg-mark-as-unread')"
      />
    </v-container>
    <div
      v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_viesti_lahetys')"
      class="sticky sticky--bottom"
    >
      <v-container class="white px-9">
        <FormUusiViesti
          v-model="uusiViestiFormData"
          :loading="uusiViestiFormLoading"
          @submit="postMessage"
        />
      </v-container>
    </div>
  </div>
</template>

<script>

import Timeline from '@/components/Timeline'
import FormUusiViesti from '@/components/FormUusiViesti'
import OffCanvasDetailsLasku from '@/components/OffCanvasDetailsLasku'
import OffCanvasDetailsOstolasku from '@/components/OffCanvasDetailsOstolasku'
import OffCanvasDetailsToimeksianto from '@/components/OffCanvasDetailsToimeksianto'

export default {
  name: 'OffCanvasMessage',
  components: {
    Timeline,
    FormUusiViesti,
    OffCanvasDetailsLasku,
    OffCanvasDetailsOstolasku,
    OffCanvasDetailsToimeksianto,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
  data () {
    return {
      linkTooltip: '',
      linkRoute: {},
      messages: [],
      uusiViestiFormLoading: false,
      uusiViestiFormData: {},
    }
  },
  computed: {
    viestinTiedotTeksti () {
      let teksti = 'Viestin tiedot'
      if (this.value.toimeksianto) {
        teksti += ' (toimeksianto)'
      } else if (this.value.lasku) {
        teksti += ' (lasku)'
      }

      return teksti
    },
  },
  mounted () {
    this.linkTooltip = ''
    this.linkRoute = {}
    this.messages = []
    this.uusiViestiFormData = {}

    if (typeof this.value.id != 'undefined') {
      if (this.value.toimeksianto) {
        this.linkRoute = {
          name: 'perinta-nayta',
          params: {
            id: this.value.toimeksianto,
          },
        }

        this.linkTooltip = 'Avaa toimeksianto'
      } else if (this.value.lasku) {
        this.linkRoute = {
          name: 'laskutus-nayta',
          params: {
            id: this.value.lasku.id,
          },
        }

        this.linkTooltip = 'Avaa lasku'
      } else {
        this.linkTooltip = ''
      }

      if (this.value.tapausData) {
        if (this.value.tapausData.aikajana) {
          this.messages = this.value.tapausData.aikajana.filter(item => item.tyyppi == 'PaamiesViesti')
        }
      }
    }
  },

  updated () {
    this.$nextTick().then(() => {
      if (this.$refs['content'] && this.$refs['wrap']) {
        this.$refs['wrap'].parentElement.scrollTop = 200000
      }
    })
  },
  methods: {
    async postMessage () {
      try {
        this.uusiViestiFormLoading = true

        const requestBody = {
          sisalto: this.uusiViestiFormData.message,
        }
        let request

        if (this.value.toimeksianto) {
          request = await this.$doRequestWithTimeout(
            this.$api.Toimeksianto,
            {
              method: 'POST',
              url: 'toiminnot/viesti/',
              body: requestBody,
              params: {
                id: this.value.toimeksianto
              },
            },
            'doSingleRequest',
          )
        } else if (this.value.lasku) {
          request = await this.$doRequestWithTimeout(
            this.$api.Lasku,
            {
              method: 'POST',
              url: 'toiminnot/viesti/',
              body: requestBody,
              params: {
                id: this.value.lasku.id
              },
            },
            'doSingleRequest',
          )
        } else if (this.value.ostolasku) {
          request = await this.$doRequestWithTimeout(
            this.$api.Ostolasku,
            {
              method: 'POST',
              url: 'toiminnot/viesti/',
              body: requestBody,
              params: {
                id: this.value.ostolasku.id
              },
            },
            'doSingleRequest',
          )
        }

        if (!request.success) throw new this.$HttpError(request)

        this.$emit('messageSubmit')
        this.uusiViestiFormData = {}
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Viestin lähetys epäonnistui!')
      } finally {
        this.uusiViestiFormLoading = false
      }
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
