<script>
import { Line, mixins } from 'vue-chartjs'

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,

      required: false,
      default() {
        return {
          labels: ['January', 'February', 'March'],
          datasets: [{ data: [40, 20, 12] }],
        }
      },
    },
  },

  computed: {
    options() {
      return {
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let thisLabel = data.labels[tooltipItem.index]

              return thisLabel
            },
          },
        },
        legend: {
          position: 'left',
        },
        responsive: false,
      }
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}
</script>
