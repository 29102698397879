import Laskurivi from './Laskurivi'

const SopimusLaskutusaihe = class extends Laskurivi {
  constructor (preset = {}) {
    super(preset)
    this.alennusp = preset.alennusp ? preset.alennusp : 0
    this.laskutusjakso = preset.laskutusjakso ? preset.laskutusjakso : null
    this.laskutuspaiva = preset.laskutuspaiva ? preset.laskutuspaiva : null
  }

  getPostData () {
    const postData = Laskurivi.prototype.getPostData.call(this)
    delete postData.alvp // Käytetään vain alvtunnusta
    postData.alennusp = this.alennusp
    postData.laskutusjakso = this.laskutusjakso
    postData.laskutuspaiva = this.laskutuspaiva
    return postData
  }
}

export default SopimusLaskutusaihe
