import { render, staticRenderFns } from "./FormPaamies.vue?vue&type=template&id=11308576&scoped=true&"
import script from "./FormPaamies.vue?vue&type=script&lang=js&"
export * from "./FormPaamies.vue?vue&type=script&lang=js&"
import style0 from "./FormPaamies.vue?vue&type=style&index=0&id=11308576&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11308576",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VCombobox,VForm,VRow,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11308576')) {
      api.createRecord('11308576', component.options)
    } else {
      api.reload('11308576', component.options)
    }
    module.hot.accept("./FormPaamies.vue?vue&type=template&id=11308576&scoped=true&", function () {
      api.rerender('11308576', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FormPaamies.vue"
export default component.exports