<template>
  <FormPalveluhakemusBase
    v-on="$listeners"
  >
    <v-text-field
      v-model="kayttaja.email"
      :label="$t('email')"
      :rules="[$validationRules.required, $validationRules.email]"
      outlined
      required
    />
    <v-text-field
      v-model="tunnus"
      :label="$t('companyId')"
      class="required"
      :rules="[$validationRules.required]"
      :error-messages="tunnusErrors"
      outlined
      @blur="tarkistaTunnuksenOikeellisuus(tunnus)"
    />
    <v-text-field
      v-model="salasana"
      :label="$t('password')"
      class="required"
      :append-icon="(naytaSalasana ? 'visibility_off' : 'visibility')"
      :type="(naytaSalasana ? 'text' : 'password')"
      :rules="[$validationRules.required]"
      outlined
      required
      :error-messages="salasanaErrors"
      @click:append="naytaSalasana = !naytaSalasana"
    />
    <v-text-field
      v-model="salasanaUudelleen"
      :label="$t('serviceApplication.repeatPassword')"
      class="required"
      :append-icon="(naytaSalasana ? 'visibility_off' : 'visibility')"
      :type="(naytaSalasana ? 'text' : 'password')"
      :rules="[$validationRules.required]"
      outlined
      required
      :error-messages="salasanaUudelleenErrors"
      @click:append="naytaSalasana = !naytaSalasana"
    />
    <v-checkbox
      v-model="palveluehdotHyvaksytty"
      dense
      :rules="[$validationRules.required]"
    >
      <template v-slot:label>
        <span>{{ $t('serviceApplication.IAccept') }}&nbsp;<a
          class="font-weight-bold"
          @click.stop.prevent="palveluehdotAuki = true"
        >{{ $t('serviceApplication.termsofService') }}</a> *</span>
      </template>
    </v-checkbox>
    <v-checkbox
      v-model="tietosuojaselosteHyvaksytty"
      dense
      :rules="[$validationRules.required]"
    >
      <template v-slot:label>
        <span>{{ $t('serviceApplication.IHaveRead') }}&nbsp;<a
          class="font-weight-bold"
          @click.stop.prevent="tietosuojaselosteAuki = true"
        >{{ $t('serviceApplication.thePrivacyPolicy') }}</a> *</span>
      </template>
    </v-checkbox>
    <v-checkbox
      v-model="markkinointiViestitHyvaksytty"
      dense
      :label="$t('serviceApplication.sendMeMarketingEmail')"
    ></v-checkbox>

    <CardDialog
      :dialog-auki="palveluehdotAuki"
      :title="$t('uuvaCloudTermsOfService')"
      width="70%"
      @close="palveluehdotAuki = false"
    >
      <iframe
        src="/palveluehdot.html"
        width="100%"
        class="teksti-iframe"
        sandbox="allow-same-origin allow-scripts"
      />
    </CardDialog>

    <CardDialog
      :dialog-auki="tietosuojaselosteAuki"
      :title="$t('privacyPolicy')"
      width="70%"
      @close="tietosuojaselosteAuki = false"
    >
      <iframe
        src="/tietosuojaseloste.html"
        width="100%"
        class="teksti-iframe"
        sandbox="allow-same-origin allow-scripts"
      />
    </CardDialog>
  </FormPalveluhakemusBase>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'
import FormPalveluhakemusBase from '@/components/FormPalveluhakemusBase'
import { mapState } from 'vuex'

export default {

  name: 'FormPalveluhakemusPerustiedot',
  components: {
    FormPalveluhakemusBase,
  },
  mixins: [FormMixin],
  data () {
    return {
      debounceTimeout: '',
      naytaSalasana: false,
      palveluehdotAuki: false,
      salasanaErrors: [],
      salasanaUudelleenErrors: [],
      salasanaRegex: /^(?=.*[A-Za-z])(?=.*[@$!%*#?&_,.-]).{12,}$/,
      tunnusErrors: [],
      tietosuojaselosteAuki: false,
    }
  },
  computed: {
    ...mapState({
      kayttaja: state => state.palveluhakemus.kayttaja,
      paamies: state => state.palveluhakemus.paamies,
    }),
    tunnus: {
      get () {
        return this.$store.state.palveluhakemus.paamies.tunnus
      },
      set (val) {
        this.$store.commit('palveluhakemus/setPaamiesTunnus', val)
      },
    },
    salasana: {
      get () {
        return this.$store.state.palveluhakemus.kayttaja.salasana
      },
      set (val) {
        this.$store.commit('palveluhakemus/setKayttajaSalasana', val)
      },
    },
    salasanaUudelleen: {
      get () {
        return this.$store.state.palveluhakemus.kayttaja.salasanaUudelleen
      },
      set (val) {
        this.$store.commit('palveluhakemus/setKayttajaSalasanaUudelleen', val)
      },
    },
    palveluehdotHyvaksytty: {
      get () {
        return this.$store.state.palveluhakemus.kayttaja.palveluehdotHyvaksytty
      },
      set (val) {
        this.$store.commit('palveluhakemus/setKayttajaPalveluehdotHyvaksytty', val)
      },
    },
    tietosuojaselosteHyvaksytty: {
      get () {
        return this.$store.state.palveluhakemus.kayttaja.tietosuojaselosteHyvaksytty
      },
      set (val) {
        this.$store.commit('palveluhakemus/setKayttajaTietosuojaselosteHyvaksytty', val)
      },
    },
    markkinointiViestitHyvaksytty: {
      get () {
        return this.$store.state.palveluhakemus.kayttaja.markkinointiViestitHyvaksytty
      },
      set (val) {
        this.$store.commit('palveluhakemus/setKayttajaMarkkinointiViestitHyvaksytty', val)
      },
    },
  },
  watch: {
    'paamies.tunnus': {
      handler (val) {
        if (!val) return

        this.debounceTimeout = setTimeout(async () => {
          if (val.length < 9) return

          await this.tarkistaTunnuksenOikeellisuus(val)
          if (this.tunnusErrors.length) return

          try {
            this.$store.dispatch('palveluhakemus/haeYTJsta', val)
          } catch (e) {
            this.$sentryCaptureCustom(e)
          }
        }, 300)
      }
    },
    'kayttaja.salasana': {
      handler (val) {
        if (!this.kayttaja.salasana || !this.kayttaja.salasana.match(this.salasanaRegex)) {
          this.salasanaErrors = [
            this.$t('serviceApplication.passwordRequirements')
          ]
        } else if (this.kayttaja.salasanaUudelleen && this.kayttaja.salasanaUudelleen !== val) {
          this.salasanaUudelleenErrors = [this.$t('serviceApplication.passwordsDontMatch')]
        } else {
          this.salasanaErrors = []
        }
      }
    },
    'kayttaja.salasanaUudelleen': {
      handler (val) {
        if (val && val !== this.kayttaja.salasana) {
          this.salasanaUudelleenErrors = [this.$t('serviceApplication.passwordsDontMatch')]
        } else {
          this.salasanaUudelleenErrors = []
        }
      }
    }
  },
  methods: {
    async tarkistaTunnuksenOikeellisuus (tunnus) {
      this.tunnusErrors = []

      try {
        const request = await this.$store.dispatch('palveluhakemus/tarkistaYTunnus', tunnus)
        if (!request.success) throw new this.$HttpError(request)

        const tunnusOnOikeellinen = request.result.body
        if (!tunnusOnOikeellinen && this.paamies.tunnus) {
          this.tunnusErrors = [this.$t('checkId')]
        } else {
          this.tunnusErrors = []
        }
      } catch (e) {
        this.$sentryCaptureCustom(e)
      }
    },
    async tarkistaSahkopostiosoite () {
      this.loading = true

      try {
        const queryParams = new URLSearchParams()
        queryParams.append('email', this.email)

        const request = await this.$store.dispatch('palveluhakemus/tarkistaSahkopostiosoite', queryParams)

        if (!request.success) {
          const { body } = request.result
          if (request.result.status === 400 && body && body.length && body[0] === 'v') {
            // Annetulla sähköpostiosoitteella on jo tunnus palveluun. Näytetään epäselvä viesti,
            // jotta ei suoraan kerrota kenelle tahansa sähköpostilla olevan jo tunnus palveluun.
            this.$naytaVaroitusilmoitus('Olemme lähettäneet sinulle sähköpostia. Tarkista sähköpostisi jatkaaksesi tunnusten luomista.', { timeout: 10000 })

            return false
          } else {
            throw new this.$HttpError(request)
          }
        }

        return true
      } catch (e) {
        console.log(e)
        this.$oletusVirheenkasittely(e)
        return false
      } finally {
        this.loading = false
      }
    },
  }
}

</script>

<style lang="scss" scoped>

</style>
