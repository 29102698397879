/**
 * https://stackoverflow.com/questions/48327261/way-to-make-inheritance-in-vuex-modules/56790942#56790942
 */

import api from '@/plugins/api'
import { Asiakas } from '@/class/Asiakas'

import { HttpError } from '@/utils/errors'
import {
  doRequestWithTimeout
} from '@/utils/misc'
import BaseModule from '@/store/modules/baseModule'

export default class LaskutusAsiakasNayta extends BaseModule {
  state () {
    return {
      ...super.state(),
      asiakas: {
        avoinna: null,
        ikoni_ja_vari: {
          ikoni: null,
          vari: null,
        },
        kirje_set: [],
        paamies: {
          nimi: '',
        },
        puhelin: {},
        tila: {},
      },
      kenttienValinnat: {},
      muistiinpanoMuokkaaFormData: {},
      uusiMuistiinpanoFormData: {},
    }
  }

  mutations () {
    return {
      setAsiakas (state, payload) {
        state.asiakas = payload
      },
      setKenttienValinnat (state, payload) {
        state.kenttienValinnat = payload
      },
      setMuistiinpanoMuokkaaFormData (state, payload) {
        state.muistiinpanoMuokkaaFormData = payload
      },
      setUusiMuistiinpanoFormData (state, payload) {
        state.uusiMuistiinpanoFormData = payload
      },
    }
  }

  actions () {
    return {
      async clearDetails ({ commit }) {
        commit('setAsiakas', {})
        commit('setMuistiinpanoMuokkaaFormData', {})
        commit('setUusiMuistiinpanoFormData', {})
      },
      async loadItem ({ commit }, sroute) {
        const request = await doRequestWithTimeout(
          api.Asiakas,
          {
            method: 'GET',
            url: '',
            params: {
              id: sroute.params.id
            },
          },
          'pushRequest',
        )
        if (!request.success) throw new HttpError(request)

        commit('setAsiakas', request.result.body)
      },
      async muistiinpanoMuokkaaSubmit ({ dispatch, commit, state }, sroute) {
        const requestBody = {
          data: state.muistiinpanoMuokkaaFormData.message,
        }

        const request = await doRequestWithTimeout(
          api.Muistiinpano,
          {
            method: 'PUT',
            url: '',
            body: requestBody,
            params: {
              id: state.muistiinpanoMuokkaaFormData.id,
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new HttpError(request)

        dispatch('loadItem', sroute)
        commit('setMuistiinpanoMuokkaaFormData', {})

      },
      async muistiinpanoSubmit ({ commit, state }) {
        const requestBody = {
          muistiinpano: state.uusiMuistiinpanoFormData.message,
        }

        const request = await doRequestWithTimeout(
          api.Asiakas,
          {
            method: 'POST',
            url: 'toiminnot/muistiinpano/',
            body: requestBody,
            params: {
              id: state.asiakas.id,
            }
          },
          'doSingleRequest',
        )

        if (!request.success) throw new HttpError(request)

        commit('setAsiakas', request.result.body)
        commit('setUusiMuistiinpanoFormData',  {})
      },
      alustaDialogMuistiinpanoMuokkaa ({ commit }, muistiinpano) {
        const muistiinpanoMuokkaaFormData = {
          id: muistiinpano.id,
          message: muistiinpano.sisalto,
        }
        commit('setMuistiinpanoMuokkaaFormData', muistiinpanoMuokkaaFormData)
      },
      async poistaMuistiinpano ({ dispatch }, { sroute, id }) {
        const request = await doRequestWithTimeout(
          api.Muistiinpano,
          {
            method: 'DELETE',
            params: { id },
          },
          'doRequest',
        )

        if (!request.success) throw new HttpError(request)

        dispatch('loadItem', sroute)
      },
      async haeAsiakkaanKenttienValinnat ({ commit }, asiakas_id) {
        const kenttienValinnat = await Asiakas.haeAsiakkaanKenttienValinnat(asiakas_id)
        commit('setKenttienValinnat', kenttienValinnat)
      },
    }
  }
}
