var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-text-field", {
        attrs: {
          rules: [_vm.$validationRules.required],
          label: "Tunniste",
          autocomplete: "off",
          autofocus: "",
        },
        model: {
          value: _vm.value.tunniste,
          callback: function ($$v) {
            _vm.$set(_vm.value, "tunniste", $$v)
          },
          expression: "value.tunniste",
        },
      }),
      _c(
        "v-btn",
        { attrs: { type: "submit", disabled: !_vm.valid, color: "success" } },
        [_vm._v(" Lähetä ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }