<template>
  <TyopoytaPikanappain
    height="100%"
    :klikattava="false"
  >
    <template #otsikko>
      <span class="tyopoyta-myynnin-kuvaaja--otsikko">Myynti</span>
    </template>
    <template>
      <div
        class="d-flex white justify-center primary--text text-h6 py-4 rounded"
        :class="numeroClass"
      >
        <span>{{ aikajanteenMyynti | formatSumToFixed2 }}</span>
      </div>
      <div class="mt-4 d-flex justify-center primary--text">
        <v-select
          v-model="aikajanneValinta"
          class="px-12"
          type="text"
          :items="myyntiItems"
          item-value="aikajanne"
          :item-text="aikajanteenSelite"
          :menu-props="{ zIndex: '999' }"
          filled
          dense
          outlined
          color="primary"
        >
        </v-select>
      </div>
    </template>
  </TyopoytaPikanappain>
</template>

<script>
import TyopoytaPikanappain from '@/components/TyopoytaPikanappain'

export default {
  name: 'TyopoytaMyynninKuvaaja',
  components: {
    TyopoytaPikanappain,
  },
  props: {
    laskuMyyntiData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      aikajanneValinta: '1kk',
      numeroClass: '',
      selitteet: {
        vuosi: 'Vuodessa',
        '3kk': '3 kuukaudessa',
        '1kk': '1 kuukaudessa',
        viikko: 'Viikossa',
        paiva: 'Päivässä',
      },
    }
  },
  computed: {
    aikajanteenMyynti() {
      const aikajanne = this.laskuMyyntiData.find(
        (item) => item.aikajanne === this.aikajanneValinta
      )
      return (aikajanne && aikajanne.summa) || 0
    },
    myyntiItems() {
      return this.laskuMyyntiData || []
    },
  },
  watch: {
    aikajanneValinta() {
      this.valayta()
    },
  },
  methods: {
    aikajanteenSelite(item) {
      return this.selitteet[item.aikajanne]
    },
    valayta() {
      this.numeroClass = ''

      setTimeout(() => {
        this.numeroClass = 'taustavalahdys'
      }, 50)
    },
  },
}
</script>

<style lang="scss" scoped>
/* keyframes valayta {
  0% {
    background-color: initial !important;
    border-radius: 15px !important;
  }
  50% {
    background-color: var(--v-primary-lighten1);
    border-radius: 15px !important;
  }
  100% {
    background-color: initial !important;
    border-radius: 15px !important;
  }
}
*/

.taustavalahdys {
  animation-name: valayta;
  animation-duration: 0.2s;
}

.tyopoyta-myynnin-kuvaaja {
  &--otsikko {
    font-size: 1rem;
  }
}
</style>
