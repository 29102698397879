var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-tooltip", {
    attrs: { top: "", disabled: !_vm.infoteksti },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function (ref) {
          var on = ref.on
          return [
            _c(
              "v-btn",
              _vm._g(
                {
                  class: _vm.kaikkiLuokat,
                  attrs: {
                    fab: "",
                    small: "",
                    depressed: "",
                    color: _vm.taustavari,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("click")
                    },
                  },
                },
                on
              ),
              [
                _c("v-icon", { attrs: { color: "ikoniVari" } }, [
                  _vm._v(" " + _vm._s(_vm.ikoni) + " "),
                ]),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.infoteksti) + " ")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }