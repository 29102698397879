import api from '@/plugins/api'
import { HttpError } from '@/utils/errors'
import {
  doRequestWithTimeout,
  sentryCaptureCustom,
} from '@/utils/misc'

export default {
  namespaced: true,
  state: {
    ALVTunnusItems: [],
    maat: [],
    verkkolaskuoperaattorit: [],
    // Asetetaan oletuksena ostotiliItemsin sisällöksi null, jonka perusteella päätellään onko
    // käyttäjän päämiehiin liittyviä ostotilejä haettu. Tällä vältetään turhat kyselyt jos tilejä on 0.
    ostotiliItems: [null],
    tiliointitiliItems: [],
    muokattavaTuote: {}
  },
  getters: {
    tiliointitiliItems: state => {
      return state.tiliointitiliItems
    },
    haeTiliointitili: state => id => {
      return state.tiliointitiliItems.find(tili => tili.id === id)
    },
    getMuokattavaTuote: state => {
      return state.muokattavaTuote
    },
  },
  mutations: {
    setALVTunnusItems (state, payload) {
      state.ALVTunnusItems = payload
    },
    setMaat (state, payload) {
      state.maat = payload
    },
    setVerkkolaskuoperaattorit (state, payload) {
      state.verkkolaskuoperaattorit = payload
    },
    setOstotiliItems (state, payload) {
      state.ostotiliItems = payload
    },
    setTiliointitiliItems (state, payload) {
      state.tiliointitiliItems = payload
    },
    setMuokattavaTuote (state, payload) {
      state.muokattavaTuote = payload
    },
  },
  actions: {
    async haeALVTunnukset ({ commit }) {
      try {
        const request = await doRequestWithTimeout(api.ALVTunnus, { url: '' })
        if (!request.success) throw new HttpError(request)
        const ALVTunnusItems = request.result.body
        commit('setALVTunnusItems', ALVTunnusItems)
      } catch (e) {
        sentryCaptureCustom(e)
        return []
      }
    },
    async haeMaavaihtoehdot ({ commit }) {
      try {
        const request = await doRequestWithTimeout(api.Yleinen, { url: 'maavaihtoehdot/' })
        if (!request.success) throw new HttpError(request)
        const maat = request.result.body.maat
        commit('setMaat', maat)
      } catch (e) {
        sentryCaptureCustom(e)
        return []
      }
    },
    async haeVerkkolaskuoperaattorit ({ commit }) {
      try {
        const request = await doRequestWithTimeout(
          api.Finvoice,
          { url: 'finvoice_operaattori_nimi/' },
        )
        if (!request.success) throw new HttpError(request)
        const verkkolaskuoperaattorit = request.result.body
        commit('setVerkkolaskuoperaattorit', verkkolaskuoperaattorit)
      } catch (e) {
        sentryCaptureCustom(e)
        return []
      }
    },
    async haeOstotilit ({ commit }) {
      try {
        const request = await doRequestWithTimeout(api.Ostotili, { url: '' })
        if (!request.success) throw new HttpError(request)
        const ostotiliItems = request.result.body.results
        commit('setOstotiliItems', ostotiliItems)
      } catch (e) {
        sentryCaptureCustom(e)
        return []
      }
    },
    async haeTiliointitilit ({ commit }) {
      try {
        const query = new URLSearchParams()
        query.append('psize', 99999)
        const request = await doRequestWithTimeout(api.Tiliointitilit, { query: query.toString(), url: '' })
        if (!request.success) throw new HttpError(request)
        const tiliointitiliItems = request.result.body.results
        commit('setTiliointitiliItems', tiliointitiliItems)
      } catch (e) {
        sentryCaptureCustom(e)
        return []
      }
    },
  }
}
