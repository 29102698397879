<template>
  <FormRaporttiAsetuksetBase>
    <template v-slot:oikea-sarake>
      <v-checkbox
        v-model="raporttilomake.asetukset.vain_yhteenveto"
        label="Vain yhteenveto"
      ></v-checkbox>
    </template>
  </FormRaporttiAsetuksetBase>
</template>

<script>

import { mapState } from 'vuex'
import FormRaporttiAsetuksetBase from '@/components/FormRaporttiAsetuksetBase'
import { ReskontraRaportti } from '@/class/Raportti'

export default {
  name: 'FormRaporttiAsetuksetReskontraRaportti',
  components: {
    FormRaporttiAsetuksetBase,
  },
  computed: {
    ...mapState({
      raporttilomake: state => state.raportitUusi.raporttilomake,
    })
  },
  created () {
    this.$store.commit('raportitUusi/setRaporttilomakeAsetukset', ReskontraRaportti.oletusasetukset)
  }
}
</script>

<style>

</style>
