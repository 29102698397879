var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.languages.length
    ? _c(
        "ul",
        { staticClass: "languageSwitch" },
        _vm._l(_vm.getLanguagesNotSelected, function (item) {
          return _c(
            "li",
            { key: item, staticClass: "languageSwitch__item" },
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.changeLanguage(item)
                    },
                  },
                },
                [
                  _c("v-list-item-avatar", [
                    _vm._v(" " + _vm._s(item.toUpperCase()) + " "),
                  ]),
                  _c("v-list-item-title", [_vm._v("Vaihda kieli")]),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }