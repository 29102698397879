<template>
  <v-data-table
    :items="items"
    :headers="tableHeadersToimeksiantoAsiakirja"
    :hide-default-footer="items.length <= 10"
    :dense="tiivisNakyma"
  >
    <template v-slot:item="{ item }">
      <TableRow>
        <template v-if="item.nimi">
          <td>
            <a @click="haeAsiakirja(item)">{{ item.nimi }}</a>
          </td>
          <td class="text-no-wrap">
            {{ $date(item.luotu) }}
          </td>
          <td class="text-right">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  :small="tiivisNakyma"
                  v-on="on"
                  @click="haeAsiakirja(item)"
                >
                  <v-icon :small="tiivisNakyma">
                    anitta-icon-file-download
                  </v-icon>
                </v-btn>
              </template>
              <template
                v-slot:default
              >
                <span>{{ $t('common:dataTableToimeksiantoAsiakirja.downloadAttachment') }}</span>
              </template>
            </v-tooltip>
            <!-- Toteutetaan poistaminen tarvittaessa -->
            <!-- <v-tooltip
              top
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  :small="tiivisNakyma"
                  v-on="on"
                  @click="avaaDialogPoistaTiedosto(item)"
                >
                  <v-icon :small="tiivisNakyma">
                    delete
                  </v-icon>
                </v-btn>
              </template>
              <template
                v-slot:default
              >
                <span>Poista liite</span>
              </template>
            </v-tooltip> -->
          </td>
        </template>
      </TableRow>
    </template>
  </v-data-table>
</template>

<script>

import { TableHeadersToimeksiantoAsiakirja } from '@/utils/tableHeaders'
import TiivisNakymaMixin from '@/mixins/TiivisNakymaMixin'

export default {
  name: 'DataTableToimeksiantoAsiakirja',
  mixins: [TiivisNakymaMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    toimeksianto: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      tableHeadersToimeksiantoAsiakirja: TableHeadersToimeksiantoAsiakirja,
    }
  },
  methods: {
    async avaaDialogPoistaTiedosto (item) {
      const result = await this.$swal({
          text: this.$t('common:dataTableToimeksiantoAsiakirja.sureFileDelete'),
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: this.$t('common:dataTableToimeksiantoAsiakirja.deleteFile'),
          cancelButtonText: this.$t('common:cancel'),
        })

      if (!result.value) return

      this.poistaTiedosto(item)
    },

    async poistaTiedosto (poistettavaTiedosto) {
      // Toteutetaan tarvittaessa
      // try {
      //   const request = await this.$doRequestWithTimeout(
      //     this.$api...,
      //     {
      //       url: `toiminnot/poista_liite/${poistettavaTiedosto.id}/`,
      //       method: 'delete',
      //     },
      //     'doSingleRequest',
      //   )

      //   if (!request.success) throw new this.$HttpError(request, 'Tiedoston poistaminen epäonnistui.')

      //   this.$naytaOnnistumisilmoitus('Tiedosto poistettu.')
      //   this.$emit('deleteCompleted', poistettavaTiedosto)
      // } catch (e) {
      //   this.$oletusVirheenkasittely(e)
      // }
    },

    async haeAsiakirja (item) {
      const { id, nimi } = item
      try {
        const options = {
          parser: 'arrayBuffer',
          url: `toiminnot/asiakirja/${id}/`,
          params: {
            id: this.toimeksianto.id
          }
        }

        const request = await this.$doRequestWithTimeout(
          this.$api.Toimeksianto,
          options,
        )

        if (request && !request.success) throw new this.$HttpError(request)

        this.$naytaTiedostonlataamisdialog({
          fileData: request.result.body,
          filename: nimi,
          headers: request.result.headers,
        })
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
  }
}

</script>

<style lang="scss" scoped>

</style>
