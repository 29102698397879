var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sticky" },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "px-2",
          attrs: { color: "primary", flat: "", tabs: "" },
        },
        [
          _vm.$route.meta.title
            ? _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.$route.meta.title) + " "),
              ])
            : _vm._e(),
          _c("v-spacer"),
          _vm._t("default"),
          _c("v-spacer"),
          !_vm.searchPanelOpen
            ? [
                _c("FormSearch", {
                  ref: "formSearch",
                  attrs: {
                    label: _vm.pikahaunPlaceholderTeksti,
                    "input-hint":
                      _vm.currentTab && _vm.currentTab !== "kaikki"
                        ? _vm.$t("common:noteTabAffectsSearch")
                        : "",
                  },
                  model: {
                    value: _vm.searchQuery,
                    callback: function ($$v) {
                      _vm.searchQuery = $$v
                    },
                    expression: "searchQuery",
                  },
                }),
                _vm.searchPanel
                  ? _c("v-tooltip", {
                      attrs: { left: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        text: "",
                                        icon: "",
                                        large: "",
                                        dark: "",
                                      },
                                      on: { click: _vm.openSearchPanel },
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v(" add_circle ")])],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("common:moreSearch"))),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2970748113
                      ),
                    })
                  : _vm._e(),
              ]
            : _vm.searchPanel
            ? _c(
                "v-btn",
                {
                  attrs: { text: "", dark: true },
                  on: { click: _vm.closeSearchPanel },
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("common:emptySearch")) + " "),
                  _c("v-icon", { attrs: { right: "", dark: true } }, [
                    _vm._v(" close "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }