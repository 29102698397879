<template>
  <v-card
    :class="cardClass"
    color="warning"
  >
    <v-icon
      dark
      class="mr-2"
    >
      warning
    </v-icon>
    <span
      class="subtitle-1"
      :class="tekstiClass"
    >{{ teksti }}</span>
  </v-card>
</template>


<script>
export default {
  name: 'CardVaroitus',
  props: {
    cardClass: {
      type: String,
      required: false,
      default: 'pa-4',
    },
    teksti: {
      type: String,
      required: true,
    },
    tekstiClass: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style>

</style>