import BaseOptimizedCountSelaa from "@/store/modules/baseOptimizedCountSelaa";
import { TabsSopimus } from '@/utils/tabs'

export default class SopimusSelaa extends BaseOptimizedCountSelaa {
  state () {
    return {
      ...super.state(),
      apiName: 'Sopimukset',
      searchTerms: { paamies: [] },
      tabs: TabsSopimus || [],
    }
  }

  mutations () {
    return {
      ...super.mutations(),
      resetViewViewKohtainen (state) {
        state.searchTerms = { paamies: [] }
      }
    }
  }
}
