<template>
  <div class="rah-container">
    <v-container>
      <v-row>
        <v-col cols="7">
          <v-simple-table
            dense
            class="tyopoyta__gradient"
          >
            <template #default>
              <tbody>
                <tr>
                  <td>Limiitti</td>
                  <td class="text-right">
                    {{ rahoitusdata.rahoitettu_limiitti | formatSumToFixed2 }}
                  </td>
                </tr>
                <tr :class="limiitistaJaljellaRiviClass">
                  <td>Limiitistä jäljellä</td>
                  <td class="text-right">
                    {{
                      rahoitusdata.rahoitettu_limiitti_vara | formatSumToFixed2
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Avoinna</td>
                  <td class="text-right">
                    {{ rahoitusdata.rahoitettu_avoinna | formatSumToFixed2 }}
                  </td>
                </tr>
                <tr>
                  <td>Suoritettu yhteensä</td>
                  <td class="text-right">
                    {{ rahoitusdata.rahoitettu_suoritettu | formatSumToFixed2 }}
                  </td>
                </tr>
                <tr>
                  <td>Hyvitetty yhteensä</td>
                  <td class="text-right">
                    {{ rahoitusdata.rahoitettu_hyvitys | formatSumToFixed2 }}
                  </td>
                </tr>
                <tr>
                  <td>Rahoitettu yhteensä</td>
                  <td class="text-right">
                    {{ rahoitusdata.rahoitettu_lasku | formatSumToFixed2 }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col
          ref="gaugeCol"
          cols="5"
        >
          <vue-gauge
            v-if="!paivittaaDataa"
            :refid="
              'vue-gauge-rahoitettavan-yhteenveto' +
                Math.floor(Math.random() * 100)
            "
            class=""
            :options="gaugeOptions"
          ></vue-gauge>
          <h3 class="mt-n4 text-center">
            <span v-if="prosenttiaLimiitistaKaytetty === undefined">Ei limiittiä</span>
            <span v-else>Limiitistä käytetty: {{ prosenttiaLimiitistaKaytetty }} %</span>
          </h3>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VueGauge from 'vue-gauge'

export default {
  name: 'RahoitettavanYhteenveto',
  components: {
    VueGauge,
  },
  props: {
    rahoitusdata: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paivittaaDataa: false,
      windowWidth: window.innerWidth,
      gaugeColWidth: 300,
      gaugeOptions: {
        needleColor: 'black',
        needleValue: 0,
        rangeLabel: ['0 %', '100 %'],
        arcColors: [],
        arcDelimiters: [90],
        chartWidth: 300,
      },
    }
  },
  computed: {
    colorTheme() {
      return this.$vuetify.theme.themes.light
    },
    prosenttiaLimiitistaKaytetty() {
      if (this.rahoitusdata.rahoitettu_limiitti <= 0) {
        return undefined
      } else {
        return Math.round(
          (this.rahoitusdata.rahoitettu_avoinna /
            this.rahoitusdata.rahoitettu_limiitti) *
            100
        )
      }
    },
    limiitistaJaljellaRiviClass() {
      let cssLuokka = ''
      if (this.rahoitusdata.rahoitettu_limiitti > 0)
        if (this.prosenttiaLimiitistaKaytetty > 100) {
          cssLuokka = 'error'
        } else if (this.prosenttiaLimiitistaKaytetty >= 90) {
          cssLuokka = 'warning'
        }

      return cssLuokka
    },
  },
  watch: {
    rahoitusdata() {
      // Pakotetaan gaugen rerenderöinti, jota ilman viisari näyttää vanhaa arvoa päämiehen vaihtuessa
      this.asetaGaugeOptions()
      this.pakotaGaugenRender()
    },
    windowWidth: {
      immediate: true,
      handler() {
        this.asetaGaugeOptions()
        this.pakotaGaugenRender()
      },
    },
  },
  created() {
    window.onresize = this.asetaWindowWidth.bind(this)
  },
  mounted() {
    this.asetaWindowWidth()
    this.asetaGaugeOptions()
    this.pakotaGaugenRender()
  },
  methods: {
    asetaWindowWidth() {
      this.windowWidth = window.innerWidth
      const { gaugeCol } = this.$refs
      this.gaugeColWidth =
        gaugeCol && gaugeCol.clientWidth ? gaugeCol.clientWidth : 260
    },
    asetaGaugeOptions() {
      let neulanVari = 'black'
      if (this.prosenttiaLimiitistaKaytetty > 100) {
        neulanVari = this.colorTheme.error
      } else if (this.prosenttiaLimiitistaKaytetty >= 90) {
        neulanVari = this.colorTheme.warning
      }

      this.gaugeOptions = Object.assign(this.gaugeOptions, {
        needleColor: neulanVari,
        needleValue: this.prosenttiaLimiitistaKaytetty,
        rangeLabel: ['0 %', '100 %'],
        arcColors: [this.colorTheme.primary, this.colorTheme.secondary],
        arcDelimiters: [90],
        chartWidth: this.gaugeColWidth,
      })
    },
    pakotaGaugenRender() {
      this.paivittaaDataa = true
      setTimeout(() => {
        this.paivittaaDataa = false
      }, 0)
    },
  },
}
</script>

<style lang="scss">
.vue-gauge-item {
  svg {
    display: block;
    margin: 0 auto;
  }
}
.rah-container{
  height: 100%;
}
</style>
