var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bgImg" }, [
    _vm.imgUrl
      ? _c("div", { staticClass: "bgImg__img", style: _vm.imgStyle })
      : _vm._e(),
    _vm.overlayColor
      ? _c("div", { staticClass: "bgImg__overlay", style: _vm.overlayStyle })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }