var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormPalveluhakemusBase",
    _vm._g({}, _vm.$listeners),
    [
      _c("v-text-field", {
        attrs: {
          label: _vm.$t("email"),
          rules: [_vm.$validationRules.required, _vm.$validationRules.email],
          outlined: "",
          required: "",
        },
        model: {
          value: _vm.kayttaja.email,
          callback: function ($$v) {
            _vm.$set(_vm.kayttaja, "email", $$v)
          },
          expression: "kayttaja.email",
        },
      }),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: _vm.$t("companyId"),
          rules: [_vm.$validationRules.required],
          "error-messages": _vm.tunnusErrors,
          outlined: "",
        },
        on: {
          blur: function ($event) {
            return _vm.tarkistaTunnuksenOikeellisuus(_vm.tunnus)
          },
        },
        model: {
          value: _vm.tunnus,
          callback: function ($$v) {
            _vm.tunnus = $$v
          },
          expression: "tunnus",
        },
      }),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: _vm.$t("password"),
          "append-icon": _vm.naytaSalasana ? "visibility_off" : "visibility",
          type: _vm.naytaSalasana ? "text" : "password",
          rules: [_vm.$validationRules.required],
          outlined: "",
          required: "",
          "error-messages": _vm.salasanaErrors,
        },
        on: {
          "click:append": function ($event) {
            _vm.naytaSalasana = !_vm.naytaSalasana
          },
        },
        model: {
          value: _vm.salasana,
          callback: function ($$v) {
            _vm.salasana = $$v
          },
          expression: "salasana",
        },
      }),
      _c("v-text-field", {
        staticClass: "required",
        attrs: {
          label: _vm.$t("serviceApplication.repeatPassword"),
          "append-icon": _vm.naytaSalasana ? "visibility_off" : "visibility",
          type: _vm.naytaSalasana ? "text" : "password",
          rules: [_vm.$validationRules.required],
          outlined: "",
          required: "",
          "error-messages": _vm.salasanaUudelleenErrors,
        },
        on: {
          "click:append": function ($event) {
            _vm.naytaSalasana = !_vm.naytaSalasana
          },
        },
        model: {
          value: _vm.salasanaUudelleen,
          callback: function ($$v) {
            _vm.salasanaUudelleen = $$v
          },
          expression: "salasanaUudelleen",
        },
      }),
      _c("v-checkbox", {
        attrs: { dense: "", rules: [_vm.$validationRules.required] },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("serviceApplication.IAccept")) + " "),
                  _c(
                    "a",
                    {
                      staticClass: "font-weight-bold",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.palveluehdotAuki = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("serviceApplication.termsofService"))
                      ),
                    ]
                  ),
                  _vm._v(" *"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.palveluehdotHyvaksytty,
          callback: function ($$v) {
            _vm.palveluehdotHyvaksytty = $$v
          },
          expression: "palveluehdotHyvaksytty",
        },
      }),
      _c("v-checkbox", {
        attrs: { dense: "", rules: [_vm.$validationRules.required] },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("serviceApplication.IHaveRead")) + " "),
                  _c(
                    "a",
                    {
                      staticClass: "font-weight-bold",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.tietosuojaselosteAuki = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("serviceApplication.thePrivacyPolicy"))
                      ),
                    ]
                  ),
                  _vm._v(" *"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.tietosuojaselosteHyvaksytty,
          callback: function ($$v) {
            _vm.tietosuojaselosteHyvaksytty = $$v
          },
          expression: "tietosuojaselosteHyvaksytty",
        },
      }),
      _c("v-checkbox", {
        attrs: {
          dense: "",
          label: _vm.$t("serviceApplication.sendMeMarketingEmail"),
        },
        model: {
          value: _vm.markkinointiViestitHyvaksytty,
          callback: function ($$v) {
            _vm.markkinointiViestitHyvaksytty = $$v
          },
          expression: "markkinointiViestitHyvaksytty",
        },
      }),
      _c(
        "CardDialog",
        {
          attrs: {
            "dialog-auki": _vm.palveluehdotAuki,
            title: _vm.$t("uuvaCloudTermsOfService"),
            width: "70%",
          },
          on: {
            close: function ($event) {
              _vm.palveluehdotAuki = false
            },
          },
        },
        [
          _c("iframe", {
            staticClass: "teksti-iframe",
            attrs: {
              src: "/palveluehdot.html",
              width: "100%",
              sandbox: "allow-same-origin allow-scripts",
            },
          }),
        ]
      ),
      _c(
        "CardDialog",
        {
          attrs: {
            "dialog-auki": _vm.tietosuojaselosteAuki,
            title: _vm.$t("privacyPolicy"),
            width: "70%",
          },
          on: {
            close: function ($event) {
              _vm.tietosuojaselosteAuki = false
            },
          },
        },
        [
          _c("iframe", {
            staticClass: "teksti-iframe",
            attrs: {
              src: "/tietosuojaseloste.html",
              width: "100%",
              sandbox: "allow-same-origin allow-scripts",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }