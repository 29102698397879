var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: _vm.width },
      model: {
        value: _vm.dialogAuki,
        callback: function ($$v) {
          _vm.dialogAuki = $$v
        },
        expression: "dialogAuki",
      },
    },
    [
      _c("v-card", { ref: "wrap", staticClass: "card-dialog" }, [
        _vm.title || _vm.showCloseButton
          ? _c(
              "div",
              { staticClass: "card-dialog__header" },
              [
                _c(
                  "v-row",
                  { class: _vm.titleClass, attrs: { align: "center" } },
                  [
                    _c("v-col", { staticClass: "px-4" }, [
                      _vm.title
                        ? _c(
                            "h2",
                            [
                              _vm._v(" " + _vm._s(_vm.title) + " "),
                              _vm.tooltipInfoTeksti
                                ? _c("TooltipInfo", {
                                    attrs: {
                                      text: _vm.tooltipInfoTeksti,
                                      "icon-color": "primary",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "v-col",
                      { staticClass: "px-4 shrink" },
                      [
                        _vm.showCloseButton
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ma-0",
                                attrs: { icon: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("close")
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.$slots["default"]
          ? _c(
              "div",
              {
                ref: "content",
                staticClass: "card-dialog__content",
                class: _vm.contentClass,
              },
              [_vm._t("default")],
              2
            )
          : _vm._e(),
        _vm.$slots["footer"]
          ? _c("div", { staticClass: "card-dialog__footer pa-4" }, [
              _c("div", { class: _vm.footerClass }, [_vm._t("footer")], 2),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }