<template>
  <v-autocomplete
    ref="autocompletePaamies"
    v-model="tapaus.paamies"
    :items="paamiesItems"
    :loading="paamiesLoading"
    :search-input.sync="paamiesSearchQuery"
    :hide-no-data="(!paamiesSearchQuery || paamiesLoading)"
    item-text="nimi"
    item-value="id"
    :label="label"
    :placeholder="$t('common:autocompletePaamies.searchWithCreditorName')"
    persistent-placeholder
    return-object
    class="required show-hidden-child-on-hover"
    clearable
    :cache-items="multiple"
    :chips="multiple"
    :deletable-chips="multiple"
    :multiple="multiple"
    :counter="valintojenMaksimiLkm"
    :rules="validointiSaannotKoottu"
    :disabled="disabled"
    @input="kasitteleValinta"
  >
    <template
      v-slot:item="{ item }"
    >
      <template>
        <v-list-item-content>
          <v-list-item-title>{{ item.nimi }} ({{ item.id }})</v-list-item-title>
          <v-list-item-subtitle v-if="tarkistaLaskuEsto && item.esta_laskun_luonti">
            {{ $t('common:autocompletePaamies.createInvoiceNotPermitted') }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="tarkistaToimeksiantoEsto && item.esta_toimeksiannon_luonti">
            {{ $t('common:autocompletePaamies.createAssignmentNotPermitted') }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="item.postiosoite">
            <template v-if="item.postiosoite.postiosoite">
              {{ item.postiosoite.postiosoite }},
            </template>
            <template v-if="item.postiosoite.postinumero">
              {{ item.postiosoite.postinumero }}
            </template>
            <template v-if="item.postiosoite.postitoimipaikka">
              {{ item.postiosoite.postitoimipaikka }}
            </template>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
    <template v-slot:no-data>
      <p class="pa-4">
        {{ $t('common:autocompletePaamies.searchFoundNoCreditors') }}
      </p>
    </template>
    <template
      v-if="paamieshallinta && $permission.checkSinglePerm('onlinerestapi.onlinerestapi_paamieshallinta') && !tapaus.paamies.id"
      v-slot:append-outer
    >
      <LomakenappiIkonilla
        ikoni="add"
        :infoteksti="$t('autocompletePaamies.createNewCreditor')"
        @click="$emit('open-dialog-paamies')"
      />
    </template>
  </v-autocomplete>
</template>

<script>

import LomakenappiIkonilla from '@/components/LomakenappiIkonilla'

export default {
  name: 'AutocompletePaamies',
  components: {
    LomakenappiIkonilla,
  },
  model: {
    prop: 'tapaus',
  },
  props: {
    label: {
      type: String,
      required: false,
      default () {
        return this.$t('common:invoicer')
      }
    },
    multiple: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    paamieshallinta: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    tarkistaLaskuEsto: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    tarkistaToimeksiantoEsto: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    tarkistettavaOikeus: {
      type: String,
      required: false,
      default: '',
    },
    // Esim. lasku tai toimeksianto
    tapaus: {
      type: Object,
      required: true,
    },
    validointiSaannot: {
      type: Array,
      required: false,
      default: () => [],
    },
    valintojenMaksimiLkm: {
      type: [Boolean, Number],
      required: false,
      default: false, // Ei maksimia kun arvo on false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      debounceDelay: 500,
      debounceTimeout: '',
      paamiesItems: [],
      paamiesLoading: false,
      paamiesSearchQuery: '',
    }
  },
  computed: {
    validointiSaannotKoottu () {
      const maksimiLkm = this.valintojenMaksimiLkm ? [this.validoiValintojenMaksimiLkm] : []
      return [...this.validointiSaannot, ...maksimiLkm]
    },
    user () {
      return this.$store.state.user
    }
  },
  watch: {
    async paamiesSearchQuery (val, oldVal) {
      // Estetään valinnan (input event) yhteydessä tapahtuva haun laukeaminen.
      if (this.tapaus.paamies && val === this.tapaus.paamies.nimi) return

      clearTimeout(this.debounceTimeout)
      this.paamiesItems = []

      if (!val && !this.multiple) {
        // Tyhjennetään päämies tapaukselta vain jos se kumitetaan pois, koska halutaan säilyttää
        // storesta haettu päämies tapauksella.
        if (typeof oldVal === 'string' && oldVal.length === 1) {
          this.tapaus.paamies = {}
        }
        return
      }

      this.paamiesLoading = true

      this.debounceTimeout = setTimeout(async () => {
        try {
          const query = new URLSearchParams()
          query.append('nimi', val)
          query.append('psize', 10)

          const request = await this.$doRequestWithTimeout(
            this.$api.Paamies,
            {
              method: 'GET',
              url: '',
              query: query.toString(),
            },
            'pushRequest',
          )

          if (!request.success) throw new this.$HttpError(request)

          // Jos jotain päämiestä ei löydy storesta, on mahdollista että päämies on luotu
          // sisäänkirjautumisen jälkeen. Päivitetään päämiehet, jotta uusi päämies näkyy.
          // TODO Päämiesten päivittäminen pitäisi varmaan tehdä eri tavalla/eri paikassa,
          //  mutta näin ainakin toimii.
          const paamiesIds = request.result.body.results.map(paamies => paamies.id)
          if (
              this.$store.getters['user/tarkistetaankoPaamieskohtaisetOikeudet']
              && !(paamiesIds.every(id => id in this.user.permissionsPaamies))
          ) {
            const request = await this.$doRequestWithTimeout(
                this.$api.User,
                { method: 'GET' },
                'pushRequest',
            )
            if (!request.success) throw new this.$HttpError(request)
          }

          this.paamiesItems = request.result.body.results.map(paamies => {
            let paamiehellaEiOikeutta = false
            // Tarkistetaan päämieskohtaiset oikeudet vain kun käyttäjä ei ole henkilökuntaa
            if (
              this.tarkistettavaOikeus &&
              this.$store.getters['user/tarkistetaankoPaamieskohtaisetOikeudet']
            ) {
              const paamiesPerms = this.user.permissionsPaamies[paamies.id]
              paamiehellaEiOikeutta = !(paamiesPerms.includes?.(this.tarkistettavaOikeus))
            }

            if (
              this.tarkistaLaskuEsto && paamies.esta_laskun_luonti ||
              this.tarkistaToimeksiantoEsto && paamies.esta_toimeksiannon_luonti ||
              this.tarkistettavaOikeus && paamiehellaEiOikeutta
            ) {
              paamies.disabled = true
            }
            return paamies
          })
        } catch (e) {
          this.$sentryCaptureCustom(e)
        } finally {
          this.paamiesLoading = false
        }
      }, this.debounceDelay)
    },
  },
  methods: {
    validoiValintojenMaksimiLkm (paamiesArr)  {
      if (paamiesArr.length <= this.valintojenMaksimiLkm) {
        return true
      } else {
        if (this.valintojenMaksimiLkm === 1) {
          return this.$t('common:autocompletePaamies.selectOnlyOneCreditor')
        } else {
          return this.$t('common:autocompletePaamies.selectAtHighestCreditors', { maxNoOfCreditors: this.valintojenMaksimiLkm })
        }
      }
    },
    kasitteleValinta (paamies) {
      if (!paamies) {
        // v-autocomplete asettaa paamiehen arvoksi tyhjennettäessä undefined tai null,
        // joten asetetaan arvoksi manuaalisesti objekti ja monihaussa array.
        this.tapaus.paamies = this.multiple ? [] : {}
        paamies = this.multiple ? [] : {}
      }
      this.$emit('autocomplete-input', paamies)
      if (!this.multiple) this.asetaPaamiesStoreen(paamies)
    },
    asetaPaamiesStoreen (paamies) {
      if (paamies && paamies.id) {
        this.$store.commit('user/setValittuPaamies', paamies)
      }
    },
    paivitaPaamiehenTiedotHakukenttaan (paamies) {
      this.$refs.autocompletePaamies.lazySearch = paamies.nimi
    }
  }
}
</script>

<style>

</style>
