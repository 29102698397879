var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _vm._t("default"),
      _vm._t("painikkeet", [
        _c(
          "v-row",
          { staticClass: "mt-4 pa-3", attrs: { justify: "center" } },
          [
            _c(
              "v-btn",
              {
                staticClass: "mx-4",
                attrs: {
                  block: "",
                  large: "",
                  color: "success",
                  disabled: !_vm.valid,
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("seuraava")
                  },
                },
              },
              [_vm._v(" Seuraava ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }