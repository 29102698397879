import { render, staticRenderFns } from "./FormLiitteet.vue?vue&type=template&id=a026ed48&scoped=true&"
import script from "./FormLiitteet.vue?vue&type=script&lang=js&"
export * from "./FormLiitteet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a026ed48",
  null
  
)

/* custom blocks */
import block0 from "./FormLiitteet.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VFileInput,VForm})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a026ed48')) {
      api.createRecord('a026ed48', component.options)
    } else {
      api.reload('a026ed48', component.options)
    }
    module.hot.accept("./FormLiitteet.vue?vue&type=template&id=a026ed48&scoped=true&", function () {
      api.rerender('a026ed48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FormLiitteet.vue"
export default component.exports