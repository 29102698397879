<template>
  <v-form
    v-model="valid"
    @submit.prevent="submit"
  >
    <v-text-field
      v-model="value.email"
      label="Sähköpostiosoite"
      type="email"
      :rules="[$validationRules.required, $validationRules.email]"
      outlined
      required
      class="required"
    />
    <v-btn
      type="submit"
      :disabled="!valid || loading"
      :loading="loading"
      block
      large
      color="success"
      class="ml-0"
    >
      Lähetä
    </v-btn>
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'FormSalasananUusimispyynto',
  mixins: [FormMixin],
}

</script>

<style lang="scss" scoped>

</style>
