/**
 * Tarjoaa oletustoiminnallisuuden MenuContext-komponentin käyttämiseen näkymässä. Oletuksena
 * contextmenussa voi avata tietueen uuteen välilehteen. Jos näkymässä tarvitsee navigoida
 * contextmenun avulla vain yhteen routeen, ei avaaMenuContextille tarvitse antaa argumenttia
 * 'routeName'.
 */

export default {
  data () {
    return {
      menuContextNakyvissa: false,
      menuContextItem: {},
      menuContextValilehtiRouteName: '', // Lisää tälle arvo mixiniä käyttävään viewiin
      menuContextX: 0,
      menuContextY: 0,
      menuContextVaihtoehdot: [
        { icon: 'link', title: this.$t('common:menuContextMixin.openNewTab'), event: 'avaaValilehteen' }
      ],
    }
  },
  methods: {
    avaaUudessaValilehdessa () {
      const routeData = this.$router.resolve({
        name: this.menuContextValilehtiRouteName,
        params: {
          id: this.menuContextItem.id
        }
      })

      window.open(routeData.href, '_blank')
    },
    avaaMenuContext (event, item, routeName = null) {
      if (routeName) this.menuContextValilehtiRouteName = routeName
      this.menuContextItem = item
      this.menuContextX = event.clientX
      this.menuContextY = event.clientY
      this.menuContextNakyvissa = true
    },
    suljeMenuContext () {
      this.menuContextNakyvissa = false
    },
  }
}
