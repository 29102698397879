<template>
  <div class="tyopoyta">
    <div class="t-header sticky">
      <v-toolbar color="primary" class="px-2" flat tabs>
        <v-toolbar-title v-if="$route.meta.title">
          Tervetuloa {{ user.naytettavaNimi }}
        </v-toolbar-title>

        <v-spacer />
      </v-toolbar>
    </div>
    <div v-if="!paamiehet || !paamiehet.length" class="t-spinner">
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <Spinner v-if="!paamiehet || !paamiehet.length" div-class="text-center mt-12" justify="center" />
        </v-col>
      </v-row>
    </div>
    <div v-else id="t-content">
      <div id="tc-filter">
        <!-- :value="Boolean(palvelusopimusMaksamatta)" -->
        <v-alert :value="Boolean(palvelusopimustrialPaattyy)" type="info" class="ma-4">
          Kokeilujakso päättyy {{ $date(palvelusopimustrialPaattyy) }}
        </v-alert>
        <v-alert :value="Boolean(palvelusopimusMaksamatta)" type="error" class="ma-4">
          Cloud-tilisi lasku on maksamatta. Tilisi suljetaan
          {{ $date(palvelusopimusMaksamatta) }} ellei laskua makseta.
        </v-alert>
        <v-alert :value="Boolean(palvelusopimusPaattyy)" type="warning" class="ma-4">
          Cloud-tilisi tilaus on loppumassa {{ $date(palvelusopimusPaattyy) }}.
          Loppumisen jälkeen tilin tietoihin ei pääse enää käsiksi.
        </v-alert>
        <v-row v-if="!user.vainYksiPaamies" class="pb-1">
          <v-col class="d-flex flex-column">
            <v-card class="paamies-card pa-6 pt-8 d-flex flex-column flex-grow-1 tyopoyta__gradient">
              <CardTitle text="Päämies" :center="true" />
              <template>
                <v-autocomplete v-if="!user.vainYksiPaamies" v-model="valittavaPaamies" class="tyopoyta__gradient" :items="paamiehet"
                  :search-input.sync="paamiesSearchQuery" hide-no-data :item-text="getPaamiesNimi" item-value="id"
                  placeholder="Kirjoita hakusana" persistent-placeholder return-object outlined hide-details dense>
                  <template #item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.nimi }} ({{ item.id }})</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div id="tc-btns">
        <div v-if="
          $permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku')
        " id="tc-btns-inner">
          <TyopoytaPikanappain text="Uusi lasku" icon="invoice-add" :to="{ name: 'laskutus-uusi' }" />
          <TyopoytaPikanappain text="Uusi asiakas" icon="customer-person" :to="{
            name: 'laskutus-asiakas-selaa',
            params: {
              uusiAsiakas: true,
            },
          }" />
        </div>
      </div>
      <div id="tc-myynti">
       
          <TyopoytaMyynninKuvaaja :lasku-myynti-data="laskuMyyntiData" />
        
      </div>
      
        <div class="b-combo">
          <template>
            <v-card class="l-container">
              <v-tabs>
                <v-tab class="primary--text">
                  Laskutus
                </v-tab>
                <v-tab class="primary--text">
                  Perintä
                </v-tab>
                <v-tab class="primary--text">
                  Rahoitus
                </v-tab>
                <v-tab-item>
                  <v-card flat>
                    <div class="c-laskut">
                      <div v-if="
                        laskutusKaytossa &&
                        $permission.checkSinglePerm(
                          'onlinerestapi.onlinerestapi_lasku'
                        )
                      ">
                        <v-card class="tyopoyta__gradient">
                          <Spinner v-if="ladataanLaskutilastoja" justify="center" teksti="Ladataan tilastoja" />
                          <template v-else>
                            <v-row>
                              <v-col cols="1" lg="1"></v-col>
                              <v-col cols="8" lg="8">
                                <div v-if="paamiehellaEiLaskutilastoDataa" class="font-no-data text-center pa-12">
                                  Ei ole dataa.
                                </div>
                                <ChartPie v-else class="pa-0 tyopoyta__chart-pie" :chart-data="laskutilastoData" />
                              </v-col>
                            </v-row>
                          </template>
                        </v-card>
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <div v-if="
                      perintaKaytossa &&
                      $permission.checkSinglePerm(
                        'onlinerestapi.onlinerestapi_toimeksianto'
                      )
                    ">
                      <v-card class="tyopoyta__gradient">
                        <Spinner v-if="ladataanPerintatilastoja" justify="center" teksti="Ladataan tilastoja" />
                        <template v-else>
                          <v-row>
                            <v-col cols="1" lg="2"></v-col>
                            <v-col cols="8" lg="8">
                              <div v-if="paamiehellaEiPerintatilastoDataa" class="font-no-data text-center pa-12">
                                Ei ole dataa.
                              </div>
                              <div v-else>
                                <ChartPie class="pa-0 tyopoyta__chart-pie" :chart-data="perintatilastoData" />
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </v-card>
                    </div>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
<div class="b-rahoitus">
          <div v-if="
            rahoitusKaytossa &&
            (ladataanRahoitettavanYhteenvetoa ||
              rahoitettavanYhteenveto.length)
          " class="p-container">
            <v-card class="tyopoyta__gradient p-container">
              <template v-if="ladataanRahoitettavanYhteenvetoa">
                <v-skeleton-loader class="mb-4" type="heading" min-height="40" width="100%" />
                <v-container>
                  <v-row>
                    <v-col cols="7">
                      <v-skeleton-loader type="table-row@4" max-height="260" />
                    </v-col>
                    <v-col cols="5">
                      <v-skeleton-loader type="image" max-height="260" />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-else-if="rahoitettavanYhteenveto.length">
                <RahoitettavanYhteenveto :rahoitusdata="rahoitettavanYhteenveto[0].limiitti" />
              </template>
            </v-card>
          </div>
        </div>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </template>
          
        </div>

        
      
    </div>
    <!-- Näytetään versionumero piilotettuna, koska joidenkin tietoturvaihmisten mielestä sitä ei
     saisi näyttää
     -->
    <div v-if="user.isSuperuser" :id="versio"></div>
    <!-- Sit oli tämmönen juttu, mikä oletan et liittyy siihen logo nappiin mikä piti poistaa, mut varmistetaan
       <CardDialog
      :dialog-auki="paamiesLogoDialogAuki"
      title="Lisää laskukirjeen logo"
      @close="paamiesLogoDialogAuki = false"
    >
      <template v-if="paamiesLogoOlemassa">
        <v-card
          class="mx-4 mb-4 pa-4 d-flex justify-space-between"
          color="info"
        >
          <div>
            <v-icon dark class="mr-2">
              info
            </v-icon>
            <span class="subtitle-1 white--text">Logo on jo lisätty.</span>
          </div>
          <v-btn
            class="ml-4 mt-0"
            color="#26c6da"
            dark
            small
            @click="haePaamiesLogo(valittuPaamies.id)"
          >
            Lataa tästä
          </v-btn>
        </v-card>
        <v-card class="mx-4 mb-4 pa-4" color="info">
          <v-icon dark class="mr-2">
            info
          </v-icon>
          <span class="subtitle-1 white--text"
            >Uuden logon lisääminen korvaa vanhan logon.</span
          >
        </v-card>
      </template>
      <FormLiite
        v-model="paamiesLogoFormData"
        class="pa-4"
        :loading="paamiesLogoFormLoading"
        :on-auki="paamiesLogoDialogAuki"fixed-tabs
      >
      </FormLiite>
    </CardDialog>
     -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Paamies } from '@/class/Paamies'
import {
  ChartPie,
  RahoitettavanYhteenveto,
  TyopoytaMyynninKuvaaja,
  TyopoytaPikanappain,
} from '@/components'
import { haeTiedostoFetchilla, onkoModuuliKaytossa } from '@/utils/misc'
import formatSumToFixed2 from '@/utils/filters/formatSumToFixed2'

export default {
  name: 'Tyopoyta',
  components: {
    ChartPie,
    RahoitettavanYhteenveto,
    TyopoytaMyynninKuvaaja,
    TyopoytaPikanappain,
  },
  data() {
    return {
      tabs: null,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      laskuMyyntiData: [],
      laskutilastoData: {},
      perintatilastoData: {},
      ladataanRahoitettavanYhteenvetoa: false,
      ladataanLaskutilastoja: false,
      ladataanPerintatilastoja: false,
      paamiehet: [],
      paamiesSearchQuery: '',
      perinnanOnnistumisprosenttiInfo:
        'Perinnän tilassa toimeksiantojen lukumäärä jaetaan maksettuihin (perintä täysin onnistunut), avoinna oleviin (perintä vielä kesken) ja lopetettuihin toimeksiantoihin. Laskelmissa ei ole mukana toimeksiantajan perumat toimeksiannot eivätkä sellaiset toimeksiannot, jotka on lopetettu toiseen toimeksiantoon yhdistämisen johdosta.',
      accessToken: '',
      rahoitettavanYhteenveto: [],
      paamiesLogoFormData: {},
      paamiesLogoFormLoading: false,
      paamiesLogoDialogAuki: false,
      paamiesLogoOlemassa: false,
      valittavaPaamies: {},
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    valittuPaamies() {
      return this.user.valittuPaamies
    },
    palvelusopimustrialPaattyy() {
      return this.user.valittuPaamies.palvelusopimustrial_paattyy
    },
    palvelusopimusMaksamatta() {
      return this.user.valittuPaamies.palvelusopimus_maksamatta
    },
    palvelusopimusPaattyy() {
      return this.user.valittuPaamies.palvelusopimus_paattyy
    },
    laskutusKaytossa() {
      return onkoModuuliKaytossa('laskutus')
    },
    paamiehellaEiLaskutilastoDataa() {
      return !(
        this.laskutilastoData &&
        this.laskutilastoData.datasets &&
        (this.laskutilastoData.datasets[0].data[0] ||
          this.laskutilastoData.datasets[0].data[1])
      )
    },
    perintaKaytossa() {
      return onkoModuuliKaytossa('perinta')
    },
    paamiehellaEiPerintatilastoDataa() {
      return !(
        this.perintatilastoData &&
        this.perintatilastoData.datasets &&
        (this.perintatilastoData.datasets[0].data[0] ||
          this.perintatilastoData.datasets[0].data[1] ||
          this.perintatilastoData.datasets[0].data[2])
      )
    },
    rahoitusKaytossa() {
      return onkoModuuliKaytossa('rahoitus')
    },
    versio() {
      return process.env.VUE_APP_PACKAGE_VERSION
    },
  },
  watch: {
    valittavaPaamies: {
      deep: true,
      handler(val, oldVal) {
        if (!val || val.id === oldVal.id) return

        this.haePaamiehentiedot(val.id)
      },
    },
  },
  created() {
    this.haePaamiehet()
  },
  methods: {
    getPaamiesNimi(paamies) {
      if (this.$store.state.user.vainYksiPaamies) {
        return paamies.nimi
      } else {
        return paamies.nimi + ' (' + paamies.id + ')'
      }
    },
    async haePaamiehet() {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.UserPaamies,
          { url: '' }
        )

        if (!request.success) throw new this.$HttpError(request)

        this.paamiehet = request.result.body

        // Täydennetään päämies autocompleten hakukenttään ja laukaistaan tarkempien tietojen haku
        if (this.valittuPaamies.id) {
          this.valittavaPaamies = this.paamiehet.find(
            (paamies) => paamies.id === this.valittuPaamies.id
          )
        } else if (this.paamiehet.length) {
          this.valittavaPaamies = this.paamiehet[0]
        }
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          'Tietojen lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.'
        )
      }
    },
    haePaamiehentiedot(paamiesId) {
      Paamies.haePaamiesTiedotLuontitoiminnoille(paamiesId)

      if (
        this.$permission.checkSinglePerm(
          'onlinerestapi.onlinerestapi_toimeksianto'
        )
      ) {
        this.lataaPaamiesPerintatilasto(paamiesId)
      }

      if (
        onkoModuuliKaytossa('rahoitus') &&
        this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_rahoitus')
      ) {
        this.haeRahoitettavanYhteenveto(paamiesId)
      }

      if (
        this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku')
      ) {
        this.lataaPaamiesLaskutilasto(paamiesId)
        this.haeLaskuMyyntiData(paamiesId)
        this.haePaamiesLogonOlemassaolo(paamiesId)
      }
    },
    async lataaPaamiesPerintatilasto(paamiesId = null) {
      try {
        if (!paamiesId) return

        this.ladataanPerintatilastoja = true

        const request = await this.$doRequestWithTimeout(
          this.$api.UserPaamiesPerintatilasto,
          {
            url: `${paamiesId}/`,
            method: 'GET',
          },
          'doRequest',
          60000
        )

        if (!request.success) throw new this.$HttpError(request)

        const { tilastot } = request.result.body

        this.perintatilastoData = {
          labels: [
            `Maksettu: ${tilastot.maksettu.pros.toLocaleString()}% (${tilastot.maksettu.kpl
            } kpl)`,
            `Avoinna: ${tilastot.avoinna.pros.toLocaleString()}% (${tilastot.avoinna.kpl
            } kpl)`,
            `Lopetettu: ${tilastot.lopetettu.pros.toLocaleString()}% (${tilastot.lopetettu.kpl
            } kpl)`,
          ],
          datasets: [
            {
              backgroundColor: ['#897fa6', '#bfb4dd', '#3f3759'],
              data: [
                tilastot.maksettu.kpl,
                tilastot.avoinna.kpl,
                tilastot.lopetettu.kpl,
              ],
            },
          ],
        }
        this.ladataanPerintatilastoja = false
      } catch (e) {
        this.$oletusVirheenkasittely(
          'Perintätilaston lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.'
        )
        this.ladataanPerintatilastoja = false
      }
    },
    async lataaPaamiesLaskutilasto(paamiesId = null) {
      try {
        if (!paamiesId) return

        this.ladataanLaskutilastoja = true

        const request = await this.$doRequestWithTimeout(
          this.$api.UserPaamiesLaskutilasto,
          {
            url: `${paamiesId}/`,
            method: 'GET',
          },
          'doRequest',
          60000
        )

        if (!request.success) throw new this.$HttpError(request)

        const { tilastot } = request.result.body

        this.laskutilastoData = {
          labels: [
            `Avoinna: ${tilastot.avoinna.kpl} kpl (${formatSumToFixed2(
              tilastot.avoinna.summa
            )})`,
            `Myohassa: ${tilastot.myohassa.kpl} kpl (${formatSumToFixed2(
              tilastot.myohassa.summa
            )})`,
          ],
          datasets: [
            {
              backgroundColor: ['#bfb4dd', '#3f3759'],
              data: [tilastot.avoinna.kpl, tilastot.myohassa.kpl],
            },
          ],
        }
        this.ladataanLaskutilastoja = false
      } catch (e) {
        this.$oletusVirheenkasittely(
          'Laskutilaston lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.'
        )
        this.ladataanLaskutilastoja = false
      }
    },
    async haeRahoitettavanYhteenveto(paamiesId = null) {
      try {
        if (!paamiesId) return

        this.ladataanRahoitettavanYhteenvetoa = true
        const query = new URLSearchParams()
        query.append('paamies_id', paamiesId)

        const request = await this.$doRequestWithTimeout(
          this.$api.WithAuthService,
          {
            url: 'rahoitus_limiitti/',
            method: 'GET',
            query: query.toString(),
          }
        )

        if (!request.success) throw new this.$HttpError(request)

        this.rahoitettavanYhteenveto = request.result.body || []

        this.ladataanRahoitettavanYhteenvetoa = false
      } catch (e) {
        this.$oletusVirheenkasittely(
          'Rahoitusyhteenvedon lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.'
        )
        this.ladataanRahoitettavanYhteenvetoa = false
      }
    },
    async haeLaskuMyyntiData(paamiesId) {
      if (!paamiesId) return

      try {
        const queryParams = new URLSearchParams()
        queryParams.append('paamies_id', paamiesId)

        const request = await this.$doRequestWithTimeout(
          this.$api.WithAuthService,
          {
            url: 'lasku_myynti',
            query: queryParams.toString(),
          }
        )

        if (!request.success) throw new this.$HttpError(request)

        this.laskuMyyntiData = request.result.body
      } catch (e) {
        this.$sentryCaptureCustom(e)
      }
      //Tän alapuolelle chart jutut

      this.laskuMyyntiData = {
        labels: ['Avoinna', 'Myohassa'],
        datasets: [
          {
            backgroundColor: ['#bfb4dd', '#3f3759'],
            data: [laskuMyyntiData],
          },
        ],
      }
    },
    async haePaamiesLogonOlemassaolo(paamiesId) {
      if (!paamiesId) return

      try {
        const queryParams = new URLSearchParams()
        queryParams.append('vain_tiedoston_olemassaolo', true)

        const request = await this.$doRequestWithTimeout(
          this.$api.WithAuthService,
          {
            url: `paamies_logo/${paamiesId}/`,
            query: queryParams.toString(),
          }
        )

        if (!request.success) throw new this.$HttpError(request)

        this.paamiesLogoOlemassa = request.result.body
      } catch (e) {
        this.$sentryCaptureCustom(e)
      }
    },
    async haePaamiesLogo(paamiesId) {
      if (!paamiesId) return

      try {
        const url =
          process.env.VUE_APP_API_ROOT + `/paamies_logo/${paamiesId}/`
        const result = await haeTiedostoFetchilla(url)

        this.$naytaTiedostonlataamisdialog({
          blob: await result.blob(),
          headers: {
            'content-disposition': result.headers.get('content-disposition'),
          },
        })
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          'Logon lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.'
        )
      }
    },
    avaaPaamiesLogoDialog() {
      this.paamiesLogoFormData = {
        paamies: this.valittuPaamies,
      }
      this.paamiesLogoDialogAuki = true
    },
    async paamiesLogoSubmit() {
      try {
        const formData = new FormData()
        formData.set('attachment_file', this.paamiesLogoFormData.tiedosto)

        const request = await this.$doRequestWithTimeout(
          this.$api.WithAuthService,
          {
            method: 'POST',
            body: formData,
            url: `paamies_logo/${this.paamiesLogoFormData.paamies.id}/`,
          }
        )

        if (!request.success) throw new this.$HttpError(request)

        this.paamiesLogoDialogAuki = false
        this.$naytaOnnistumisilmoitus('Logo lisätty!')

        this.paamiesLogoOlemassa = true
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Logon lisääminen ei onnistunut!')
      }
    },
    // async siirryAnittaOnlineen () {
    //   this.accessToken = localStorage.getItem('accessToken')
    //   try {
    //     const request = await this.$doRequestWithTimeout(
    //       this.$api.RedirectAnittaOnline,
    //     )

    //     if (!request.success) throw new this.$HttpError(request)

    //     window.location.href = 'http://localhost:8000/online'
    //   } catch (e) {
    //     this.$oletusVirheenkasittely(e, 'Hups')
    //   }
    // }
  },
}
</script>

<style scoped lang="scss">
.tyopoyta {
  height: 100%;
}

.tyopoyta__chart-pie {
  margin: -100px 0;
}

.tyopoyta__gradient {
  background-image: linear-gradient(to bottom right,
      wjote,
      var(--v-secondary-lighten3));
}
.paamies-card{
  width: 60%;
  display: flex;
  justify-self: center;
  align-self: center;
}

#t-content {
  height: calc(100vh - 64px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr 2fr;
  grid-template-areas:
    "filtering filtering"
    "btns btns"
    "combo myynti";
  row-gap: 2rem;
  column-gap: 1rem;
  //Top-Right-Bottom-Left
  padding: 1rem 1rem 1rem 1rem;
}

#tc-filter {
  grid-area: filtering;
}

#tc-btns {
  grid-area: btns;
  display: flex;
  align-items: center;
  justify-content: center;

  #tc-btns-inner {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}

#tc-myynti {
  grid-area: myynti;
}

.b-combo {
  grid-area: combo;
}

.b-rahoitus {
  grid-area: rahoitus;
  height: 100%;}

.p-container {
  height: 100%;
}

.l-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100%;
}
</style>
