<template>
  <v-data-table
    :items="items"
    :headers="tableHeadersSopimusAsiakirja"
    :hide-default-footer="items.length <= 10"
    :dense="tiivisNakyma"
  >
    <template v-slot:item="{ item }">
      <TableRow>
        <td>
          <a @click="haeTiedosto(item)">{{ item.nimi }}</a>
        </td>
        <td class="text-no-wrap">
          {{ $date(item.luotu) }}
        </td>
        <td class="text-no-wrap text-right">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                icon
                :small="tiivisNakyma"
                v-on="on"
                @click="haeTiedosto(item)"
              >
                <v-icon :small="tiivisNakyma">
                  anitta-icon-file-download
                </v-icon>
              </v-btn>
            </template>
            <template
              v-slot:default
            >
              <span>{{ $t('common:loadDocument') }}</span>
            </template>
          </v-tooltip>
        </td>
      </TableRow>
    </template>
  </v-data-table>
</template>

<script>

import { TableHeadersSopimusAsiakirja } from '@/utils/tableHeaders'
import TiivisNakymaMixin from '@/mixins/TiivisNakymaMixin'

export default {
  name: 'DataTableSopimusAsiakirja',
  mixins: [TiivisNakymaMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    sopimus: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      tableHeadersSopimusAsiakirja: TableHeadersSopimusAsiakirja,
    }
  },
  methods: {
    async haeTiedosto (tiedosto) {
      try {
        const asiakirjaId = tiedosto.id

        const request = await this.$doRequestWithTimeout(
          this.$api.Sopimus,
          {
            url: `toiminnot/asiakirja/${asiakirjaId}/`,
            params: {
              id: this.sopimus.id
            }
          },
        )

        if (!request.success) throw new this.$HttpError(request)

        this.$naytaTiedostonlataamisdialog({
          fileData: request.result.body,
          filename: tiedosto.nimi,
          headers: request.result.headers,
        })
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
  }
}

</script>

<style lang="scss" scoped>

</style>
