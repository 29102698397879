var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { class: _vm.cardClass, attrs: { height: _vm.height, to: _vm.to } },
    [
      _c(
        "div",
        {
          staticClass:
            "tyopoyta-pikanappain__text tyopoyta-pikanappain__text--otsikko d-flex justify-center white primary--text",
        },
        [_vm._t("otsikko", [_vm._v(" " + _vm._s(_vm.otsikko) + " ")])],
        2
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-center pb-2 primary--text white" },
        [_vm._t("icon", [_c("span", { class: _vm.iconClass })])],
        2
      ),
      _vm._t("default"),
      _c(
        "div",
        {
          staticClass:
            "tyopoyta-pikanappain__text d-flex justify-center primary--text white",
        },
        [_vm._t("text", [_c("div", [_vm._v(_vm._s(_vm.text))])])],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }