var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FormRaporttiAsetuksetBase", {
    scopedSlots: _vm._u([
      {
        key: "oikea-sarake",
        fn: function () {
          return [
            _c("v-checkbox", {
              attrs: { label: "Vain yhteenveto" },
              model: {
                value: _vm.raporttilomake.asetukset.vain_yhteenveto,
                callback: function ($$v) {
                  _vm.$set(_vm.raporttilomake.asetukset, "vain_yhteenveto", $$v)
                },
                expression: "raporttilomake.asetukset.vain_yhteenveto",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }