<template>
  <div v-if="palvelusopimusId">
    <CardTitle
      color="black--text"
      :text="$t('common:settings.subscription')"
      :center="false"
    />
    <v-card
      class="sub-card"
      flat
      outlined
    >
      <v-radio-group
        v-model="palvelusopimusId"
        hide-details
        class="radio"
        :items="palvelusopimusItems"
      >
        <div class="subscription">
          <v-radio :value="palvelusopimukset[0].id"></v-radio>
          <div class="sub-info">
            <div>{{ palvelusopimukset[0].nimi }}</div>
            <div>
              {{ palvelusopimukset[0].kkhinta }}€ / {{$t('common:monthShorthand')}} ({{
                palvelusopimukset[0].vuosihinta
              }}
              € / {{$t('common:yearLowerCase')}})
            </div>
            <div>{{$t('common:settings.manualCollection')}}</div>
          </div>
        </div>

        <v-divider width="100%"></v-divider>
        <div class="subscription">
          <v-radio :value="palvelusopimukset[1].id"></v-radio>
          <div class="sub-info">
            <div>{{ palvelusopimukset[1].nimi }}</div>
            <div>
              {{ palvelusopimukset[1].kkhinta }} € / {{$t('common:monthShorthand')}} ({{
                palvelusopimukset[1].vuosihinta
              }}
              € / {{$t('common:yearLowerCase')}})
            </div>
            <div>{{$t('common:settings.automaticPaymentMonitoring')}}</div>
          </div>
        </div>
      </v-radio-group>
    </v-card>
    <div class="switch">
      <div class="switch-box">
        <v-switch
          v-model="voimassaolevaTilaus.laskutusjakso"
          true-value="VUOSI"
          false-value="KUUKAUSI"
        ></v-switch>
        <div>{{$t('common:settings.yearlyBilling')}}</div>
        <div>({{$t('common:settings.saveTenPercent')}})</div>
      </div>
      <div class="switch-btn">
        <v-btn
          class="primary"
          @click="vaihdaSopimus()"
        >
        {{$t('common:save')}}
        </v-btn>
      </div>
    </div>

    <div>
      <CardTitle
        color="black--text"
        :text="$t('common:settings.paymentHistory')"
      />
       <v-data-table
    :headers="headers"
    :items="paamiesLaskut"
    item-key="name"
    sort-by="name"
    class="elevation-1"
  >
  <template v-slot:item="{ item }">
          <TableRow
          :data="item"
          >
            <td class="no-wrap text-xs-right">
              {{ item.laskupaiva }}
            </td>
            <td class="no-wrap text-xs-right">
              {{ item.erapaiva }}
            </td>
            <td class="no-wrap text-xs-right">
             {{ item.selite}} - {{ item.laskutusjakso}}
            </td>
            <td class="no-wrap text-xs-right">
              {{ item.summa }}
            </td>
            <td class="no-wrap text-xs-right">
              {{ item.avoinna }}
            </td>
            <td class="no-wrap text-xs-right">
              <div v-if="item.tila === 'A'">{{$t('common:open')}}</div>
              <div v-if="item.tila === 'M'">{{$t('common:paid')}}</div>
            </td>
          </TableRow>
        </template>

  </v-data-table>
     
    </div>

    <div class="cancel-sub">
      <div>
        <CardTitle
          color="black--text"
          :text="$t('common:settings.cancelSubscription')"
        />
      </div>

      <div class="cs-content">
        {{$t('common:settings.youCanCancelAnytime')}}
      </div>
      <div class="cs-btn">
        <v-btn class="grey" @click="peruutaTilaus()">
          {{$t('common:settings.cancelSubscription')}}
        </v-btn>
      </div>
    </div>
  </div>
  <div class="else" v-else>
    <h2>{{$t('common:settings.creditorLacksServiceAgreement')}}</h2>
  </div>
</template>

<script>
import { Tilaustilat } from '@/utils/constants'
import FormMixin from '@/mixins/FormMixin'
import { mapState } from 'vuex'

export default {
  name: 'PalvelusopimusKayttajatili',
  mixins: [FormMixin],
  data() {
    return {
      palvelusopimusId: null,
      tilaustilat: Tilaustilat,
      headers: [
        { text: this.$t('common:settings.billingDate'), value: 'laskupaiva' },
        { text: this.$t('common:settings.dueDate'), value: 'erapaiva' },
        { text: this.$t('common:settings.description'), value: 'selite'  },
        { text: this.$t('common:sum'), value: 'summa' },
        { text: this.$t('common:open'), value: 'avoinna' },
        { text: this.$t('common:status'), value: 'tila' },
      ],
    }
  },
  computed: {
    ...mapState({
      palvelusopimukset: (state) => state.user.palvelusopimukset,
      paamies: (state) => state.user.valittuPaamies,
      tilaukset: (state) => state.asetukset.tilaukset,
    }),
    paamiehenPalvelusopimus() {
      return this.$store.getters['user/paamiehenPalvelusopimus']
    },
    palvelusopimusItems() {
      // Ei sallita vaihtoa palvelusopimukseen, joka päämiehellä on jo käytössä
      const disablePalvelusopimusKaytossa = function (ps, ps_id_kaytossa) {
        if (ps.id === ps_id_kaytossa)
          return { selite: ps.nimi, value: ps.id, disabled: true }

        return { selite: ps.nimi, value: ps.id, disabled: false }
      }

      return this.palvelusopimukset.map((ps) =>
        disablePalvelusopimusKaytossa(ps, this.paamiehenPalvelusopimus.id)
      )
    },
    vanhatTilaukset() {
      if (!this.tilaukset) return []
      return this.tilaukset.filter(
        (tilaus) => tilaus.tila === Tilaustilat.PAATTYNYT
      )
    },
    voimassaolevaTilaus() {
      if (!this.tilaukset) return []
      return this.tilaukset.filter(
        (tilaus) =>
          tilaus.tila === Tilaustilat.AKTIIVINEN ||
          tilaus.tila === Tilaustilat.PERUUTETTU
      )[0]
    },
    paamiesLaskut() {
      if (!this.tilaukset) return []
      const paamieslaskut = []
      this.tilaukset.map((tilaus) =>
        tilaus.paamieslaskut.map((paamieslasku) => {
          paamieslasku.selite = tilaus.selite
          paamieslasku.laskutusjakso = tilaus.laskutusjakso
          paamieslaskut.push(paamieslasku)
        }
        )
      )
      return paamieslaskut
    },
  },
  watch: {
    paamiehenPalvelusopimus: {
      immediate: true,
      handler() {
        if (this.paamiehenPalvelusopimus) {
          this.palvelusopimusId = this.paamiehenPalvelusopimus.id
        } else {
          this.palvelusopimusId = null
        }
      },
    },
  },
  methods: {
    async peruutaTilaus() {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: 'post',
            url: `${this.paamies.id}/toiminnot/peruuta_palvelusopimus/`,
          },
          'doSingleRequest'
        )

        if (!request.success) throw new this.$HttpError(request)

        await this.$store.dispatch('asetukset/haeTilaukset')

        this.$naytaOnnistumisilmoitus(
          this.$t('common:settings.subscriptionCanceled')
        )
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t('common:settings.subscriptionCancelFailed')
        )
      }
    },
    async aktivoiTilaus() {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: 'post',
            url: `${this.paamies.id}/toiminnot/aktivoi_palvelusopimus/`,
          },
          'doSingleRequest'
        )

        if (!request.success) throw new this.$HttpError(request)

        await this.$store.dispatch('asetukset/haeTilaukset')

        this.$naytaOnnistumisilmoitus(
          this.$t('common:settings.subscriptionActivated')
        )
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t('common:settings.subscriptionActivationFailed')
        )
      }
    },
    async vaihdaSopimus() {
      try {
        const body = { palvelusopimus_id: this.palvelusopimusId }
        const request = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: 'post',
            url: `${this.paamies.id}/toiminnot/vaihda_palvelusopimus/`,
            body: body,
          },
          'doSingleRequest'
        )

        if (!request.success) throw new this.$HttpError(request)

        this.$store.commit('user/setValittuPaamiesPalvelusopimusId', {
          palvelusopimusId: this.palvelusopimusId,
        })

        await this.$store.dispatch('asetukset/haeTilaukset')

        this.$naytaOnnistumisilmoitus(
          this.$t('common:settings.subscriptionChanged')
        )
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t('common:settings.subscriptionChangeFailed')
        )
      }
    },
  },
}
</script>
<style scoped lang="scss">
.subscription {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  width: 100%;
}
.switch {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.switch-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
}
.switch-btn {
  display: flex;
  align-self: center;
  justify-self: flex-end;
  margin-right: 2rem;
}
.sub-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.cancel-sub {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.cs-content {
  display: flex;
  margin-left: 1em;
}
.cs-btn {
  display: flex;
  margin-left: 1em;
}
.radio {
  margin-top: 0px !important;
}
.else{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--v-primary-base);
}
</style>
