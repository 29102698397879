var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.divClass },
    [
      _c("v-progress-circular", {
        attrs: { indeterminate: "", color: "primary" },
      }),
      _vm.teksti
        ? _c("p", { staticClass: "my-4 font-no-data" }, [
            _vm._v(" " + _vm._s(_vm.teksti) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }