var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "formLiitteet",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit($event)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-file-input", {
        attrs: {
          chips: "",
          counter: "5",
          multiple: true,
          rules: [
            _vm.$validationRules.requiredArrayLength(_vm.value.tiedostot, 1),
            _vm.$validationRules.smallerThanOrEqualTo(5, 5),
          ],
          "validate-on-blur": true,
          hint: _vm.fileInputHint,
        },
        model: {
          value: _vm.value.tiedostot,
          callback: function ($$v) {
            _vm.$set(_vm.value, "tiedostot", $$v)
          },
          expression: "value.tiedostot",
        },
      }),
      _c("PeruutaHyvaksyPainikerivi", {
        attrs: {
          "submit-disabled": _vm.submitDisabloitu,
          "submit-loading": _vm.loading,
        },
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }