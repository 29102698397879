var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.palvelusopimusId
    ? _c(
        "div",
        [
          _c("CardTitle", {
            attrs: {
              color: "black--text",
              text: _vm.$t("common:settings.subscription"),
              center: false,
            },
          }),
          _c(
            "v-card",
            { staticClass: "sub-card", attrs: { flat: "", outlined: "" } },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "radio",
                  attrs: { "hide-details": "", items: _vm.palvelusopimusItems },
                  model: {
                    value: _vm.palvelusopimusId,
                    callback: function ($$v) {
                      _vm.palvelusopimusId = $$v
                    },
                    expression: "palvelusopimusId",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "subscription" },
                    [
                      _c("v-radio", {
                        attrs: { value: _vm.palvelusopimukset[0].id },
                      }),
                      _c("div", { staticClass: "sub-info" }, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.palvelusopimukset[0].nimi)),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.palvelusopimukset[0].kkhinta) +
                              "€ / " +
                              _vm._s(_vm.$t("common:monthShorthand")) +
                              " (" +
                              _vm._s(_vm.palvelusopimukset[0].vuosihinta) +
                              " € / " +
                              _vm._s(_vm.$t("common:yearLowerCase")) +
                              ") "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("common:settings.manualCollection"))
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider", { attrs: { width: "100%" } }),
                  _c(
                    "div",
                    { staticClass: "subscription" },
                    [
                      _c("v-radio", {
                        attrs: { value: _vm.palvelusopimukset[1].id },
                      }),
                      _c("div", { staticClass: "sub-info" }, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.palvelusopimukset[1].nimi)),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.palvelusopimukset[1].kkhinta) +
                              " € / " +
                              _vm._s(_vm.$t("common:monthShorthand")) +
                              " (" +
                              _vm._s(_vm.palvelusopimukset[1].vuosihinta) +
                              " € / " +
                              _vm._s(_vm.$t("common:yearLowerCase")) +
                              ") "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "common:settings.automaticPaymentMonitoring"
                              )
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "switch" }, [
            _c(
              "div",
              { staticClass: "switch-box" },
              [
                _c("v-switch", {
                  attrs: { "true-value": "VUOSI", "false-value": "KUUKAUSI" },
                  model: {
                    value: _vm.voimassaolevaTilaus.laskutusjakso,
                    callback: function ($$v) {
                      _vm.$set(_vm.voimassaolevaTilaus, "laskutusjakso", $$v)
                    },
                    expression: "voimassaolevaTilaus.laskutusjakso",
                  },
                }),
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("common:settings.yearlyBilling"))),
                ]),
                _c("div", [
                  _vm._v(
                    "(" + _vm._s(_vm.$t("common:settings.saveTenPercent")) + ")"
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "switch-btn" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary",
                    on: {
                      click: function ($event) {
                        return _vm.vaihdaSopimus()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common:save")) + " ")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            [
              _c("CardTitle", {
                attrs: {
                  color: "black--text",
                  text: _vm.$t("common:settings.paymentHistory"),
                },
              }),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.paamiesLaskut,
                  "item-key": "name",
                  "sort-by": "name",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("TableRow", { attrs: { data: item } }, [
                            _c("td", { staticClass: "no-wrap text-xs-right" }, [
                              _vm._v(" " + _vm._s(item.laskupaiva) + " "),
                            ]),
                            _c("td", { staticClass: "no-wrap text-xs-right" }, [
                              _vm._v(" " + _vm._s(item.erapaiva) + " "),
                            ]),
                            _c("td", { staticClass: "no-wrap text-xs-right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(item.selite) +
                                  " - " +
                                  _vm._s(item.laskutusjakso) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "no-wrap text-xs-right" }, [
                              _vm._v(" " + _vm._s(item.summa) + " "),
                            ]),
                            _c("td", { staticClass: "no-wrap text-xs-right" }, [
                              _vm._v(" " + _vm._s(item.avoinna) + " "),
                            ]),
                            _c("td", { staticClass: "no-wrap text-xs-right" }, [
                              item.tila === "A"
                                ? _c("div", [
                                    _vm._v(_vm._s(_vm.$t("common:open"))),
                                  ])
                                : _vm._e(),
                              item.tila === "M"
                                ? _c("div", [
                                    _vm._v(_vm._s(_vm.$t("common:paid"))),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  96702713
                ),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "cancel-sub" }, [
            _c(
              "div",
              [
                _c("CardTitle", {
                  attrs: {
                    color: "black--text",
                    text: _vm.$t("common:settings.cancelSubscription"),
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "cs-content" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("common:settings.youCanCancelAnytime")) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "cs-btn" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "grey",
                    on: {
                      click: function ($event) {
                        return _vm.peruutaTilaus()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("common:settings.cancelSubscription")) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _c("div", { staticClass: "else" }, [
        _c("h2", [
          _vm._v(
            _vm._s(_vm.$t("common:settings.creditorLacksServiceAgreement"))
          ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }