<template>
  <div class="pagination py-4">
    <v-pagination
      v-model="localState"
      v-bind="$props"
      :total-visible="totalVisible"
    />
  </div>
</template>

<script>

export default {
  name: 'Pagination',
  props: {
    value: {
      type: Number,
      required: false,
      default () {
        return 1
      },
    },
    length: {
      type: Number,
      required: false,
      default () {
        return 1
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default () {
        return false
      },
    },
    totalVisible: {
      type: Number,
      required: false,
      default () {
        return 7
      },
    },
  },
  computed: {
    localState: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
  },
}

</script>

<style lang="scss">

</style>
