<template>
  <v-btn-toggle
    v-model="tiivisNakyma"
    dense
    :dark="color ? true : false"
    :background-color="bgColor"
  >
    <v-btn
      :color="color"
      :value="(true)"
    >
      <v-icon>view_headline</v-icon>
    </v-btn>
    <v-btn
      :color="color"
      :value="(false)"
    >
      <v-icon>view_list</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>

export default {
  name: 'DenseToggleButton',
  props: {
    color: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
    bgColor: {
      type: String,
      required: false,
      default () {
        return ''
      },
    },
  },
  computed: {
    tiivisNakyma: {
      get () {
        return this.$store.state.user.tiivisNakyma
      },
      set (val) {
        this.$store.commit('user/setTiivisNakyma', val)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
