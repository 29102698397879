<template>
  <v-card
    :height="height"
    :class="cardClass"
    :to="to"
  >
    <div
      class="tyopoyta-pikanappain__text tyopoyta-pikanappain__text--otsikko d-flex justify-center white primary--text"
    >
      <slot name="otsikko">
        {{ otsikko }}
      </slot>
    </div>
    <div class="d-flex justify-center pb-2 primary--text white">
      <slot name="icon">
        <span :class="iconClass" />
      </slot>
    </div>
    <slot name="default"></slot>
    <div
      class="tyopoyta-pikanappain__text d-flex justify-center primary--text white"
    >
      <slot name="text">
        <div>{{ text }}</div>
      </slot>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'TyopoytaPikanappain',
  props: {
    icon: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default:
        'tyopoyta-pikanappain pa-4 d-flex flex-column justify-space-between',
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: String,
      default: '128',
    },
    klikattava: {
      type: Boolean,
      default: true,
    },
    otsikko: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    to: {
      type: Object,
      required: false,
      default() {
        return null
      },
    },
  },
  computed: {
    iconClass() {
      return ['tyopoyta-pikanappain__icon', 'anitta-icon-' + this.icon]
    },
    cardClass() {
      return [
        this.classes,
        this.klikattava ? 'tyopoyta-pikanappain--klikattava' : '',
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.tyopoyta-pikanappain {
  background-image: var(--v-white)
    /* linear-gradient(
    to bottom right,
    var(--v-primary-base),
    var(--v-primary-darken1))*/;

  &__icon {
    display: block;
    font-size: 3.2em;
  }

  &__text {
    font-size: 0.75rem; // Mukailee tyyliä v-btn.v-size--small
    font-weight: 500;
    letter-spacing: 0.0892857143em; // Mukailee v-tabsin tyyliä
    text-transform: uppercase;
    text-align: center;

    &--otsikko {
      font-size: 0.875rem; // Mukailee v-tabsin tyyliä
    }
  }

  &--klikattava:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
</style>
