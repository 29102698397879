<template>
  <v-form
    v-model="valid"
    @submit.prevent="submit"
  >
    <v-text-field
      v-model="value.email"
      :label="$t('email')"
      :disabled="disabloitu"
      :rules="[$validationRules.required, $validationRules.email]"
      outlined
      required
    />
    <v-btn
      type="submit"
      :disabled="!valid || loading || disabloitu"
      :loading="loading"
      block
      color="success"
      large
      class="ml-0"
    >
      {{ $t('serviceApplication.startFromHere') }}
    </v-btn>
  </v-form>
</template>

<script>

import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'FormPalveluhakemusAloita',
  mixins: [FormMixin],
  props: {
    disabloitu: {
      type: Boolean,
      default: false,
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
