<template>
  <div class="formTable">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'FormTable',
}

</script>

<style lang="scss" scoped>

  .formTable {
    display: table;
    width: 100%;
  }

</style>
