<template>
  <v-navigation-drawer
    class="sidebarLeft"
    width="250"
    app
    permanent
    :mini-variant="miniVariant"
  >
    <div class="sidebarLeft__wrap">
      <div class="sidebarLeft__section sidebarLeft__section--top">
        <div class="sidebarLeft__block pa-4">
          <Logo
            :small="miniVariant"
            max-width="130px"
            class="sidebarLeft__logo"
            :class="miniVariant ? null : 'pa-3'"
          />
          <TekeytyminenAlert
            v-if="tekeytyminenKaynnissa"
            :mini-variant="miniVariant"
          />
          <LoggedInUser
            v-else
            :show-username="!miniVariant"
            class="my-6"
            :mini-variant="miniVariant"
            :class="miniVariant ? 'px-0' : 'px-4'"
            @logOut="logOut"
            @siirry-onlineen="siirryOnlineen"
          />
          <NavMain :show-titles="!miniVariant" />
        </div>
      </div>
      <div class="sidebarLeft__section sidebarLeft__section--bottom">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="$vuetify.breakpoint.lgAndUp"
              icon
              :class="toggleButtonClass"
              @click="toggleWidth"
              v-on="on"
            >
              <v-icon>arrow_back</v-icon>
            </v-btn>
          </template>
          <template v-slot:default>
            {{ toggleButtonTooltip }}
          </template>
        </v-tooltip>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>

import Logo from '@/components/Logo'
import LoggedInUser from '@/components/LoggedInUser'
import NavMain from '@/components/NavMain'
import TekeytyminenAlert from '@/components/TekeytyminenAlert'

export default {
  name: 'SidebarLeft',
  components: {
    Logo,
    LoggedInUser,
    NavMain,
    TekeytyminenAlert,
  },
  data () {
    return {
      widthNarrow: false,
    }
  },
  computed: {
    miniVariant () {
      if (this.$vuetify.breakpoint.lgAndUp) return this.widthNarrow

      return true
    },
    toggleButtonClass () {
      return [
        'sidebarLeft__toggleButton',
        this.widthNarrow ? 'sidebarLeft__toggleButton--rotated' : null,
      ]
    },
    toggleButtonTooltip () {
      return (this.widthNarrow) ? 'Leveämpi sivupalkki' : 'Kapeampi sivupalkki'
    },
    tekeytyminenKaynnissa () {
      return this.$store.state.user.tekeytyminenKaynnissa
    }
  },
  watch: {
    widthNarrow (val) {
      localStorage.sidebarWidthNarrow = val
    },
  },
  mounted () {
    // Load width config
    if (typeof localStorage.sidebarWidthNarrow != 'undefined') {
      this.widthNarrow = (localStorage.sidebarWidthNarrow == 'true')
    } else {
      localStorage.sidebarWidthNarrow = this.widthNarrow
    }
  },
  methods: {
    toggleWidth () {
      this.widthNarrow = !this.widthNarrow
    },

    siirryOnlineen () {
      window.location.assign(process.env.VUE_APP_ANITTA_ONLINE_URL)
    },

    logOut () {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')

      this.$store.dispatch('user/clearDetails')

      const redirectUrl = process.env.VUE_APP_LOGOUT_REDIRECT_URL
      if (redirectUrl) {
        window.location.replace(redirectUrl)

        return
      }

      this.$router.push({ name: 'kirjaudu' })

      this.$naytaOnnistumisilmoitus('Olet kirjautunut ulos')
    },

    switchLang () {
      if (this.$i18n.i18next.language == 'fi') {
        this.$i18n.i18next.changeLanguage('en')
      } else {
        this.$i18n.i18next.changeLanguage('fi')
      }
    },
  },
}

</script>

<style lang="scss">

  .sidebarLeft {
    &__wrap {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      height: 100%;
      color: lighten(#000, 20);
      background: #fff;
   }

    &__section {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      &--top {
        flex: 1 1 auto;
      }

      &--bottom {
        align-items: center;
      }
    }

    &__block {
      border-top: solid 1px lighten(#000, 90);

      &:first-child {
        border-top: 0;
      }
    }

    // &__logo {
    //   margin: auto;
    //   width: 70%;
    // }

    &__toggleButton {
      &--rotated {
        transform: rotate(180deg);
      }
    }
  }

</style>
