<template>
  <div>
    <div v-if="naytaMaksutiedot">
      <h2 class="my-4">
        {{ $t("common:paymentInfo") }}
      </h2>
      <FormAsetuksetMaksutiedot
        v-model="maksutiedotFormData"
        @submit="maksutiedotSubmit"
      />
      <v-divider class="my-6" />
    </div>
    <v-row>
      <v-col>
    <h2 class="my-4">
      {{ $t("common:logo") }}
    </h2>
    <v-btn
      v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku')"
      class="mt-3 align-self-start d-block"
      color="info"
      @click="avaaPaamiesLogoDialog"
    >
      {{ $t("common:settings.uploadLogo") }}
    </v-btn>
      </v-col>
      <v-col>
      <div v-if="paamiesLogoOlemassa" class="d-flex align-left app-bg--white rounded">
          <v-img
            alt="Logo"
            class="mx-4"
            contain
            :src="info.src"
            aspect-ratio="1"
            :height="$vuetify.breakpoint.smAndDown ? 40 : 100"
          />
      </div>
      <div v-if="!paamiesLogoOlemassa" class="d-flex align-left app-bg--white rounded">
        {{ $t("common:settings.logoNotUploaded") }}
      </div>
      </v-col>
      </v-row>
    <v-divider class="my-6" />
    <h2 class="my-4">
      {{ $t("common:settings.invoiceLetterText") }}
    </h2>
    <FormAsetuksetPaamiesasetukset
      v-model="paamiesasetuksetFormData"
      @submit="paamiesasetuksetSubmit"
    />
    <div v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_beta')">
      <v-divider class="my-6" />
      <h2 class="my-4">
        Laskunumero (2.vaihe)
      </h2>
      <ul>
        <li>seuraavan laskunumeron valinta</li>
        <li>laskunumeron etuliitteen valinta</li>
        <li>laskunumeron takaliiteen valinta</li>
      </ul>
      <h2 class="my-4">
        Oletusasetukset (2.vaihe)
      </h2>
      <ul>
        <li>sähköpostiviestin saatesanat oletuksena</li>
        <li>oletusasetus: laskulla näkyvä teksti ennen tuoterivejä</li>
        <li>oletusasetus: (vain yritykset): viivästyskorko</li>
        <li>oletusasetus: maksuehto</li>
      </ul>
    </div>
    <CardDialog
      ref="refLogoLataus"
      :dialog-auki="paamiesLogoDialogAuki"
      title="Lisää laskukirjeen logo"
      @close="paamiesLogoDialogAuki = false"
    >
      <template v-if="paamiesLogoOlemassa">
        <v-card
          class="mx-4 mb-4 pa-4 d-flex justify-space-between"
          color="info"
        >
          <div>
            <v-icon
              dark
              class="mr-2"
            >
              info
            </v-icon>
            <span class="text-subtitle-1 white--text">Logo on jo lisätty.</span>
          </div>
          <!--v-btn
            class="ml-4 mt-0"
            color="#26c6da"
            dark
            small
            @click="haePaamiesLogo(paamies.id)"
          >
            Lataa tästä
          </v-btn-->
        </v-card>
        <v-card
          class="mx-4 mb-4 pa-4"
          color="info"
        >
          <v-icon
            dark
            class="mr-2"
          >
            info
          </v-icon>
          <span class="text-subtitle-1 white--text">Uuden logon lisääminen korvaa vanhan logon.</span>
        </v-card>
      </template>
      <FormLiite
        v-model="paamiesLogoFormData"
        class="pa-4"
        :loading="paamiesLogoFormLoading"
        :on-auki="paamiesLogoDialogAuki"
        tarkista-logon-kuvasuhde
        @cancel="paamiesLogoDialogAuki = false"
        @submit="paamiesLogoSubmit"
        @close="paamiesLogoDialogAuki = false"
      >
      </FormLiite>
    </CardDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  FormAsetuksetPaamiesasetukset,
  FormAsetuksetMaksutiedot,
  FormLiite,
} from '@/components/index'
import { haeTiedostoFetchilla } from '@/utils/misc'

export default {
  name: 'Laskutusasetukset',
  components: {
    FormAsetuksetMaksutiedot,
    FormLiite,
    FormAsetuksetPaamiesasetukset,
  },
  data() {
    return {
      maksutiedotFormData: {},
      paamiesasetuksetFormData: {},
      paamiesLogoFormData: {},
      paamiesLogoFormLoading: false,
      paamiesLogoDialogAuki: false,
      paamiesLogoOlemassa: false,
      info: {
        name: '',
        src: '',
      },
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      paamies: (state) => state.user.valittuPaamies,
    }),
    naytaMaksutiedot() {
      // Maksutietoja saa muokata vain, jos päämiehellä on palvelusopimus, joka käyttää päämiehen
      // omia maksutietoja
      if (
        this.$permission.checkSinglePerm(
          'onlinerestapi.onlinerestapi_palvelusopimus'
        ) &&
        this.paamies.palvelusopimus &&
        this.paamies.palvelusopimus.omat_maksutiedot
      ) {
        return true
      }
      return false
    },
  },
  watch: {
    paamies: {
      immediate: true,
      handler() {
        if (this.naytaMaksutiedot) {
          this.maksutiedotFormData = {
            iban: this.paamies.maksutieto.iban,
            bic: this.paamies.maksutieto.bic,
          }
        }

        if (
          this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_lasku')
        ) {
          this.paamiesasetuksetFormData = {
            viestikirjeeseen: this.paamies?.viestikirjeeseen || '',
          }

          this.haePaamiesLogonOlemassaolo(this.paamies.id)
          this.haeTalletettuPaamiesLogo(this.paamies.id)
        }
      },
    },
  },
  methods: {
    async paamiesasetuksetSubmit() {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: 'put',
            url: `${this.paamies.id}/toiminnot/laskutusasetukset/`,
            body: this.paamiesasetuksetFormData,
          },
          'doSingleRequest'
        )

        if (!request.success) throw new this.$HttpError(request)

        this.$store.commit(
          'user/setValittuPaamiesPaamiesasetukset',
          this.paamiesasetuksetFormData
        )

        this.$naytaOnnistumisilmoitus(
          this.$t('common:settings.invoicingSettingsSaved')
        )
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t('common:settings.invoicingSettingsSaveFailed')
        )
      }
    },
    async maksutiedotSubmit() {
      try {
        const request = await this.$doRequestWithTimeout(
          this.$api.Paamies,
          {
            method: 'put',
            url: `maksutieto/${this.paamies.maksutieto.id}/`,
            body: this.maksutiedotFormData,
          },
          'doSingleRequest'
        )

        if (!request.success) throw new this.$HttpError(request)

        await this.$store.commit(
          'user/setValittuPaamiesMaksutiedot',
          this.maksutiedotFormData
        )

        this.$naytaOnnistumisilmoitus(
          this.$t('common:settings.paymentInfoSaved')
        )
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          this.$t('common:settings.paymentInfoSaveFailed')
        )
      }
    },
    avaaPaamiesLogoDialog() {
      this.paamiesLogoFormData = {
        paamies: this.paamies,
      }
      this.paamiesLogoDialogAuki = true
    },
    async haePaamiesLogonOlemassaolo(paamiesId) {
      if (!paamiesId) return

      try {
        const queryParams = new URLSearchParams()
        queryParams.append('vain_tiedoston_olemassaolo', true)

        const request = await this.$doRequestWithTimeout(
          this.$api.WithAuthService,
          {
            url: `paamies_logo/${paamiesId}/`,
            query: queryParams.toString(),
          }
        )

        if (!request.success) throw new this.$HttpError(request)

        this.paamiesLogoOlemassa = request.result.body
      } catch (e) {
        this.$sentryCaptureCustom(e)
      }
    },
    async encode(data){
    let buf = Buffer.from(data);
    let base64 = buf.toString('base64');
    return base64
    },
    async haeTalletettuPaamiesLogo(paamiesId) {
      if (!paamiesId) return

      try {
        const queryParams = new URLSearchParams()
        let mimeType = 'image/png'

        const request = await this.$doRequestWithTimeout(
          this.$api.WithAuthService,
          {
            url: `paamies_logo/${paamiesId}/`,
            query: queryParams.toString(),
          }
        )

        if (!request.success && request.result.status !== 404) throw new this.$HttpError(request)

        if (!request.result || !request.result.body) return
        if (request.result.headers && request.result.headers['content-type']) {
          mimeType = request.result.headers['content-type']
        }

        this.info = new Image()
        var byteCharacters = atob(request.result.body);
        var byteNumbers = new Array(byteCharacters.length)
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers)
        var file = new Blob([byteArray], { type: mimeType+";base64" })
        this.info.src = URL.createObjectURL(file);

      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          'Logon lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.'
        )
      }
    },
    naytaPaamiesLogo (paamiesId) {
      const logo = haeTalletettuPaamiesLogo(paamiesId)
      return logo ? require(`@/assets/${logo}.png`) : ''
    },
    async haePaamiesLogo(paamiesId) {
      if (!paamiesId) return

      try {
        const url =
          process.env.VUE_APP_API_ROOT + `/paamies_logo/${paamiesId}/`
        const result = await haeTiedostoFetchilla(url)

        this.$naytaTiedostonlataamisdialog({
          blob: await result.blob(),
          headers: {
            'content-disposition': result.headers.get('content-disposition'),
          },
        })
      } catch (e) {
        this.$oletusVirheenkasittely(
          e,
          'Logon lataaminen ei onnistunut! Yritä hetken kuluttua uudelleen.'
        )
      }
    },
    async paamiesLogoSubmit() {
      try {
        const formData = new FormData()
        formData.set('attachment_file', this.paamiesLogoFormData.tiedosto)

        const request = await this.$doRequestWithTimeout(
          this.$api.WithAuthService,
          {
            method: 'POST',
            body: formData,
            url: `paamies_logo/${this.paamiesLogoFormData.paamies.id}/`,
          }
        )

        if (!request.success) throw new this.$HttpError(request)

        this.paamiesLogoDialogAuki = false
        this.$naytaOnnistumisilmoitus('Logo lisätty!')
        this.haeTalletettuPaamiesLogo(this.paamiesLogoFormData.paamies.id)

        this.paamiesLogoOlemassa = true
      } catch (e) {
        this.$oletusVirheenkasittely(e, 'Logon lisääminen ei onnistunut!')
      }
    },
  },
}
</script>
