<template>
  <v-form
    v-model="valid"
    @submit.prevent="submit"
  >
    <v-row class="mb-n7">
      <v-col>
        <v-autocomplete
          v-model="value.tilasto"
          :items="tilastot"
          :rules="[$validationRules.required]"
          :loading="lataaTilastoja"
          :label="$t('common:statistics.statistics')"
          item-text="nimi"
          return-object
        ></v-autocomplete>
      </v-col>

      <v-col>
        <v-autocomplete
          v-model="value.raportti"
          :items="raportit"
          :loading="lataaRaportteja"
          :label="$t('common:report')"
          item-text="nimi"
          item-value="id"
          return-object
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="naytaPaamiehet"
        class="mb-n7"
      >
        <AutocompletePaamies
          v-model="value"
          :label="$t('creditor')"
          :validointi-saannot="ACValidointisaannot"
          multiple
          tarkistettava-oikeus="onlinerestapi.onlinerestapi_tilasto"
          :disabled="value.kaikkiPaamiehet"
        />
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col>
            <v-checkbox
              v-model="value.kaikkiPaamiehet"
              :label="$t('statistics.allCreditors')"
            />
          </v-col>
          <v-col>
            <v-btn
              type="submit"
              color="success"
              :disabled="!valid"
            >
              {{ $t('common:statistics.searchStatistics') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'
import { AutocompletePaamies } from '@/components'

export default {
  name: 'FormTilasto',
  components: {
    AutocompletePaamies,
  },
  mixins: [FormMixin],
  props: {
    tilastot: {
      type: Array,
      required: true,
    },
    raportit: {
      type: Array,
      required: true,
    },
    lataaTilastoja: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    lataaRaportteja: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    naytaPaamiehet: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    ACValidointisaannot () {
      if (this.value.kaikkiPaamiehet) return []
      return [this.$validationRules.requiredArrayLength]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
