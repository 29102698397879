<template>
  <DefList :bordered="(true)">
    <DefListItem>
      <template v-slot:label>
        Tila
      </template>
      <template
        v-if="value.tila && value.ikoni_ja_vari"
        v-slot:value
      >
        <StatusText
          :text="value.tila.selite"
          :color="value.ikoni_ja_vari.vari"
        />
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        Laskunumero
      </template>
      <template
        v-if="value.nro"
        v-slot:value
      >
        {{ value.nro }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        Asiakas
      </template>
      <template
        v-if="value.asiakas"
        v-slot:value
      >
        <template v-if="eiOikeuttaNahdaAsiakastaValue(value)">
          <span
            class="font-italic"
          >Ei voida näyttää tietosuojasyistä</span>
        </template>
        <template v-else>
          {{ value.asiakas.nimi }} <template v-if="value.asiakas.nro">
            ({{ value.asiakas.nro }})
          </template>
        </template>
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        Päämies
      </template>
      <template
        v-if="value.paamies"
        v-slot:value
      >
        {{ value.paamies.nimi }} <template v-if="value.paamies.id">
          ({{ value.paamies.id }})
        </template>
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        Laskupäivä
      </template>
      <template
        v-if="value.laskupaiva"
        v-slot:value
      >
        {{ $date(value.laskupaiva) }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        Viite
      </template>
      <template
        v-if="value.viite"
        v-slot:value
      >
        {{ value.viite }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.pm_viite">
      <template v-slot:label>
        PM-viite
      </template>
      <template v-slot:value>
        {{ value.pm_viite }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.viitteemme">
      <template v-slot:label>
        Viitteemme
      </template>
      <template v-slot:value>
        {{ value.viitteemme }}
      </template>
    </DefListItem>
    <DefListItem v-if="value.viitteenne">
      <template v-slot:label>
        Viitteenne
      </template>
      <template v-slot:value>
        {{ value.viitteenne }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        Eräpäivä
      </template>
      <template
        v-if="value.erapaiva"
        v-slot:value
      >
        <strong v-if="value.onko_myohassa">
          {{ $date(value.erapaiva) }} (myöhässä)
        </strong>
        <template v-else>
          {{ $date(value.erapaiva) }}
        </template>
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        Summa
      </template>
      <template v-slot:value>
        {{ value.summa | formatSumToFixed2 }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        Veroton
      </template>
      <template v-slot:value>
        {{ value.veroton | formatSumToFixed2 }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        Vero
      </template>
      <template v-slot:value>
        {{ value.vero | formatSumToFixed2 }}
      </template>
    </DefListItem>
    <DefListItem>
      <template v-slot:label>
        Avoinna
      </template>
      <template v-slot:value>
        <strong>{{ value.avoinna | formatSumToFixed2 }}</strong>
      </template>
    </DefListItem>
  </DefList>
</template>

<script>

import StatusText from '@/components/StatusText'
import TrafiLupaMixin from '@/mixins/TrafiLupaMixin'

export default {
  name: 'OffCanvasDetailsLasku',
  components: {
    StatusText,
  },
  mixins: [TrafiLupaMixin],
  props: {
    value: {
      type: Object,
      required: false,
      default () {
        return {}
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
