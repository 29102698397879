var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.titleText
        ? _c("h3", { class: _vm.titleClass }, [
            _vm._v(" " + _vm._s(_vm.titleText) + " "),
          ])
        : _vm._e(),
      _c("v-data-table", {
        attrs: {
          items: _vm.sopimus.yhteenvetorivit,
          headers: _vm.headers,
          "hide-default-footer":
            !_vm.sopimus.yhteenvetorivit ||
            _vm.sopimus.yhteenvetorivit.length <= 10,
          "no-data-text": _vm.$t("common:noRows"),
          dense: _vm.tiivisNakyma,
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("TableRow", [
                  _c("td", [_vm._v(_vm._s(item.selite))]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatSumToFixed2")(item.laskutettu)) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatSumToFixed2")(item.suoritettu)) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatSumToFixed2")(
                            _vm.sopimus.onko_lainasopimus
                              ? item.avoinna
                              : item.laskutettu_avoinna
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "body.append",
            fn: function () {
              return [
                _c("TableRow", [
                  _c("td", [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("common:total")))]),
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatSumToFixed2")(_vm.sopimus.laskutettu)
                        )
                      ),
                    ]),
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatSumToFixed2")(
                            _vm.sopimus.laskutettu -
                              (_vm.sopimus.avoinna_kaikki -
                                _vm.sopimus.laskuttamatta)
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatSumToFixed2")(
                            _vm.sopimus.onko_lainasopimus
                              ? _vm.sopimus.avoinna_kaikki
                              : _vm.sopimus.avoinna - _vm.sopimus.laskuttamatta
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }