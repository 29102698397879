<template>
  <div class="px-6 pt-4 primary">
    <v-tabs
      v-model="currentTab"
      background-color="primary"
      color="grey lighten-4"
      slider-color="transparent"
      show-arrows="mobile"
    >
      <v-tab
        v-for="item in tabs"
        :key="item.index"
        :href="`#${item.id}`"
        :ripple="(false)"
      >
        <p :style="styleClass">{{ item.label }}</p>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>

export default {
  name: 'ViewSelaaTabs',
  props: {
    vuexModuleName: {
      type: String,
      required: true,
    },
    styleClass: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    currentTab: {
      get () {
        return this.$store.state[this.vuexModuleName].currentTab
      },
      set (val) {
        this.$store.commit(`${this.vuexModuleName}/setCurrentTab`, val)
      }
    },
    tabs () {
      return this.$store.state[this.vuexModuleName].tabs
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
